import React from 'react';
import ReactPaginate from 'react-paginate';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import Loader from 'react-loader-spinner';
import { Trans, t } from '@lingui/macro';
import { I18n, i18nMark} from "@lingui/react";
import '../Landing/Groups.scss';
import AddButton from'../Landing/Add.png';
import {AccountConsumer} from"../AccountContext";
import './Jobs.scss';
import AuthService from '../AuthService';
import { Row, Col, UncontrolledTooltip } from 'reactstrap';
import AssetForm  from './assetForm';
import { printConsole } from '../helpers';
import { encryptData } from '../helpers';
import downloadAssetQrCode from "./assetQrPdf";
import QRPDF from "./hw_qr_pdf";

class Asset extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            allAssetData:[], assetData: [], assetSearch: [], selectedasset: {},
            isLoading: false,
            pageCount: 0, assPageCount: 0, pagination: { currentPage: 1, perPage: props.maxPerPage ? props.maxPerPage : 10 }, //TP-2883
            jobPagination: { currentPage: 1, perPage: props.maxPerPage ? props.maxPerPage : 10 }, /**TP-3260*/
            action: '',
            showAssetForm: false,
            retCode: 400, errStrReturned: '', bSubmitted: false, bResponded: false,
            showJobList: false,
            associatedJob: [], assJobCurrent: [], /**TP-3260*/ smartImageTasks: []
        }
        this.authService = new AuthService();
    }

    componentDidMount = () => {
        this.fetchAsset();
    }

    componentWillReceiveProps(nextProps){
        /**TP-2883 */
        if(nextProps.maxPerPage !== this.props.maxPerPage) {
            this.setState({ pagination: { currentPage: 1/* this.state.pagination.currentPage */, perPage: nextProps.maxPerPage }}, () => {
                const {perPage} = this.state.pagination;
                this.setState({
                    pageCount: Math.ceil(this.state.total / perPage)                    
                }, () => {
                    this.getAssetCurrentPage();                    
                })
            });
            //TP-3260
            if (this.state.showJobList === true) {
                this.setState({ jobPagination: { currentPage: 1, perPage: nextProps.maxPerPage }}, () => {
                    const {perPage} = this.state.jobPagination;
                    this.setState({
                        assPageCount: Math.ceil(this.state.totalAssociatedJob / perPage)                        
                    }, () => {
                        this.getAssJobCurrentPage();                    
                    })
                });
            }
        }
    }

    changeAssetView = ({action, showAssetForm, selectedAsset}) => {
        this.setState({
            action,
            showAssetForm,
            selectedAsset
        })
    }

    deleteAsset = (e) => {
        if (e) e.preventDefault();
        const { pagination: {perPage}, selectedAsset, showJobList } = this.state;
        this.setState({ 
            isLoading: true, bSubmitted: true, action: '',
        });

        let fetchString = 'jobs/deleteAsset/';

        this.authService.fetch(fetchString, {
            method: 'post',
            body: JSON.stringify({
                asset_id: selectedAsset.id,
                account_id: this.props.accountId,
                delink_jobs: showJobList
            })
        })
        .then(response => response.json())
        .then(data => {
            if(data !== undefined && data !== null){
                let allAssetData = this.state.allAssetData.filter((a) => a.id !== data.id);
                let total = allAssetData.length;
                this.setState({ allAssetData, isLoading: false, action: '', bSubmitted: false,
                    total: total, pageCount: Math.ceil(total / perPage), showJobList: false,  associatedJob: []
                },() => this.getAssetCurrentPage());
            }
        })
        .catch(async err => {
            if(err.status === 405){
                const {jobs: associatedJob, smartImageTasks} = await err.json();
                this.setState({ isLoading: false, bResponded: true, bSubmitted: false,
                    retCode: err.status, showJobList: true, associatedJob, smartImageTasks,
                    totalAssociatedJob: associatedJob.length, assPageCount: Math.ceil(associatedJob.length / perPage)
                },() => this.getAssJobCurrentPage()); //TP-3260
            }else{
                this.setState({ isLoading: false, bResponded: true, bSubmitted: false,
                    errStrReturned: i18nMark('Failed to process request'),
                    retCode: err.status, action: 'message'
                });
            }
            printConsole(`error in deleteAsset ===========> `)
            printConsole(err);
        })
    }

    fetchAsset = () => {
        console.log("fetc assets");
        const { perPage } = this.state.pagination;
        this.setState({ 
            isLoading: true
        });

        let fetchString = 'jobs/getAssetsOfAccount/';
        this.authService.fetch(fetchString, {
            method: 'get'
        })
        .then(response => response.json())
        .then(data => {
            if(data !== undefined && data !== null){
                let allAssetData = data;
                let total = allAssetData.length;
                this.setState({ allAssetData, isLoading: false,
                    total: total, pageCount: Math.ceil(total / perPage)
                },() => this.getAssetCurrentPage());
            }
        })
        .catch(err => {
            this.setState({ isLoading: false });
            printConsole(`error in fetchAsset ===========> ${JSON.stringify(err)}`)
        })
    }

    getAssetCurrentPage = () => {
        const { pagination: {perPage, currentPage}, allAssetData, } = this.state;
        let startIndex = Math.ceil(perPage*(currentPage-1));
        let endIndex = Math.ceil(perPage*(currentPage-1)) + perPage;
        let assetData = allAssetData.slice(startIndex, endIndex);
        this.setState({assetData})
    }

    handlePaginationClick = (data) => {
        let selected = data.selected;
        const { pagination } = this.state;
        this.setState({ 
            pagination: {
                ...pagination,
                currentPage: selected + 1
            }
        }, () => {
            this.getAssetCurrentPage();
        });
    };

    //TP-3260
    getAssJobCurrentPage = () => {
        const { jobPagination: {perPage, currentPage}, associatedJob } = this.state;
        let startIndex = Math.ceil(perPage*(currentPage-1));
        let endIndex = Math.ceil(perPage*(currentPage-1)) + perPage;
        let assJobCurrent = associatedJob.slice(startIndex, endIndex);
        this.setState({assJobCurrent});
    }

    //TP-3260
    handleJobPaginationClick = (data) => {
        let selected = data.selected;
        const { jobPagination } = this.state;
        this.setState({
            jobPagination: {
                ...jobPagination,
                currentPage: selected + 1
            }
        }, () => {
            this.getAssJobCurrentPage();
        })
    }

    showDataLoader = () => {
        const { isLoading } = this.state;
        if (isLoading) {
            return ( <div className="loader">
                    <Loader
                        type="ThreeDots"
                        color="#23c97d"
                        height="70" 
                        width="70"
                    />
                </div> )
        } else {
            return '';
        }        
    }
    
    closeDeleteAssetDialog = (e) => {
        if(e) e.preventDefault();
        this.setState({
            action: ''
        });
    };

    onCloseJobList = () => {
        this.setState({
            showJobList: false
        });
    }

    onClickAssetQR = async (asset) => {
        try{
            const {customer} = this.props, domain = window.location.hostname;
            const qrcodeStr = `${asset.id}:::${asset.asset_name}:::${domain}:::${asset.created_on}`;
            let encryptedQrCode = encryptData(qrcodeStr);
            await downloadAssetQrCode({
                name: asset.asset_name, 
                qrCode: encryptedQrCode, 
                company_name: customer.company_name,
                account_id: customer.account_id,
                env: (window._env_.REACT_APP_ENV === 'production') ? '' : domain.substring(0, 3).toUpperCase()
            });
        }catch(error){
            printConsole(`onClickAssetQR error ${error}`)
        }
    }

    //TP-6640,TP-6830
    downloadQRCode = async (row, type) => {
        try{
            const {customer} = this.props;
            const domain = window.location.hostname;
            const obj = {uid: row.id, "room": row.asset_name, "createdOn": new Date(), type}
            await QRPDF({
                name: row.asset_name, 
                qrCode: encryptData(JSON.stringify(obj)), 
                company_name: customer.company_name,
                account_id: customer.account_id,
                env: (window._env_.REACT_APP_ENV === 'production') ? '' : domain.substring(0, 3).toUpperCase(),
                type
            });
        }catch(error){
            printConsole(`error in downloadQRCode ${error}`)
        }
    }

    assetList = () =>{
        let { currentPage, perPage } = this.state.pagination;
        const { assetData } = this.state;
        const { isexpert, isadmin, isSmallFormFactor, customer } = this.props; 

            return(<>
                <div className="flex-space-between">
                    {isexpert || isadmin ? 
                        <div>
                            <I18n>
                                {({ i18n }) =>
                                    <>
                                        <input type="image" 
                                        id="addAsset"
                                        src={AddButton} 
                                        className="btn-green-add"
                                        alt={i18n._(t`Add Asset`)} 
                                        onClick={() => this.changeAssetView({action:'add', showAssetForm: true})}/>
                                        { isSmallFormFactor === false ?
                                            <UncontrolledTooltip placement="right" target="addAsset">
                                                <Trans>Click to add a new Asset</Trans>
                                            </UncontrolledTooltip>
                                            :
                                            ''
                                        }
                                    </>
                                }
                            </I18n>
                        </div>
                        : ''
                    }
                    
                </div>
                <div className="table-responsive">
                    <table id="usersTable">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th id="name">
                                    <Trans>Name</Trans>
                                </th>
                                <th><Trans>Description</Trans></th>
                                <th >
                                    <Trans>Asset Class</Trans>
                                </th>
                                <th >
                                    <Trans>Owner</Trans>
                                </th>
                                <th >
                                    <Trans>Location</Trans>
                                </th>
                                <th>
                                    <Trans>Asset Id</Trans>
                                </th>
                                <th style={{width:'8%'}}><Trans>Actions</Trans></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                (assetData && assetData.length > 0) ? 
                                assetData.map((row, index) => { 
                                        return (
                                            <tr id={'row-'+index} key={index}>
                                                <td>{((currentPage-1)*perPage)+(index + 1)}</td>
                                                <td>{row.asset_name.length> 50 ? row.asset_name.substring(0,50) + "..." : row.asset_name}</td>
                                                <td>{row.asset_description? row.asset_description.length> 50 ? row.asset_description.substring(0,50) + "..." : row.asset_description: row.asset_description}</td>
                                                <td>{row.asset_class_name}</td>
                                                <td>{row.author_name}</td>
                                                <td>{row.asset_installed_at}</td>
                                                <td>{row.id}</td>
                                                <td className="actions">
                                                    <I18n>
                                                        {({ i18n }) =>
                                                            <>
                                                                <span 
                                                                    /*TP-2753 -- data-rh={i18n._(t`Edit Asset `)}  */
                                                                    className="icon-wrapper pointer" 
                                                                    onClick={() => this.changeAssetView({action: 'edit', showAssetForm:true, selectedAsset: row})}>
                                                                    { isSmallFormFactor === false ?
                                                                        <UncontrolledTooltip placement="top" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target={'asedit' + index}>
                                                                            <Trans>Edit Asset </Trans>
                                                                        </UncontrolledTooltip> 
                                                                        :
                                                                        ''
                                                                    }
                                                                    <i className="fas fa-edit" id={'asedit' + index}></i>
                                                                </span>
                                                                {
                                                                    customer.behaviour === "honeywell"?
                                                                        <>
                                                                            <span id={"icon-qrcode-entry-" + index} className="icon-wrapper pointer float-right" onClick={() => { this.downloadQRCode(row, "entry") }}>
                                                                                <i className="fas fa-qrcode"></i>                                
                                                                            </span>
                                                                            { isSmallFormFactor === false ?
                                                                                    <UncontrolledTooltip placement="top"  target={"icon-qrcode-entry-" + index}>
                                                                                        {
                                                                                            <Trans>Download Entry QR Code</Trans>
                                                                                        }
                                                                                    </UncontrolledTooltip>
                                                                                :
                                                                                    null
                                                                            }
                                                                        
                                                                            <span id={"icon-qrcode-exit-" + index} className="icon-wrapper pointer float-right" onClick={() => { this.downloadQRCode(row, "exit") }}>
                                                                                <i className="fas fa-qrcode"></i>                                
                                                                            </span>
                                                                            { isSmallFormFactor === false ?
                                                                                    <UncontrolledTooltip placement="top"  target={"icon-qrcode-exit-" + index}>
                                                                                        {
                                                                                            <Trans>Download Exit QR Code</Trans>
                                                                                        }
                                                                                    </UncontrolledTooltip>
                                                                                :
                                                                                    null
                                                                            }

                                                                        </>
                                                                    :
                                                                        <span className="icon-wrapper pointer" onClick={() => this.onClickAssetQR(row)}>
                                                                            { isSmallFormFactor === false ?
                                                                                <UncontrolledTooltip placement="top" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target={"asset-qrcode-" + index}>
                                                                                    <Trans>Download QR Code</Trans>
                                                                                </UncontrolledTooltip>
                                                                                :
                                                                                ''
                                                                            }
                                                                            <i className="fas fa-qrcode" id={'asset-qrcode-' + index}></i>                                
                                                                        </span>
                                                                }
                                                                <span 
                                                                    /*TP-2753 -- data-rh={i18n._(t`Delete Asset `)}  */
                                                                    className="icon-wrapper pointer" 
                                                                    onClick={() => this.changeAssetView({action: 'delete', selectedAsset: row})}>
                                                                    { isSmallFormFactor === false ?
                                                                        <UncontrolledTooltip placement="top" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target={'asdelete' + index}>
                                                                            <Trans>Delete Asset </Trans>
                                                                        </UncontrolledTooltip>
                                                                        :
                                                                        ''
                                                                    }
                                                                    <i className="far fa-trash-alt" id={'asdelete' + index}></i>
                                                                </span>
                                                            </>
                                                        }
                                                    </I18n>
                                                </td>
                                            </tr>
                                        );
                                    })
                                :(
                                    <tr>
                                        <td colSpan={8} style={{ textAlign: 'center' }}><Trans id={'No data found'}/></td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                </div>
                <div className="pagination-wrapper d-flex justify-content-end">
                    <I18n>
                        {({ i18n }) => 
                            <ReactPaginate
                                previousLabel={i18n._(t`previous`)}
                                nextLabel={i18n._(t`next`)}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={this.state.pageCount}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={10}
                                forcePage={this.state.pagination.currentPage -1}
                                onPageChange={this.handlePaginationClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-item'}
                                subContainerClassName={'pages pagination'}
                                pageLinkClassName={'page-link'}
                                disabledClassName={'disabled'}
                                activeClassName={'active'}
                                previousClassName={'page-item'}
                                nextClassName={'page-item'}
                                previousLinkClassName={'page-link'}
                                nextLinkClassName={'page-link'}
                                breakLinkClassName={'page-link'}
                            />
                        }
                    </I18n>
                </div>
                {this.showDataLoader()}
            </>);
    }

    jobList = () => {
        /**TP-3260 */
        const { assJobCurrent, smartImageTasks, jobPagination: { perPage } } = this.state;//TP-3716
        const { isSmallFormFactor, isMobileDevice } = this.props;
        const header = document.getElementById("header_bar");
        const areaHeight = Math.round(window.innerHeight - header.offsetHeight - 75) + "px";
        const isrelative = assJobCurrent.length <= perPage - 3 || isMobileDevice ? true : false;
        return (
            <div className='justify-content-center align-items-center centering' style={{height: areaHeight}}>
				<div className='relative' style={isrelative ? {height: areaHeight} : {}}>
                    <div className="formheader flex-center row width-header">
                        { isSmallFormFactor === true ?
                            <h4 >
                                <Trans>Following jobs are associated with the asset</Trans>
                            </h4>
                            :
                            <h2 >
                                <Trans>Following jobs are associated with the asset</Trans>
                            </h2>
                        }
                    </div>
                    <div className="formbody form-text-color ">
                        <div className="table-responsive mt-4">
                            <table id="assJobsTable">
                                <thead>
                                    <tr>
                                        <th>
                                            <Trans>Job Name</Trans>
                                        </th>
                                        <th><Trans>Description</Trans></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        assJobCurrent.map((row, index) => { 
                                            return (
                                                <tr key={index}>
                                                    <td>{row.name.length> 50 ? row.name.substring(0,50) + "..." : row.name}</td>
                                                    <td>{row.description? row.description.length> 50 ? row.description.substring(0,50) + "..." : row.description: row.description}</td>
                                                </tr>
                                            );
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="pagination-wrapper d-flex justify-content-end">
                            <I18n>
                                {({ i18n }) => 
                                    <ReactPaginate
                                        previousLabel={i18n._(t`previous`)}
                                        nextLabel={i18n._(t`next`)}
                                        breakLabel={'...'}
                                        breakClassName={'break-me'}
                                        pageCount={this.state.assPageCount}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={10}
                                        onPageChange={this.handleJobPaginationClick}
                                        containerClassName={'pagination'}
                                        pageClassName={'page-item'}
                                        subContainerClassName={'pages pagination'}
                                        pageLinkClassName={'page-link'}
                                        disabledClassName={'disabled'}
                                        activeClassName={'active'}
                                        previousClassName={'page-item'}
                                        nextClassName={'page-item'}
                                        previousLinkClassName={'page-link'}
                                        nextLinkClassName={'page-link'}
                                        breakLinkClassName={'page-link'}
                                    />
                                }
                            </I18n>
                        </div>
                        <div className="mt-4">
                            {smartImageTasks && smartImageTasks.length > 0 ? <span className='times tm-regular' style={{fontSize: '14px'}}><Trans>Asset cannot be deleted due to Smart Image Procedures</Trans></span> : null}
                            <Row>
                                <Col sm="5" className="d-flex justify-content-end">
                                    <button className="btn-red" onClick={(e) =>this.onCloseJobList(e)}><Trans>Cancel</Trans></button>
                                </Col>
                                <Col sm="2"></Col>
                                {   smartImageTasks && smartImageTasks.length > 0 ?
                                        <Col sm="5" className="d-flex justify-content-left">
                                            <button className="btn-grey" disabled={true}><Trans>Delink All Jobs</Trans></button>
                                        </Col>
                                    :
                                        <Col sm="5" className="d-flex justify-content-left">
                                            <button className="btn-green" onClick={(e) =>this.deleteAsset(e)}><Trans>Delink All Jobs</Trans></button>
                                        </Col>
                                }
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render(){
        let {action} = this.state;        
        let colorText = '#485890';
        if(this.state.retCode !== 200) colorText = 'red';
        return (
            <>
                { /**TP-3260 */ this.state.showJobList? 
                    <>{this.jobList()}</>
                    :
                    this.state.showAssetForm? 
                    <AccountConsumer>
                        {({language}) =>

                            <AssetForm
                                language={language}
                                changeAssetView={this.changeAssetView}
                                accountId={this.props.accountId}
                                fetchAsset={this.fetchAsset}
                                selectedAsset={this.state.selectedAsset}
                                action={this.state.action}
                                email={this.props.email}
                                name={this.props.name}
                                customer={this.props.customer}
                                userid={this.props.userid}
                                isSmallFormFactor={this.props.isSmallFormFactor}
                        />
                    }
                    </AccountConsumer>
                    : 
                    this.assetList()
                }
                {
                    action === 'message' && (this.state.bSubmitted || this.state.bResponded) ?
                        <div className='modale opened'>
                            <div className='__modal-dialog'>
                                <form>
                                    <div className="__modal-header">
                                        <h4 style={{color: colorText}}><Trans id={this.state.errStrReturned}/></h4>
                                    </div>
                                    <div className="__modal-footer flex-center">
                                        <button className="btn-green" onClick={() =>this.changeAssetView({action: '', showJobForm: false})}><Trans id='OK'/></button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    : ''
                }

                {
                    action === 'delete' ?
                    <I18n>

                        {({ i18n }) =>
                            <ConfirmModal 
                                primaryButton={i18n._(t`Delete`)}
                                secondaryButton={i18n._(t`Cancel`)}
                                headerText={i18n._(t`Delete Asset `)}
                                message ={i18n._(t`Are you sure you want to delete asset?`)} /**TP-2753 */
                                onClickSencondary={(e) => this.closeDeleteAssetDialog(e)}
                                onClickPrimary={(e) => this.deleteAsset(e)}
                            />
                        }
                    </I18n>
                    : ''
                }
            </>
        );
    }
}

export default Asset