import React from 'react';
import ReactPaginate from 'react-paginate';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import Loader from 'react-loader-spinner';
import { Trans, t } from '@lingui/macro';
import { I18n , i18nMark} from "@lingui/react";
import '../Landing/Groups.scss';
import AddButton from'../Landing/Add.png';
import {AccountConsumer} from"../AccountContext";
import './Jobs.scss';
import AuthService from '../AuthService';
import { Col, Row, UncontrolledTooltip } from 'reactstrap';
import AssetClassForm  from './assetClassForm';
import { printConsole } from '../helpers';
class AssetClass extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            allAssetClassData:[], assetClassData: [], assetClassSearch: [], selectedAssetClass: {},
            isLoading: false,
            pageCount: 0, assPageCount: 0, pagination: { currentPage: 1, perPage: props.maxPerPage ? props.maxPerPage : 10 }, //TP-2883
            assetPagination: { currentPage: 1, perPage: props.maxPerPage ? props.maxPerPage : 10 }, /**TP-3259 */
            action: '',
            showAssetClassForm: false,
            retCode: 400, errStrReturned: '', bSubmitted: false, bResponded: false,
            showAssetList: false,
            associatedAsset: [], assAssetCurrent: [] /**TP-3259 */
        }
        this.authService = new AuthService();
    }

    componentDidMount = () => {
        this.fetchAssetClass();
    }

    componentWillReceiveProps(nextProps){
        /**TP-2883 */
        if(nextProps.maxPerPage !== this.props.maxPerPage) {
            this.setState({ pagination: { currentPage: 1/* this.state.pagination.currentPage */, perPage: nextProps.maxPerPage }}, () => {
                const {perPage} = this.state.pagination;
                this.setState({
                    pageCount: Math.ceil(this.state.total / perPage)
                }, () => {
                    this.getAssetClassCurrentPage();
                })
            });
            //TP-3259
            if (this.state.showAssetList === true) {
                this.setState({ assetPagination: { currentPage: 1, perPage: nextProps.maxPerPage }}, () => {
                    const {perPage} = this.state.assetPagination;
                    this.setState({
                        assPageCount: Math.ceil(this.state.totalAssociatedAssets / perPage)                        
                    }, () => {
                        this.getAssAssetCurrentPage();                    
                    })
                });
            }
        }
    }

    changeAssetClassView = ({action, showAssetClassForm, selectedAssetClass}) => {
        this.setState({
            action,
            showAssetClassForm,
            selectedAssetClass
        })
    }

    deleteAssetClass = (e) => {
        if (e) e.preventDefault();
        const { pagination: {perPage}, selectedAssetClass, showAssetList } = this.state;
        this.setState({ 
            isLoading: true, bSubmitted: true, action: '',
        });

        let fetchString = 'jobs/deleteAssetClass/';

        this.authService.fetch(fetchString, {
            method: 'post',
            body: JSON.stringify({
                asset_class_id: selectedAssetClass.id,
                account_id: selectedAssetClass.owner,
                delink_assets: showAssetList
            })
        })
        .then(response => response.json())
        .then(data => {
            if(data !== undefined && data !== null){
                let allAssetClassData = this.state.allAssetClassData.filter((a) => a.id !== data.id);
                let total = allAssetClassData.length;
                this.setState({ allAssetClassData, isLoading: false, action: '', bSubmitted: false,
                    total: total, pageCount: Math.ceil(total / perPage), showAssetList: false,  associatedAsset: []
                },() => this.getAssetClassCurrentPage());
            }
        })
        .catch(async err => {
            if(err.status === 405){
                const associatedAsset = await err.json();
                this.setState({ isLoading: false, bResponded: true, bSubmitted: false,
                    retCode: err.status, showAssetList: true, associatedAsset,
                    totalAssociatedAssets: associatedAsset.length, assPageCount: Math.ceil(associatedAsset.length / perPage)
                },() => this.getAssAssetCurrentPage()); //TP-3259
            }else{
                this.setState({ isLoading: false, bResponded: true, bSubmitted: false,
                    errStrReturned: i18nMark('Failed to process request'),
                    retCode: err.status, action: 'message'
                });
            }
            printConsole(`error in deleteAssetClass ===========>`);
            printConsole(err);
        })
    }

    fetchAssetClass = () => {
        const { perPage } = this.state.pagination;
        this.setState({ 
            isLoading: true
        });

        let fetchString = 'jobs/getAssetClass/';
        this.authService.fetch(fetchString, {
            method: 'get'
        })
        .then(response => response.json())
        .then(data => {
            if(data !== undefined && data !== null){
                let allAssetClassData = data;
                let total = allAssetClassData.length;
                this.setState({ allAssetClassData, isLoading: false,
                    total: total, pageCount: Math.ceil(total / perPage)
                },() => this.getAssetClassCurrentPage());
            }
        })
        .catch(err => {
            this.setState({ isLoading: false });
            printConsole(`error in fetchAssetClass ===========> ${JSON.stringify(err)}`)
        })
    }

    getAssetClassCurrentPage = () => {
        const { pagination: {perPage, currentPage}, allAssetClassData, } = this.state;
        let startIndex = Math.ceil(perPage*(currentPage-1));
        let endIndex = Math.ceil(perPage*(currentPage-1)) + perPage;
        let assetClassData = allAssetClassData.slice(startIndex, endIndex);
        this.setState({assetClassData})
    }

    handlePaginationClick = (data) => {
        let selected = data.selected;
        const { pagination } = this.state;
        this.setState({ 
            pagination: {
                ...pagination,
                currentPage: selected + 1
            }
        }, () => {
            this.getAssetClassCurrentPage();
        });
    };

    //TP-3259
    getAssAssetCurrentPage = () => {
        const { assetPagination: {perPage, currentPage}, associatedAsset } = this.state;
        let startIndex = Math.ceil(perPage*(currentPage-1));
        let endIndex = Math.ceil(perPage*(currentPage-1)) + perPage;
        let assAssetCurrent = associatedAsset.slice(startIndex, endIndex);
        this.setState({assAssetCurrent});
    }

    //TP-3259
    handleAssetPaginationClick = (data) => {
        let selected = data.selected;
        const { assetPagination } = this.state;
        this.setState({
            assetPagination: {
                ...assetPagination,
                currentPage: selected + 1
            }
        }, () => {
            this.getAssAssetCurrentPage();
        })
    }

    showDataLoader = () => {
        const { isLoading } = this.state;
        if (isLoading) {
            return ( <div className="loader">
                    <Loader
                        type="ThreeDots"
                        color="#23c97d"
                        height="70" 
                        width="70"
                    />
                </div> )
        } else {
            return '';
        }        
    }
    
    closeDeleteAssetClassDialog = (e) => {
        if(e) e.preventDefault();
        this.setState({
            action: ''
        });
    };

    onCloseAssetList = () => {
        this.setState({
            showAssetList: false
        });
    }

    assetClassList = () =>{
        let { currentPage, perPage } = this.state.pagination;
        const { assetClassData } = this.state;
        const { isexpert, isadmin, isSmallFormFactor} = this.props; 

            return(<>
                <div className="flex-space-between">
                    {isexpert || isadmin? 
                        <div>
                            <I18n>
                                {({ i18n }) =>
                                    <>
                                        <input type="image" 
                                        id="addAssetClass"
                                        src={AddButton} 
                                        className="btn-green-add"
                                        alt={i18n._(t`Add Asset Class`)} 
                                        onClick={() => this.changeAssetClassView({action:'add', showAssetClassForm: true})}/>
                                        { isSmallFormFactor === false ?
                                            <UncontrolledTooltip placement="right" target="addAssetClass">
                                                <Trans>Click to add a new Asset Class</Trans>
                                            </UncontrolledTooltip>
                                            :
                                            ''
                                        }
                                    </>
                                }
                            </I18n>
                        </div>
                        : ''
                    }
                    
                </div>
                <div className="table-responsive">
                    <table id="usersTable">{/**TP-6025*/}
                        <thead>
                            <tr>
                                <th>#</th>
                                <th id="name">
                                    <Trans>Name</Trans>
                                </th>
                                <th><Trans>Description</Trans></th>
                                <th >
                                    <Trans>Manufacturer</Trans>
                                </th>
                                <th >
                                    <Trans>Model</Trans>
                                </th>
                                <th >
                                    <Trans>Access</Trans>
                                </th>
                                <th style={{width:'5%'}}><Trans>Actions</Trans></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                (assetClassData && assetClassData.length > 0) ? 
                                assetClassData.map((row, index) => { 
                                        return (
                                            <tr id={'row-'+index} key={index}>
                                                <td>{((currentPage-1)*perPage)+(index + 1)}</td>
                                                <td>{row.name.length> 50 ? row.name.substring(0,50) + "..." : row.name}</td>
                                                <td>{row.description? row.description.length> 50 ? row.description.substring(0,50) + "..." : row.description: row.description}</td>
                                                <td>{row.manufacturer}</td>
                                                <td>{row.model}</td>
                                                <td>{/**TP-2753 */}<Trans id={row.access}></Trans></td>
                                                <td className="actions">
                                                    <I18n>
                                                        {({ i18n }) =>
                                                            <>
                                                                <span 
                                                                    /* TP-2753 --data-rh={i18n._(t`Edit Asset Class`)}  */
                                                                    className="icon-wrapper pointer" 
                                                                    onClick={() => this.changeAssetClassView({action: 'edit', showAssetClassForm:true, selectedAssetClass: row})}>
                                                                    { isSmallFormFactor === false ?
                                                                        <UncontrolledTooltip placement="top" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target={'acedit' + index}>
                                                                            <Trans>Edit Asset Class</Trans>
                                                                        </UncontrolledTooltip> 
                                                                        :
                                                                        ''
                                                                    }
                                                                    <i className="fas fa-edit" id={'acedit' + index}></i>
                                                                </span>
                                                                <span 
                                                                    /* TP-2753 -- data-rh={i18n._(t`Delete Asset Class`)} */ 
                                                                    className="icon-wrapper pointer" 
                                                                    onClick={() => this.changeAssetClassView({action: 'delete', selectedAssetClass: row})}>
                                                                    { isSmallFormFactor === false ?
                                                                        <UncontrolledTooltip placement="top" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target={'acdelete' + index}>
                                                                            <Trans>Delete Asset Class</Trans>
                                                                        </UncontrolledTooltip> 
                                                                        :
                                                                        ''
                                                                    }
                                                                    <i className="far fa-trash-alt" id={'acdelete' + index}></i>
                                                                </span>
                                                            </>
                                                        }
                                                    </I18n>
                                                </td>
                                            </tr>
                                        );
                                    })
                                :(
                                    <tr>
                                        <td colSpan={7} style={{ textAlign: 'center' }}><Trans id={'No data found'}/></td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                </div>
                <div className="pagination-wrapper d-flex justify-content-end">
                    <I18n>
                        {({ i18n }) => 
                            <ReactPaginate
                                previousLabel={i18n._(t`previous`)}
                                nextLabel={i18n._(t`next`)}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={this.state.pageCount}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={10}
                                forcePage={this.state.pagination.currentPage -1}
                                onPageChange={this.handlePaginationClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-item'}
                                subContainerClassName={'pages pagination'}
                                pageLinkClassName={'page-link'}
                                disabledClassName={'disabled'}
                                activeClassName={'active'}
                                previousClassName={'page-item'}
                                nextClassName={'page-item'}
                                previousLinkClassName={'page-link'}
                                nextLinkClassName={'page-link'}
                                breakLinkClassName={'page-link'}
                            />
                        }
                    </I18n>
                </div>
                {this.showDataLoader()}
            </>);
    }

    assetList = () => {
        /**TP-3259 */
        const { assAssetCurrent, assetPagination: {perPage} } = this.state;//TP-3716
        const { isSmallFormFactor, isMobileDevice } = this.props;
        const header = document.getElementById("header_bar");
        const areaHeight = Math.round(window.innerHeight - header.offsetHeight - 80) + "px";
        const isrelative = assAssetCurrent.length <= perPage - 3 || isMobileDevice ? true : false;
        return (
            <div className='justify-content-center align-items-center centering' style={{height: areaHeight}}>
				<div className='relative' style={isrelative ? {height: areaHeight} : {}}>
                    <div className="formheader flex-center row width-header">
                        { isSmallFormFactor === true ?
                            <h4>
                                <Trans>Following assets are associated with the asset class</Trans>
                            </h4>
                            :
                            <h2>
                                <Trans>Following assets are associated with the asset class</Trans>
                            </h2>
                        }
                    </div>
                    <div className="formbody form-text-color ">
                        <div className="table-responsive mt-4" style={{color: 'white'}}>
                            <table id="assAssetsTable">
                                <thead>
                                    <tr>
                                        <th>
                                            <Trans>Asset Name</Trans>
                                        </th>
                                        <th><Trans>Description</Trans></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        assAssetCurrent.map((row, index) => { 
                                            return (
                                                <tr key={index}>
                                                    <td>{row.asset_name.length> 50 ? row.asset_name.substring(0,50) + "..." : row.asset_name}</td>
                                                    <td>{row.asset_description? row.asset_description.length> 50 ? row.asset_description.substring(0,50) + "..." : row.asset_description: row.asset_description}</td>
                                                </tr>
                                            );
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="pagination-wrapper d-flex justify-content-end">
                            <I18n>
                                {({ i18n }) => 
                                    <ReactPaginate
                                        previousLabel={i18n._(t`previous`)}
                                        nextLabel={i18n._(t`next`)}
                                        breakLabel={'...'}
                                        breakClassName={'break-me'}
                                        pageCount={this.state.assPageCount}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={10}
                                        onPageChange={this.handleAssetPaginationClick}
                                        containerClassName={'pagination'}
                                        pageClassName={'page-item'}
                                        subContainerClassName={'pages pagination'}
                                        pageLinkClassName={'page-link'}
                                        disabledClassName={'disabled'}
                                        activeClassName={'active'}
                                        previousClassName={'page-item'}
                                        nextClassName={'page-item'}
                                        previousLinkClassName={'page-link'}
                                        nextLinkClassName={'page-link'}
                                        breakLinkClassName={'page-link'}
                                    />
                                }
                            </I18n>
                        </div>
                        <div className="mt-4">
                            <Row>
                                <Col sm="12" className="d-flex justify-content-center">
                                    <button className="btn-red" onClick={(e) =>this.onCloseAssetList(e)}><Trans>Cancel</Trans></button>
                                </Col>
                                {/* <Col sm="2"></Col>//TP-4487
                                <Col sm="5" className="d-flex justify-content-left">
                                    <button className="btn-green" onClick={(e) =>this.deleteAssetClass(e)}><Trans>Delink All Assets</Trans></button>
                                </Col> */}
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render(){
        let {action} = this.state;        
        let colorText = '#485890';
        if(this.state.retCode !== 200) colorText = 'red';

        return (
            <>
                {/**TP-3259 */this.state.showAssetList? 
                    <>{this.assetList()}</>
                    :
                    this.state.showAssetClassForm? 
                    <AccountConsumer>
                        {({language}) =>

                            <AssetClassForm
                                language={language}
                                changeAssetClassView={this.changeAssetClassView}
                                accountId={this.props.accountId}
                                fetchAssetClass={this.fetchAssetClass}
                                selectedAssetClass={this.state.selectedAssetClass}
                                action={this.state.action}
                                email={this.props.email}
                                name={this.props.name}
                                customer={this.props.customer}
                                userid={this.props.userid}
                                isSmallFormFactor={this.props.isSmallFormFactor}
                        />
                    }
                    </AccountConsumer>
                    : 
                    this.assetClassList()
                }

                {
                    action === 'message' && (this.state.bSubmitted || this.state.bResponded) ?
                        <div className='modale opened'>
                            <div className='__modal-dialog'>
                                <form>
                                    <div className="__modal-header">
                                        <h4 style={{color: colorText}}><Trans id={this.state.errStrReturned}/></h4>
                                    </div>
                                    <div className="__modal-footer flex-center">
                                        <button className="btn-green" onClick={() =>this.changeAssetClassView({action: '', showJobForm: false})}><Trans id='OK'/></button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    : ''
                }

                {
                    action === 'delete' ?
                    <I18n>

                        {({ i18n }) =>
                            <ConfirmModal 
                                primaryButton={i18n._(t`Delete`)}
                                secondaryButton={i18n._(t`Cancel`)}
                                headerText={i18n._(t`Delete Asset Class`)}
                                message ={i18n._(t`Are you sure you want to delete asset class ?`)} /**TP-2795 */
                                onClickSencondary={(e) => this.closeDeleteAssetClassDialog(e)}
                                onClickPrimary={(e) => this.deleteAssetClass(e)}
                            />
                        }
                    </I18n>
                    : ''
                }
            </>
        );
    }
}

export default AssetClass