import React from 'react';
import { Form, Row, Col, Card, CardBody, UncontrolledTooltip } from 'reactstrap';
import { Trans, t } from '@lingui/macro';
import { I18n,i18nMark } from "@lingui/react";
import '../Landing/Groups.scss';
import DatePicker from "react-datepicker";
import SimpleReactValidator from 'simple-react-validator';
import _ from 'lodash';
import Select from 'react-select';
import makeAnimated from 'react-select/lib/animated';
import moment from 'moment';
import CheckListTasks from './checkListTasks';//TP-2479
import Loader from '../Loader/Loader';
import FlashMessage from '../FlashMessage/FlashMessage';
import { printConsole, getWeekdayCountBetweenDates, getMonthCountBetweenDates } from '../helpers';
import { nanoid } from 'nanoid';
import Scheduler from './scheduler';

class CheckListForms extends React.Component{
    constructor(props){
        super(props);
        this.authService = props.authService;
        /**TP-3222 */
        this.cardColor = {
            created: 'bg-created',
            assigned: 'bg-assigned',
            in_progress: 'bg-in-progress',
            finished: 'bg-finished',
            skipped: 'bg-finished'
        };
        this.state = {
            isLoading: false, retCode: 400, errStrReturned: '',	bSubmitted: false, bResponded: false,
            selectedJob:{accountId: props.accountId, reference_id: nanoid(), asset_id: 0,
                technician_id: !props.customer.mandatory_assignee_jobs && (props.customer.enable_asset_class !== "disabled" && props.isadmin || 
                (props.isexpert && ((props.enable_asset && props.customer.enable_asset_class === "enabled_on_demand") || props.customer.enable_asset_class === "enabled_for_all" ))) ? 0 : null, //TP-2721
                job_type: 'checklist'
            },
            groupUsers:[], selectedUser: {},
            selectedTechnician: '', pastTechnician: '',
            selectedExpert: '', accountExperts: [],
            startDate: '', endDate: '',
            unFormatedStartDate: '', unFormatedEndDate: '',
            isJobExists: false, isJobReferenceIdExists: false,
            checkListTasks: [], //TP-3222
            themes: [{name: 'Yes/No/NA', id: 1}, {name: 'Pass/Fail/NA', id: 1}],
            unformattedCheckListTasks:[],
            isAddProcedure: true,
            isJobLoading: true,
            flashMsgText: "",
            showFlashMessage: false,
            flashLeft: "",
            flashTop: "",
            loadingText: "Loading",
            allAssetData:[], selectedAsset: '', copyOfAllAssets: [],
            action: props.action,
            bShowSubTask: false, //TP-3222
            index: 0 //TP-3222
        }

		// initialize validator
        SimpleReactValidator.addLocale(props.language, require(`../../locales/${props.language}/messages.json`));
		this.validator = new SimpleReactValidator({
            locale: props.language,
            autoForceUpdate: this,
            element: message => <div className="job-form-validation-msg">{message.replace(/field|The|[0-9]/g, '').capitalize()}</div>,
            validators: {
                maxCharLength: {
                    rule: (val, params, validator) => {
                      return (val.length <= parseInt(params[0]))
                    }
                },
                minCharLength: {
                    rule: (val, params, validator) => {
                      return (val.length >= parseInt(params[0]))
                    }
                },                
                checkStartDate: {
                    message: 'Start date cannot be less than todays date.',
                    rule: (val, params, validator) => {
                        if(props.action !== 'edit_checklist'){
                            return new Date(val) >= new Date();//TP-4898
                        }else{
                            return true;
                        }
                    },
                },
                beforEndDate: {
                    message: 'Start date cannot be greater than end date.',
                    rule: (val, params, validator) => {
                        return new Date(val) <= this.state.unFormatedEndDate;//TP-4898
                    },
                },
                checkEditStartDate:{
                    message: 'Cannot be less than existing start date.',
                    rule: (val, params, validator) => {
                        if(props.action !== 'edit_checklist'){
                            return true;
                        }else{
                            return new Date(val) >= new Date(props.selectedJob.start_date);//TP-4898
                        }
                    },
                },
                afterStartDate: {
                    message: 'End date cannot be less than start date.',
                    rule: (val, params, validator) => {
                        return new Date(val) >= this.state.unFormatedStartDate;//TP-4898
                    },
                },
                afterStartDateForScheduler: {
                    message: 'Cannot be less than start date.',
                    rule: (val, params, validator) => {
                        return new Date(val) >= this.state.unFormatedStartDate;//TP-4898
                    },
                },
                alpha_num_dash_underscore: {
                    rule: (val, params, validator) => {
                        const regex = /^[A-Za-z0-9_-]+$/g;
                        return validator.helpers.testRegex(
							val,
							regex
						);
                    }
                },
                check_technician_asset: {
                    rule: (val, params, validator) => {
                        return (this.state.selectedJob.technician_id !==0 || this.state.selectedJob.asset_id !== 0)
                    },
                    messageReplace: (message, params) => message.replace('{role}', params[0]) /**TP-6723*/
                },
                checkInterval: {
                    message: 'Interval is not valid',
                    rule: (val, params, validator) => {
                      return (val >= 1)
                    }
                },
            }
		});
        
    }

    componentDidMount(){
        this.setState(() => ({
            unFormatedStartDate: new Date(),
            unFormatedEndDate: new Date((new Date()).setDate((new Date()).getDate() +1)),
            startDate: new Date(),// MB2-175
            endDate: new Date((new Date()).setDate((new Date()).getDate() +1)),// MB2-175 time stamp also needs to be there in end date
        }),() => {
            this.fetchAsset();
        });
		this.delayedCallback = _.debounce(this.checkJobExists, 500);
		this.delayedCallbackForReferenceId = _.debounce(this.checkReferenceIdExists, 500);
    }

    //TP-2535
    fetchAsset = () => {
        let fetchString = 'jobs/getAssetsOfAccount/';
        this.authService.fetch(fetchString, {
            method: 'get'
        })
        .then(response => response.json())
        .then(data => {
            if(data !== undefined && data !== null){
                const filteredAssets = data.filter((asset) => asset.asset_class_name)//TP-2580
                this.setState({ allAssetData: filteredAssets, copyOfAllAssets: filteredAssets});
            }
        })
        .catch(err => {
            printConsole(`error in fetchAsset ===========> ${JSON.stringify(err)}`)
        })
        .finally(() => {
            if(this.props.action=== 'add_checklist' || this.props.action === 'edit_checklist' || this.props.action === 'clone_checklist_job'){
                this.fetchAllTech()
            }else{
                this.fetchAllExpert();
            }
            this.fetchAllThemes();//TP-3520
        })
    }

    fetchAllTech = () => {
        let fetchString = 'getusersByGroups/';
        this.props.authService.fetch(fetchString, {
            method: 'get'
        })
        .then(response => response.json())
        .then(data => {
            if(data){
               this.setState({
                   groupUsers: data,
                });
            }
        })
        .catch(error => {
            this.setState({
                isJobLoading: false, bResponded: true, retCode: error.status, flashLeft: "45%", 
                flashTop: "", flashMsgText: "Failed to process request", showFlashMessage: true
            });
        })
    };

    fetchAllThemes = () => {
        let {selectedJob, action} = this.props;
        let fetchString = 'jobs/getCheckListThemes/';
        this.props.authService.fetch(fetchString, {
            method: 'get'
        })
        .then(response => response.json())
        .then(data => {
            if(data){
                this.setState({
                   themes: data,
                },()=>{
                    if((action === 'edit_checklist' || action === 'edit_checklist_template' || action === 'clone_checklist_job' || action === 'clone_checklist_template') && selectedJob){
                        this.fetchAllCheckListTasks(selectedJob);
                    }else{
                        this.setState({isJobLoading:false});
                    }
                });
            }
        })
        .catch(error => {
            this.setState({
                isJobLoading: false, bResponded: true, retCode: error.status, flashLeft: "45%", 
                flashTop: "", flashMsgText: "Failed to process request", showFlashMessage: true,
            });
        })
    };

    fetchAllCheckListTasks = (selectedJob) => {
        let fetchString = `jobs/getAllCheckListTasks/${selectedJob.id}`;
        this.props.authService.fetch(fetchString, {
            method: 'get'
        })
        .then(response => response.json())
        .then(data => {
            if(data){
                this.setState({
                    unformattedCheckListTasks: data,
                    isJobLoading: false,
                });
            }
        })
        .catch(error => {
            this.setState({
                isJobLoading: false, bResponded: true, retCode: error.status, flashLeft: "45%", 
                flashTop: "", flashMsgText: "Failed to process request", showFlashMessage: true
            });
        })
        .finally(() => {
            this.initialiseJobForEdit(selectedJob);
            this.mapCheckListTasks()
        })
    };

// TP-3520
    fetchAllExpert = () => {
        try{
            let { accountId: account_id, isadmin, userid, customer} = this.props;

            let fetchString = 'getusers/';
            fetchString += account_id;
    
            this.props.authService.fetch(fetchString, {
                method: 'get'
            })
            .then(response => response.json())
            .then(data => {
                if(data !== undefined && data !== null){
                    //only for expert
                    data = data.filter((d) => (d.isexpert && d.enable_job_template) || (d.isexpert && d.isadmin) || (d.isexpert && customer.enable_job_template === "enabled_for_all"))//TP-3635,3738,5239 all expert and admin expert himself
                    this.setState((prevState) => ({accountExperts: data, 
                        selectedJob: {...prevState.selectedJob, job_type: 'checklist_template'},
                        isJobLoading: false
                    }))
                    if(!isadmin){
                        this.setState((prevState) => ({selectedJob: {...prevState.selectedJob, assigned_by: userid}}))
                    }
                }
            })
            .catch(err => {
                this.setState({
                    isJobLoading: false, bResponded: true, retCode: err.status,
                    flashLeft: "45%", flashTop: "", flashMsgText: "Failed to process request", showFlashMessage: true
                });
                printConsole(`fetchAllExpert error ${JSON.stringify(err)}`)
            })
        }catch(error){
            printConsole(`fetchAllExpert try catch error ${JSON.stringify(error)}`)
        }
    };

    initialiseJobForEdit = (selectedJob) => {
        try{
            let {groupUsers, selectedTechnician, allAssetData, selectedAsset, action, selectedExpert, accountExperts } = this.state;
            let {customer, isadmin, isexpert, enable_asset} = this.props;//TP-2721
            let user, job;
            if(selectedJob.asset_id && allAssetData && allAssetData.length > 0){
                selectedAsset = allAssetData.find((asset) => asset.id === selectedJob.asset_id);
            }        
            if(action === 'clone_checklist_job' || action === 'clone_checklist_template'){
                job = {
                    ...this.state.selectedJob, description: selectedJob.description, asset_id: selectedJob.asset_id && selectedAsset ? selectedJob.asset_id : 0, 
                    source_id: selectedJob.id //TP-3599
                };
            }else{
                [user] = groupUsers.filter(st => st.user_id === selectedJob.technician_id)//TP-2678
                var [assigned_by_expert] = accountExperts.filter(st => st.id === selectedJob.assigned_by)//instantiating expert here
                if(assigned_by_expert){
                    // allAssetData = allAssetData.filter(asset => asset.owner_id === assigned_by_expert.id)
                    if(allAssetData.length > 0){
                        selectedAsset = allAssetData.find((asset) => asset.id === selectedJob.asset_id);
                    }
                }
                job = {
                    ...selectedJob, technician_id: selectedJob.technician_id && user ? selectedJob.technician_id : (!customer.mandatory_assignee_jobs && (customer.enable_asset_class !== "disabled" && isadmin || (isexpert && ((enable_asset && customer.enable_asset_class === "enabled_on_demand") || customer.enable_asset_class === "enabled_for_all" )))) ? 0 : null,
                    asset_id: selectedJob.asset_id && selectedAsset ? selectedJob.asset_id : 0, 
                    reference_id : selectedJob.reference_id || nanoid(), assigned_by: assigned_by_expert ? selectedJob.assigned_by : null, //TP-4419
                };
            }
            let unFormatedStartDate= new Date(selectedJob.start_date || ''), unFormatedEndDate= new Date(selectedJob.end_date || '');
            this.setState({
                selectedJob: job, unFormatedStartDate, unFormatedEndDate,
                selectedTechnician: user ? {
                    value: user.user_id || '',
                    label: `${user.first_name} ${user.last_name}`
                } : selectedTechnician,
                selectedAsset: selectedAsset ? {value: selectedAsset.id, label: selectedAsset.asset_name}: selectedAsset,
                selectedExpert: assigned_by_expert ? {
                    value: assigned_by_expert.id || '',
                    label: `${assigned_by_expert.first_name} ${assigned_by_expert.last_name}`
                } : selectedExpert,
                allAssetData
            });
        }catch(error){
            printConsole(`error in initialiseJobForEdit ${error}`)
        }
    }

    mapCheckListTasks = () =>{
        printConsole('start of map task')
        try{
            let {unformattedCheckListTasks, checkListTasks, action} = this.state;
            if(unformattedCheckListTasks && unformattedCheckListTasks.length > 0){
                //TP-3838,3812
                checkListTasks = unformattedCheckListTasks.filter(t => !t.sub_sequence).map((t) => {
                    if(action === 'clone_checklist_job' || action === 'clone_checklist_template' ){
                        return {
                            job_id: t.job_id, task_instruction: t.instruction, theme_id: t.theme_id,
                            collapse: true,
                            sequence: t.sequence,
                            isAdded: true
                        }
                    }else{
                        return {
                            id: t.id, job_id: t.job_id, task_instruction: t.instruction, theme_id: t.theme_id,
                            collapse: true,
                            sequence: t.sequence, status: t.status, checklist_task_assignee_id: t.checklist_task_assignee_id
                        }
                    }
                });
                this.setState({ checkListTasks },() =>{
                    printConsole('End of map task')
                    setTimeout(() => { this.checkIsAddProcedure(); }, 1000);
                })
            }else{
                setTimeout(() => { this.checkIsAddProcedure(); }, 1000);//TP-3449
            }

        }catch(error){
            printConsole(`mapCheckListTasks try catch error ${JSON.stringify(error)}`)
        }
    }

    checkJobExists = ({jobName, jobField, jobId}) => {
		if (!this.validator.fieldValid(jobField)){
			this.setState({ isJobExists: false });
			return
        };
        let fetchString = `jobs/checkJobExists/${jobName}`;
        if(jobId){
            fetchString += `/${jobId}`;//T32-197, pass the job id so that it can be passed to the query. This is optional and only used for edit
        }

        this.props.authService.fetch(fetchString, {
            method: 'get'
        })
		.then(response => {
			if(response.status === 200) 
				return response.json();
			else
				throw new Error(response.statusText);
		})
		.then(isExists => {
			this.setState({
				isJobExists: isExists
			}, () => this.checkIsAddProcedure());//Mb2-935
		})
		.catch((error) => {
            printConsole(`checkJobExists try catch error ${JSON.stringify(error)}`)
		})
    }

    checkReferenceIdExists = ({referenceId, jobField, jobId}) => {
        if (!this.validator.fieldValid(jobField)){
            this.setState({ isJobReferenceIdExists: false });
			return
        };
        let fetchString = 'jobs/checkReferenceIdExists/';
        fetchString += referenceId;
        if(jobId){
            fetchString += `/${jobId}`;//TP-795, pass the job id so that it can be passed to the query. This is optional and only used for edit
        }

        this.props.authService.fetch(fetchString, {
            method: 'get'
        })
		.then(response => {
			if(response.status === 200) 
				return response.json();
			else
				throw new Error(response.statusText);
		})
		.then(isExists => {
			this.setState({
				isJobReferenceIdExists: isExists
			}, () => this.checkIsAddProcedure());//Mb2-935
		})
		.catch((error) => {
            printConsole(`checkReferenceIdExists try catch error ${JSON.stringify(error)}`)
		})
    }

    onChangeJobForm = (fieldName) => ({ target }) => {
        const jobId = this.props.selectedJob ? this.props.selectedJob.id : null;//T32-197
        (fieldName === 'name') && this.delayedCallback({jobName:target.value, jobField:target.name, jobId});
        (fieldName === 'reference_id') && this.delayedCallbackForReferenceId({referenceId:target.value, jobField: 'identifier', jobId});//TP-795
        this.setState(prevState => ({
            selectedJob: {
                ...prevState.selectedJob,
                [fieldName]: (fieldName === "is_recurring") ? target.checked : target.value.trimStart().replace(/(<([^>]+)>)/ig,""),
                mode_of_recurrence: (fieldName === "is_recurring" && !target.checked) ? null : prevState.selectedJob.mode_of_recurrence//TP-4891
            }
        }),() =>{
            // check the add task button state
            this.checkIsAddProcedure();
        });
    };

    //TP-4891
    onChangeJobSchedule = (fieldName) => ({ target }) => {
        try{
            let mode_of_recurrence = this.state.selectedJob && this.state.selectedJob.mode_of_recurrence ? JSON.parse(this.state.selectedJob.mode_of_recurrence) : {}
            let days_of_week = mode_of_recurrence.days_of_week ? mode_of_recurrence.days_of_week : []
            if(fieldName === "days_of_week"){
                //array
                if(target.checked){
                    days_of_week.push(parseInt(target.dataset.id))//push the day selected sun-0, mon-1, tue-2
                }else{
                    days_of_week = days_of_week.filter(day => day !== (parseInt(target.dataset.id)))//remove the day
                }
            }

            // days of week will be array
            const value = fieldName === "days_of_week" ? days_of_week : target.value

            if(fieldName === "mode"){
                //reset interval, weekday
                if(value !== "daily"){
                    delete mode_of_recurrence.interval;
                    delete mode_of_recurrence.weekday;
                }
                //reset weekly_interval, days_of_week
                if(value !== "weekly"){
                    delete mode_of_recurrence.weekly_interval;
                    delete mode_of_recurrence.days_of_week;
                }
                //set weekday
                if(value === "daily"){
                    mode_of_recurrence.weekday = "false";
                    mode_of_recurrence.interval = 1
                }
                //set the day of the week 
                if(value === "weekly"){
                    // mode_of_recurrence.weekly_interval = 1
                    let day = this.state.unFormatedStartDate.getDay();
                    days_of_week.push(parseInt(day));
                    mode_of_recurrence.days_of_week = days_of_week;
                }
            }
            if(fieldName === "weekday"){
                if(value === "false"){
                    mode_of_recurrence.interval = mode_of_recurrence.interval ? mode_of_recurrence.interval : 1
                }else{
                    delete mode_of_recurrence.interval;
                }
            }

            const modifiedModeOfRecurrence = {...mode_of_recurrence, [fieldName]: value }//first assign the the key value then pass it for no of ocurrence
            const no_of_occurences = this.noOfOccurences({startDate: this.state.unFormatedStartDate, endDate: mode_of_recurrence.recurrence_end_date,
                mode_of_recurrence: modifiedModeOfRecurrence
            })

            this.setState(prevState => ({
                selectedJob: {
                    ...prevState.selectedJob, mode_of_recurrence: JSON.stringify({...modifiedModeOfRecurrence, no_of_occurences})
                }
            }),() =>{
                this.checkIsAddProcedure();
            });
        }catch(error){
            printConsole(`onChangeJobSchedule ==============> ${error}`)
        }
    };

    //TP-4891
    onChangeScheduleEndDate = (date) =>{
        try{
            const mode_of_recurrence = this.state.selectedJob && this.state.selectedJob.mode_of_recurrence ? JSON.parse(this.state.selectedJob.mode_of_recurrence) : {}
            const no_of_occurences = this.noOfOccurences({startDate: this.state.unFormatedStartDate, endDate: date, mode_of_recurrence})
            this.setState(prevState => ({
                selectedJob: {
                        ...prevState.selectedJob, mode_of_recurrence: JSON.stringify({...mode_of_recurrence, ["recurrence_end_date"]: date,
                        no_of_occurences
                    })
                }
            }),() =>{
                this.checkIsAddProcedure();
            });
        }catch(error){
            printConsole(`onChangeScheduleEndDate ==============> ${error}`)
        }
    }

    noOfOccurences = ({startDate, endDate, mode_of_recurrence}) => {
        try{
            const {mode, interval, weekday, days_of_week} = mode_of_recurrence;
            if(mode === "daily"){
                if(weekday === "false" && interval > 0){
                    const cd = 24 * 60 * 60 * 1000, modifiedEndDate = new Date(endDate), modifiedStartDate = new Date(startDate);//TP-5020
                    modifiedStartDate.setHours(0, 0, 0, 0);//set the beginning of the day
                    modifiedEndDate.setHours(23, 59, 59, 999);//set the end of the day
                    let dateDiff = modifiedEndDate - modifiedStartDate;

                    if(dateDiff){
                        return Math.floor(dateDiff/(parseInt(interval) * cd) +1)//includes the job itself
                    }else{
                        return 0
                    }
                }

                if(weekday === "true"){
                    const dateDiff = new Date(endDate) - startDate
                    if(dateDiff){
                        const noOfOccurences = getWeekdayCountBetweenDates(startDate, endDate, [1,2,3,4,5])//mon,tue,wed,thu,fri
                        return noOfOccurences
                    }else{
                        return 0
                    }
                }
            }

            if(mode === "weekly"){
                if(days_of_week && days_of_week.length > 0){
                    const dateDiff = new Date(endDate) - startDate
                    if(dateDiff){
                        const noOfOccurences = getWeekdayCountBetweenDates(startDate, endDate, days_of_week)
                        return noOfOccurences
                    }else{
                        return 0
                    }
                }
            }

            if(mode === "monthly"){
                const dateDiff = new Date(endDate) - startDate
                if(dateDiff){
                    const noOfOccurences = getMonthCountBetweenDates(startDate, endDate)
                    return noOfOccurences
                }else{
                    return 0
                }
            }

        }catch(error){
            printConsole(`noOfOccurences ==============> ${error}`)
        }
    }

    onChangeCheckListTask = (fieldName, index) => ({target}) => {
        let checkListTasks = [...this.state.checkListTasks];
        checkListTasks[index][fieldName] = target.value.trimStart().replace(/(<([^>]+)>)/ig,"")
        this.setState({ checkListTasks },() =>{
            this.checkIsAddProcedure();
        })
    }

    handleTechChange = (idx) => (selectedUser) => {
        let checkListTasks = [...this.state.checkListTasks];
        checkListTasks[idx]['selectedUser']= selectedUser;
        checkListTasks[idx]['user_id']= selectedUser.value;
        this.setState({ 
            checkListTasks,
        })
    }

    handleTechnicianChange = (selectedTechnician) => {
        let {selectedJob} = this.state;
        this.setState({ 
            pastTechnician: selectedJob.technician_id
        });
        selectedJob.technician_id = selectedTechnician.value;        
        if(selectedJob.status === 'draft'){
            selectedJob.status = 'created' //TP-2359
        }
        this.setState({ 
            selectedTechnician,
            selectedJob
        },()=>{
            this.checkIsAddProcedure();
        })
    }

    handleThemeChange = (idx) => (selectedTheme) => {
        let checkListTasks = [...this.state.checkListTasks];
        checkListTasks[idx]['theme_id'] = selectedTheme.value;
        this.setState({ 
            checkListTasks,
        },() => {
            this.checkIsAddProcedure();// check status of addtask button
        })
    }

    //TP-3520
    handleExpertChange = (selectedExpert) => {
        let {selectedJob, allAssetData, selectedAsset, copyOfAllAssets} = this.state, assetSelected;
        selectedJob.assigned_by = selectedExpert.value;
        //TP-3640,4419,TZ-623
        if(copyOfAllAssets.length > 0){
            //load asset as per the expert selected, in edit if different expert is chosen then check the asset selected
            // allAssetData = copyOfAllAssets.filter(asset => asset.owner_id === selectedExpert.value)
            allAssetData = copyOfAllAssets
            if(selectedJob && selectedJob.asset_id){
                [assetSelected] =  allAssetData.filter(asset => asset.id === selectedJob.asset_id)
                if(!assetSelected){
                    selectedJob.asset_id = null;
                    selectedAsset= '';
                }
            }
        }
        this.setState({ selectedExpert, selectedJob, allAssetData, selectedAsset },()=>{
            this.checkIsAddProcedure();
        })
    }

    getTechNameOptions = (i18n) => {
        let users = this.state.groupUsers;
        const {customer, isadmin, isexpert, enable_asset} = this.props;//TP-2721
        users = users.filter(obj => (!obj.isadmin && !obj.isexpert))
        const techNameOptions = users.map(p => {
            return {
                value: p.user_id,
                label: `${p.first_name} ${p.last_name}`
            }
        });
        //add the option when technician is optional and asset class is enabled
        if(!customer.mandatory_assignee_jobs && (customer.enable_asset_class !== "disabled" && isadmin || (isexpert && ((enable_asset && customer.enable_asset_class === "enabled_on_demand") || customer.enable_asset_class === "enabled_for_all")))){
            techNameOptions.unshift({value: 0, label: i18n._(t`Clear Assignee`)});//TP-2579,2673,6687
        }
        return techNameOptions;
    }

    getExpertNameOptions = (i18n) => {
        let users = this.state.accountExperts;
        const expertNameOptions = users.map(p => {
            return {
                value: p.id,
                label: `${p.first_name} ${p.last_name}`
            }
        });
        return expertNameOptions;
    }
    
    themeNames = (obj, i18n) => {
        if(obj){
            let {value, label} = obj;
            switch (label) {
                case 'Pass/Fail/NA':
                    return {value,label: i18n._(t`Pass/Fail/NA`)};//TP-2480
                case 'Yes/No/NA':
                    return {value,label: i18n._(t`Yes/No/NA`)};//TP-2554
                default:
                    return '';
            } 
        }else{
            return '';
        }
    }

    getChecklistThemeNameOptions = (i18n) => {
        let {themes} = this.state;
        return themes.map(p => {
            let action = this.themeNames({value: p.id,label: p.name}, i18n)
            return action;
        });
    }

    //TP-2535
    getAssetOptions = (i18n) => {
        const assetOptions = this.state.allAssetData.map(p => { return {
                value: p.id, label: p.asset_name
            }
        });
        assetOptions.unshift({value: 0, label: i18n._(t`Clear Asset`)});//TP-2579
        return assetOptions;
    }
    //TP-2535
    handleAssetChange = (selectedAsset) => {
        const {selectedJob} = this.state;
        let status = selectedJob.status === 'draft' ? 'created' : selectedJob.status //TP-2921
        this.setState((prevState) =>({ selectedJob: {...prevState.selectedJob, status, asset_id: selectedAsset.value}, selectedAsset}),()=>{ 
            this.checkIsAddProcedure();
        });
    }

    formatDate = (str) => {
        let date = str,
            mnth = ("0" + (date.getMonth()+1)).slice(-2),
            day  = ("0" + date.getDate()).slice(-2);
        return [ date.getFullYear(), mnth, day ].join("-");
    }

    onStartDateChange = (date, e) => {
        if (e && typeof e.preventDefault === 'function') {
            e.preventDefault();
        }
        // let startDate = this.formatDate(date);
        const mode_of_recurrence = this.state.selectedJob && this.state.selectedJob.mode_of_recurrence ? JSON.parse(this.state.selectedJob.mode_of_recurrence) : {}
        let startDate = date;
        const no_of_occurences = this.noOfOccurences({startDate, endDate: mode_of_recurrence.recurrence_end_date, mode_of_recurrence })
        this.setState(prevState => ({
            startDate: startDate,
            unFormatedStartDate: date,
            selectedJob: {
                ...prevState.selectedJob,
                ['start_date']: startDate,
                mode_of_recurrence: prevState.selectedJob.is_recurring? JSON.stringify({...mode_of_recurrence, no_of_occurences }) : null//TP-4891
            }

        }),() => {
            this.checkIsAddProcedure();// check status of addtask button
        });
    }

    onEndDateChange = (date, e) => {
        if (e && typeof e.preventDefault === 'function') {
            e.preventDefault();
        }
        // let endDate = this.formatDate(date);
        let endDate = date;
        this.setState(prevState => ({
             endDate: endDate,
             unFormatedEndDate: date,
             selectedJob: {
				...prevState.selectedJob,
				['end_date']: endDate
            }
        }),() => {
            this.checkIsAddProcedure();// check status of addtask button
        });
    }

    onAddJob = (e) => {
        e.preventDefault();
        if (!this.validator.allValid() || this.state.isJobExists || this.state.isJobReferenceIdExists) {
            this.openInvalidProcedure(this.validator.fields);
			this.validator.showMessages();
			return;
		}

        const { selectedJob, startDate, endDate, checkListTasks } = this.state;
        selectedJob.start_date = selectedJob.start_date || startDate; selectedJob.end_date = selectedJob.end_date || endDate; selectedJob.checkListTasks = checkListTasks;
        selectedJob.technician_id = selectedJob.technician_id === 0 ? null : selectedJob.technician_id;//the validation is not triggering if set to '', or null
        selectedJob.asset_id = selectedJob.asset_id === 0 ? null : selectedJob.asset_id;//the validation is not triggering if set to '', or null

        if(checkListTasks.length === 0 && selectedJob.job_type !== 'checklist_template'){
            selectedJob.status = 'draft'//TP-3445
        }

		this.setState({
            bSubmitted: true,
            errStrReturned: selectedJob.job_type === 'checklist_template' ? i18nMark('Submitting new Template details') : i18nMark('Submitting new Job details') //TP-6460
        });
        this.props.authService.fetch('jobs/addCheckListJob', {
            method: 'post',
            body: JSON.stringify(selectedJob)
        })
        .then(response => {
			this.setState({retCode: response.status});
			if(response.status === 200){
                this.props.fetchJobs();//reload the jobs
				return response.json();
			} else {
				throw new Error(response.body);
            }
        })
        .then(id => {
			if(id){
				this.setState({
                    bSubmitted: false,
                    // bResponded: true,//MB2-923
                    // errStrReturned: i18nMark('New Job successfully added!')//MB2-923
                });
                this.props.changeJobView({action:'', showJobForm: false});//MB2-923
			}
        })
        .catch((error) => {
            this.setState({
                bSubmitted: false,
                bResponded: true,
                retCode: error.status,
                errStrReturned: i18nMark('Failed to add the new job. Please review the entries and resubmit.')
            });

        });
    };

    onEditJob = (e) => {
        e.preventDefault();
        if (!this.validator.allValid() || this.state.isJobExists || this.state.isJobReferenceIdExists) {
            this.openInvalidProcedure(this.validator.fields);
            this.validator.showMessages();
			return;
		}
        
        const { selectedJob, checkListTasks } = this.state;
        selectedJob.id = this.props.selectedJob.id; selectedJob.checkListTasks = checkListTasks;
        selectedJob.technician_id = selectedJob.technician_id === 0 ? null : selectedJob.technician_id;//the validation is not triggering if set to '', or null
        selectedJob.asset_id = selectedJob.asset_id === 0 ? null : selectedJob.asset_id;//the validation is not triggering if set to '', or null

        const job_data = {
            id: selectedJob.id, name: selectedJob.name, description: selectedJob.description,
            start_date: selectedJob.start_date, end_date: selectedJob.end_date,
            status: selectedJob.status, reference_id: selectedJob.reference_id,
            job_type: selectedJob.job_type//TP-3299
        }
        let json = {  
            type: "ASSIGNED_JOB_REFRESH",          
            to: selectedJob.technician_id,
            from: this.props.email,
            job_data: JSON.stringify(job_data),            
            refresh_type: "new_job"
        };
        if (selectedJob.status === "in_progress"){
            if (this.state.pastTechnician && this.state.pastTechnician !== selectedJob.technician_id){
                if(this.state.pastTechnician){
                    json.to = this.state.pastTechnician;
                    json.refresh_type = "job_reassigned";
                    this.authService.socket.emit('job-signal', json);
                }

                //TP-2958
                if(selectedJob.technician_id){
                    json.to = selectedJob.technician_id;
                    json.refresh_type = "new_job";
                    this.authService.socket.emit('job-signal', json);
                }
            } else {
                if(selectedJob.technician_id){
                    json.to = selectedJob.technician_id;
                    json.refresh_type = "job_edited";
                    this.authService.socket.emit('job-signal', json);
                }
            }
        } 
        //console.log(json);        
        if(checkListTasks.length === 0 && selectedJob.status === 'created' && selectedJob.job_type !== 'checklist_template'){
            selectedJob.status = 'draft'//TP-3445 ignore status change for draft job when tech changes or assets changed
        }
        if(checkListTasks.length !== 0 && selectedJob.status === 'draft' && selectedJob.job_type !== 'checklist_template'){
            selectedJob.status = 'created'//TP-3445 change the status to created
        }

		this.setState({
            bSubmitted: true,
            errStrReturned: selectedJob.job_type === 'checklist_template' ? i18nMark('Submitting Template details') : i18nMark('Submitting Job details')
        });
        this.props.authService.fetch('jobs/editCheckListJob', {
            method: 'put',
            body: JSON.stringify(selectedJob)
        })
        .then(response => {
			this.setState({retCode: response.status});
			if(response.status === 200){
                this.props.fetchJobs();//reload the jobs
				return response.json();
			} else {
				throw new Error(response.body);
            }
        })
        .then(id => {
			if(id){
				this.setState({
                    bSubmitted: false,
                    bResponded: true,
                    errStrReturned: selectedJob.job_type === 'checklist_template' ? i18nMark('Template successfully updated!') : i18nMark('Job successfully updated!') //TP-6460
                });
            }
        })
        .catch((error) => {
            this.setState({
                retCode: error.status,
                errStrReturned: i18nMark('Failed to edit the job. Please review the entries and resubmit.')
            });            
        });
    };

    deleteCheckListTask = async ({e, index}) => {
        try{
            e.preventDefault();
            let { checkListTasks } = this.state;
            let selectedCheckList = checkListTasks[index];
            selectedCheckList['isDelete'] = true //mark the one to be deleted
            checkListTasks.splice(index,1) // seperate it from the other tasks
            checkListTasks= checkListTasks.map((task, i) => {
                return {...task, sequence: (i+1)} // update the sequence of the tasks
            });
            checkListTasks.push(selectedCheckList) // push the to be deleted one
            if(selectedCheckList){
                this.props.authService.fetch(`jobs/deleteCheckListTask`, {
                    method: 'put',
                    body: JSON.stringify({checkListTasks})
                })
                .then(response => {
                    checkListTasks.pop(); // remove the deleted one added at the end
                    if(response.status === 200){
                        this.setState({ 
                            checkListTasks,
                            /**TP-3222 */
                            index: 0,
                            isAddProcedure: false, // status of addtask button
                            bShowSubTask: false
                        },() =>{ 
                            this.checkIsAddProcedure() 
                        });
                        return response.json();
                    } else {
                        throw new Error(response.body);
                    }
                })
                .catch((error) => {
                    this.setState({
                        bResponded: true, bSubmitted: false, errStrReturned: i18nMark('Failed to process request'),
                        retCode: error.status
                    });
                })
            }
        }catch(error){
            printConsole(`deleteCheckListTask try catch error ${JSON.stringify(error)}`)
        }
    }
    
    deleteNewCheckListTask = async ({e, index}) => {
        try{
            if(e){
                e.preventDefault();
            }
            let { checkListTasks } = this.state;
            checkListTasks.splice(index,1);
            if(Array.isArray(checkListTasks) && checkListTasks.length > 0){
                checkListTasks= checkListTasks.map((task, i) => {
                    return {...task, sequence: (i+1)} // update the sequence of the tasks
                });
            }
            this.setState({ 
				checkListTasks,
				index: 0,
                isAddProcedure: false, // status of addtask button
                bShowSubTask: false 
			},()=>{
                this.checkIsAddProcedure();
            });
        }catch(error){
            printConsole(`deleteNewCheckListTask try catch error ${JSON.stringify(error)}`)
        }
    }

	//TP-3222
    updateSelectedTask = (index) => {
        this.setState({index, bShowSubTask: true}, () => {
            let checkListTasks = [...this.state.checkListTasks] 
            if(checkListTasks.length>0){
                checkListTasks[index]['collapse'] = false;
                delete checkListTasks[index].isValid;//remove invalid state to re validate again
            }
            this.setState({
                checkListTasks
            });
        });
    }

    //TP-3222
    showNextSeries = (e) => {
        e.preventDefault();
        //Hide the "..." before showing the new set of Procedures
        e.target.parentNode.classList.add("d-none");

        //Show the next set of Procedures
        const index = parseInt(e.target.parentNode.id.split("-")[1]);
        const elements = document.querySelectorAll(`[id^="pro-${index}"]`);
        for(let ii=0; ii<elements.length; ii++) {
            elements[ii].classList.remove("d-none");
        }

        //Show the next "..." if there
        const nextBreakElem = document.querySelector(`[id^="break-${index+1}"]`);
        if (nextBreakElem) {
            nextBreakElem.classList.remove("d-none");
        }
    }

    //TP-3222 -- Invoke when user click to request another page.
    handleProcedureClick = (event) => {
        const newOffset = event.selected;
        console.log(
        `User requested page number ${event.selected}`
        );
        this.updateSelectedTask(newOffset);
    };
    
    addTask = (e) => {
        e.preventDefault();
        let checkListTasks = [...this.state.checkListTasks] 
        if(checkListTasks.length>0){
            checkListTasks[checkListTasks.length-1]['collapse'] = true; //TP-3222
        }  
        checkListTasks.push({
            task_instruction:'',
            collapse: false,
            sequence: (checkListTasks.length +1),
        });

        this.setState({
            checkListTasks,
			index: checkListTasks.length - 1, //TP-3222
            isAddProcedure: true // status of addtask button
        }, () => {
            // this.setState({ bShowSubTask: true }); //TP-3222,4004
        });
    }

    //TP-3222
    cancelSubTask = (e) => {
        e.preventDefault();
        let {checkListTasks, index} = this.state, isAddProcedure = false;
        this.openInvalidProcedure(this.validator.fields);
        if (!checkListTasks[index].id && !checkListTasks[index].isAdded){
            this.deleteNewCheckListTask({e,index})//delete the checklist task considering its a new one
        }

        if (checkListTasks[index] && !checkListTasks[index].id && checkListTasks[index].isValid === false && checkListTasks[index].isAdded) {
            isAddProcedure = true//disable update button if sub task was added but again opened and updated
        }

        if(checkListTasks[index] && checkListTasks[index].id && checkListTasks[index].isValid === false){
            isAddProcedure = true //disable update button if sub task invalid
        }
        this.setState({
            bShowSubTask: false,
            index: 0,
            isAddProcedure // status of addtask button
        });
    }

    //TP-3222
    updateTask = (e) => {
        e.preventDefault(); 
        if (!this.validator.allValid()) {
            this.validator.showMessages();
			return;
		}
        let {checkListTasks, index} = this.state;
        checkListTasks[index].isAdded = true; //TP-3491, mark the task as added
        this.setState({ bShowSubTask: false, checkListTasks });
    }
    
    collapseSubTask = (idx) => {
        let checkListTasks = [...this.state.checkListTasks]  
        checkListTasks = checkListTasks.map((task, i) => {
            if(idx === i){
                return {...task, collapse: !task.collapse}
            }else{
                return {...task, collapse: true}
            }
        }) 
        this.setState({ 
            checkListTasks
        })
    }

    /* this method will enable/disable the addtask button. This needs to be called in the callback
    of set state or else validator will give inconsistent result
    */
    checkIsAddProcedure = () => {
        if (!this.validator.allValid() || this.state.isJobExists || this.state.isJobReferenceIdExists) {
            this.validator.showMessages();
            this.openInvalidProcedure(this.validator.fields)//TP-4004, show the error text
            this.setState({isAddProcedure: true})
        }else{
            this.setState({isAddProcedure: false})
        }
    }

    openInvalidProcedure = (fields) => {
        let invalidFields = [], matches= [];
        for(let key in fields) {
            if(fields[key] === false && /\d/.test(key)){
                invalidFields.push(key)
            }
        }
        // loop through the invalid fields to find out the field name with number in it
        invalidFields.map((f) => {
            let matchArr = f.match(/\d+/g);
            matches.push(matchArr[0]); 
        })

        matches = [...new Set(matches)];
        let {checkListTasks} = this.state;
        for(let i = 0; i < matches.length; i++){
            // if not collapsed then showInvalidError is true
            if(checkListTasks[parseInt(matches[i])]['collapse']){
                checkListTasks[parseInt(matches[i])]['showInvalidError']= true
            }else{
                checkListTasks[parseInt(matches[i])]['showInvalidError']= false//once chnanges are made hide the already shown message
            }
            checkListTasks[parseInt(matches[i])]['isValid'] = false //TP-3450,3455 mark which is invalid
        }
        this.setState({checkListTasks})
    }

    //MB2-775 before closing the form check for any unsaved tasks reference material
    oncloseJobForm = async () => {
        this.props.changeJobView({action:'', showJobForm: false});
    }

    //Flash message specific methods
    updateShowMessage = (val) => {
        if (val === false)
            this.setState({ flashMsgText: "", showFlashMessage: val, flashLeft: "", flashTop: "" });
    }

    // TP-3520
    checkActionType = (action, selectedJob) => {
        if(selectedJob && selectedJob.id){
            if(action === 'edit_checklist_template'){
                return 'Edit Template'
            }else if(action === 'edit_checklist'){
                return 'Edit Job'
            }
        }else{
            if(action === 'add_checklist_template' || action === 'clone_checklist_template'){
                return 'Add Template'
            }else if(action === 'add_checklist' || action === 'clone_checklist_job'){
                return 'Add Job'
            }
        }
    }

    render(){
        let {selectedTechnician, checkListTasks, isAddProcedure, selectedAsset, selectedJob, action, index, selectedExpert} = this.state;
        const {customer, isadmin, isexpert, enable_asset, isSmallFormFactor, user_enable_job_scheduler} = this.props;//TP-2721
        const isJobFinished = (selectedJob && selectedJob.status === 'finished');
        const showTech = (action === 'add_checklist' || action === 'edit_checklist' || action === 'clone_checklist_job' )
        const header = document.getElementById("header_bar");
        const tabHeader = document.getElementById("jobsTab");
        //TP-3840
        let areaHeight = 0 + 'px';
        //TP-5173
        if (header && header !== null && tabHeader && tabHeader !== null)
            areaHeight = Math.round(window.innerHeight - header.offsetHeight - tabHeader.offsetHeight - 30- 20 - 20) + "px";//TP-3710 30-footer height, 20-mt3 height, 20-for the space at the bottom when multiple procedure are there
        this.validator.purgeFields();
        let brkIdx = -1; //TP-3222
		if(this.state.bSubmitted || this.state.bResponded){
			let colorText = '#485890';
			if(this.state.retCode !== 200) colorText = 'red';
			return(
                <>
                    <div className='modale opened'>
                        <div className='__modal-dialog'>
                            <form>
                                <div className="__modal-header">
                                    <h4 style={{color: colorText}}><Trans id={this.state.errStrReturned}/></h4>
                                </div>
                                <div className="__modal-footer flex-center">
                                    <button className="btn-green" onClick={() =>this.props.changeJobView({action:'', showJobForm: false})}><Trans id='OK'/></button>
                                </div>
                            </form>
                        </div>
                    </div>

                    <FlashMessage 
                        flashMsgText={this.state.flashMsgText} 
                        showMessage={this.state.showFlashMessage} 
                        flashLeft={this.state.flashLeft}
                        flashTop={this.state.flashTop}
                        updateShowMessage={this.updateShowMessage}
                        isTimerEnabled={true} /**TP-5986*/
                    />
                </>
			);
		} else {
            //TP-3222 -- Embedded forms page
			return(
                <>
                    <div className={`formmodule ${checkListTasks.length > 0? "row": "center"}`}>
					    <div className='mt-3 form-color width-min col-6' style={{height: areaHeight}}>
                            <div className="formheader flex-center row">
                                {/* TP-2704 */}
                                <h2>
                                    <Trans id={this.checkActionType(action, selectedJob)}></Trans> - <Trans>Checklist</Trans>
                                </h2>
                            </div>
                            <div className="formbody form-text-color position-sticky">
                                <Form>
                                    <div className="jobs-form-body pb-0">
                                        <Row className="margin-top-15 mb-1">
                                            <Col>
                                                <div id="job_name" className="input-wrapper">
                                                    <I18n>
                                                        {({ i18n }) =>
                                                            <>
                                                                <input className='signup-input-field' name={i18n._(t`name`)} type="text" placeholder={showTech? i18n._(t` Enter the job name`) : i18n._(t` Enter the template name`)} value={selectedJob.name} onChange={this.onChangeJobForm('name')} disabled={isJobFinished} autoComplete="off"/>
                                                                { this.validator.message(i18n._(t`name`), selectedJob.name, 'required|maxCharLength:30') }
                                                                { this.state.isJobExists ? <div className="add-users-validation-msg"><Trans>There is a job with this name.</Trans></div> : '' }
                                                            </>									
                                                        }
                                                    </I18n>
                                                    { this.props.isSmallFormFactor === false ? 
                                                        <UncontrolledTooltip placement="top" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="job_name">
                                                            <>{showTech? <Trans>Job</Trans> : <Trans>Template</Trans>} <Trans>Name</Trans></>
                                                        </UncontrolledTooltip>       
                                                        :
                                                        ''
                                                    }
                                                </div>
                                            </Col>
                                            <Col>
                                                <div id="unique_id" className="input-wrapper">
                                                    <I18n>
                                                        {({ i18n }) =>
                                                            <>
                                                                <input className='signup-input-field' name={i18n._(t`reference_id`)} type="text" placeholder={i18n._(t` Enter unique identifier, (atleast 8 characters)`)} value={selectedJob.reference_id} onChange={this.onChangeJobForm('reference_id')} disabled={isJobFinished} autoComplete="off"/>
                                                                { this.validator.message(i18n._(t`identifier`), selectedJob.reference_id, 'required|alpha_num_dash_underscore|minCharLength:8|maxCharLength:30') }
                                                                { this.state.isJobReferenceIdExists ? <div className="add-users-validation-msg"><Trans>There is a job with this identifier</Trans></div> : '' }
                                                            </>									
                                                        }
                                                    </I18n>
                                                    { this.props.isSmallFormFactor === false ? 
                                                        <UncontrolledTooltip placement="top" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="unique_id">
                                                            <Trans>Unique Identifier</Trans>
                                                        </UncontrolledTooltip>       
                                                        :
                                                        ''
                                                    }
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col>
                                                <div id="job_desc"  className="input-wrapper">
                                                    <I18n>
                                                        {({ i18n }) =>
                                                        <>
                                                            <textarea rows={4} className='signup-input-field form-control' name={i18n._(t`description`)} 
                                                                placeholder={showTech? i18n._(t` Enter the job Description`) : i18n._(t` Enter the template Description`)} 
                                                                value={selectedJob.description} onChange={this.onChangeJobForm('description')} disabled={isJobFinished} autoComplete="off"
                                                            />
                                                            { this.validator.message(i18n._(t`description`), selectedJob.description, 'required') }

                                                        </>
                                                        }
                                                    </I18n>
                                                        { this.props.isSmallFormFactor === false ? 
                                                            <UncontrolledTooltip placement="top" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="job_desc">
                                                                <>{showTech? <Trans>Job</Trans> : <Trans>Template</Trans>} <Trans>Description</Trans></>
                                                            </UncontrolledTooltip>       
                                                            :
                                                            ''
                                                        }
                                                </div>
                                            </Col>
                                        </Row>
                                        <I18n>
                                            {({ i18n }) =>
                                                <> 
                                                    <Row>
                                                        <Col className="input-wrapper">
                                                            { showTech && !customer.mandatory_assignee_jobs && (customer.enable_asset_class !== "disabled" && isadmin || (isexpert && ((enable_asset && customer.enable_asset_class === "enabled_on_demand") || customer.enable_asset_class === "enabled_for_all"))) ? 
                                                                <span className='times tm-regular'><Trans values={{role: this.props.customerRoles.technician}}>check_technician_asset</Trans></span>
                                                                :
                                                                null
                                                            }                                                        
                                                        </Col>
                                                    </Row>
                                                    <Row className="mb-1">
                                                        { showTech? 
                                                            <Col id="assign_tech" className="input-wrapper">
                                                                <Select
                                                                    value={selectedTechnician}
                                                                    onChange={this.handleTechnicianChange}
                                                                    placeholder={i18n._({ id: `Select A Technician`, values: {role: this.props.customerRoles.technician}})}
                                                                    components={makeAnimated()}
                                                                    options={this.getTechNameOptions(i18n)}
                                                                    isSearchable={isSmallFormFactor ? false : true}
                                                                    isDisabled={isJobFinished}
                                                                    className="text-black"
                                                                />
                                                                { this.props.isSmallFormFactor === false ? 
                                                                    <UncontrolledTooltip placement="top" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="assign_tech">
                                                                        <Trans values={{role: this.props.customerRoles.technician}}>Select A Technician</Trans>
                                                                    </UncontrolledTooltip>       
                                                                    :
                                                                    ''
                                                                }
                                                                <input type="text" readOnly value={selectedJob.technician_id} name={i18n._({id: `technician`, values: {role: this.props.customerRoles.technician}})} className="hidden"/>
                                                                {(!customer.mandatory_assignee_jobs && (customer.enable_asset_class !== "disabled" && isadmin || (isexpert && ((enable_asset && customer.enable_asset_class === "enabled_on_demand") || customer.enable_asset_class === "enabled_for_all" ))))?
                                                                    this.validator.message(i18n._({id: `technician`, values: {role: this.props.customerRoles.technician}}), selectedJob.technician_id, 'check_technician_asset:'+i18n._({id: `technician`, values: {role: this.props.customerRoles.technician}}))
                                                                    :
                                                                    this.validator.message(i18n._({id: `technician`, values: {role: this.props.customerRoles.technician}}), selectedJob.technician_id, 'required')
                                                                }
                                                            </Col>
                                                        :
                                                            isadmin ?
                                                                <Col id="assign_exp" className="input-wrapper">
                                                                        <Select
                                                                            value={selectedExpert}
                                                                            onChange={this.handleExpertChange}
                                                                            placeholder={i18n._({ id: `Select An Expert`, values: {role: this.props.customerRoles.expert}})}
                                                                            components={makeAnimated()}
                                                                            options={this.getExpertNameOptions(i18n)}
                                                                            isSearchable={this.props.isSmallFormFactor ? false : true}
                                                                            className="text-black"
                                                                        />
                                                                    { this.props.isSmallFormFactor === false ? 
                                                                        <UncontrolledTooltip placement="top" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="assign_exp">
                                                                            <><Trans>select_option</Trans> <Trans>An</Trans> <Trans values={{role: this.props.customerRoles.expert}}>Expert</Trans></>
                                                                        </UncontrolledTooltip>       
                                                                        :
                                                                        ''
                                                                    }
                                                                    <input type="text" readOnly value={this.state.selectedJob.assigned_by} name={i18n._({id: `expert`, values: {role: this.props.customerRoles.expert}})} className="hidden"/>
                                                                    {this.validator.message(i18n._({id: `expert`, values: {role: this.props.customerRoles.expert}}), this.state.selectedJob.assigned_by, 'required')}
                                                                </Col>
                                                            : null                                                        
                                                        }
                                                        {(customer.enable_asset_class !== "disabled" && isadmin || (isexpert && ((enable_asset && customer.enable_asset_class === "enabled_on_demand") || customer.enable_asset_class === "enabled_for_all" )))?
                                                            <Col id="select_asset" className="input-wrapper">
                                                                <Select
                                                                    value={selectedAsset}
                                                                    onChange={this.handleAssetChange}
                                                                    placeholder={i18n._(t` Select An Asset`)}
                                                                    components={makeAnimated()}
                                                                    options={this.getAssetOptions(i18n)}
                                                                    isSearchable={isSmallFormFactor ? false : true}
                                                                    isDisabled={isJobFinished}
                                                                    className="text-black"
                                                                />
                                                                <input type="text" readOnly value={selectedJob.asset_id} name={i18n._(t`asset`)} className="hidden"/>
                                                                {showTech && !customer.mandatory_assignee_jobs ? this.validator.message(i18n._(t`asset`), selectedJob.asset_id, 'check_technician_asset:'+i18n._({id: `technician`, values: {role: this.props.customerRoles.technician}})) : null}
                                                            </Col>
                                                            : null
                                                        }
                                                    </Row>
                                                    {
                                                        showTech?   
                                                            <Row className="mt-3 mb-1">
                                                                <Col id="start_date" className="input-wrapper">
                                                                    <DatePicker selected={this.state.unFormatedStartDate} className="custom-date" /**TP-3031 */ onFocus={isSmallFormFactor ? (e) => e.target.readOnly = true : () => {return null;}} 
                                                                        popperPlacement="top-start" onChange={this.onStartDateChange} disabled={isJobFinished} showTimeSelect timeFormat="HH:mm" dateFormat="Pp"
                                                                        timeIntervals={15} onKeyDown={(e) => { e.preventDefault();}}/**TP-4847 **/
                                                                    />
                                                                    <input type="date" readOnly value={selectedJob.start_date} name={i18n._(t`start_date`)} className="hidden"/>
                                                                    { this.validator.message(i18n._(t`start_date`), selectedJob.start_date, 'checkStartDate|beforEndDate|checkEditStartDate') }
                                                                    { this.props.isSmallFormFactor === false ? 
                                                                        <UncontrolledTooltip placement="top" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="start_date">
                                                                            <><Trans>select_option</Trans> <Trans>Start Date</Trans></>
                                                                        </UncontrolledTooltip>       
                                                                        :
                                                                        ''
                                                                    }
                                                                </Col>
                                                                <Col id="end_date" className="input-wrapper">
                                                                    <DatePicker selected={this.state.unFormatedEndDate} className="custom-date" /**TP-3031 */ onFocus={isSmallFormFactor ? (e) => e.target.readOnly = true : () => {return null;}} 
                                                                        popperPlacement="top-start" onChange={this.onEndDateChange} disabled={isJobFinished} showTimeSelect timeFormat="HH:mm" dateFormat="Pp"
                                                                        timeIntervals={15} onKeyDown={(e) => { e.preventDefault();}}/**TP-4847 **/
                                                                    />
                                                                    <input type="date" readOnly value={selectedJob.end_date} name={i18n._(t`end_date`)} className="hidden"/>
                                                                    { this.validator.message(i18n._(t`end_date`), selectedJob.end_date, 'afterStartDate') }
                                                                    { this.props.isSmallFormFactor === false ? 
                                                                        <UncontrolledTooltip placement="top" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="end_date">
                                                                            <><Trans>select_option</Trans> <Trans>End Date</Trans></>
                                                                        </UncontrolledTooltip>       
                                                                        :
                                                                        ''
                                                                    }
                                                                </Col>
                                                            </Row>
                                                        : null
                                                    }
                                                </>
                                            }
                                        </I18n>
                                    </div>
                                    {showTech && (customer.enable_job_scheduler !== "disabled" && isadmin || (isexpert && ((user_enable_job_scheduler && customer.enable_job_scheduler === "enabled_on_demand") || customer.enable_job_scheduler === "enabled_for_all" )))? 
                                        <div>
                                            <label htmlFor="is_recurring" className="h4">
                                                <input className="checkbox" type="checkbox" name="is_recurring" checked={this.state.selectedJob.is_recurring} 
                                                    onChange={this.onChangeJobForm("is_recurring")} disabled={isJobFinished}
                                                    >
                                                </input>
                                                <Trans>Recurring</Trans>
                                            </label>

                                            {
                                                this.state.selectedJob.is_recurring ?
                                                    <Scheduler 
                                                        selectedJob={this.state.selectedJob} onChangeJobSchedule={this.onChangeJobSchedule} 
                                                        isSmallFormFactor={this.props.isSmallFormFactor} onChangeScheduleEndDate={this.onChangeScheduleEndDate}
                                                        validator={this.validator}
                                                    />
                                                :   null
                                            }
                                        </div>
                                    : null}

                                    <div className="mt-3">
                                        {(action === 'edit_checklist' || action === 'edit_checklist_template' && selectedJob) ?
                                        <Row>
                                            <Col className="d-flex justify-content-center">
                                                <button className='btn-red job-button' onClick={() => this.oncloseJobForm()} ><Trans>Cancel</Trans></button>
                                            </Col>
                                            <Col className="d-flex justify-content-center">
                                                <button className={isAddProcedure || isJobFinished ? "btn-grey job-button": "btn-blue job-button"} disabled={isAddProcedure || isJobFinished} onClick={(e) => this.addTask(e)}><Trans>Add</Trans> <Trans>New</Trans> <Trans>Procedure</Trans></button>
                                            </Col>
                                            <Col className="d-flex justify-content-center">
                                                <button className={isAddProcedure ? "btn-grey job-button": "btn-green job-button"} disabled={isAddProcedure} onClick={(e) =>this.onEditJob(e)}><Trans>Update</Trans></button>
                                            </Col>
                                        </Row>
                                        :
                                        <Row>
                                            <Col className="d-flex justify-content-center"><button className='btn-red job-button' onClick={() => this.oncloseJobForm()}><Trans>Cancel</Trans></button></Col>
                                            <Col className="d-flex justify-content-center"><button className={isAddProcedure ? "btn-grey job-button": "btn-blue job-button"} disabled={isAddProcedure} onClick={(e) => this.addTask(e)}><Trans>Add</Trans> <Trans>New</Trans> <Trans>Procedure</Trans></button></Col>
                                            <Col className="d-flex justify-content-center"><button className={isAddProcedure ? "btn-grey job-button": "btn-green job-button"} disabled={isAddProcedure} onClick={(e) =>this.onAddJob(e)}><Trans>Create</Trans></button></Col>
                                        </Row>
                                        }
                                    </div>
                                </Form>
                            </div>
                        </div>
                        {   checkListTasks.length > 0 ?
                            <div className='mt-3 form-color width-min col-6' style={{height: areaHeight}}>
                                <div className="formheader flex-center row">
                                    {/* TP-2704 */}
                                    <h2>
                                        <Trans id={this.checkActionType(action, selectedJob)}></Trans> - <Trans>Procedure</Trans>
                                    </h2>
                                </div>
                                <div className="mt-4">
                                    <CheckListTasks 
                                        checkListTasks={checkListTasks} 
                                        selectedSubTask = {checkListTasks[index]}
                                        idx={index}
                                        getChecklistThemeNameOptions={this.getChecklistThemeNameOptions}
                                        handleThemeChange={this.handleThemeChange}
                                        validator={this.validator}
                                        collapseSubTask={this.collapseSubTask}
                                        deleteCheckListTask={this.deleteCheckListTask}
                                        onChangeCheckListTask={this.onChangeCheckListTask}
                                        deleteNewCheckListTask={this.deleteNewCheckListTask}
                                        selectedJob={selectedJob}
                                        themeNames={this.themeNames}
                                        themes={this.state.themes}
                                        isSmallFormFactor={isSmallFormFactor} //TP-2947
                                    />
                                </div>
                            </div>
                            :
                            null
                        }
                    </div>
                    <Loader text={this.state.loadingText} isLoading={this.state.isJobLoading} />
                    <FlashMessage 
                        flashMsgText={this.state.flashMsgText} 
                        showMessage={this.state.showFlashMessage} 
                        flashLeft={this.state.flashLeft}
                        flashTop={this.state.flashTop}
                        updateShowMessage={this.updateShowMessage}
                        isTimerEnabled={true} /**TP-5986*/
                    ></FlashMessage>
                </>
            );
		}
    }
}

export default CheckListForms;