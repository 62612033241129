import React from 'react';
import './Groups.scss';
import moment from 'moment';
import { printConsole, checkDummyEmail, formatMobile } from '../helpers';
import AddUser from './AddUser';
import ChatUser from './ChatUser';
import AddButton from'./Add.png';
import { TableRow, NoDataFoundRow } from './TableRow';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import Actions from './Actions';
import Select from 'react-select';
import makeAnimated from 'react-select/lib/animated';
import Button from '../Button/Button';
import SimpleReactValidator from 'simple-react-validator';
import Loader from 'react-loader-spinner';
import ReactPaginate from 'react-paginate';
import _ from 'lodash';
import SortIcons from './SortIcons';
import ReactHintFactory from 'react-hint';
import { Trans, t } from '@lingui/macro';
import { I18n,i18nMark } from "@lingui/react";
import {AccountConsumer} from"../AccountContext";
import { Modal, ModalBody, ModalHeader, ModalFooter, Button as BButton, Spinner, UncontrolledTooltip } from 'reactstrap';
import {diagonastics, diagnostics} from "../DiagonasticHelper";
import QRPDF from "./qr_pdf";
const DetectRTC = require('detectrtc');

const fileDialog = require('file-dialog');
const ReactHint = ReactHintFactory(React);
const Papa = require('papaparse');

// TZ-36 -- Converting the jSON to CSV format
function convertToCSV(objArray) {
    var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    var str = '';

    for (var i = 0; i < array.length; i++) {
        var line = '';
        for (var index in array[i]) {
            if (line != '') line += ','

            line += array[i][index];
        }

        str += line + '\r\n';
    }

    return str;
}

// TZ-36 -- Exporting jSON data to csv format
function exportCSVFile(headers, items, fileTitle) {
    if (headers) {
        items.unshift(headers);
    }

    // Convert Object to JSON
    var jsonObject = JSON.stringify(items);

    var csv = convertToCSV(jsonObject);

    var exportedFilenmae = fileTitle + '.csv' || 'export.csv';

    var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, exportedFilenmae);
    } else {
        var link = document.createElement("a");
        if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", exportedFilenmae);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}

class Users extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			bNeedPull: true,
			mode: 'view',
			userdata: [{
				id: '',
				email: '',
				first_name: '',
				last_name: '',
				salutation: '',
				password: '',
				created_on: '',
				last_accessed_on: '',
				isadmin: '',
				birth_month: '',
                birth_year: '',
                is_inactive: false,
				isexpert: '',
				isonline: '',
				geolocation: '',
				last_location_report_on: '',
				phonenumber: '',
				accountid: ''
            }],
            retCode: 400,
            errStrReturned: '',
            selectedUser: {},
            assignedTo: null,
            isLoading: false,
            assignedToId: null,
            pageCount: 0,
            pagination: {
                currentPage: 1,
                perPage: props.maxPerPage ? props.maxPerPage : 10, //TP-2883
                filterText: '',
                orderBy: '',
                direction: 'desc'
            },
            numberOfExperts: 0,
            total: 0,
            uploadedCount: 0,
            inviteeCount: 0,
            totalUser: 0,
            userCallResponse: '',
            usersInSession: props.usersInSession, //TP-1599
            toggleCallDialog: false,
            groups: [],
            selectedGroup: '',
            selectedGroupId: '',
            selectedAction: '',
            isIOS: false,
            webcamAvailable: false,
            micAvailable: false,
            userList: [], firstNameBlankRowMsg:'', lastNameBlankRowMsg:'', emailBlankRowMsg:'', isExpertBlankRowMsg:'', invalidMobileRowMsg: "",//T32-480
            isPartOfAGroup: props.isPartOfAGroup ? props.isPartOfAGroup : false /**TP-4814*/
        }
        // initialize validator
        SimpleReactValidator.addLocale(props.language, require(`../../locales/${props.language}/messages.json`));
		this.validator = new SimpleReactValidator({locale: props.language,
			element: message => <div className="groups-form-validation-msg">{message.replace(/The|field/g, '').capitalize()}</div>,
        });
        this.timer = null;
        this.isMount = false;
    }

    checkPlatform = () => {
        let ua = navigator.userAgent.toLowerCase(); 
        let chromeAgent = ua.indexOf("chrome") > -1; 
        let safariAgent = ua.indexOf("safari") > -1; 
        if ((chromeAgent) && (safariAgent)) safariAgent = false; 

        let isIOS = /iPad|iPhone|iPod/.test(navigator.platform) || (safariAgent)
        this.setState({isIOS})
    };

    // TP-614
    checkAudioAvailable = () => {
        return new Promise((resolve, reject) => {
            navigator.mediaDevices.getUserMedia({
                video: false,
                audio: true
            })
            .then((stream) => {
                stream.getTracks().forEach(track => track.stop());
                resolve(true);
            })
            .catch(err => {
                console.log('cannot access microphone');
                console.log(`Device detection Error :: ${err}`);
                resolve(false)
            });
        });
    }

    // MB2- 459
    checkVideoAvialble = () => {
        return new Promise((resolve, reject) => {
            navigator.mediaDevices.getUserMedia({
                video: true,
                audio: true
            })
            .then((stream) => {
                stream.getTracks().forEach(track => track.stop());
                resolve(true);
            })
            .catch(err => {
                console.log('cannot access camera');
                console.log(`Device detection Error :: ${err}`);
                resolve(false)
            });
        });
    };

	onClickAdd = () => {
        // add a new user pop-up
        if (this.props.customer.subscription_status === 'enterprise') {
            this.setState({mode: 'uploadCsv'});
            return;
        }
		this.setState({mode: 'add'});
	}

	onDeleteUser = (e) => {
        e.preventDefault();
        this.setState({mode: 'view', bSubmitted: true});
        this.setState({errStrReturned: i18nMark('Deleting the user')});
        const selectedUser = this.state.selectedUser;
        const {perPage} = this.state.pagination;
        const apiEndpoint = selectedUser.id ? `deleteUser/${selectedUser.id}` : `deleteInvitee/${selectedUser.inviteeid}`;
		this.props.authService.fetch(apiEndpoint, {
			method: 'delete'
		})
		.then(response => {
			this.setState({retCode: response.status});
			if(response.status === 200){
                return response.json();
			} else {
                throw new Error(response.body);
			}
		})
		.then(data => {
            if(data !== null && data !== undefined){
                // Socket signal sent to all users of this account
                // to delete this user from their user list
                // and to Log this user out 
                const json = {
                    userIsDeleted: true,
                    email: selectedUser.email,
                    loginTimeStamp: this.props.last_accessed_on
                }
                printConsole(json);
                this.props.authService.socket.emit('log-me-out', json);
                const users = this.state.userdata.filter(e => {
                    if(selectedUser.id) {
                        return e.id !== selectedUser.id;
                    } else {
                        return e.inviteeid !== selectedUser.inviteeid;
                    }
                });
                this.setState((prev) => ({
                    mode: 'message',
                    userdata: users,
                    total: prev.total - 1,
                    pageCount: Math.ceil(prev.total / perPage),
                    numberOfExperts: selectedUser.isexpert ? prev.numberOfExperts - 1 : prev.numberOfExperts,
                    errStrReturned: i18nMark('User deleted successfully!')
                }), () => this.props.updateuserdata(users)); //TZ-487
                this.props.onStateChange();
                if (this.state.userdata.length === 0) {
                    this.getUsersByAccountId();
                }
			}
		})
		.catch((error) => {
			this.setState({errStrReturned: i18nMark('Something went wrong while deleting the user.')});
		})
	}

	onEditUser = (user) => {
	}

	onPlayStream = (user) => {
	}

	onActionClick = async ({ action, row }) => {
        console.log("Action: ", action);
        printConsole(row); //return;
        const {isexpert} = this.props;
        /* if (action === 'qrConfirmation') {
            this.showQRCode();
        } */
        if (action === 'generatePassword') {
            this.generateNewPassword({user_id: row.id , first_name: row.first_name, last_name: row.last_name, email: row.email, return_pass: false});
            return;
        }
        if (action === 'resendInvite') {
            this.resendInvitationEmail(row.email);
            return;
        }
        if (action === 'callUser') {
            this.getUserGroup({ action, row, callerName: this.props.adminname});
        }
        if (action !== 'callUser' && action !== 'resendInvite'){
            this.setState({ mode: action, selectedUser: row})
        }
    }

    //TP-2493
    getFilteredGroupsForLoggedinUser = (data) => {
        const {groupsData, isexpert, isadmin, userid} = this.props;
        let groupsList = groupsData;
        //console.log(groupsList);
        if(isadmin && isexpert) {
            groupsList = groupsData.map(grp => {
                let [ggg] = grp.users.filter(u => u.value === userid)
                if (ggg !== undefined) 
                    return grp;
            })
            groupsList = groupsList.filter(grp => grp !== undefined)
        }
        //console.log(groupsList);
        let updatedData = [];
        groupsList.forEach(grp => {
            let [tempGroup] = data.filter(d => d.groupid === grp.groupid);
            if (tempGroup !== undefined)
                updatedData.push(tempGroup); 
        })
        //console.log("updtaed group list ::",updatedData);
        return updatedData;
    }

    getUserGroup = ({action, row}) => {
        const {isexpert, session_type} = this.props; //TP-2539
        if(row && row.email){
            this.props.authService.fetch(`getgroups/${row.email}`, {
                method: 'get'
            })
            .then(response => {
                return response.json();
            })
            .then(async (data) => {
                diagonastics(this.props.authService,{
                    action: `clicked on call user`,
                    sequence: 1,
                    next_step: '',
                    data: ``,
                    error: 'none'
                })
                // don't show pop up when there is one matching group
                if (data.length === 1){
                    // if expert then first get the session token and then send the signal-UQ3-186
                    if(!isexpert){
                        this.setState({selectedGroupId: data[0].groupid});//TP-4460
                        const cameraAvailable = await this.checkVideoAvialble();
                        if (!cameraAvailable){
                            this.setState({ mode: 'message', errStrReturned: i18nMark('DevicesNotFoundError')});//MB2-459
                            return false;
                        }
                        this.timer = setTimeout(() => this.cancelCall({ mode: 'timeoutCall', msg: i18nMark(`{recipient} did not pick up the call, please try again later.`) }), window._env_.REACT_APP_API_CALL_TIME_OUT);
                        // TP-1572 -- Setting the isBusy flag for the recipient user to whom the new connect-the-call signal is triggered
                        let {usersInSession} = this.state;
                        usersInSession.push(row.email);
                        this.setState({usersInSession});
                        this.props.setUsersInSession(usersInSession); //TP-1599
                        this.props.authService.socket.emit('connect-the-call', { recipientEmail: row.email, callerName: this.props.adminname, isCancelled: false });
                        this.props.isUserCall(true); //TP-5036
                        this.props.setCalleeList(null);//TP-2381
                        this.props.updateCallDialogStatus(true);
                        this.setState({ mode: action, selectedUser: row, groups: data, userCallResponse: ''})
                    }/**TP-3419 & TP-3119 & TP-2539 & TP-3156 */ else if (isexpert && (session_type === "CM" || (session_type === "RM" && this.props.myCameraOn() === true))) {
                        const cameraAvailable = await this.checkVideoAvialble();
                        if (!cameraAvailable){
                            this.props.setCameraResolution(0, 0); //TP-5429
                            //this.setState({bResponded: true, errStrReturned: i18nMark('DevicesNotFoundError')})// MB2-459
                            //return false;
                        }
                        //TP-5429 -- Only check if mic is connected or not
                        const micAvailable = await this.checkAudioAvailable();
                        if (!micAvailable) {
                            this.setState({ bResponded: true, errStrReturned: i18nMark('MicrophoneNotFoundError')})
                            return false;
                        }
                        this.getSessionTokenForSingleGroup(data, row, action)
                    } else {
                        //TP-3156 & TP-614
                        const micAvailable = await this.checkAudioAvailable();
                        if (!micAvailable) {
                            this.setState({ bResponded: true, errStrReturned: i18nMark('MicrophoneNotFoundError')})
                            return false;
                        }
                        this.getSessionTokenForSingleGroup(data, row, action)
                    }
                }else{
                    let updatedData = this.getFilteredGroupsForLoggedinUser(data);//TP-2493
                    //TP-4751 -- don't show pop up when there is one updated group
                    if (updatedData.length === 1){
                        // if expert then first get the session token and then send the signal-UQ3-186
                        if(!isexpert){
                            this.setState({selectedGroupId: updatedData[0].groupid});//TP-4460
                            const cameraAvailable = await this.checkVideoAvialble();
                            if (!cameraAvailable){
                                this.setState({ mode: 'message', errStrReturned: i18nMark('DevicesNotFoundError')});//MB2-459
                                return false;
                            }
                            this.timer = setTimeout(() => this.cancelCall({ mode: 'timeoutCall', msg: i18nMark(`{recipient} did not pick up the call, please try again later.`) }), window._env_.REACT_APP_API_CALL_TIME_OUT);
                            // TP-1572 -- Setting the isBusy flag for the recipient user to whom the new connect-the-call signal is triggered
                            let {usersInSession} = this.state;
                            usersInSession.push(row.email);
                            this.setState({usersInSession});
                            this.props.setUsersInSession(usersInSession); //TP-1599
                            this.props.authService.socket.emit('connect-the-call', { recipientEmail: row.email, callerName: this.props.adminname, isCancelled: false });
                            this.props.isUserCall(true); //TP-5036
                            this.props.setCalleeList(null);//TP-2381
                            this.props.updateCallDialogStatus(true);
                            this.setState({ mode: action, selectedUser: row, groups: updatedData, userCallResponse: ''})
                        }/**TP-3419 & TP-3119 & TP-2539 & TP-3156 */ else if (isexpert && (session_type === "CM" || (session_type === "RM" && this.props.myCameraOn() === true))) {
                            const cameraAvailable = await this.checkVideoAvialble();
                            if (!cameraAvailable){
                                this.props.setCameraResolution(0, 0);//TP-5429
                                //this.setState({bResponded: true, errStrReturned: i18nMark('DevicesNotFoundError')})// MB2-459
                                //return false;
                            }
                            //TP-5429 -- Only check if mic is connected or not
                            const micAvailable = await this.checkAudioAvailable();
                            if (!micAvailable) {
                                this.setState({ bResponded: true, errStrReturned: i18nMark('MicrophoneNotFoundError')})
                                return false;
                            }
                            this.getSessionTokenForSingleGroup(updatedData, row, action)
                        } else {
                            //TP-3156 & TP-614
                            const micAvailable = await this.checkAudioAvailable();
                            if (!micAvailable) {
                                this.setState({ bResponded: true, errStrReturned: i18nMark('MicrophoneNotFoundError')})
                                return false;
                            }
                            this.getSessionTokenForSingleGroup(updatedData, row, action)
                        }
                    } else {
                        this.setState({
                            groups: updatedData,/**TP-2493 */
                            toggleCallDialog: true,
                            selectedUser: row,
                            selectedAction: action
                        });
                    }
                }
            })
            .catch((error) => {
                printConsole(error)
            })
        }else{
            printConsole('No email of user')
        }
    }

    callUserDialog = (props) => {
        //console.log(props.isSmallFormFactor);
        //TP-2892
        /* if (props.isSmallFormFactor === true ) { /**TP-2947
            const groups = this.calleGroupOptions();
            return(
                <Modal isOpen={this.state.toggleCallDialog} toggle={this.toggleCallUser}>
                    <ModalHeader toggle={this.toggleCallUser}><Trans>Select group</Trans></ModalHeader>
                    <ModalBody>
                        <I18n>
                            {({ i18n }) =>
                                    <select name="groups" id="groups" className='group-dropdown' onChange={this.handleOnChangecalleGroupMobile}>
                                        <option className='group-option hidden-optn' selected disabled value={0} isOnprem={false}>{i18n._(t`Select a group to call`)}</option>
                                        { groups && groups.map(grp => {
                                            return (<option className='group-option' value={grp.value} isOnprem={grp.is_onprem}>{grp.label}</option>)
                                            })
                                        }
                                    </select>                   
                            }
                        </I18n>  
                    </ModalBody>
                </Modal>
            )
        } else { */
            return(
                <Modal isOpen={this.state.toggleCallDialog} toggle={this.toggleCallUser}>
                    <ModalHeader toggle={this.toggleCallUser}><Trans>Select group</Trans></ModalHeader>
                    <ModalBody>
                        <I18n>
                            {({ i18n }) =>
                                <Select
                                    value={this.state.selectedGroup}
                                    components={makeAnimated()}
                                    onChange={this.handleOnChangecalleGroup}
                                    placeholder={i18n._(t`Select a group to call`)}
                                    options={this.calleGroupOptions()}
                                    isSearchable={props.isSmallFormFactor === true? false : true} /**TP-2953 */
                                />                 
                            }
                        </I18n>  
                    </ModalBody>
                </Modal>
            )
        //}
    }

    toggleCallUser = () => {
        this.setState((prev) => (
            { toggleCallDialog : !prev.toggleCallDialog}
        ))
    }

    calleGroupOptions = () => {
        const {groups} = this.state;
        return groups.map(group => {
            return {
               value: group.groupid,
               label: group.group_name,
               is_onprem: group.is_onprem
            }
        }) 
    }

    //API to reset the primary_expert_id
    setPrimaryExpertIDApi = (selectedGroup, userID) => {
        printConsole(selectedGroup.groupid);
        let fetchString = 'setPrimaryExpertID';
        let primary_expert_id = this.props.userid;
        if (userID !== null && userID !== undefined) 
            primary_expert_id = userID;

        this.props.authService.fetch(fetchString, {
            method: 'put',
            body: JSON.stringify({
                groupID: selectedGroup.groupid,
                primary_expert_id
            })
        })
            .then(response => {
                if (response.status >= 200 && response.status < 300) {
                    //return response.json();
                } else {
                    throw response;
                }
            })
            .catch(err => {
                throw err;
            })
    }

    getSessionTokenForSingleGroup = (groupData, row, action) => {
        const {isexpert} = this.props;
        let groupId;
        groupId = groupData[0].groupid;
        let fetchString = 'sessiondataH364?groupID=';
        fetchString += groupId;
        fetchString += '&loggedInUserId=';
        fetchString += this.props.userid;
        this.props.authService.fetch(fetchString, {
            method: 'get'
        })
        .then(response => {
            return response.json();
        })
        .then((data) => {
            /* if(!data.isongoingnow){
                this.setPrimaryExpertIDApi(groupData[0]);
            } */
            if (this.props.log_level >= 3) {
                diagnostics(this.props.authService,{
                    action: `sessionData`,
                    sequence: 3,
                    next_step: '',
                    data: data,
                    error: 'none'
                }) 
            }
            printConsole(data);
            this.props.loadSessionInfo(data);
            if (isexpert) {
                this.props.updateCallDialogStatus(true); //TP-5547
                this.setState({ selectedUser:row }); /**TP-5277	*/
                this.timer = setTimeout(() => this.cancelCall({ mode: 'timeoutCall', msg: i18nMark(`{recipient} did not pick up the call, please try again later.`), groupId }), window._env_.REACT_APP_API_CALL_TIME_OUT);
                this.props.setGroupUsersList(groupId);//TP-1735
                // TP-1572 -- Setting the isBusy flag for the recipient user to whom the new connect-the-call signal is triggered
                let {usersInSession} = this.state;
                usersInSession.push(row.email);
                this.setState({usersInSession});
                this.props.setUsersInSession(usersInSession);//TP-1599
                this.props.authService.socket.emit('connect-the-call', { recipientEmail: row.email, callerName: this.props.adminname, isCancelled: false, source: "user" });
                this.props.isUserCall(true); //TP-5036
                this.props.setCalleeList(null);//TP-2381                
                this.setState({ mode: action, selectedGroupId:groupId, userCallResponse: '' }) //TP-5277
            } else {
                // Technician handling                 
            }

        })
        .catch((error) => {
            printConsole(error)
            diagonastics(this.props.authService,{
                action: `sessiondataH364 has returned error`,
                sequence: 1,
                next_step: '',
                data: `groupID: ${groupId}, loggedInUserId: ${this.props.userid}`,
                error: `error code: ${error.status}`
            })            
            // output error in sessiondata
            if(error.status === 444) {
                this.setState({mode: 'message', errStrReturned: i18nMark('Establishing the live call failed due to a server error (444). Please contact Telepresenz support if this problem persists.')});
            } else {
                this.setState({mode: 'message', errStrReturned: i18nMark('The call could not proceed due to some issues.')});
            }
        })
    }

    getSessionTokenForMultiGroup = (newVal) => {
        let groupId;
        const {selectedUser, selectedAction} = this.state;
        const {isexpert} = this.props;
        groupId = newVal.value;
        let fetchString = 'sessiondataH364?groupID=';
        fetchString += groupId;
        fetchString += '&loggedInUserId=';
        fetchString += this.props.userid;
        this.props.authService.fetch(fetchString, {
            method: 'get'
        })
        .then(response => {
            return response.json();
        })
        .then((data) => {
            /* if(!data.isongoingnow){
                const selectedGroup = { groupid: newVal.value };
                this.setPrimaryExpertIDApi(selectedGroup);
            } */
            printConsole(data);
            this.props.loadSessionInfo(data);
            if (isexpert) {
                this.timer = setTimeout(() => this.cancelCall({ mode: 'timeoutCall', msg: i18nMark(`{recipient} did not pick up the call, please try again later.`), groupId }), window._env_.REACT_APP_API_CALL_TIME_OUT);
                this.props.setGroupUsersList(groupId);//TP-1735
                // TP-1572 -- Setting the isBusy flag for the recipient user to whom the new connect-the-call signal is triggered
                let {usersInSession} = this.state;
                usersInSession.push(selectedUser.email);
                this.setState({usersInSession});
                this.props.setUsersInSession(usersInSession);//TP-1599
                this.props.updateCallDialogStatus(true);
                this.props.authService.socket.emit('connect-the-call', { 
                    recipientEmail: selectedUser.email, 
                    callerName: this.props.adminname, 
                    isCancelled: false,
                    group_id: newVal.value,
                    source: "user"
                });
                this.props.isUserCall(true); //TP-5036
                this.props.setCalleeList(null);//TP-2381
                this.props.updateCallDialogStatus(true);
            }
            else {
                // Technician Handling                
            }
        })
        .catch((error) => {
            printConsole(error)
        })
    }

    getSessionTokenForTechnician = (groupId, groupName) => {
        const {isexpert, audio_mode} = this.props;
        let fetchString = "", apiname;
        //TP-4213 -- New API integration to support Tech2Tech call feature from Users Tab
        if (!isexpert && audio_mode === "tech2Tech") {
            fetchString = 'user_session/callPeer?groupID=';
            apiname = 'callPeer';
        } else {
            fetchString = 'sessiondataH364?groupID=';
            apiname = 'sessionData';
        }
        fetchString += groupId;
        fetchString += '&loggedInUserId=';
        fetchString += this.props.userid;
        printConsole(`${apiname} is called on respond-to-the-call with payload ---> { groupID: ${groupId}, loggedInUserId: ${this.props.userid} }`)
        this.props.authService.fetch(fetchString, {
            method: 'get'
        })
        .then(response => {
            return response.json();
        })
        .then((data) => {
            /* if(!data.isongoingnow){
                const selectedGroup = { groupid: newVal.value };
                this.setPrimaryExpertIDApi(selectedGroup);
            } */
            printConsole(data);
            this.props.loadSessionInfo(data);
            if (!isexpert) {
                // Technician Handling
                this.props.updateCallDialogStatus(false);
                this.props.loadSelectedGroup(groupId, groupName);
                this.props.onRouteChange('session');
            } else {
                // Expert Handling
                this.props.updateCallDialogStatus(false);
                this.props.loadSelectedGroup(groupId, groupName);
                this.props.onRouteChange('session');
            }
        })
        .catch((error) => {
            printConsole(error)
        })
    }

    //TP-2892
    handleOnChangecalleGroupMobile = () => {
        let select = document.getElementById('groups');
        let newVal = {
            value: select.options[select.selectedIndex].value,
            label: select.options[select.selectedIndex].label,
            is_onprem: select.options[select.selectedIndex].isOnprem
        }        
        //console.log(newVal); 
        this.handleOnChangecalleGroup(newVal);
    }

    handleOnChangecalleGroup = async (newVal) => {
        const {selectedUser, selectedAction} = this.state;
        const {isexpert, session_type} = this.props;
        // if expert then first get the session token and then send the signal-UQ3-186
        if (/*TP-3419*/ isexpert && (session_type === "CM" || (session_type === "RM" && this.props.myCameraOn() === true))) {
            //TP-3156
            const cameraAvailable = await this.checkVideoAvialble();
            if (!cameraAvailable){
                this.props.setCameraResolution(0, 0); //TP-5429
                // Close the Group selection box and show the Camera detection error messages
                /* this.setState({
                    bResponded: true, 
                    errStrReturned: i18nMark('DevicesNotFoundError'),
                    selectedGroupId: newVal.value,
                    toggleCallDialog:false
                }) */// TP-580
                //return false;
            }
            //TP-5429 -- Only check if mic is connected or not
            const micAvailable = await this.checkAudioAvailable();
            if (!micAvailable) {
                this.setState({ 
                    bResponded: true, 
                    errStrReturned: i18nMark('MicrophoneNotFoundError'),
                    selectedGroupId: newVal.value,
                    toggleCallDialog:false
                })// TP-580
                return false;
            }
            this.getSessionTokenForMultiGroup(newVal);
        } else if(isexpert){
            /* if (newVal.is_onprem === false) {
                const cameraAvailable = await this.checkVideoAvialble();
                if (!cameraAvailable){
                    // Close the Group selection box and show the Camera detection error messages
                    this.setState({
                        bResponded: true, 
                        errStrReturned: i18nMark('DevicesNotFoundError'),
                        selectedGroupId: newVal.value,
                        toggleCallDialog:false
                    })// TP-580
                    return false;
                }
            } else { */
                //TP-614
                const micAvailable = await this.checkAudioAvailable();
                if (!micAvailable) {
                    this.setState({ 
                        bResponded: true, 
                        errStrReturned: i18nMark('MicrophoneNotFoundError'),
                        selectedGroupId: newVal.value,
                        toggleCallDialog:false
                    })// TP-580
                    return false;
                }
            //}
            this.getSessionTokenForMultiGroup(newVal);
        }else{
            const cameraAvailable = await this.checkVideoAvialble();
            if (!cameraAvailable){
                    this.setState({ mode: 'message', errStrReturned: i18nMark('DevicesNotFoundError')});//MB2-459
                    this.toggleCallUser();
                    return false;
            }
            this.timer = setTimeout(() => this.cancelCall({ mode: 'timeoutCall', msg: i18nMark(`{recipient} did not pick up the call, please try again later.`) }), window._env_.REACT_APP_API_CALL_TIME_OUT);
            // TP-1572 -- Setting the isBusy flag for the recipient user to whom the new connect-the-call signal is triggered
            let {usersInSession} = this.state;
            usersInSession.push(selectedUser.email);
            this.setState({usersInSession});
            this.props.setUsersInSession(usersInSession);//TP-1599
            this.props.authService.socket.emit('connect-the-call', { 
                recipientEmail: selectedUser.email, 
                callerName: this.props.adminname, 
                isCancelled: false,
                group_id: newVal.value
            });
            this.props.isUserCall(true); //TP-5036
            this.props.setCalleeList(null);//TP-2381
        }
        this.props.updateCallDialogStatus(true);
        this.setState({ 
            selectedGroupId: newVal.value,
            mode: selectedAction,
            toggleCallDialog:false,
            userCallResponse: ''
        });
    }

	getUsersByAccountId = () => {
        const { perPage, currentPage, filterText, orderBy, direction } = this.state.pagination;
		if(this.props.accountid){
            printConsole("Calling getusersbyaccountid API");
            this.setState({ isLoading: true });
			let fetchString = 'getusersbyaccountid/';
            fetchString += this.props.accountid; //by account id
            fetchString += `?perpage=${perPage}&currentpage=${currentPage}`;
            if (!this.props.isadmin) {
                fetchString += `&userId=${this.props.userid}`;
            }
            if (filterText) {
                fetchString += `&filter=${filterText}`;
            }
            if (orderBy) {
                fetchString += `&sortBy=${orderBy}`;
            }
            if (direction) {
                fetchString += `&direction=${direction}`;
            }
            
			this.props.authService.fetch(fetchString, {
                method: 'get'
			})
			.then(response => response.json())
			.then(data => {
			 	if(data !== undefined && data !== null){
                    this.setState({
                        userdata: data.users,
                        isLoading: false,
                        total: data.total,
                        pageCount: Math.ceil(data.total / perPage),
                        numberOfExperts: data.numberOfExperts
                    }, () => {
                        //TP-566
                        if(this.props.autoCall && this.props.userIdForAutocall && this.props.userIdForAutocall !== ''){
                            this.findUserForAutoCall()
                        }
                        this.getUserProfilePictures();
                        this.props.updateuserdata(data.users)//TZ-487
                    });
			 	}
			})
			.catch(err => {
                this.setState({ isLoading: false });
                printConsole(err.message)
            })
		}
	}

    //TP-3746
    getUserProfileSettings = () => {
		if(this.props.userid){
            //this.setState({ isLoading: true });
			let fetchString = 'user/getProfileData/';
            fetchString += this.props.userid; //by account id
            fetchString += "/true"; //to fetch the image as well

            //TP-3672
            this.props.authService.fetch(fetchString, {
                method: 'get'
			})
			.then(response => response.json())
			.then(data => {
			 	if(data !== undefined && data !== null){       
                    //console.log(data);
                    this.props.loadUserProfile(data); //TP-3328 
                    //TP-3735 & TP-3506 -- Handled the "" for image when deleted
                    if (data.my_picture !== undefined && data.my_picture !== "" && data.my_picture !== null) {
                        this.props.updateMyProfilePicture(data.my_picture);                         
                    }
                    //TP-5431 & TP-3821
                    if (this.props.defaultHeight && this.props.defaultHeight !== 0 && this.props.defaultWidth && this.props.defaultWidth !== 0)
                        this.props.setCurrentStreamingRes(data.streaming_quality);                       
			 	}
			})
			.catch(err => {
                this.setState({ isLoading: false });
                printConsole(err.message)
            })
		} else {
            printConsole("User profile data can't be fetched since user Id not known");
        }
	}

    getUserProfilePictures = (userArr=null) => {
        const {userdata} = this.state; 
        if (userdata === undefined) return; //TP-4709
        printConsole("Calling the getProfilePicture API");//TP-3459
        let isAllUsersfetch = false;
        if (userArr === null) {
            isAllUsersfetch = true;
            userArr = [];
            userdata.forEach(u => {
                userArr.push(u.id)
            })
        } 
        if (userArr.length > 0) {
            let fetchString = 'user/getProfilePicture/';
            const params = {
                user_ids: userArr
            }
            
			this.props.authService.fetch(fetchString, {
                method: 'post',
                body: JSON.stringify(params)
			})
			.then(response => response.json())
			.then(data => {
			 	if(data !== undefined && data !== null){
                    if (isAllUsersfetch === true) {
                        this.props.updateProfilePic(data);
                        userdata.forEach(u => {
                            data.forEach(d => {
                                //TP-3497
                                if(d.user_id === u.id && d.my_picture)
                                    u.myPicture = d.my_picture;
                            })
                        })
                    }
                    else {
                        this.props.updatePrfilePicInArray(data[0]);
                        /**TP-3672 -- Update my own Header Profile picture when */
                        if (userArr.length === 1 && userArr[0] === this.props.userid) {
                            let url;
                            if (data[0] && data[0] !== null && data[0].my_picture.includes("base64"))
                                url = data[0].my_picture.split(';base64,')[1];
                            else { //TP-3497
                                url = data[0].my_picture;
                            }
                            this.props.updateMyProfilePicture(url);
                        }
                        userdata.forEach(u => {
                            //TP-3506 & TP-3497
                            if(data[0].user_id === u.id && data[0].my_picture && data[0].my_picture !== "")
                                u.myPicture = 'data:image/jpg;base64,'+ data[0].my_picture;
                            else if (data[0].user_id === u.id && data[0].my_picture === "") 
                                u.myPicture = "";
                        })
                    }

                    this.setState({ userdata }, () => {
                        //console.log(this.state.userdata);
                    })
			 	}
			})
			.catch(err => {
                this.setState({ isLoading: false });
                printConsole(err.message)
            })
        }
    }

	// prepare the group data
	componentDidMount = () => {
        this.isMount = true;
        const { isexpert } = this.props;
        this.getUsersByAccountId();
        this.getUserProfileSettings();//TP-3746
        this.delayedCallback = _.debounce(this.getUsersByAccountId, 500);
        this.props.authService.socket.on('respond-to-the-call', this.subscribeToRespondToTheCall);
        this.props.authService.socket.on('user-details-updated', this.subscribeToUserDetailsUpdate);
        //this.props.authService.socket.on('user-is-in-session', this.subscribeToUserIsInSession)
        if (isexpert === false) {
            DetectRTC.load(() => {
                this.setState({webcamAvailable: DetectRTC.hasWebcam && DetectRTC.hasMicrophone});
            });
        } else {
            DetectRTC.load(() => {
                this.setState({micAvailable: DetectRTC.hasMicrophone});
            });
        }        
        this.checkPlatform();
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (this.props.usersInSession !== prevProps.usersInSession) {
            this.setState({ usersInSession: this.props.usersInSession }); //TP-1599
        }
        if (this.props.upload_image_user_list !== prevProps.upload_image_user_list && this.props.upload_image_user_list.length > 0) {
            this.getUserProfilePictures(this.props.upload_image_user_list);
        }
        if (this.props.allUsersofAccount !== prevProps.allUsersofAccount && this.props.allUsersofAccount !== null && typeof(Storage) !== "undefined" && sessionStorage.getItem("isGuestUsersTobeDeleted") === "true") {
            //TP-4352
            if (this.props.allUsersofAccount && this.props.allUsersofAccount.length > 0) {
                this.props.allUsersofAccount.forEach(user => {
                    if(user.is_guest === true){
                        this.removeGuest(user.email)
                    }
                })
                sessionStorage.removeItem("isGuestUsersTobeDeleted");
            }
        }
        //TP-6222
        if (this.props.openUserChat !== prevProps.openUserChat && this.props.openUserChat === true && this.props.selectedUserEmail !== '') {
            if (this.state.userdata && this.state.userdata.length > 0) {
                this.state.userdata.forEach((user) => {
                    if (this.state.mode !== 'chat'){
                        if (user.email === this.props.selectedUserEmail) this.onActionClick({ action: 'chat', row: user })
                    } else if (this.state.mode === 'chat' && this.state.selectedUser.email !== this.props.selectedUserEmail && user.email === this.props.selectedUserEmail) {
                        //this.onCloseEditModal();
                        this.setState({ selectedUser: {}, mode: 'view'}, () => {
                            this.onActionClick({ action: 'chat', row: user });                            
                        })
                    }
                }) 
            }
        }
    }

    // TP-1534 -- Check if there are other Expert users in that session
    isAnotherExpertInSession = (groupId) => {
        const {inSessionArr, isexpert} = this.props;
        printConsole("in session array");
        printConsole(inSessionArr);
        const expertUsers = this.state.userdata.filter(u => u.isexpert);
        printConsole(expertUsers);
        let flag = false;
        for (let ii = 0; ii<inSessionArr.length; ii++) {
            if (expertUsers.some(ex => ex.email === inSessionArr[ii].userName) === true && groupId === inSessionArr[ii].groupId) {
                flag = true;
                break;
            }
            else
                flag = false;
        }
        printConsole(`is Passive expert ${flag}`);
        return flag;
    }
    
    subscribeToRespondToTheCall = ({ action, groupName, groupId, recipient }) => {
        if (!this.isMount || this.props.activePage !== "Users") return;
        printConsole("User's tab listener for respond-to-the-call");
        let msg;
        const {isexpert, isadmin} = this.props;
        const {selectedGroupId} = this.state;
        // Clear the timer if there is already a timeout.
        if (this.timer && action !== "timeout") clearTimeout(this.timer);
        if (action === 'accepted') {
            if (isexpert) {
                // Expert Handling
                const isPassive = this.isAnotherExpertInSession(groupId) //TP-1534
                if (isPassive) 
                    this.getSessionTokenForTechnician(groupId, groupName); 
                else {
                    this.props.updateCallDialogStatus(false);
                    this.props.loadSelectedGroup(groupId, groupName);
                    this.props.onRouteChange('session');
                }
            } else
                this.getSessionTokenForTechnician(groupId, groupName);            
            return;
        } else if(action === 'declined') {
            this.props.updateCallDialogStatus(false);
            msg = i18nMark('User has declined your call. Please try later.');
            if (isexpert && selectedGroupId !== ''){
                this.clearSessionToken(selectedGroupId);
            }
            //TP-5903 -- Do not send the missed call notification during the declined call flow
            this.cancelCall({ mode: 'declinedUser', msg: i18nMark(`{recipient} did not pick up the call, please try again later.`) });
        } else if(action === 'auto-decline') {
            //TP-6077
            this.props.updateCallDialogStatus(false);
            msg = i18nMark('User has declined your call. Please try later.');
            if (isexpert && selectedGroupId !== ''){
                this.clearSessionToken(selectedGroupId);
            }
            //Send the missed call notification during the auto-declined call flow
            this.cancelCall({ mode: 'callUser', msg: i18nMark(`{recipient} did not pick up the call, please try again later.`) });
        } else if (action === 'in_session') {
            //TP-4228 -- Join the ongoing call if by same groupid else drop the call attempt only for Technician
            if (!isexpert && !isadmin && selectedGroupId && groupId && groupId === selectedGroupId) {
                this.getSessionTokenForTechnician(groupId, groupName);    
            } else {
                this.props.updateCallDialogStatus(false);
                msg = i18nMark('User is already on a call with another user, please try again later.');
            }
        } else if (action === 'no_group_found') {
            this.props.updateCallDialogStatus(false);
            msg = i18nMark('You are not currently setup to call {recipient}');
        } /* TP-5903 else if (action === 'timeout') {
            this.props.updateCallDialogStatus(false);
            msg = i18nMark(`{recipient} did not pick up the call, please try again later.`);            
        } */
        //TP-2420
        if (msg !== undefined) {
            this.setState({
                userCallResponse: msg
            });
        }
    }

    //TP-6431
    subscribeToUserDetailsUpdate = (data) => {
        const {userId} = data;
        const {userdata} = this.state;
        const [user] = userdata.filter(u => u.id === userId)
        if(user){
            this.getUsersByAccountId()
        }
    }
    // Commented out this method due to the changes done for TP-1599
    /* subscribeToUserIsInSession = ({ isInSession, userName, caller, groupId }) => {
        printConsole("payload for user-is-in-session signal received");
        printConsole(`{isInSession: ${isInSession}, userName: ${userName}, caller ${caller}, groupId ${groupId}}`)
        let { usersInSession } = this.state;
        //MB2-279
        if(isInSession) {
            if (userName) usersInSession.push(userName);
            if (caller) usersInSession.push(caller);
        } else {
            if (userName) usersInSession = usersInSession.filter(e => ![userName].includes(e));
            if (caller) usersInSession = usersInSession.filter(e => ![caller].includes(e));
        }
        this.setState({ usersInSession });
    } */


	searchUsers = (event) => {
        event.persist();
        const { pagination } = this.state;
        this.setState({ 
            pagination: {
                ...pagination,
                currentPage: 1,
                filterText: event.target.value,
                orderBy: 'first_name'
            }
        }, () => {
            this.delayedCallback();
        });
	}

	sortTable = (orderByColumn) => {
        const { pagination } = this.state;
        this.setState({ 
            pagination: {
                ...pagination,
                currentPage: 1,
                orderBy: orderByColumn,
                direction: pagination.direction === 'desc' ? 'asc' : 'desc'
            }
        }, () => {
            this.delayedCallback();
        });
    }
    
    onAssignToPurchaseItem = (e, selectedUser) => {
		e.preventDefault();
		if (!this.validator.allValid()) {
			this.validator.showMessages();
			// rerender to show messages for the first time
			this.forceUpdate();
			return;
		}
		this.setState({ isLoading: true });
		this.props.authService.fetch('assignUserToPurchase', {
			method: 'PUT',
			body: JSON.stringify({
                purchaseId: this.state.assignedToId,
                email: selectedUser.email
			})
		})
		.then(response => {
			this.setState({retCode: response.status});
			if(response.status === 200){
				return response.json();
			} else {
				throw new Error(response.body);
			}
		})
		.then(data => {
			if(data !== null && data !== undefined){
                const users = this.state.userdata.map(p => {
                    if (p.email === selectedUser.email) {
                        return {
                            ...p,
                            is_inactive: false,
                            purchase_id: this.state.assignedToId
                        }
                    }
                    return p;
                });  
                const updatedPurchases = this.props.purchases.map(p => {
                    if (p.id === this.state.assignedToId) {
                        return {
                            ...p,
                            available_credits: p.available_credits - 1
                        }
                    }
                    return p;
                });  

                this.setState({
                    isLoading: false,
                    mode: 'message',
                    userdata: users,
                    assignedToId: null,
                    assignedTo: null,
                    errStrReturned: i18nMark('User successfully assign to a purchase!')
                }, () => this.props.updateuserdata(users)); //TZ-487

                this.props.onUpdatePurchases(updatedPurchases);
			}
		})
		.catch((error) => {
            this.setState({
                isLoading: false,
                mode: 'message'
            });
			// output error in login
			this.setState({errStrReturned: i18nMark('Failed to assign the user to a purchase')});
		})
    }

	addedUser = (userDetails) => {
        let numberOfExperts = 0;
        if (this.state.mode === 'edit') {
            const users = this.state.userdata.map(e => {
                if(e.email === userDetails.email) {
                    if (!e.isexpert && userDetails.isexpert) numberOfExperts += 1;
                    if (e.isexpert && !userDetails.isexpert) numberOfExperts -=1;
                    return {
                        ...e,
                        ...userDetails 
                    }
                }
                return e;
            })
            this.setState((prev) => ({
                userdata: users,
                numberOfExperts: parseInt(prev.numberOfExperts) + numberOfExperts
            }), () => this.props.updateuserdata(users)); //TZ-487
        } else {
            if (userDetails.isexpert) numberOfExperts +=1;
            this.setState(prevState => ({
                userdata: [
                    ...prevState.userdata,
                    userDetails
                ],
                numberOfExperts: parseInt(prevState.numberOfExperts) + numberOfExperts
            }), () => this.props.updateuserdata(this.state.userdata)); //TZ-487
        }
        this.props.onStateChange(userDetails);
    }

    onCloseEditModal = () => {
        this.setState({
            mode: 'view',
            userList: [], firstNameBlankRowMsg: '', lastNameBlankRowMsg: '', emailBlankRowMsg: '', isExpertBlankRowMsg: '' //T32-480
        })
    }

    handleOnChangePurchase = (newVal) => {
        this.setState({ assignedTo: newVal, assignedToId: newVal.value });
    }

    //TP-6222
    updateChatUser = (user_email) => {
        this.state.userdata.forEach(user => {
            if (user_email === user.email) this.setState({ selectedUser: user });
        })
    }

    componentWillReceiveProps = (nextProps) => {
        if (this.props.purchases.length !== nextProps.purchases.length) {
           const users = this.state.userdata.map(user => {
                const purchases = [...nextProps.purchases];
                const found = purchases.find(e => (e.id || e.value) === user.purchase_id);
                if (!found && !user.isadmin) {
                    return {
                        ...user,
                        is_inactive: true
                    }      
                }
                if (!found && user.isadmin && user.isexpert) {
                    return {
                        ...user,
                        isexpert: false
                    }
                }
                return user;
           })
           this.setState({ userdata: users }, () => this.props.updateuserdata(users)); //TZ-487
        } else if (nextProps.purchases.length === 0) {
            //TP-4708
            if (this.state.userdata !== undefined) {
                this.state.userdata.map(user => {
                    if (user.isadmin) {
                        return {
                            ...user,
                            purchase_id: null,
                            isexpert: false
                        }
                    }  
                    return user;
                })
            }
        }else if(nextProps.isChanged !== this.props.isChanged || ((nextProps.refreshUser !== this.props.refreshUser) && nextProps.refreshUser)){
            // refresh the groups and users tab to not show the removed guest users NS2-116
            this.getUsersByAccountId();
        } 
        /**TP-2883 */
        if(nextProps.maxPerPage !== this.props.maxPerPage) {
            this.setState({ pagination: { currentPage: 1, perPage: nextProps.maxPerPage, filterText: '',
                        orderBy: '', direction: "desc" }}, () => {
                const {perPage, currentPage} = this.state.pagination;
                //console.log(perPage);
                this.setState({
                    pageCount: Math.ceil(this.state.total / perPage)
                }, ()=> {
                    this.getUsersByAccountId();
                });
            });
        }
        if(nextProps.myPicture !== this.props.myPicture && this.state.userdata.length > 0) {
            let userdata = this.state.userdata;            
            userdata.forEach(u => {
                if(u.id === this.props.userid)
                    u.myPicture = 'data:image/jpg;base64,'+ this.props.myPicture;
            })

            this.setState({userdata});
        }
        //TP-4814
        if (nextProps.isPartOfAGroup !== this.props.isPartOfAGroup) {
            this.setState({ isPartOfAGroup: nextProps.isPartOfAGroup }, () => {
                printConsole(this.state.isPartOfAGroup);
            });
        }
    }

    getPurchasesForSelect = () => {
        let str = i18nMark('Available Licenses');
        return this.props.purchases
        // .filter(p => p.available_credits > 0)
        .map(p => {
            return {
                value: p.id,
                available_credits:  p.available_credits,
                label: `${p.plan_name}. ${str}: ${p.available_credits}`
            }
        });
    }

    onDownloadCsvTemplate = (e) => {
        const {customer} = this.props;//TP-5587
        e.preventDefault();
        const save_link = document.createElementNS(
            "http://www.w3.org/1999/xhtml",
            "a"
        );
        save_link.href = customer.sms_support ? '/newUploadUserCsvTemplate.csv': '/uploadUserCsvTemplate.csv';
        save_link.download = 'uploadUserCsvTemplate.csv';
        document.body.append(save_link);
        save_link.click();
        document.body.removeChild(save_link)
    }

    onUploadUsers = (e) => {
        e.preventDefault();
        const {customer} = this.props;//TP-5587
        fileDialog({ multiple: false, accept: '.csv' })
        .then(([file]) => {
            this.setState({ isLoading: true });
            let newTemplate, numberOfLines = 0, expertsCount = 0, noOfRows = 1, isEmptyData, isCsvValid= true, userList= [], skip = false, row_data= {},
            firstNameBlankRows= [], lastNameBlankRows= [], emailBlankRows= [], isExpertBlankRows= [], invalidMobileRows= [], isFirstNameBlank, isLastNameBlank, isEmailBlank, isExpertBlank, isPhoneBlank, isPhoneInvalid,
            invalidMobileRowMsg;//T32-480,TP-5257,5587
            Papa.parse(file, {
                header : true,
                skipEmptyLines: true,
                // newline: "\r\n",
                step: (row, parser) => {
                    try{
                        parser.pause(); // pause the parser
                        const first_row_data = row.data[0];
                        newTemplate = ('Mobile *' in first_row_data) && customer.sms_support;//TP-5257,5587
                        noOfRows +=1;
                        [isEmptyData, skip, row_data, isFirstNameBlank, isLastNameBlank, isEmailBlank, isExpertBlank, isPhoneBlank, isPhoneInvalid] = newTemplate ? this.findInvalidFieldForNewTemplate({row: row.data[0]}) : this.findInvalidFieldForOldTemplate({row: row.data[0]});//T32-480,TP-5257,5587
                        // if (('FirstName' in first_row_data) && ('LastName' in first_row_data) && ('Email' in first_row_data) && ('Telephone' in first_row_data) && ('UniqueDeviceSerialNumberForTechnician' in first_row_data) && ('IsExpert' in first_row_data)) {
                        if(isFirstNameBlank) firstNameBlankRows.push(noOfRows); //push the row number to be shown later
                        if(isLastNameBlank) lastNameBlankRows.push(noOfRows); //push the row number to be shown later
                        if(newTemplate){
                            if(isEmailBlank && isPhoneBlank){
                                emailBlankRows.push(noOfRows); //push the row number to be shown later, either phone or email is blank
                            }
                            if(isPhoneInvalid){
                                invalidMobileRows.push(noOfRows);//TP-6202
                            }
                        }else{
                            if(isEmailBlank) emailBlankRows.push(noOfRows); //push the row number to be shown later, either phone or email is blank
                        }
                        if(isExpertBlank) isExpertBlankRows.push(noOfRows); //push the row number to be shown later
                        // Now check object keys value, if it exists
                        if(newTemplate){
                            if(isEmptyData && ('Mobile *' in first_row_data) && ('UniqueDeviceSerialNumberForTechnician (Optional)' in first_row_data && !isPhoneInvalid)){
                                // check whether the row is empty
                                if(!skip){
                                    numberOfLines += 1; userList.push(row_data);
                                }
                                parser.resume();
                            }else{
                                //some key or column data is missing, continue parsing to scan the csv in one go and collectively show message
                                isCsvValid = false;
                                parser.resume();
                            }
                        }else{
                            if(isEmptyData && ('Telephone (Optional)' in first_row_data) && ('UniqueDeviceSerialNumberForTechnician (Optional)' in first_row_data)){
                                // check whether the row is empty
                                if(!skip){
                                    numberOfLines += 1; userList.push(row_data);
                                }
                                parser.resume();
                            }else{
                                //some key or column data is missing, continue parsing to scan the csv in one go and collectively show message
                                isCsvValid = false;
                                parser.resume();
                            }
                        }
                    }catch(error){
                        printConsole('error while parsing csv', error);
                    }
                
                },
                complete: (results) => {
                    /** Check whether number of experts in the file should alwas less than allowed experts 
                        *  (DIsabled account level restriction)  https://care4d-telepresenz.atlassian.net/browse/T22-125
                        * */
                    // if ((expertsCount + parseInt(this.state.numberOfExperts)) > parseInt(this.props.customer.number_of_experts)) {
                    //     this.setState({
                    //         isLoading: false,
                    //         mode: 'message',
                    //         retCode: 400,
                    //         errStrReturned: `You can't add more than ${this.props.customer.number_of_experts} expert(s)`
                    //     });
                    //     return;
                    // }
                    /** check whether if there are any errors in the result object, of found ignore the upload */
                    try{
                        if(results.errors.length > 0 || !isCsvValid){
                            let errorMsg = '', isNoColumnBlank = (firstNameBlankRows.length <= 0) && (lastNameBlankRows.length <= 0) && (emailBlankRows.length <= 0) && (isExpertBlankRows.length <= 0) && (invalidMobileRows.length <= 0),
                            firstNameBlankRowMsg = '', lastNameBlankRowMsg = '', emailBlankRowMsg = '', isExpertBlankRowMsg = '';
                            if(firstNameBlankRows.length > 0) errorMsg += `FirstName is blank,`; firstNameBlankRowMsg = firstNameBlankRows.join(', ');
                            if(lastNameBlankRows.length > 0) errorMsg += `LastName is blank,`; lastNameBlankRowMsg = lastNameBlankRows.join(', ');
                            if(emailBlankRows.length > 0) errorMsg += newTemplate ? `Email and Mobile are blank,` : `Email is blank,` ; emailBlankRowMsg = emailBlankRows.join(', ');
                            if(isExpertBlankRows.length > 0) errorMsg += `IsExpert is blank`; isExpertBlankRowMsg = isExpertBlankRows.join(', ');
                            if(invalidMobileRows.length > 0) errorMsg = `Mobile is invalid`; invalidMobileRowMsg = invalidMobileRows.join(', ')
                            this.setState({
                                isLoading: false, mode: 'message',
                                retCode: 400, firstNameBlankRowMsg, lastNameBlankRowMsg, emailBlankRowMsg, isExpertBlankRowMsg, invalidMobileRowMsg,
                                errStrReturned: isNoColumnBlank ? i18nMark('Format is incorrect. Please verify against downloaded template and try again')
                                : i18nMark(`${errorMsg}`) 
                            });
                            return;//if error or csv file is invalid
                        }
                        const totalLicenses = this.props.purchases.reduce((prev, cur) => { return prev.available_credits + cur.available_credits; }, { available_credits: 0 });
                        this.setState({totalUser: numberOfLines, userList})
                        if(totalLicenses=== 0 && totalLicenses < numberOfLines){
                            this.setState({
                                isLoading: false,
                                mode: 'message',
                                retCode: 400,
                                errStrReturned: i18nMark('The number of users in CSV exceeds the number of licenses purchased. Please correct and upload CSV again or contact telepresenz support team to increase the lincese count.')
                            });
                            return;
                        }
                        this.uploadUsers(file);
                    }catch(error){
                        printConsole('error after parsing of csv', error);
                    }
                },
                error: (e) => {
                    this.setState({
                        isLoading: false,
                        mode: 'message',
                        retCode: 400,
                        errStrReturned: i18nMark('Format is incorrect. Please verify against downloaded template and try again')
                    });
                }
            });
        })
        .catch(e => {
            this.setState({ isLoading: false });
            printConsole(e)
        })
    }

    uploadUsers = (file) => {
        const {perPage} = this.state.pagination;
        // const fd = new FormData(); fd.append("Content-Type", file.type); fd.append("file", file);
        this.props.authService.fetch('account_user/addEnterpriseUser', {
            method: "POST",
            body: JSON.stringify({
                userList: this.state.userList //T32-480
			})
        })
        .then(response => {
            this.setState({retCode: response.status});
            if (response.status === 200) {
                return response.json()
            }
            throw response;
        })
        .then(({uploadedCount, existingInvitees, url, currentPageInvitees = [], numberOfExperts}) => {
            let newUserCount = this.state.totalUser - existingInvitees.length
            let currentPageInviteesAdded = [];
            //printConsole(parseInt(this.state.total) + uploadedCount);
            ///printConsole(perPage);
            if ((parseInt(this.state.total) + uploadedCount) > perPage) {
                if (parseInt(this.state.total) < perPage) {
                    let newCount = perPage - this.state.total;
                    currentPageInviteesAdded = currentPageInvitees.slice(0, newCount);
                }
            }
            //printConsole(currentPageInviteesAdded);
            this.setState((prev) => ({
                isLoading: false,
                mode: 'message',
                uploadedCount: uploadedCount,
                inviteeCount: existingInvitees.length,
                errStrReturned: existingInvitees.length ? i18nMark('UploadedCsvMessage') : i18nMark('Uploaded {uploadedCount} of {newUserCount} new user(s) successfully.'),
                pagination: {
                    ...prev.pagination
                },
                // FQ3-385. All the below state are updating in getUsersByAccountId
                // userdata: ((parseInt(prev.total) + uploadedCount) > perPage) ? [
                //     ...prev.userdata,
                //     ...currentPageInviteesAdded
                // ] :  [
                //     ...prev.userdata,
                //     ...currentPageInvitees
                // ],
                // total: parseInt(prev.total) + uploadedCount,
                // pageCount: Math.ceil((parseInt(prev.total) + uploadedCount) / perPage),
                // numberOfExperts: prev.numberOfExperts + numberOfExperts
            }));
            this.getUsersByAccountId();//FQ3-385
            this.props.onStateChange();
        })
        .catch(e => {
            this.setState({
                isLoading: false,
                mode: 'message',
                errStrReturned: i18nMark('Users upload failed.')
            });
        })
    }

    // T32-480
    findInvalidFieldForOldTemplate = ({row}) => {
        let isEmptyData = true, skip = false, row_data = {}, isFirstNameBlank, isLastNameBlank, isEmailBlank, isExpertBlank;
        try{
            // we have to also find rows where multiple coulmns are blank so if condition and not if else
            if((row['FirstName (Mandatory)'] === undefined || row['FirstName (Mandatory)'].trim() === '') && (row['LastName (Mandatory)'] === undefined || row['LastName (Mandatory)'].trim() === '' ) &&
            (row['Email (Mandatory)'] === undefined || row['Email (Mandatory)'].trim() === '' ) && (row['IsExpert (Mandatory)'] === undefined || row['IsExpert (Mandatory)'].trim() === '' )){
                skip = true;// all the fields are blank
            }
            if((row['FirstName (Mandatory)'] === undefined || row['FirstName (Mandatory)'].trim() === '') && !skip){
                isEmptyData = false; isFirstNameBlank= true;//only first name is blank
            }
            if((row['LastName (Mandatory)'] === undefined || row['LastName (Mandatory)'].trim() === '')  && !skip){
                isEmptyData = false; isLastNameBlank= true;//only last name is blank
            }
            if((row['Email (Mandatory)'] === undefined || row['Email (Mandatory)'].trim() === '')  && !skip){
                isEmptyData = false; isEmailBlank= true;// only email is blank
            }
            if((row['IsExpert (Mandatory)'] === undefined || row['IsExpert (Mandatory)'].trim() === '' )  && !skip){
                isEmptyData = false; isExpertBlank= true;// only is expert is blank
            }
            // set the data row here. If the pattern changes backend code need not change
            row_data['FirstName']= row['FirstName (Mandatory)']; row_data['LastName']= row['LastName (Mandatory)']; row_data['Email']= row['Email (Mandatory)']; 
            row_data['IsExpert']= row['IsExpert (Mandatory)']; row_data['Telephone']= row['Telephone (Optional)']; row_data['UniqueDeviceSerialNumberForTechnician']= row['UniqueDeviceSerialNumberForTechnician (Optional)'];
    
            return [isEmptyData, skip, row_data, isFirstNameBlank, isLastNameBlank, isEmailBlank, isExpertBlank];
        }catch(error){
            return [false, skip, row_data, isFirstNameBlank, isLastNameBlank, isEmailBlank, isExpertBlank];
        }
    }

    // T32-480,5587
    findInvalidFieldForNewTemplate = ({row}) => {
        let isEmptyData = true, skip = false, row_data = {}, isFirstNameBlank, isLastNameBlank, isEmailBlank, isExpertBlank, isPhoneBlank, isPhoneInvalid;
        try{
            // we have to also find rows where multiple coulmns are blank so if condition and not if else
            if((row['FirstName (Mandatory)'] === undefined || row['FirstName (Mandatory)'].trim() === '') && (row['LastName (Mandatory)'] === undefined || row['LastName (Mandatory)'].trim() === '' ) &&
            (row['Email (Optional)'] === undefined || row['Email (Optional)'].trim() === '' ) && (row['IsExpert (Mandatory)'] === undefined || row['IsExpert (Mandatory)'].trim() === '' )){
                skip = true;// all the fields are blank
            }
            if((row['FirstName (Mandatory)'] === undefined || row['FirstName (Mandatory)'].trim() === '') && !skip){
                isEmptyData = false; isFirstNameBlank= true;//only first name is blank
            }
            if((row['LastName (Mandatory)'] === undefined || row['LastName (Mandatory)'].trim() === '')  && !skip){
                isEmptyData = false; isLastNameBlank= true;//only last name is blank
            }
            if((row['Mobile *'] === undefined || row['Mobile *'].trim() === '')  && !skip){
                isPhoneBlank= true;// only phone is blank
            }
            if((row['Mobile *'] !== undefined && row['Mobile *'].trim() !== '' && formatMobile(row['Mobile *'].trim()).length !== 10) && !skip){
                isPhoneInvalid= true;//TP-6202 only phone is blank
            }
            if((row['Email (Optional)'] === undefined || row['Email (Optional)'].trim() === '')  && !skip){

                isEmailBlank= true;// only email is blank
                if(isPhoneBlank || isPhoneInvalid) isEmptyData = false;//TP-5204 both email and phone is blank
            }
            if((row['IsExpert (Mandatory)'] === undefined || row['IsExpert (Mandatory)'].trim() === '' )  && !skip){
                isEmptyData = false; isExpertBlank= true;// only is expert is blank
            }
            // set the data row here. If the pattern changes backend code need not change
            row_data['FirstName']= row['FirstName (Mandatory)']; row_data['LastName']= row['LastName (Mandatory)'];
            row_data['IsExpert']= row['IsExpert (Mandatory)']; row_data['mobile']= formatMobile(row['Mobile *']); row_data['UniqueDeviceSerialNumberForTechnician']= row['UniqueDeviceSerialNumberForTechnician (Optional)'];
            row_data['Email']= !isEmailBlank ? row['Email (Optional)'] : !isPhoneBlank ? `${row['Mobile *'].trim()}@telepresenz.com` : row['Email (Optional)'] ; //TP-5204

            return [isEmptyData, skip, row_data, isFirstNameBlank, isLastNameBlank, isEmailBlank, isExpertBlank, isPhoneBlank, isPhoneInvalid];
        }catch(error){
            return [false, skip, row_data, isFirstNameBlank, isLastNameBlank, isEmailBlank, isExpertBlank];
        }
    }

    resendInvitationEmail = (email) => {
        this.setState({
            isLoading: true,
            mode: 'view'
        })
		this.props.authService.fetch('resendInviteEmail', {
			method: 'POST',
			body: JSON.stringify({
                email
			})
		})
		.then(response => {
			this.setState({retCode: response.status});
			if(response.status === 200){
				return response.json();
			} else {
				throw new Error(response.body);
			}
		})
		.then(data => {
			if(data !== null && data !== undefined){
                this.setState({
                    isLoading: false,
                    mode: 'message',
                    errStrReturned: i18nMark('Invitation email sent successfully!')
                });
			}
		})
		.catch((error) => {
            this.setState({
                isLoading: false,
                mode: 'message',
                errStrReturned: i18nMark('Failed to send invitation email')
            });
		})
    }

    updateImageQR = async ({src, domain}) => {
        const {generatePassName, generatePassEmail} = this.state;
        const { customer, adminname } = this.props;        
        
        await QRPDF(
            {
                name: generatePassName, 
                email: generatePassEmail, 
                qrCode: src, 
                company_name: customer.company_name,
                admin_name: adminname,
                account_id: customer.account_id,
                env: (window._env_.REACT_APP_ENV === 'production') ? '' : domain.substring(0, 3).toUpperCase()
            });
        //this.setState({ return_pass: false });
    }

    generateNewPassword = ({user_id, first_name, last_name, email, return_pass}) => {
        this.setState({
            isLoading: true,
            generatePassName: first_name+" "+last_name,
            generatePassEmail: email,
            mode: 'view'            
        }) 
        
        //return;
		this.props.authService.fetch('dash/setUserPassword', {
			method: 'PUT',
			body: JSON.stringify({
                user_id,
                return_pass
			})
		})
		.then(response => {
			this.setState({retCode: response.status});
			if(response.status === 200){
				return response.json();
			} else {
				throw new Error(response.body);
			}
		})
		.then (data => {
			if(data !== null && data !== undefined){
                if (return_pass === true) {
                    //console.log("encypted string ----", data.password);
                    let domain = window.location.hostname;
                    //console.log(domain);
                    this.updateImageQR({src: data.password, domain});                    
                    this.setState({
                        isLoading: false,
                        mode: 'passwordMessage',
                        errStrReturned: i18nMark('Generate New Password')
                    });
                } else {
                    this.setState({
                        isLoading: false,
                        mode: 'passwordMessage',
                        errStrReturned: i18nMark('Generate New Password')
                    });
                }
			}
		})
		.catch((error) => {
            let errStrReturned = '';
            if (error.status === 403) {
                errStrReturned = i18nMark('Not Authorized');
            } else if (error.status === 400) {
                errStrReturned = i18nMark('Failed to generate new password');
            } else {
                errStrReturned = i18nMark('Password generation failure');
            }

            this.setState({
                isLoading: false,
                mode: 'passwordMessage',
                errStrReturned
            });
		})
    }

    updateNumberOfExperts = (val) => {
        this.setState((prev) => ({
            numberOfExperts: val ? prev.numberOfExperts +=1 : prev.numberOfExperts -=1
        }));
    }

    cancelCall = ({ mode = 'view', msg = '', groupId= '' }) => {
        const {isexpert} = this.props;
        diagonastics(this.props.authService,{
            action: `cancelling the call`,
            sequence: 3,
            next_step: '',
            data: msg,
            error: 'none'
        })
        if (this.timer) clearTimeout(this.timer);
        this.setState({
            mode: mode === "declinedUser" ? "callUser": mode, /**TP-6103*/
            isLoading: false,
            userCallResponse: msg
        }, () => {
            this.props.updateCallDialogStatus(false);
        })
        //TP-1572 -- Resetting the Call Icon for the user whose call has timed out/cancelled
        let {usersInSession} = this.state;
        usersInSession = usersInSession.filter(e => e !== this.state.selectedUser.email);
        this.setState({usersInSession});
        this.props.setUsersInSession(usersInSession);
        this.props.authService.socket.emit('connect-the-call', { recipientEmail: this.state.selectedUser.email, isCancelled: true, callerName: this.props.adminname, source: "user" });
        this.props.isUserCall(true); //TP-5036
        this.props.setCalleeList(null);//TP-2381
        const isPassive = this.isAnotherExpertInSession(groupId) //TP-1550
        if (!isPassive && isexpert && groupId !== '') {
            this.clearSessionToken(groupId)
        }
        // TP-1129 -- Sending Missed Call Notification to all online users 
        // of a Group call if the call is timeout and no one accepts/declines the call
        if (mode === "callUser" || mode === "timeoutCall" /**TP-5891*/) {
            this.props.authService.socket.emit('send-missed-call-notification', { 
                emails: [this.state.selectedUser.email]
            });
        }
    }

    // TP-1550 -- Close the timeout call Dialog box
    closeTimeoutDialog = () => {
        this.setState({
            mode: 'view',
            isLoading: false,
            userCallResponse: ''            
        }, () => {
            this.props.updateCallDialogStatus(false); 
        })
    }

    clearSessionToken = (groupId) => {
        this.props.authService.fetch('updateGroupToken', {
            method: 'post',
            body: JSON.stringify({
                groupId
            })
        })
        .then(response => response.json())
        .then(data => {
            console.info('token cleared from users tab')
        })
        .catch(err => console.error(err.message))
    }

    //TP-4352
    removeGuest = (guestUser) => {
        printConsole(`remove guest user from user table ${guestUser}`)
        //printConsole(guestUser); return;
        this.setState({ isLoading: true });
        let fetchString = 'deleteGuestUser/'+guestUser;
        this.props.authService.fetch(fetchString, {
            method: 'delete',
        })
            .then(response => {
                this.setState({retCode: response.status});
                if (response.status === 200) {
                    return response.json();
                } else {
                    throw new Error(response.body);
                }
            })
            .then(data => {
                if (data !== null && data !== undefined && data.deleted === true) {
                    //this.getUsersByAccountId();
                    window.location.reload();
                    return;
                } 
                else {
                    printConsole("Delete guest user API returned false");
                }
            })
            .catch((error) => {
                // output error in delete guest
                const retCode = error.status;
                let errStrReturned = i18nMark('Exception error');
                if(retCode === 400) {                    
                    errStrReturned = i18nMark('User not found');
                } else if(retCode === 422) {                    
                    errStrReturned = i18nMark('Invalid Data');
                } else if(retCode === 403) {                    
                    errStrReturned = i18nMark('Unauthorised access');
                } else {
                    errStrReturned = i18nMark('Exception error');
                }

                this.setState({
                    mode: "passwordMessage",
                    errStrReturned
                });
            })
        

    }


    renderSwitch(mode, selectedUser, purchases) {
        const totalLicenses = this.props.purchases.reduce((prev, cur) => { return prev.available_credits + cur.available_credits; }, { available_credits: 0 });
        switch(mode) {
            case 'delete':
                return (
                        <I18n>
                            {({ i18n }) =>

                                <ConfirmModal primaryButton={i18n._(t`Delete`)} 
                                    secondaryButton={i18n._(t`Cancel`)} 
                                    headerText={i18n._(t`Delete User`)} 
                                    message={i18n._(t`Are you sure you want to delete this user?`)} 
                                    onClickSencondary={(e) => this.onCloseEditModal(e)}
                                    onClickPrimary={(e) => this.onDeleteUser(e)}
                                />
                            }
                        </I18n>
                    )
            case 'message':
                let colorText = '#485890';
                if(this.state.retCode !== 200) colorText = 'red';
                return <div className='modale opened'>
					<div className='__modal-dialog'>
						<form>
							<div className="__modal-header">
                                <h4 style={{color: colorText}}><Trans id={this.state.errStrReturned} 
                                    values={{uploadedCount: this.state.uploadedCount, 
                                        newUserCount: this.state.totalUser - this.state.inviteeCount,
                                        existingInvitees_length: this.state.inviteeCount,
                                        firstNameBlankRowMsg: this.state.firstNameBlankRowMsg,// T32-480
                                        lastNameBlankRowMsg: this.state.lastNameBlankRowMsg,// T32-480
                                        emailBlankRowMsg: this.state.emailBlankRowMsg,// T32-480
                                        isExpertBlankRowMsg: this.state.isExpertBlankRowMsg,// T32-480
                                        invalidMobileRowMsg: this.state.invalidMobileRowMsg//TP-6202
                                    }}/>
                                </h4>
							</div>
							<div className="__modal-footer flex-center">
								<button className="btn-green" onClick={(e) =>this.onCloseEditModal(e)}><Trans id='OK'/></button>
							</div>
						</form>
					</div>
                </div>
            case 'passwordMessage':
                let passwordText = '#485890';
                if(this.state.retCode !== 200) passwordText = 'red';
                return <div className='modale opened'>
					<div className='__modal-dialog'>
						<form>
							<div className="__modal-header">
                                <h4 style={{color: passwordText}}><Trans id={this.state.errStrReturned} 
                                    values={{name: this.state.generatePassName, 
                                        email: this.state.generatePassEmail
                                    }}/>
                                </h4>
							</div>
							<div className="__modal-footer flex-center">
								<button className="btn-green" onClick={(e) =>this.onCloseEditModal(e)}><Trans id='OK'/></button>
							</div>
						</form>
					</div>
                </div>    
            case 'assign':
                return <div className='modale opened'>
                    <div className='__modal-dialog'>
                        <form>
                            <div className="__modal-header flex-center">
                                <h4><Trans>Assign User</Trans></h4>
                                <span className="btn-close"  onClick={() =>this.onCloseEditModal()}>&times;</span>
                            </div>
                            <div className="__modal-body">
                                <label><Trans>Assign to</Trans>:
                                    <div style={{marginTop: 5, marginBottom: 10}}>
                                    <I18n>
                                        {({ i18n }) =>
                                            <Select
                                                value={this.state.assignedTo}
                                                components={makeAnimated()}
                                                onChange={this.handleOnChangePurchase}
                                                placeholder={i18n._(t`Select a purchase`)}
                                                options={this.getPurchasesForSelect()}
                                            />
                                        }
                                    </I18n>                                        
                                        <input type="text" readOnly value={this.state.assignedToId} name="purchase" className="hidden"/>
                                        { this.validator.message('purchase', this.state.assignedToId, 'required') }
                                    </div> 
                                </label>
                            </div>
                            <div className="__modal-footer flex-center">
                                <Button showSpinner={this.state.isLoading} onClick={(e) =>this.onAssignToPurchaseItem(e, selectedUser)}><Trans>Assign</Trans></Button>
                            </div>
                        </form>
                    </div>
                </div>
            case 'uploadCsv':
                return <div className='modale opened'>
                    <div className='__modal-dialog'>
                        <form>
                            <div className="__modal-header flex-center">
                                <h4><Trans>Upload Users</Trans></h4>
                                <span className="btn-close"  onClick={() =>this.onCloseEditModal()}>&times;</span>
                            </div>
                            <div className="__modal-body">
                                {/* <a href='/terms&conditions.pdf' download>Download Csv Template</a> */}
                                <Button className="align-center margin-b-10" onClick={this.onDownloadCsvTemplate}><Trans>Download Csv template</Trans></Button>
                                <Button className="align-center margin-b-10" showSpinner={this.state.isLoading} onClick={this.onUploadUsers}><Trans>Upload users</Trans> <Trans>(Csv only)</Trans></Button>
                                <div className="text-center" style={{ color: `${totalLicenses === 0 ? 'red': ''}` }}><Trans id='availbleLicenseCount' values={{licenseCount: totalLicenses }}></Trans></div>
                            </div>
                        </form>
                    </div>
                </div>
            case 'callUser':
                //TP-4228 -- Close out the dialog box automatically after 30 seconds, when response has come
                if (this.state.userCallResponse) {
                    this.dialogTimer = setTimeout(() => {
                        this.cancelCall({ mode : 'view', msg : '', groupId: '' });
                        clearTimeout(this.dialogTimer);
                    }, 30000);
                }
                return <Modal size="sm" isOpen={true} className="call-notification-toast">
                    <ModalHeader className="text-center">
                        { this.state.userCallResponse ? <Trans>Call failed</Trans> : <Trans id="Calling {name}" values={{'name': `${this.state.selectedUser.first_name} ${this.state.selectedUser.last_name}`}}></Trans> }
                    </ModalHeader>
                    <ModalBody className="d-flex justify-content-start align-items-center">
                    {this.state.userCallResponse ? '' : <Spinner className="align-self-center mr-2" type="grow" size="sm" />}
                    {
                        this.state.userCallResponse ?
                        <Trans id={this.state.userCallResponse} values={{recipient: `${this.state.selectedUser.first_name} ${this.state.selectedUser.last_name}`}}></Trans>:
                        <>
                            <Trans id="Calling {name}" values={{'name': `${this.state.selectedUser.first_name} ${this.state.selectedUser.last_name}`}}></Trans>
                            {
                                !this.state.isIOS ?
                                    <audio autoPlay="true" loop="true" src="/incoming_call.mp3"/>
                                : ''
                            }
                        </>
                    }
                    </ModalBody>
                    <ModalFooter>
                        <div className="d-flex justify-content-end">
                            <BButton size="sm" onClick={this.cancelCall}><Trans>Cancel</Trans></BButton>
                        </div>
                    </ModalFooter>
                </Modal>
            case 'timeoutCall': /**TP-1550 */
                return <Modal size="sm" isOpen={true} className="call-notification-toast">
                    <ModalHeader className="text-center">
                        { this.state.userCallResponse ? <Trans>Call failed</Trans> : '' }
                    </ModalHeader>
                    <ModalBody className="d-flex justify-content-start align-items-center">                    
                    {
                        this.state.userCallResponse ?
                            <Trans id={this.state.userCallResponse} values={{recipient: `${this.state.selectedUser.first_name} ${this.state.selectedUser.last_name}`}}></Trans>
                        :
                        ''
                    }
                    </ModalBody>
                    <ModalFooter>
                        <div className="d-flex justify-content-end">
                            <BButton size="sm" onClick={this.closeTimeoutDialog}><Trans>Close</Trans></BButton>
                        </div>
                    </ModalFooter>
                </Modal>
            case 'qrConfirmation':
                return (
                    <I18n>
                        {({ i18n }) =>

                            <ConfirmModal primaryButton={i18n._(t`Yes`)} 
                                secondaryButton={i18n._(t`No`)} 
                                headerText={i18n._(t`Download QR Code`)} 
                                message={i18n._(t`This action will reset the password of`) + selectedUser.first_name + " " + selectedUser.last_name + ". " + i18n._(t`Please ensure`) + " " + selectedUser.first_name + " " +i18n._(t`Logged out for Password reset`) +". \n\n"+ i18n._(t`Are you sure you want to generate a new password for this user?`)} 
                                onClickSencondary={(e) => this.onCloseEditModal(e)}
                                onClickPrimary={() => this.generateNewPassword({user_id: selectedUser.id , first_name: selectedUser.first_name, last_name: selectedUser.last_name, email: selectedUser.email, return_pass: true})}
                            />
                        }
                    </I18n>
                )
            default:
                return '';
        }
    }

    handlePageClick = data => {
        let selected = data.selected;
        const { pagination } = this.state;
        this.setState({ 
            pagination: {
                ...pagination,
                currentPage: selected + 1
            }
        }, () => {
            this.getUsersByAccountId();
        });
    };

    getUserType = (user) => {
        let userType, values;
        const {customerRoles} = this.props;
        //TP-5931
        let classString = '';
        if (user.is_inactive) {
            classString = 'Inactive';
            userType='Inactive'; values={};
        } else if(user.isclicked !== undefined && user.isclicked === false) {
            classString = 'Invitee';
            userType = 'Invitee'; /**TP-5993*/ values={};
        } else if (!user.isadmin && !user.isexpert && user.isclicked) {
            classString = 'Technician';
            if (customerRoles.technician.toLowerCase() === "inspector" || customerRoles.technician.toLowerCase() === "observer" || customerRoles.technician.toLowerCase() === "technician") {
                userType = customerRoles.technician; values={};
            }
            else {
                userType = 'Technician Default'; values={role: customerRoles.technician};
            }
        } else if (user.isadmin && user.isexpert) {
            classString = 'admin-expert';
            if (customerRoles.admin.toLowerCase() === "admin" && customerRoles.expert.toLowerCase() === "expert") {
                userType = 'Admin/Expert'; values={};
            } else if (customerRoles.admin.toLowerCase() === "admin" && customerRoles.expert.toLowerCase() === "supervisor") {
                userType = 'Admin/Supervisor'; values={};
            } else {
                userType = 'Admin/Expert Default'; values={role1: customerRoles.admin, role2: customerRoles.expert};
            }
        } else if (user.isexpert) {
            classString = 'Expert';
            if (customerRoles.expert.toLowerCase() === "expert" || customerRoles.expert.toLowerCase() === "supervisor") {
                userType = customerRoles.expert; values={};
            } else {
                userType = 'Expert Default'; values={role: customerRoles.expert};
            }
        } else if (user.isadmin) {
            classString = 'Admin';
            if (customerRoles.admin.toLowerCase() === "admin"){
                userType = customerRoles.admin; values={};  
            } else {
                userType = 'Admin Default'; values={role: customerRoles.admin};
            }
        }
        return <span className={'user-type-flag '+ classString}>
            <Trans id={userType} values={values} />
        </span>;
    }

    showLoader = () => {
        const { mode, isLoading } = this.state;
        if (mode === 'view' && isLoading) {
            return (
                <div className="loader">
                    <Loader
                        type="ThreeDots"
                        color="#23c97d"
                        height="70"	
                        width="70"
                    />
                </div>
            )
        }
        return '';
    }

    componentWillUnmount = () => {
        this.isMount = false;
        /** Unsubscribe / remove the event listeners while component unmounts */
        this.props.authService.socket.removeListener('respond-to-the-call', this.subscribeToRespondToTheCall);
        this.props.authService.socket.removeListener('user-details-updated', this.subscribeToUserDetailsUpdate);
        //this.props.authService.socket.removeListener('user-is-in-session', this.subscribeToUserIsInSession);
        //clear out the timer also. groups subscribeToRespondToTheCall is getting executed first the component is unmounting so isMount = false and this subscribeToRespondToTheCall not executing
        if (this.timer) clearTimeout(this.timer);
    }

    // TP-566
    findUserForAutoCall = () => {
        printConsole(`auto calling the technician, ${this.props.userIdForAutocall}`)
        const row_user = this.state.userdata.find(user => user.id === parseInt(this.props.userIdForAutocall))
        this.getUserGroup({ action: 'callUser', row: row_user , callerName: this.props.adminname});
    }

    closeModal = () => {
        this.setState({mode: "view", bResponded: false});
    }

    //TP-3423 -- onclick method to open the user info bubble
    openBubble = (row) => {
        const allBubbleEle = document.querySelectorAll('[id^="bubble-"]');
        for(let ii=0; ii<allBubbleEle.length; ii++) {
            allBubbleEle[ii].classList.add("d-none");
        }
		const bubbleEle = document.getElementById("bubble-"+row.id);
		bubbleEle.classList.remove("d-none");
	}

    //TZ-36
    getRoleUser = (user) => {
        let userType;
        const {customerRoles} = this.props;
        if (user.is_inactive) {
            userType='Inactive'; 
        } else if(user.isclicked !== undefined && user.isclicked === false) {
            userType = 'Invitee'; /**TP-5993*/ 
        } else if (!user.isadmin && !user.isexpert && user.isclicked) {
            if (customerRoles.technician.toLowerCase() === "inspector" || customerRoles.technician.toLowerCase() === "observer" || customerRoles.technician.toLowerCase() === "technician") {
                userType = customerRoles.technician; 
            }
            else {
                userType = 'Technician'; 
            }
        } else if (user.isadmin && user.isexpert) {
            if (customerRoles.admin.toLowerCase() === "admin" && customerRoles.expert.toLowerCase() === "expert") {
                userType = 'Admin/Expert'; 
            } else if (customerRoles.admin.toLowerCase() === "admin" && customerRoles.expert.toLowerCase() === "supervisor") {
                userType = 'Admin/Supervisor'; 
            } else {
                userType = 'Admin/Expert';
            }
        } else if (user.isexpert) {
            if (customerRoles.expert.toLowerCase() === "expert" || customerRoles.expert.toLowerCase() === "supervisor") {
                userType = customerRoles.expert; 
            } else {
                userType = 'Expert'; 
            }
        } else if (user.isadmin) {
            if (customerRoles.admin.toLowerCase() === "admin"){
                userType = customerRoles.admin;   
            } else {
                userType = 'Admin'; 
            }
        }
        return userType;            
        //return i18n._(userType, { values: { values } });
    }

    getDateString = (_date) => {
        let date = _date ? moment(_date) : '';
        if (date !== ''){
            return date.format("DD MMMM YYYY");
        }else{
            return ''
        }
    }

    // onClick of Download as CSV Icon
    downloadAsCsv = async () => {
        var headers = {            
            first_name: "First Name",
            last_name: "Last Name",
                // TZ-53 email: "Email", 
            phonenumber: "Phone", 
            role: "Role",
            created_on: "Added On"            
        };        
        let {userid,isadmin} = this.props;        
        let {total} = this.state;
        this.setState({ isLoading: true });
        var response = [];
        let fetchString = 'getusersbyaccountid/';
        fetchString += this.props.accountid; //by account id
        fetchString += `?perpage=${total}&currentpage=1`;
        if (!isadmin) {
            fetchString += `&userId=${userid}`;
        }            
        fetchString += `&direction=desc`;
        this.props.authService.fetch(fetchString, {
            method: 'get'
        })
        .then(response => response.json())
        .then(data => {
            if(data !== undefined && data !== null){
                if (parseInt(data.total) === 0) {
                    this.setState({ isLoading: false });
                    printConsole("No data found")
                    response = [];
                    return;
                }
                response = response.concat(data.users) ;
                printConsole(response); 
                if (response.length === parseInt(data.total)) {
                    var itemsFormatted = [];
                    // format the data
                    response.forEach((item) => {                        
                        itemsFormatted.push({
                            first_name: (item.first_name === null || item.first_name === ''? "Removed": item.first_name), //TP-2141
                            last_name: (item.last_name === null || item.last_name === ''? "Removed": item.last_name), //TP-2141
                            //TZ-53 email: !checkDummyEmail({verified: item.verified, email: item.email}) ? item.email: '', 
                            phonenumber: item.phonenumber, 
                            role: this.getRoleUser(item),
                            created_on: this.getDateString(item.created_on)                            
                        });
                    });
                    const timestamp = new Date().toISOString();

                    var fileTitle = `Users ${timestamp}`; // or 'my-unique-title'

                    exportCSVFile(headers, itemsFormatted, fileTitle); // call the exportCSVFile() function to process the JSON
                    this.setState({ isLoading: false });
                } else {
                    this.setState({ isLoading: false });
                    printConsole("The total data count is not same as the total value in the API response");
                }
            }
        })
        .catch(err => {
            this.setState({ isLoading: false });
            printConsole(err.message)
            response = [];
        })        
    }

	render() {
        const { isadmin, isexpert, onlineUsers, behaviour, isSmartAdmin,canResetUserPassword, isSmallFormFactor} = this.props;
        const { reset_user_password } = this.props.customer;
        const noDataRawColspan = 8; //TP-4893 TP-4452 (!isadmin && !isexpert) ? 5 : 6;
        const { mode, selectedUser, pagination, usersInSession, bResponded, errStrReturned } = this.state;
        const isSmartvision = (behaviour === "smartvision") ? false : true;
        //TP-6222
        let {chatMsgInfoArr} = this.props;
        //console.log(this.state.userdata);
        if (chatMsgInfoArr !== undefined && chatMsgInfoArr.length > 1 && this.state.userdata !== undefined && this.state.userdata.length > 1) {
            chatMsgInfoArr.forEach(chatMsgInfo => {
                if (chatMsgInfo.msgId && chatMsgInfo.msgType !== 1) {
                    const [user] = this.state.userdata.filter(user => user.email === chatMsgInfo.msgId);
                    if (user && user.myPicture) chatMsgInfo.myPicture = user.myPicture
                }
            })
        }
        //console.log("usersInSession array contains ----",usersInSession);
        //let colorText = '#485890';
        //if(retCode !== 200) colorText = 'red';
        let colorText = 'red';
        if (mode === 'edit') {
            return (
                <AccountConsumer>
                    {({language}) =>

                        <AddUser 
                            language={language}
                            isEdit={true}
                            user={selectedUser}
                            adminemailid={this.props.adminemailid}
                            adminfirstname={this.props.adminname}
                            adminuserid={this.props.userid}
                            addedUser={this.addedUser}
                            onClose={this.onCloseEditModal}
                            accountid={this.props.accountid}
                            purchases={this.getPurchasesForSelect()}
                            authService={this.props.authService}
                            numberOfExperts={this.props.customer.number_of_experts}
                            availableExperts={this.state.numberOfExperts}
                            onRouteChange={this.props.onRouteChange}
                            isadmin={this.props.isadmin} //TP-2980
                            isexpert={this.props.isexpert} //TP-2980
                            sms_support = {this.props.customer.sms_support} //TP-6053,6203
                            customerRoles={this.props.customerRoles}
                        />
                    }
                </AccountConsumer>
            )
        } else if (mode === 'add') {
            return (
                <AccountConsumer>
                    {({language}) =>

                        <AddUser 
                            language={language}
                            isEdit={false}
                            adminemailid={this.props.adminemailid}
                            adminfirstname={this.props.adminname}
                            adminuserid={this.props.userid}
                            addedUser={this.addedUser}
                            accountid={this.props.accountid}
                            onClose={this.onCloseEditModal}
                            purchases={this.getPurchasesForSelect()}
                            onUpdatePurchases={this.props.onUpdatePurchases}
                            authService={this.props.authService}
                            numberOfExperts={this.props.customer.number_of_experts}
                            availableExperts={this.state.numberOfExperts}
                            onRouteChange={this.props.onRouteChange}
                            sms_support = {this.props.customer.sms_support} //TP-6053,6203
                        />
                }
                </AccountConsumer>
            )
        }  else if (mode === 'chat') {
            const isBusy = usersInSession.includes(selectedUser.email);
            const isOnline = onlineUsers.includes(selectedUser.email) && !this.props.noInternet && this.props.isConnected;//MB2-577
            const isVisible = (this.props.isexpert || (this.props.isadmin && this.props.isexpert)) ? (!this.state.selectedUser.isexpert && !this.state.selectedUser.isadmin) : (!this.props.isexpert && !this.props.isadmin && this.props.audio_mode !== "tech2Tech" /**TP-4263 & TP-3303*/) ? !!this.state.selectedUser.isexpert : (!this.props.isexpert && !this.props.isadmin && this.props.audio_mode === "tech2Tech") ? true /**TP-3303*/ : false; //TP-2254
            const deviceAvailable = this.state.micAvailable || this.state.webcamAvailable;
            //console.log(isBusy, isOnline, isVisible, deviceAvailable, this.props.enable_streaming);
            //TP-6222
            return (
                <>
                    <AccountConsumer>
                        {({language}) =>

                            <ChatUser 
                                language={language}
                                enable_streaming={this.props.enable_streaming}
                                isSmartAdmin={isSmartAdmin}
                                isBusy={isBusy}
                                isOnline={isOnline}
                                isPartOfAGroup={this.state.isPartOfAGroup}
                                isVisible={isVisible}
                                noInternet={this.props.noInternet}
                                isConnected={this.props.isConnected}
                                isMonthlyMinutesConsumed={this.props.isMonthlyMinutesConsumed} 
                                deviceAvailable={deviceAvailable}
                                isExpert={this.props.isexpert } 
                                isAdmin={this.props.isadmin} 
                                isSmallFormFactor={isSmallFormFactor}
                                adminemailid={this.props.adminemailid}
                                adminname={this.props.adminname}
                                myPicture={this.props.myPicture}
                                recipientUser={selectedUser}
                                accountid={this.props.accountid}
                                onClose={this.onCloseEditModal}
                                authService={this.props.authService}
                                numberOfExperts={this.props.customer.number_of_experts}
                                availableExperts={this.state.numberOfExperts}
                                onRouteChange={this.props.onRouteChange}
                                userdata={this.state.userdata}
                                updateChatArr={this.props.updateChatArr} //TP-6222
                                chatMsgInfoArr={chatMsgInfoArr} //TP-6222
                                updateChatUser={this.updateChatUser} //TP-6222
                                onlineUsers={onlineUsers} //TP-6222
                                onClickUserCall={this.onActionClick}
                                getUserType={this.getUserType}
                            />
                    }
                    </AccountConsumer>
                    {/*TP-2892*/this.callUserDialog(this.props)}                
                </>
            )
        }
        else {
            let bodyHeight = window.innerHeight - (25 * (window.innerHeight/100));
            return(
                    <div style={{'overflowX': 'auto',height: bodyHeight + "px"}} className='ag-body-container'>
                        <div className="flex-space-between">
                        { isadmin ?
                            <div>
                                <I18n>
                                    {({i18n}) =>
                                        <>
                                            <input type="image" id="addUser" src={AddButton} className="btn-green-add" alt={i18n._(t`Add User`)} /* data-rh={i18n._(t`Click to add a new User`)} */
                                            onClick={() => this.onClickAdd()} /> 
                                            { isSmallFormFactor === false ?
                                                <UncontrolledTooltip placement="right" target="addUser">
                                                    <Trans>Click to add a new User</Trans>
                                                </UncontrolledTooltip>
                                                :
                                                ''
                                            }
                                        </>
                                    }
                                </I18n>
                            </div>
                            :<span></span>}
                        { /**TZ-36 */ isadmin && behaviour === "pndsafety" ?
                            <span className='fa-stack fa-lg col-sm-10' >
                                <a id="Download" className=" pointer" onClick={() => this.downloadAsCsv()}>
                                    <i className="fas fa-file-csv fa-lg" ></i>
                                    <i className="fas fa-long-arrow-alt-down"></i>
                                </a>
                                { isSmallFormFactor === false ?
                                    <UncontrolledTooltip placement="right" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target={"Download"}>
                                        {
                                            <Trans>Download as csv</Trans>
                                        }
                                    </UncontrolledTooltip>
                                    :
                                    ''
                                }
                            </span>
                            :
                            ""
                        }
                            <div className="search-input-wrapper relative">
                                <i className="fas fa-search" aria-hidden="true"></i>
                                <I18n>
                                    {({i18n}) =>
                                        <input placeholder={i18n._(t`Search for Users`) + '...'} type="text" id="userSearchBox" onChange={(e) => this.searchUsers(e)} />
                                    }
                                </I18n>
    
                            </div>
                        </div>
                        <div className="table-container">
                            <ReactHint autoPosition events />
                            <table id="usersTable">
                                <thead>
                                    <tr>
                                        <th>{/**TP-4871*/}#</th>
                                        <th /**TP-5040 TP-4871*/ className={isSmallFormFactor === true ? 'min-width-profile' : 'max-width-profile'}>{/**TP-3459<div className="profile-header">{this.props.isMobileDevice === true ? "Profile":</div>*/<Trans>Profile</Trans>}</th>
                                        <th onClick={() => this.sortTable('first_name')}>
                                            { /**TP-2892 */ this.props.isMobileDevice === true ?
                                                <Trans>First</Trans>
                                                :
                                                <Trans>First Name</Trans>
                                            }
                                            { pagination.orderBy === 'first_name' ? <SortIcons direction={pagination.direction} /> : ''}
                                        </th>
                                        <th onClick={() => this.sortTable('last_name')}>
                                            { /**TP-2892 */ this.props.isMobileDevice === true ?
                                                <Trans>Last</Trans>
                                                :
                                                <Trans>Last Name</Trans>
                                            }
                                            { pagination.orderBy === 'last_name' ?<SortIcons direction={pagination.direction} />:''}
                                        </th>
                                        <th onClick={() => this.sortTable('email')}>
                                            <Trans>Email</Trans>
                                            { pagination.orderBy === 'email' ?<SortIcons direction={pagination.direction} /> : ''}
                                        </th>
                                        <th onClick={() => this.sortTable('phonenumber')}>
                                            { /**TP-2892 */ this.props.isMobileDevice === true ?
                                                <Trans>Phone</Trans>
                                                :
                                                <Trans>Phone Number</Trans>
                                            }
                                            { pagination.orderBy === 'phonenumber' ? <SortIcons direction={pagination.direction} /> : '' }
                                        </th>
                                        <th><Trans>Type</Trans></th>
                                        { /**TP-3298*/ this.props.enable_streaming || this.props.isadmin ?
                                            <th><Trans>Actions</Trans></th>
                                            :
                                            ''
                                        }
                                    </tr>
                                </thead>   
                                <tbody>
                                    {
                                        <I18n>
                                            {({i18n}) =>
                                            (/*TP-4452, TP-4709*/this.state.userdata && this.state.userdata.length > 0) ? 
                                                this.state.userdata.map((row, index) => {
                                                    const isPartOfAGroup = this.state.isPartOfAGroup; //TP-4814
                                                    const isOnline = onlineUsers.includes(row.email) && !this.props.noInternet && this.props.isConnected;//MB2-577
                                                    row.showOnlineIndicator = isOnline;
                                                    const isBusy = usersInSession.includes(row.email);
                                                    const deviceAvailable = this.state.micAvailable || this.state.webcamAvailable;
                                                    // If the loggedin user is 'expert' or admin and expert then only show the call icon if the user is 'technician' and viceversa
                                                    /**TZ-989 */
                                                    let isVisible = false;
                                                    if (this.props.allow_expert_to_expert_call === false )
                                                        isVisible = (this.props.isexpert || (this.props.isadmin && this.props.isexpert)) ? (!row.isexpert && !row.isadmin) : (!this.props.isexpert && !this.props.isadmin && this.props.audio_mode !== "tech2Tech" /**TP-4263 & TP-3303*/) ? !!row.isexpert : (!this.props.isexpert && !this.props.isadmin && this.props.audio_mode === "tech2Tech") ? true /**TP-3303*/ : false; //TP-2254
                                                    else 
                                                        isVisible = (this.props.isexpert || (this.props.isadmin && this.props.isexpert)) ? (row.expert || !row.expert || row.expert && row.isadmin) /**TZ-1007*/ : (!this.props.isexpert && !this.props.isadmin && this.props.audio_mode !== "tech2Tech" /**TP-4263 & TP-3303*/) ? !!row.isexpert : (!this.props.isexpert && !this.props.isadmin && this.props.audio_mode === "tech2Tech") ? true /**TP-3303*/ : false; //TP-2254
                                                    const isSelf = (this.props.adminemailid === row.email) ? true : false;
                                                    return <TableRow 
                                                                key={index}
                                                                row={row}
                                                                showOnlineIndicator={isOnline}
                                                                index={((this.state.pagination.perPage *  this.state.pagination.currentPage) - this.state.pagination.perPage) + index}
                                                                isAdmin={isadmin}
                                                                isSmallFormFactor
                                                                columns={['first_name', 'last_name', 'email', 'phonenumber', 'myPicture', this.getUserType(row)]}
                                                                isPictureShow={true} 
                                                                openBubble={this.openBubble} 
                                                                customerRoles={this.props.customerRoles}
                                                                hideActions={/*TP-3298*/ !(this.props.enable_streaming || this.props.isadmin)}>                                                            
                                                                    <Actions>
                                                                        { /*TP-2790*/ this.props.enable_streaming === true ?
                                                                            <IconCallUser selectedUser={this.state.selectedUser.email} /**TP-5277*/ isSmartAdmin={isSmartAdmin} index={index} isBusy={isBusy} isVisible={/*TP-4814*/ isPartOfAGroup && isOnline && (row.email !== this.props.adminemailid) && isVisible /* && isSmartvision */} row={row} onActionClick={this.onActionClick} noInternet={this.props.noInternet} isConnected={this.props.isConnected} deviceAvailable={deviceAvailable} isMonthlyMinutesConsumed={this.props.isMonthlyMinutesConsumed} isExpert={this.props.isexpert } isAdmin={this.props.isadmin} isSmallFormFactor={isSmallFormFactor} /**TP-4327*/ />
                                                                            :
                                                                            ''
                                                                        }
                                                                        {/* <IconChatUser index={index} isExpert={this.props.isExpert} isAdmin={this.props.isadmin} row={row} isOnline={isOnline} isSelf={isSelf} onActionClick={this.onActionClick} isSmallFormFactor={isSmallFormFactor} //TP-6222  />  */}
                                                                        <IconResendInvite index={index} isExpert={this.props.isExpert} isAdmin={this.props.isadmin} row={row} onActionClick={this.onActionClick} isSmallFormFactor={isSmallFormFactor} /**TP-4327*/ />
                                                                        <IconAssignPurchase index={index} isExpert={this.props.isExpert} isAdmin={this.props.isadmin} row={row} onActionClick={this.onActionClick} isSmallFormFactor={isSmallFormFactor} /**TP-4327*/ />
                                                                        <IconQRCode isSmartAdmin={isSmartAdmin} resetUserPassword={reset_user_password} canResetUserPassword={canResetUserPassword} index={index} isExpert={this.props.isexpert} isAdmin={this.props.isadmin} row={row} isSelf={isSelf} onActionClick={this.onActionClick} isSmallFormFactor={isSmallFormFactor} /**TP-4327*/ />
                                                                        <IconChangePassword resetUserPassword={reset_user_password} canResetUserPassword={canResetUserPassword} index={index} isExpert={this.props.isexpert} isAdmin={this.props.isadmin} row={row} isSelf={isSelf} onActionClick={this.onActionClick} isSmallFormFactor={isSmallFormFactor} /**TP-4327*/ />
                                                                        <IconEditUser index={index} isExpert={this.props.isExpert} isAdmin={this.props.isadmin} row={row} onActionClick={this.onActionClick} /**TP-2980*/ adminemailid={this.props.adminemailid} isSmallFormFactor={isSmallFormFactor} /**TP-4327*/ />
                                                                        <IconDeleteUser index={index} isExpert={this.props.isExpert} isAdmin={this.props.isadmin} isActiveUser={this.props.userid === row.id } row={row} onActionClick={this.onActionClick} isSmallFormFactor={isSmallFormFactor} /**TP-4327*/ />
                                                                    </Actions>                                                        
                                                            </TableRow>
                                                        })
                                                : /**TP-4452 */
                                                <NoDataFoundRow colspan={noDataRawColspan} />
                                            }
                                        </I18n>                                                                     
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="pagination-wrapper d-flex justify-content-end">
                        <I18n>
                            {({ i18n }) => 
                                <ReactPaginate
                                    previousLabel={i18n._(t`previous`)}
                                    nextLabel={i18n._(t`next`)}
                                    breakLabel={'...'}
                                    breakClassName={'break-me'}
                                    pageCount={this.state.pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={10}
                                    onPageChange={this.handlePageClick}
                                    containerClassName={'pagination'}
                                    pageClassName={'page-item'}
                                    subContainerClassName={'pages pagination'}
                                    pageLinkClassName={'page-link'}
                                    disabledClassName={'disabled'}
                                    activeClassName={'active'}
                                    previousClassName={'page-item'}
                                    nextClassName={'page-item'}
                                    previousLinkClassName={'page-link'}
                                    nextLinkClassName={'page-link'}
                                    breakLinkClassName={'page-link'}
                                />
                            }
                        </I18n>
                        </div>
                        {this.renderSwitch(mode, selectedUser, this.props.purchases)} 
                        {this.showLoader()}
                        {/*TP-2892*/this.callUserDialog(this.props)}
                        {(bResponded) ?
                            <div className='modale opened'>
                                <div className='__modal-dialog'>
                                    <form>
                                        <div className="__modal-header">
                                            <h4 style={{color: colorText}}><Trans id={errStrReturned} /></h4>
                                        </div>
                                        <div className="__modal-footer flex-center">
                                            <button className="btn-green" onClick={(e) =>this.closeModal(e)}><Trans id='OK'/></button>
                                        </div>
                                    </form>
                                </div>
                            </div> : ''
                        }
                    </div>
            );
        }
	}
}

const IconDeleteUser = (props) => (
    // !props.isActiveUser && props.isAdmin && <>
    <>
        {!props.isActiveUser && props.isAdmin ?
            <>
                <span id={"icon-trash-" + props.index} className="icon-wrapper pointer" onClick={() => props.onActionClick({ action: 'delete', row: props.row })}>
                    <i className="far fa-trash-alt"></i>
                </span> 
                { props.isSmallFormFactor === false ?
                    <UncontrolledTooltip placement="top" target={"icon-trash-" + props.index}>
                        <Trans>Delete user</Trans>
                    </UncontrolledTooltip>
                    :
                    ''
                }
            </>
        :
            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
        }
    </>
);

const IconEditUser = (props) => (
    // !props.row.is_inactive && props.isAdmin && <>
    <>
        {!props.row.is_inactive && props.isAdmin ?
            <>
                <span id={"icon-edit-" + props.index} className="icon-wrapper pointer" onClick={() => props.onActionClick({ action: 'edit', row: props.row })}>
                    <i className="fas fa-edit"></i>
                </span>
                { props.isSmallFormFactor === false ?
                    <UncontrolledTooltip placement="top"  target={"icon-edit-" + props.index}>
                        <Trans>Edit user details</Trans>
                    </UncontrolledTooltip>
                    :
                    ''
                }
            </>
        : 
           /**TP-2980  !props.row.is_inactive && !props.isAdmin && props.row.email === props.adminemailid ?
            <>
                <span id={"icon-edit-" + props.index} className="icon-wrapper pointer" onClick={() => props.onActionClick({ action: 'edit', row: props.row })}>
                    <i className="fas fa-edit"></i>
                </span>
                { props.isSmallFormFactor === false ?
                    <UncontrolledTooltip placement="top"  target={"icon-edit-" + props.index}>
                        <Trans>Edit user details</Trans>
                    </UncontrolledTooltip>
                    :
                    ''
                }
            </>
            :*/
            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
        }
    </>
);

const IconChatUser = (props) => (
    <> { !props.isSelf && props.isOnline ?
        (!props.noInternet)?
            <>
                <span id={"icon-chat-" + props.index} className="icon-wrapper pointer" onClick={() => props.onActionClick({ action: 'chat', row: props.row })}>
                    <i className="fas fa-comment-alt"></i>                                
                </span>
                { props.isSmallFormFactor === false ?
                    <UncontrolledTooltip placement="top"  target={"icon-chat-" + props.index}>
                        {
                            <Trans>Chat with User</Trans>
                        }
                    </UncontrolledTooltip>
                    :
                    ''
                }
            </>
            :
            <I18n>
                {({ i18n }) =>
                    <>
                        { props.isSmallFormFactor === false ?
                            <span id={"icon-chat-" + props.index} className="icon-wrapper" data-rh={i18n._(t`You are Offline now`)}>
                                <i className="fas fa-comment-alt"></i>                                    
                            </span>
                            :
                            <span id={"icon-chat-" + props.index} className="icon-wrapper">
                                <i className="fas fa-comment-alt"></i>                                    
                            </span>
                        }
                    </>
                }
            </I18n>        
        :
            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
        }
    </>
)

const IconAssignPurchase = (props) => (
    // props.row.is_inactive && props.isAdmin && <>
    <>
        { props.row.is_inactive && props.isAdmin ?
            <>
                <span id={"icon-assign-" + props.index} className="icon-wrapper pointer" onClick={() => props.onActionClick({ action: 'assign', row: props.row })}>
                    <i className="fas fa-plus-circle"></i>
                </span>
                { props.isSmallFormFactor === false ?
                    <UncontrolledTooltip placement="top"  target={"icon-assign-" + props.index}>
                        <Trans>Assign to a purchase</Trans>
                    </UncontrolledTooltip>
                    :
                    ''
                }
            </>
        :
            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
        }
    </>
);

const IconResendInvite = (props) => (
    // (!props.row.isclicked && !props.row.is_inactive) && <>
    <>
        {(!props.row.isclicked && !props.row.is_inactive) && !checkDummyEmail({verified: props.row.verified, email: props.row.email}) ? 
            <>
                <span id={"icon-envelope-" + props.index} className="icon-wrapper pointer" onClick={() => props.onActionClick({ action: 'resendInvite', row: props.row })}>
                    <i className="fas fa-envelope"></i>
                </span> 
                { props.isSmallFormFactor === false ?
                    <UncontrolledTooltip placement="top"  target={"icon-envelope-" + props.index}>
                        <Trans>Resend invite</Trans>
                    </UncontrolledTooltip>
                    :
                    ''
                }
            </>
        :
            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
        }
    </>
);

const IconCallUser = (props) => (
    // props.isVisible already is taking care of props.noInternet so changing the below condition for props.isMonthlyMinutesConsumed//T32-462
    <>
        {props.isVisible && props.isSmartAdmin === false ? 
            <>
                {   (!props.isMonthlyMinutesConsumed && props.deviceAvailable)?                        
                        /*TP-4228*/props.isExpert || props.isAdmin ? 
                            <>
                                    <span id={"icon-phone-" + props.index} className={"icon-wrapper pointer " + (props.isBusy ? "text-danger disabled" : "")} onClick={() => !props.isBusy && props.onActionClick({ action: 'callUser', row: props.row })}>
                                        <i className={props.isBusy ? "fas fa-phone-slash" : "fas fa-phone"}></i>
                                    </span>
                                    { props.isSmallFormFactor === false ?
                                        <UncontrolledTooltip placement="top"  target={"icon-phone-" + props.index}>
                                            {
                                                !props.isBusy ? <Trans>Call</Trans> : /*TP-5277*/ props.selectedUser !== props.row.email ?
                                                <Trans>User is already on a call with another user, please try again later.</Trans> : /**TP-5277 */
                                                <Trans>User is attempting to make a call, please try later.</Trans>
                                            }
                                        </UncontrolledTooltip>
                                        :
                                        ''
                                    }
                            </>
                                    :
                            <>
                                {/**TP-4228*/}
                                    <span id={"icon-phone-" + props.index} className="icon-wrapper pointer" onClick={() => props.onActionClick({ action: 'callUser', row: props.row })}>
                                        <i className="fas fa-phone"></i>
                                    </span>
                                    { props.isSmallFormFactor === false ?
                                        <UncontrolledTooltip placement="top"  target={"icon-phone-" + props.index}>
                                            {
                                                <Trans>Call</Trans>
                                            }
                                        </UncontrolledTooltip>
                                        :
                                        ''
                                    }
                            </>
                        
                        :
                            <>
                                <span id={"icon-phone-" + props.index} className="icon-wrapper">
                                    <i className="fas fa-phone fa-video-disabled"></i>
                                </span>
                                { props.isSmallFormFactor === false ?
                                    <UncontrolledTooltip placement="top" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target={'icon-phone-' + props.index}>
                                        {
                                            props.deviceAvailable ? <Trans>Monthly minutes over</Trans> :
                                            (props.isExpert) ? 
                                                <Trans>MicrophoneNotFoundError</Trans>
                                                :
                                                <Trans>DevicesNotFoundError</Trans>
                                        }
                                    </UncontrolledTooltip>
                                    :
                                    ''
                                }
                            </>
                }
            </>
        :
            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
        }
    </>
);

const IconChangePassword = (props) => (
    // props.isVisible && <>//TP-5926
    <>
        {((props.resetUserPassword === "reset_password_without_qr" && props.isAdmin) || (props.resetUserPassword === "reset_password_without_qr" && props.canResetUserPassword))
            && props.row.isclicked && !props.row.is_inactive && !props.isSelf && (props.isAdmin || props.isExpert) && (props.isExpert !== props.row.isexpert || props.isAdmin) && !checkDummyEmail({verified: props.row.verified, email: props.row.email}) ? 
            <>
                {   (!props.noInternet)?
                        <>
                            <span id={"icon-password-" + props.index} className="icon-wrapper pointer" onClick={() => props.onActionClick({ action: 'generatePassword', row: props.row })}>
                                <i className="fas fa-key"></i>
                                {/* <span class="fa-layers-text fa-inverse" data-fa-transform="shrink-2 down-1" style={{fontWeight:"900"}}>P</span> */}
                            </span>
                            { props.isSmallFormFactor === false ?
                                <UncontrolledTooltip placement="top"  target={"icon-password-" + props.index}>
                                    {
                                        <Trans>Password Icon</Trans>
                                    }
                                </UncontrolledTooltip>
                                :
                                ''
                            }
                        </>
                    :
                        <I18n>
                            {({ i18n }) =>
                                <>
                                    { props.isSmallFormFactor === false ?
                                        <span id={"icon-password-" + props.index} className="icon-wrapper" data-rh={i18n._(t`You are Offline now`)}>
                                            <i className="fas fa-key"></i>
                                            {/* <span class="fa-layers-text fa-inverse" data-fa-transform="shrink-2 down-1" style={{fontWeight:"900"}}>P</span> */}
                                        </span>
                                        :
                                        <span id={"icon-password-" + props.index} className="icon-wrapper" >
                                            <i className="fas fa-key"></i>
                                            {/* <span class="fa-layers-text fa-inverse" data-fa-transform="shrink-2 down-1" style={{fontWeight:"900"}}>P</span> */}
                                        </span>
                                    }
                                </>
                            }
                        </I18n>
                }
            </>
        :
            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
        }
    </>
);

const IconQRCode = (props) => (    
    // props.isVisible && <>
    <>
        {(props.isSmartAdmin === true || (props.resetUserPassword === "reset_password_with_qr" && props.isAdmin) || (props.resetUserPassword === "reset_password_with_qr" && props.canResetUserPassword))  
            && props.row.isclicked && !props.row.is_inactive && !props.isSelf && (props.isAdmin || props.isExpert) && (props.isExpert !== props.row.isexpert || props.isAdmin) && !checkDummyEmail({verified: props.row.verified, email: props.row.email})  ? 
            <>
                {   (!props.noInternet)?
                        <>
                            <span id={"icon-qrcode-" + props.index} className="icon-wrapper pointer" onClick={() => props.onActionClick({ action: 'qrConfirmation', row: props.row })}>
                                <i className="fas fa-qrcode"></i>                                
                            </span>
                            { props.isSmallFormFactor === false ?
                                <UncontrolledTooltip placement="top"  target={"icon-qrcode-" + props.index}>
                                    {
                                        <Trans>Download QR Code</Trans>
                                    }
                                </UncontrolledTooltip>
                                :
                                ''
                            }
                        </>
                    :
                        <I18n>
                            {({ i18n }) =>
                                <>
                                    { props.isSmallFormFactor === false ?
                                        <span id={"icon-qrcode-" + props.index} className="icon-wrapper" data-rh={i18n._(t`You are Offline now`)}>
                                            <i className="fas fa-qrcode"></i>                                    
                                        </span>
                                        :
                                        <span id={"icon-qrcode-" + props.index} className="icon-wrapper">
                                            <i className="fas fa-qrcode"></i>                                    
                                        </span>
                                    }
                                </>
                            }
                        </I18n>
                }
            </>
        :
            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
        }
    </>
);



export default Users;
