//created by Roopesh Singh TP-501
import pdfMake from 'pdfmake/build/pdfmake';
import vfsFonts from 'pdfmake/build/vfs_fonts';
import moment from 'moment';
import ImageNotFound from './no-image-icon.png';
import vdoImg from './video-thumbnail.png';
import siteLogo from './GreenLogo.JPG';
import {localizedData} from '../../languages/localizedData'; //TP-4184
import { replaceGenericName } from '../helpers';
let fontStyle, customerObj;
let globalLanguage = "en"; //TP-4184

//TP-4184 -- the languages we support
const includedLanguages = ['en', 'fr', 'de', 'pt', 'es', 'it', 'ja-JP', 'zh-CN', 'zh-cn', 'hi', 'or' ]

const base64Img = require('base64-img');
let statusObj = {'created': localizedData[globalLanguage]['Created'], 'assigned': localizedData[globalLanguage]['Assigned'],'in_progress': localizedData[globalLanguage]['In Progress'], 'finished': localizedData[globalLanguage]['Finished'], 'skipped': localizedData[globalLanguage]['Skipped']}

const _format = async (data, customer) => {
    try{
        let dataArr = [];
        for(let i=0;i < data.length; i++){
            let task = data[i];
            let task_data,tableBody,parsedTaskData = {}, asset_model_references, modelReferences;
            let pageBreakObj = (i === data.length - 1) ? '' : {text: '',pageBreak: 'after'}
            let audioLink, audioText;

            switch(task.command){
                case "Ctrl+Q":
                    task_data = task.task_data ? task.task_data : []
                    audioLink = audio_link({audio_link_data: task.audio_link}); audioText = audio_text({audio_link_data: task.audio_link});
                    tableBody = task_data.map((data,index)=> {
                        let parseData = JSON.parse(data);
                        if(index === 0){
                            parsedTaskData = parseData; //for now take the first element and show the device details
                        }
                        return(
                            [
                                { qr: `${parseData.qr_code}`,fit: '50'  },
                                `${parseData.qr_code || ''}`,
                                `${parseData.parameter_name || ''}`,
                                `${parseData.unit || ''}`,
                                `${parseData.value || ''}`
                            ]
                        )
                    });
                    dataArr.push([
                        {
                            text: localizedData[globalLanguage][`Procedure`] + ` ${task.sequence + (task.sub_sequence ? task.sub_sequence : '')}`,
                            style: 'subheader',
                        },
                        {
                            style: 'tableExample',
                            color: '#444',
                            table: {
                                widths: [175, '*'],
                                body: [...task.asset_id ? jobWithAssetTaskDetailstable(task, parsedTaskData, customer) : taskDetailsTable(task, parsedTaskData, customer)]
                            },
                            layout: tableLayout()
                        },
                        {text: localizedData[globalLanguage]['Task Evidence']+':' , style: 'subheader'},
                        [task.status !== 'skipped' ? {
                            style: 'tableExample',
                            table: {
                                widths: [50,'auto','auto','auto','auto'],
                                body: [
                                        [localizedData[globalLanguage]['Qr Code'], localizedData[globalLanguage]['Qr Code string'], localizedData[globalLanguage]['Parameter'], localizedData[globalLanguage]['Unit'], localizedData[globalLanguage]['Value']],
                                        ...tableBody
                                ]
                            }

                        }: ''],
                        getSkippedComments(task),
                        getAudioLink(audioLink),
                        getAudioText(audioText),
                        pageBreakObj
                    ]);
                    break;
                case "Ctrl+E":
                    task_data = task.task_data ? task.task_data : []
                    audioLink = audio_link({audio_link_data: task.audio_link}); audioText = audio_text({audio_link_data: task.audio_link});
                    tableBody = task_data.map((data,index)=> {
                        let parseData = JSON.parse(data);
                        if(index === 0){
                            parsedTaskData = parseData
                        }
                        return(
                            [`${parseData.parameter_name || ''}`, `${parseData.type || ''}`, `${parseData.unit || ''}`, `${parseData.value || ''}`, `${parseData.range || ''}`]
                        )
                    });

                    dataArr.push([
                        {
                            text: localizedData[globalLanguage][`Procedure`]+` ${task.sequence + (task.sub_sequence ? task.sub_sequence : '')}`,
                            style: 'subheader',
                        },
                        {
                            style: 'tableExample',
                            color: '#444',
                            table: {
                                widths: [175, '*'],
                                body: [...task.asset_id ? jobWithAssetTaskDetailstable(task, parsedTaskData, customer) : taskDetailsTable(task, parsedTaskData, customer)]
                            },
                            layout: tableLayout()
                        },
                        {text: localizedData[globalLanguage]['Task Evidence']+':' , style: 'subheader'},
                        [task.status !== 'skipped' ? {
                            style: 'tableExample',
                            table: {
                                widths: ['auto', 'auto', 'auto', 'auto', 'auto'],
                                body: [
                                        [localizedData[globalLanguage]['Parameter'], localizedData[globalLanguage]['Type'], localizedData[globalLanguage]['Unit'], localizedData[globalLanguage]['Value'], localizedData[globalLanguage]['Range']],//TP-2063
                                        ...tableBody
                                ]
                            }
                        }: ''],
                        getSkippedComments(task),
                        getAudioLink(audioLink),
                        getAudioText(audioText),
                        pageBreakObj
                    ]);
                    break;
                case "Ctrl+I":
                    task_data = task.task_data ? task.task_data : []; asset_model_references = task.model_references && customer.enable_asset_class !== "disabled" ? task.model_references : [];
                    audioLink = audio_link({audio_link_data: task.audio_link}); audioText = audio_text({audio_link_data: task.audio_link});
                    tableBody = await imgDataLoop(task_data); modelReferences = await modelImgDataLoop(asset_model_references);
                    if(Array.isArray(task_data) && task_data.length> 0){
                        parsedTaskData = JSON.parse(task_data[0]);
                    }
                    dataArr.push([
                        {
                            text: localizedData[globalLanguage][`Procedure`]+` ${task.sequence + (task.sub_sequence ? task.sub_sequence : '')}`,
                            style: 'subheader',
                        },
                        {
                            style: 'tableExample',
                            color: '#444',
                            table: {
                                widths: [175, '*'],
                                body: [...task.asset_id ? jobWithAssetTaskDetailstable(task, parsedTaskData, customer) : taskDetailsTable(task, parsedTaskData, customer)]
                            },
                            layout: tableLayout()
                        },
                        {text: localizedData[globalLanguage]['Task Evidence']+':' , style: 'subheader'},
                        {table: {
                            body: [
                                    [modelReferences, '', tableBody],
                                    [`${asset_model_references.length === 0  || customer.enable_asset_class === "disabled" ? '' : localizedData[globalLanguage]['Reference Image']}`, '',
                                     `${(asset_model_references.length === 0 || task_data.length === 0 || customer.enable_asset_class === "disabled" || task.status === 'skipped') ? '' : localizedData[globalLanguage]['Captured Evidence']}`],//TP-3887
                                ]
                            },
                            layout: 'noBorders',
                            alignment: "center"
                        },
                        getSkippedComments(task),
                        getAudioLink(audioLink),
                        getAudioText(audioText),
                        pageBreakObj
                    ]);
                    break;
                case "Ctrl+IP":
                    task_data = task.task_data ? task.task_data : []; asset_model_references = task.model_references && customer.enable_asset_class !== "disabled" ? task.model_references : [];
                    audioLink = audio_link({audio_link_data: task.audio_link}); audioText = audio_text({audio_link_data: task.audio_link});
                    tableBody = await imgDataWithCommentLoop(task_data);
                    const incidentsTableBody = await showIncidents(task);//TP-6966
                    if(Array.isArray(task_data) && task_data.length> 0){
                        parsedTaskData = JSON.parse(task_data[0]);
                    }
                    dataArr.push([
                        {
                            text: localizedData[globalLanguage][`Procedure`]+` ${task.sequence + (task.sub_sequence ? task.sub_sequence : '')}`,
                            style: 'subheader',
                        },
                        {
                            style: 'tableExample',
                            color: '#444',
                            table: {
                                widths: [175, '*'],
                                body: [...task.asset_id ? jobWithAssetTaskDetailstable(task, parsedTaskData, customer) : taskDetailsTable(task, parsedTaskData, customer)]
                            },
                            layout: tableLayout()
                        },
                        {table: {
                            widths: [150, '*'],
                            body:   tableBody && tableBody.length > 0 ? [
                                        [
                                            {text:`${localizedData[globalLanguage]["Task Evidence"]}:` , alignment: 'left', style: 'subheader', border: [false, false, false, false]},
                                            {text: "", border: [false, false, false, false]}
                                        ],
                                        ...tableBody,
                                    ]: [[null, null]],
                            },
                            layout: tableBody && tableBody.length > 0 ? '' : "noBorders",//no border to hide the null data
                            alignment: "center"
                        },
                        {table: {
                            widths: [150, '*'],
                            body:   incidentsTableBody && incidentsTableBody.length > 0 ? [
                                        [{text: "Incidents:",  alignment: 'left', style: 'subheader', border: [false, false, false, false]}, {text: "", border: [false, false, false, false]}],
                                        ...incidentsTableBody,
                                    ]: [[null, null]],
                            },
                            layout: incidentsTableBody && incidentsTableBody.length > 0 ? '' : "noBorders",//no border to hide the null data
                            alignment: "center"
                        },
                        getSkippedComments(task),
                        getAudioLink(audioLink),
                        getAudioText(audioText),
                        pageBreakObj
                    ]);
                    break;
                case "Ctrl+V":
                    task_data = task.task_data ? task.task_data : []
                    audioLink = audio_link({audio_link_data: task.audio_link}); audioText = audio_text({audio_link_data: task.audio_link});
                    tableBody = task_data.map((data,index)=> {
                        let parseData = JSON.parse(data);
                        if(index === 0){
                            parsedTaskData = parseData
                        }
                        let url = parseData.url;
                        if( url ){
                            let fileLength = url.split('/').length;
                            let fileName = url.split('/')[fileLength-1];
                            return(
                                {
                                    text: localizedData[globalLanguage][`Click here to view video evidence`],
                                    link: `${'assets/'+encodeURIComponent(fileName)}`
                                }               
                            )
                        }else{
                            return ''
                        }
                    })
                    dataArr.push([
                        {
                            text: localizedData[globalLanguage][`Procedure`]+ ` ${task.sequence + (task.sub_sequence ? task.sub_sequence : '')}`,
                            style: 'subheader',
                        },
                        {
                            style: 'tableExample',
                            color: '#444',
                            table: {
                                widths: [175, '*'],
                                body: [...task.asset_id ? jobWithAssetTaskDetailstable(task, parsedTaskData, customer) : taskDetailsTable(task, parsedTaskData, customer)]
                            },
                            layout: tableLayout()
                        },
                        {text: localizedData[globalLanguage]['Task Evidence']+':' , style: 'subheader'},
                        {
                            stack: [
                                {
                                    ul:tableBody
        
                                }
                            ]
                        },
                        getSkippedComments(task),
                        getAudioLink(audioLink),
                        getAudioText(audioText),
                        pageBreakObj
                    ]);
                    break;
                    case "Ctrl+A":
                        task_data = task.task_data ? task.task_data : []
                        audioLink = audio_link({audio_link_data: task.audio_link}); audioText = audio_text({audio_link_data: task.audio_link});
                        dataArr.push([
                            {
                                text: localizedData[globalLanguage][`Procedure`]+` ${task.sequence + (task.sub_sequence ? task.sub_sequence : '')}`,
                                style: 'subheader',
                            },
                            {
                                style: 'tableExample',
                                color: '#444',
                                table: {
                                    widths: [175, '*'],
                                    body: [...task.asset_id ? jobWithAssetTaskDetailstable(task, parsedTaskData, customer) : taskDetailsTable(task, parsedTaskData, customer)]
                                },
                                layout: tableLayout()
                            },
                            {text: localizedData[globalLanguage]['Task Evidence']+':' , style: 'subheader'},
                            {
                                text: localizedData[globalLanguage]['No evidence is required for this task']
                            },
                            getSkippedComments(task),
                            getAudioLink(audioLink),
                            getAudioText(audioText),
                            pageBreakObj
                        ]);
                    break;
                    case "Ctrl+N":
                        task_data = task.task_data ? task.task_data : []
                        audioLink = audio_link({audio_link_data: task.audio_link}); audioText = audio_text({audio_link_data: task.audio_link});
                        if(Array.isArray(task_data) && task_data.length> 0){
                            parsedTaskData = JSON.parse(task_data[0]);
                        }
                        dataArr.push([
                            {
                                text: localizedData[globalLanguage][`Procedure`]+` ${task.sequence + (task.sub_sequence ? task.sub_sequence : '')}`,
                                style: 'subheader',
                            },
                            {
                                style: 'tableExample',
                                color: '#444',
                                table: {
                                    widths: [175, '*'],
                                    body: [...task.asset_id ? jobWithAssetTaskDetailstable(task, parsedTaskData, customer) : taskDetailsTable(task, parsedTaskData, customer)]
                                },
                                layout: tableLayout()
                            },
                            {text:localizedData[globalLanguage]['Task Evidence']+':' , style: 'subheader'},
                            {
                                text: localizedData[globalLanguage]['No evidence is required for this task']
                            },
                            getSkippedComments(task),
                            getAudioLink(audioLink),
                            getAudioText(audioText),
                            pageBreakObj
                        ]);
                        break;
                    case "Ctrl+P":
                        task_data = task.task_data ? task.task_data : []
                        audioLink = audio_link({audio_link_data: task.audio_link}); audioText = audio_text({audio_link_data: task.audio_link});
                        tableBody = isImageType(task_data) ? await imgDataLoop(task_data) :  vdoDataLoop(task_data);
                        if(Array.isArray(task_data) && task_data.length> 0){
                            parsedTaskData = JSON.parse(task_data[0]);
                        }
                        dataArr.push([
                            {
                                text: localizedData[globalLanguage][`Procedure`]+` ${task.sequence + (task.sub_sequence ? task.sub_sequence : '')}`,
                                style: 'subheader',
                            },
                            {
                                style: 'tableExample',
                                color: '#444',
                                table: {
                                    widths: [175, '*'],
                                    body: [...task.asset_id ? jobWithAssetTaskDetailstable(task, parsedTaskData, customer) : taskDetailsTable(task, parsedTaskData, customer),
                                        // [{text: localizedData[globalLanguage]['Observation']+':', style: 'dataOddRow'}, {text: `${/**TP-4202*/parsedTaskData.observation ? localizedData[globalLanguage][parsedTaskData.observation] : ''}`,style: 'oddRow'}],
                                    ]
                                },
                                layout: tableLayout()
                            },
                            {text:localizedData[globalLanguage]['Task Evidence']+':' , style: 'subheader'},
                            {
                                stack: [
                                    {
                                        ul:tableBody
    
                                    }
                                ]
                            },
                            getSkippedComments(task),
                            getAudioLink(audioLink),
                            getAudioText(audioText),
                            pageBreakObj
                        ]);
                        break;
                    case "Ctrl+S":
                        task_data = task.task_data ? task.task_data : []; asset_model_references = task.model_references && customer.enable_asset_class !== "disabled" ? task.model_references : []
                        audioLink = audio_link({audio_link_data: task.audio_link}); audioText = audio_text({audio_link_data: task.audio_link});
                        tableBody = await imgDataLoop(task_data); modelReferences = await modelImgDataLoop(asset_model_references);
                        const mlComments = task.ml_comments && Array.isArray(task.ml_comments) ? task.ml_comments : []
                        const parsedMlComments = getMlComments(mlComments);
                        if(Array.isArray(task_data) && task_data.length> 0){
                            parsedTaskData = JSON.parse(task_data[0]);
                        }
                        dataArr.push([
                            {
                                text: localizedData[globalLanguage][`Procedure`]+` ${task.sequence + (task.sub_sequence ? task.sub_sequence : '')}`,
                                style: 'subheader',
                            },
                            {
                                style: 'tableExample',
                                color: '#444',
                                table: {
                                    widths: [175, '*'],
                                    body: [...task.asset_id ? jobWithAssetTaskDetailstable(task, parsedTaskData, customer) : taskDetailsTable(task, parsedTaskData, customer)]
                                },
                                layout: tableLayout()
                            },
                            {text:localizedData[globalLanguage]['Task Evidence']+':' , style: 'subheader'},
                            {table: {
                                body: [
                                        [tableBody[0] || '', '', tableBody[1] || ''],
                                        [`${task_data.length === 0  || customer.enable_asset_class === "disabled" ? '' : 'Captured Evidence'}`, '', `${(task_data.length < 2 || customer.enable_asset_class === "disabled") ? '' : 'Annotated Evidence'}`],
                                    ]
                                },
                                layout: 'noBorders',
                                alignment: "center"
                            },
                            mlComments && mlComments.length > 0 ? {text: '', pageBreak: 'after'} : '',                      
                            {
                                text: mlComments && mlComments.length > 0 ? localizedData[globalLanguage][`Automated Workflow Comments`]+':' : '',
                                style: 'subheader',
                            },
                            getMlCommentsTable(parsedMlComments),
                            getSkippedComments(task),
                            getAudioLink(audioLink),
                            getAudioText(audioText),
                            pageBreakObj
                        ]);
                        break;
                    default:
                    dataArr.push('');
                    break;
            }
        }
        return dataArr.flat();
    }catch(error){
        console.error(`error in _format method while constructiing pdf data ${error.message}`)
        return [];
    }
}

const getMultipleParsedCallRecords = ({call_recordings}) => {
    let callRecordings = [];
    try{
        if(call_recordings){
            callRecordings = call_recordings.map((call_recording) => {
                const callRecordingData = call_recording ? JSON.parse(call_recording) : null
                let text = localizedData[globalLanguage][`Call to`] + callRecordingData.expert_first_name + " " + callRecordingData.expert_last_name + localizedData[globalLanguage][" at "] + moment(callRecordingData.call_start_time).format("DD MMMM YYYY, h:mm:ss a") + localizedData[globalLanguage][" for "] + callRecordingData.call_duration + localizedData[globalLanguage]["seconds"]
                return {text}
            });
        }
        return callRecordings;
    }catch(error){
        console.log('getMultipleParsedCallRecords error', error)
    }
}

// TP-1012 -- Audio files handling in PDF Job report
const getMultipleAudioParseFiles = ({audioFiles}) =>{
    let parsedAudioLinks = [];
    try {        
            parsedAudioLinks = audioFiles.map((audiObj)=>{
                const audioOURL = audiObj ? JSON.parse(audiObj).url:null;
                const audioName = audioOURL.substring(audioOURL.lastIndexOf('/')).substring(1);
                return {text:localizedData[globalLanguage][`Click here to listen to the operator’s comments`],link:'assets/'+encodeURIComponent(audioName)}
            })        
        return parsedAudioLinks;
    } catch (error) {
        console.error('get multiple Audio Parse Error:'+error);
    }
}

const audio_link = ({audio_link_data}) => {
    try {        
        if(Array.isArray(audio_link_data) && audio_link_data.length> 0){
            const parsedTaskData = JSON.parse(audio_link_data[0]);
            const audioOURL = parsedTaskData ? parsedTaskData.url:null;
            const audioName = audioOURL.substring(audioOURL.lastIndexOf('/')).substring(1);
            return 'assets/'+encodeURIComponent(audioName)
        }
        return '';
    }catch (error){
        console.error('get multiple Audio Parse Error:'+error);
        return '';
    }
}

//TP-2036
const audio_text = ({audio_link_data}) => {
    try {        
        if(Array.isArray(audio_link_data) && audio_link_data.length> 0){
            const parsedTaskData = JSON.parse(audio_link_data[0]);
            return parsedTaskData.comments;
        }
        return '';
    }catch (error){
        console.error('get multiple Audio text Parse Error:'+error);
        return '';
    }
}

const imgDataLoop = async (task_data) => {
    try{
        const tableBody = [];
        for (let index = 0; index < task_data.length; index++) {
                        
            let parseData = JSON.parse(task_data[index]);
            const url = parseData.url ? parseData.url : ''
            let imgData = await getImageDataUrl(url);
            let imgIndex = url.split('/').length -1;
            let imageLink = url.split('/')[imgIndex]
            if(url){
                tableBody.push({
                    image: imgData,
                    // link: parseData.url,
                    link: 'assets/'+encodeURIComponent(imageLink),
                    fit: [80, 80],
                    margin: 15,
                    alignment: 'left'
                });
            }else{
                tableBody.push('')
            }
        }
        return tableBody;
    }catch(err){
        console.error(`error in imgDataLoop `, err)
    }
};

//TP-6862,7085
const imgDataWithCommentLoop = async (task_data) => {
    try{
        const tableBody = [];
        //loop through the data to find the image and comments. Each row will have the image and comments as td
        for (let index = 0; index < task_data.length; index++) {
                        
            let parseData = JSON.parse(task_data[index]);
            const url = parseData.url ? parseData.url : ''
            let imgData = await getImageDataUrl(url);
            let imgIndex = url.split('/').length -1;
            let imageLink = url.split('/')[imgIndex]
            const comment = parseData.comments ? parseData.comments : ''
            tableBody.push([{
                image: imgData,
                link: 'assets/'+encodeURIComponent(imageLink),
                fit: [80, 80],
                margin: 5,
                alignment: 'center', borderColor: ['#949494', '#949494', '#949494', '#949494']
            }, {text: comment || "No comment provided", alignment: "left", margin: [10, 10, 0, 0], borderColor: ['#949494', '#949494', '#949494', '#949494'] }]);
        }
        return tableBody;
    }catch(err){
        console.error(`error in imgDataWithCommentLoop `, err)
        return [null, null]
    }
};

//TP-4612
const vdoDataLoop = (task_data) => {
    try{
        let tableBody = [];
        if(task_data.length > 0){
            tableBody = task_data.map((data,index)=> {
                let parseData = JSON.parse(data);
                let url = parseData.url;
                if( url ){
                    let fileLength = url.split('/').length;
                    let fileName = url.split('/')[fileLength-1];
                    return(
                        {
                            text: localizedData[globalLanguage][`Click here to view video evidence`],
                            link: `${'assets/'+encodeURIComponent(fileName)}`
                        }               
                    )
                }else{
                    return ''
                }
            })
        }
        return tableBody;
    }catch(err){
        console.error(`error in vdoDataLoop `, err)
    }
};

//TP-4612
const get_url_extension = ( url ) => {
    return url.split(/[#?]/)[0].split('.').pop().trim();
}

//TP-4612
const isImageType = (task_data) => {
    try{
        let isImage = true, extension= "";
        if(task_data.length > 0){
            let parseData = JSON.parse(task_data[0]);
            const url = parseData.url ? parseData.url : ''
            if(url){
                extension = get_url_extension( url )
            }
            isImage = extension.toLowerCase() !== "mp4"
        }
        return isImage;
    }catch(err){
        console.error(`error in isImageType `, err)
    }
};

const modelImgDataLoop = async (asset_model_references=[]) => {
    try{
        const tableBody = [];
        for (let index = 0; index < asset_model_references.length; index++) {
                        
            const url = asset_model_references[0].location ? asset_model_references[0].location : ''
            let imgData = await getImageDataUrl(url);
            let imgIndex = url.split('/').length -1;
            let imageLink = url.split('/')[imgIndex]
            if(url){
                tableBody.push({
                    image: imgData,
                    // link: parseData.url,
                    link: 'assets/'+encodeURIComponent(imageLink),
                    fit: [80, 80],
                    margin: 15,
                    alignment: 'left'
                });
            }else{
                tableBody.push('')
            }
        }
        return tableBody;
    }catch(err){
        console.error(`error in modelImgDataLoop `, err)
    }
};

const getImageDataUrl = (imageUrl) =>{
    try{
        let imgDataUrl;
        return new Promise((resolve, reject) => {
            base64Img.requestBase64(imageUrl, function(err, res, body) {
                if(imageUrl === '' || err || res.statusCode !== 200){
                    imgDataUrl = ImageNotFound;
                }else{
                    imgDataUrl = body;
                }
                resolve(imgDataUrl);
            });
        })
    }catch(err){
        console.error(`error in getImageDataUrl `, err)
    }
};

const getParsedFiles = ({dataString, field}) => {
    const files = dataString ? JSON.parse(dataString) : null
    if(files && field!=='location'){
        return encodeURI(files[0][field]);
    }else if(files && field ==='location'){
          let fileName =  files[0][field].substring(files[0][field].lastIndexOf('/'));  
              fileName = fileName.split('/').pop();
              return 'assets/'+encodeURI(fileName); 
    }else{
        return 'NA';
    }
}

//T32-133
const getMultipleParseFiles = ({files}) => {
    let referenceMaterials = [];
    try{
        referenceMaterials = files.map((file) => {
            const fileData = file ? JSON.parse(file.file_info) : null
            return {text: `${showOriginalReferenceFileName({fileName: fileData.name })}`, link: 'assets/'+encodeURIComponent(fileData.name)}
        });
        return referenceMaterials;
    }catch(error){
        console.log('getMultipleParseFiles error', error)
    }
}

// Mb2-836
const getExecutionDuration = ({task}) => {
    try{
        if(moment(task.execution_start_time).isValid() && moment(task.execution_end_time).isValid()){
            const a = moment(task.execution_end_time), b = moment(task.execution_start_time);
            let timediff = Math.floor(Math.abs(a.diff(b))/1000) ;
                if (timediff > 0){
                    return (localizedData[globalLanguage]['Duration']+': (' + timediff + ' '+localizedData[globalLanguage]['seconds']+')');
                }else{
                    return '';
                }
        }else{
            return ''
        }
    }catch(err){
        console.error(`error in getExecutionDuration`, err)
        return ''
    }
}

const getExecutedBy = ({task}) => {
    if(task.status === 'finished' || task.status === 'skipped'){
        let name;
        name = task.first_name || 'Non';
        name += ' ';
        name += task.last_name || '-existent';
        name += task.is_offline_mode? ' ('+localizedData[globalLanguage]['offline']+')' : ' ('+localizedData[globalLanguage]['online']+')'
        return name;
    }else{
        return '';
    }
}

//T32-292,T32-343
const showOriginalReferenceFileName = ({fileName}) => {
    try{
        let originalFileName = '';
        if (fileName){
            let splitedFileName = fileName.split(/_(.+)/);//TP-1058
            // splitedFileName.shift();originalFileName = splitedFileName.join('-');
            originalFileName = splitedFileName[1];
        }
        if(originalFileName){
            return originalFileName;
        }else{
            return ''
        }
    }catch(error){
        console.log('showOriginalFileName error', error)
        return ''
    }
}

const getParsedExternalFilename = ({dataString, field}) => {
    try{
        const file = getParsedFiles({dataString, field});
        return showOriginalReferenceFileName({fileName: file });
    }catch(error){
        return 'NA'
    }
}

// TP-2718
const getMlCommentsTable = (data) => {
    try{
        if (data.length > 0){
            return {
                style: 'tableExample',
                table: {
                    widths: ['*'],
                    body: data
                }
            }
        }else{
            return ''
        }
    }catch(error){
        console.log('getMlCommentsTable error',error)
        return ''
    }
}

// TP-2718
const getMlComments = (data) => {
    try{
        const dataArr = data.map(comment => {
        const parsedComment = JSON.parse(comment);
            return [{text: parsedComment.text, bold: parsedComment.is_bold, italics: parsedComment.is_italic, color: parsedComment.font_color}]
        })
        return dataArr
    }catch(error){
        console.log('getMlComments error',error)
        return []
    }
}

const taskDetailsTable = (task, parsedTaskData, customer) => {
    let table = [];
    try{
        table.push([{text: localizedData[globalLanguage]['Task Instruction']+':', style: `${classNameOfLeftTd(table)}`}, {text: `${task.description}`,style: `${classNameOfRightTd(table)}`}]);

        if(task.expected_result){
            table.push([{text: localizedData[globalLanguage][`Expected Result`]+':', style: `${classNameOfLeftTd(table)}`}, {text: `${task.expected_result}`,style: `${classNameOfRightTd(table)}`}]);
        }
        //TP-4867
        // table.push([{text: localizedData[globalLanguage]['Scheduled Timeline']+':', style: `${classNameOfLeftTd(table)}`}, {text: `${moment(task.start_date).format("DD MMMM YYYY")} - ${moment(task.end_date).format("DD MMMM YYYY")}`,style: `${classNameOfRightTd(table)}`}])

        if(task.reference_materials && task.reference_materials.length > 0){
            table.push([{text: localizedData[globalLanguage]['Task Reference Material']+':', style: `${classNameOfLeftTd(table)}`}, {stack:
                [
                    {
                        ul: [
                                ...getMultipleParseFiles({files: task.reference_materials})
                        ]
                    }
                ] ,style: `${classNameOfRightTd(table)}`}
            ]);
        }
        table.push([{text: localizedData[globalLanguage]['Task Status']+':', style: `${classNameOfLeftTd(table)}`}, {text: `${statusObj[task.status]}`,style: `${classNameOfRightTd(table)}`}]);
        table.push([{text: localizedData[globalLanguage][`Executed By`]+':', style: `${classNameOfLeftTd(table)}`}, {text: getExecutedBy({task}), style: `${classNameOfRightTd(table)}`}]);
        table.push([{text: `\n`+localizedData[globalLanguage][`Execution Time`]+':', style: `${classNameOfLeftTd(table)}`}, {text: `${getExecutionDuration({task})}\n ${moment(task.execution_start_time).isValid() ? 
                moment(task.execution_start_time).format("DD MMMM YYYY, h:mm:ss a"): ''} - ${moment(task.execution_end_time).isValid() ? 
                moment(task.execution_end_time).format("DD MMMM YYYY, h:mm:ss a"): ''}`, style: `${classNameOfRightTd(table)}`}]);

        if(task.call_recordings && task.call_recordings.length > 0){
            table.push([{text: `${replaceLocalisedGenericName("expert", "Expert")} ${localizedData[globalLanguage]["Call Detail(s)"]}`+':', style: `${classNameOfLeftTd(table)}`}, {stack:
                [
                    {
                        ul: [
                                ...getMultipleParsedCallRecords({call_recordings: task.call_recordings})
                        ]
                    }
                ], style: `${classNameOfRightTd(table)}`}]
            );
        }
        table.push([{text: localizedData[globalLanguage]['Evidence Type']+':', style: `${classNameOfLeftTd(table)}`}, {text: `${task.name === 'No Evidence' ? localizedData[globalLanguage]['None']: localizedData[globalLanguage][task.name]}`,style: `${classNameOfRightTd(table)}`}]);

        if(task.external_evidence){
            table.push([{text: localizedData[globalLanguage]['External Evidence']+':', style: `${classNameOfLeftTd(table)}`}, {text: `${getParsedExternalFilename({dataString: task.external_evidence, field: 'name'})}`, link: getParsedFiles({dataString: task.external_evidence, field: 'location'}), style: `${classNameOfRightTd(table)}`}]);
        }
        table.push([{text: localizedData[globalLanguage][`Device Details`]+':', style: `${classNameOfLeftTd(table)}`},
                {
                    text: `${parsedTaskData.device_manufacturer || ''} ${parsedTaskData.device_model || ''} ${parsedTaskData.serial_number || ''} ${parsedTaskData.coordinates || ''}`,
                    style: `${classNameOfRightTd(table)}`
                }
            ]);
        if(task.tech_comment || task.comment){
            table.push([{text: localizedData[globalLanguage]['Comment']+':', style: `${classNameOfLeftTd(table)}`}, {text: `${getComment(task, customer)}`,style: `${classNameOfRightTd(table)}`}]);
        };

        if(task.command === "Ctrl+P" && parsedTaskData.observation){
            table.push([{text: localizedData[globalLanguage]['Observation']+':', style: `${classNameOfLeftTd(table)}`}, {text: `${/**TP-4202*/parsedTaskData.observation ? localizedData[globalLanguage][parsedTaskData.observation] : ''}`,style: `${classNameOfRightTd(table)}`}]);
        }
        if((customerObj.behaviour === "honeywell" || customerObj.enable_incident) && task.command === "Ctrl+IP"){
            table.push([{text: `Incidents:`, style: `${classNameOfLeftTd(table)}`}, {text: task.incidents? task.incidents.length : 0, style: `${classNameOfRightTd(table)}`}])//TZ-65
        }
        return table
    }catch(error){
        console.log('getMlCommentsTable error',error)
        return table       
    }
}
//TP-2765
const jobWithAssetTaskDetailstable = (task, parsedTaskData, customer) => {
    let table = [];
    try{
        table.push([{text: localizedData[globalLanguage]['Task Instruction']+':', style: `${classNameOfLeftTd(table)}`}, {text: `${task.description}`,style: `${classNameOfRightTd(table)}`}]);

        if(task.expected_result){
            table.push([{text: localizedData[globalLanguage][`Expected Result`]+':', style: `${classNameOfLeftTd(table)}`}, {text: `${task.expected_result}`,style: `${classNameOfRightTd(table)}`}]);
        }
        //TP-4867
        // table.push([{text: localizedData[globalLanguage]['Scheduled Timeline']+':', style: `${classNameOfLeftTd(table)}`}, {text: `${moment(task.start_date).format("DD MMMM YYYY")} - ${moment(task.end_date).format("DD MMMM YYYY")}`,style: `${classNameOfRightTd(table)}`}]);

        if(task.reference_materials && task.reference_materials.length > 0){
            table.push([{text: localizedData[globalLanguage]['Task Reference Material']+':', style: `${classNameOfLeftTd(table)}`}, {stack:
                [
                    {
                        ul: [
                                ...getMultipleParseFiles({files: task.reference_materials})
                        ]
                    }
                ] ,style: `${classNameOfRightTd(table)}`}
            ]);
        }
        table.push([{text: localizedData[globalLanguage]['Task Status']+':', style: `${classNameOfLeftTd(table)}`}, {text: `${statusObj[task.status]}`,style: `${classNameOfRightTd(table)}`}]);
        table.push([{text: localizedData[globalLanguage][`Executed By`]+':', style: `${classNameOfLeftTd(table)}`}, {text: getExecutedBy({task}), style: `${classNameOfRightTd(table)}`}]);
        table.push([{text: `\n`+localizedData[globalLanguage][`Execution Time`]+':', style: `${classNameOfLeftTd(table)}`}, {text: `${getExecutionDuration({task})}\n ${moment(task.execution_start_time).isValid() ? 
                moment(task.execution_start_time).format("DD MMMM YYYY, h:mm:ss a"): ''} - ${moment(task.execution_end_time).isValid() ? 
                moment(task.execution_end_time).format("DD MMMM YYYY, h:mm:ss a"): ''}`, style: `${classNameOfRightTd(table)}`}]);
        if(task.call_recordings && task.call_recordings.length > 0){
            table.push([{text: `${replaceLocalisedGenericName("expert", "Expert")} ${localizedData[globalLanguage]["Call Detail(s)"]}`+':', style: `${classNameOfLeftTd(table)}`}, {stack:
                [
                    {
                        ul: [
                                ...getMultipleParsedCallRecords({call_recordings: task.call_recordings})
                        ]
                    }
                ], style: `${classNameOfRightTd(table)}`}]);
        }
        table.push([{text: localizedData[globalLanguage]['Evidence Type']+':', style: `${classNameOfLeftTd(table)}`}, {text: `${task.name === 'No Evidence' ? localizedData[globalLanguage]['None']: localizedData[globalLanguage][task.name]}`,style: `${classNameOfRightTd(table)}`}]);

        if(task.external_evidence){
            table.push([{text: localizedData[globalLanguage]['External Evidence']+':', style: `${classNameOfLeftTd(table)}`}, {text: `${getParsedExternalFilename({dataString: task.external_evidence, field: 'name'})}`, link: getParsedFiles({dataString: task.external_evidence, field: 'location'}), style: `${classNameOfRightTd(table)}`}]);
        }
        table.push([{text: localizedData[globalLanguage][`Device Details`]+':', style: `${classNameOfLeftTd(table)}`},
                {
                    text: `${parsedTaskData.device_manufacturer || ''} ${parsedTaskData.device_model || ''} ${parsedTaskData.serial_number || ''} ${parsedTaskData.coordinates || ''}`,
                    style: `${classNameOfRightTd(table)}`
                }
            ]);

        if(task.tech_comment || task.comment){
            table.push([{text: localizedData[globalLanguage]['Comment']+':', style: `${classNameOfLeftTd(table)}`}, {text: `${getComment(task, customer)}`,style: `${classNameOfRightTd(table)}`}]);
        }
        
        if(task.command === "Ctrl+P" && parsedTaskData.observation){
            table.push([{text: localizedData[globalLanguage]['Observation']+':', style: `${classNameOfLeftTd(table)}`}, {text: `${/**TP-4202*/parsedTaskData.observation ? localizedData[globalLanguage][parsedTaskData.observation] : ''}`,style: `${classNameOfRightTd(table)}`}]);
        }
        return table
    }catch(error){
        console.log('getMlCommentsTable error',error)
        return table       
    }
}

//TP-6966,7085
const showIncidents = async (task) => {
    try{
        const tableBody = [];
        const {incidents} = task;
        //task/procedure can have multiple incidents. Each incidents can have multiple image urls
        for (let i = 0; i < incidents.length; i++) {
            //loop through the data to find the image. Each row will have the image as td
            if(incidents[i].image_urls){
                const images = incidents[i].image_urls;//multiple images
                const description = incidents[i].description ? incidents[i].description : 'No description provided'
                for(let index = 0; index < images.length; index++){
                    //{ url: 'https://s3.us-east-2.amazonaws.com/telepresenz-20-p2pfiles/jobs/dev/APK_38010_1701412424620.mp4', type: 'video' }
                    let parseData = images[index];
                    const url = parseData.url ? parseData.url : '';
                    const imgData = parseData.type === "image" ? await getImageDataUrl(url) : vdoImg;//for image change it
                    let imgIndex = url.split('/').length -1;
                    let imageLink = url.split('/')[imgIndex]
                    const dim = parseData.type === "image" ? 80 : 50
                    const urlObj = {  image: imgData, link: 'assets/'+encodeURIComponent(imageLink), fit: [dim, dim],  margin: 5, alignment: 'center', borderColor: ['#949494', '#949494', '#949494', '#949494']}
                    tableBody.push([urlObj, {text: description, alignment: "left", margin: [10, 10, 0, 0], borderColor: ['#949494', '#949494', '#949494', '#949494'] }]);//push the element
                }
            }
        }

        return tableBody;
    }catch(err){
        console.error(`error in showIncidents `, err)
        return [null, null]
    }
}

const tableLayout = () => {
    const layout = ["NotoSansSC", "NotoSans", "NotoSansOriya"].includes(fontStyle) ? {
        paddingTop: function(i, node) { return 0; },
        paddingBottom: function(i, node) { return 0; },
    } : {}

    return layout
}

// TP-3837
const getComment = (task, customer) => {
    try{
        let comment = "";
        if (task.command === "Ctrl+I" || task.command === "Ctrl+V" ){
            comment = task.tech_comment? task.tech_comment : localizedData[globalLanguage]['NA']
        }else if(customer && customer.behaviour === "hindalco"){
            comment = task.comment? task.comment : ''//TP-4634
        }else{
            comment = task.tech_comment? task.tech_comment : ''
        }
        return comment
    }catch(error){
        console.log('getComment error',error)
        return ""
    }
}

const getSkippedComments = (task) => {
    try{
        return { text : task.skipped_comment ? [{text:localizedData[globalLanguage]['Skipped Comment']+': ' , style: 'subheader'}, { text: `${task.skipped_comment}`}] : '', style: 'recordedLink'}
    }catch(error){
        console.log('getSkippedComments error',error)
        return {text: ''}
    }
}

const getAudioLink = (audioLink) => {
    try{
        return { text : audioLink ? [{text:localizedData[globalLanguage]['Recorded Audio']+': ' , style: 'subheader'}, { text: localizedData[globalLanguage][`Click here to listen to the operator’s comments`], link: audioLink }] : '', style: 'recordedLink'}
    }catch(error){
        console.log('getAudioLink error',error)
        return {text: ''}
    }
}

const getAudioText = (audioText) => {
    try{
        return { text : audioText ? [{text:localizedData[globalLanguage]['Operator Comments']+': ' , style: 'subheader'}, { text: audioText }] : ''}
    }catch(error){
        console.log('getAudioText error',error)
        return {text: ''}
    }
}

const getAssetReferenceFiles = ({files, type}) => {
    let referenceMaterials = [];
    try{
        referenceMaterials = files.filter((file) => file.type === type).map((file) => {
            return {text: `${showOriginalReferenceFileName({fileName: file.file_name })}`, link: 'assets/'+encodeURIComponent(file.file_name)}//TP-2882
        });
        return referenceMaterials;
    }catch(error){
        console.log('getAssetReferenceFiles error', error)
    }
}

//TP-3147
const jobStatusSummary = (job_status, totalExecutionTime) => {
    try{
        const totalExecutionTimeInSecs = Math.floor(totalExecutionTime/1000);
        const minutes = Math.floor(totalExecutionTimeInSecs / 60);
        const seconds = totalExecutionTimeInSecs - minutes * 60;

        if(job_status === 'in_progress'){
            let str = `${localizedData[globalLanguage]["Job is not yet completed."]}`
            if (totalExecutionTimeInSecs > 0){
                str += ` ${localizedData[globalLanguage]["completed task time"]} - ` + ((minutes > 0 ? ` ${minutes}${localizedData[globalLanguage][" minutes and"]}` : '') + ` ${seconds} ${localizedData[globalLanguage][" seconds."]}`)
            }
            return str
        }else if(job_status === 'finished'){
            let str = localizedData[globalLanguage]['Job was completed in'] + ((minutes > 0 ? ` ${minutes}`+localizedData[globalLanguage][` minutes and`] : '') + ` ${seconds}`+localizedData[globalLanguage][` seconds.`])
            return str;
        }else{
            return localizedData[globalLanguage]["Job has been assigned but has not yet been started to be worked on."]
        }
    }catch(error){
        console.error(`error in jobStatusSummary ${error.message}`)
        return ''
    }
}

//TP-4710
const classNameOfLeftTd = (table) => {
    if((table.length + 1) % 2 == 0){
        return "dataEvenRow"
    }else{
        return "dataOddRow"
    }
}

const classNameOfRightTd = (table) => {
    if((table.length +1) % 2 == 0){
        return "evenRow"
    }else{
        return "oddRow"
    }
}

//TP-4612
const _additionaInfo = async (data) => {
    try{
        let infoData = [], headerFontSize = 50, infoFontSize = 20 ;
        if (data[0] && data[0].additional_information ){
            var additionalInfo = JSON.parse(data[0].additional_information);
        }
        infoData.push(
            {
                text:[
                    {text: `${"Container Information"}\n\n`, style:'header', fontSize: headerFontSize, alignment: 'center'},
                    {text : [{text: `${"Date"}:`, style:'header', alignment: 'center', fontSize: infoFontSize}, {text: ` ${moment(data[0].execution_start_time).isValid() ? moment(data[0].execution_start_time).format("DD MMMM YYYY") : ""}\n\n`, style:'header', alignment: 'center', italics: true, fontSize: infoFontSize}]},
                    {text : [{text: `${"Container No"}:`, style:'header', alignment: 'center', fontSize: infoFontSize}, {text: ` ${additionalInfo.container_no}\n\n`, style:'header', alignment: 'center', italics: true, fontSize: infoFontSize}]},
                    {text : [{text: `${"Grade"}:`, style:'header', alignment: 'center', fontSize: infoFontSize}, {text: ` ${additionalInfo.grade}\n\n`, style:'header', alignment: 'center', italics: true, fontSize: infoFontSize}]},
                    {text : [{text: `${"Lot Number"}:`, style:'header', alignment: 'center', fontSize: infoFontSize}, {text: ` ${additionalInfo.lot_no}\n\n`, style:'header', alignment: 'center', italics: true, fontSize: infoFontSize}]},
                    {text : [{text: `${"Number of Bags"}:`, style:'header', alignment: 'center', fontSize: infoFontSize}, {text: ` ${additionalInfo.no_of_bags}\n\n`, style:'header', alignment: 'center', italics: true, fontSize: infoFontSize}]},
                ],
                margin: [ 0, 10, 0, 0 ]
            },
            {text: '',pageBreak: 'after'}
        )
        return infoData
    }catch(error){
        console.error(`errror in _summary ${error.message}`)
        return [];
    }
};

const _summary = async (data) => {
    try{
        let tableHeaderFontSize = ["NotoSansSC", "NotoSans", "NotoSansOriya"].includes(fontStyle) ? 15 : 17;
        let tableContentFontSize = ["NotoSansSC", "NotoSans", "NotoSansOriya"].includes(fontStyle) ? 13 : 15;
        let statusObj = {'created': localizedData[globalLanguage]['Created'], 'assigned': localizedData[globalLanguage]['Assigned'],'in_progress': localizedData[globalLanguage]['WIP'], 'finished': localizedData[globalLanguage]['Finished'], 'skipped': localizedData[globalLanguage]['Skipped']}
        let tableContent = [[
            { text: '#', style: 'tableHeader', fillColor: '#7558CB', color:'#FFFFFF', fontSize: tableHeaderFontSize},
            { text: localizedData[globalLanguage]['Instruction'], style: 'tableHeader', fillColor: '#7558CB', color:'#FFFFFF', fontSize: tableHeaderFontSize},
            { text: localizedData[globalLanguage]['Assignee'], style: 'tableHeader', fillColor: '#7558CB', color:'#FFFFFF', fontSize: tableHeaderFontSize},
            { text: localizedData[globalLanguage]['Status'], style: 'tableHeader', fillColor: '#7558CB', color:'#FFFFFF', fontSize: tableHeaderFontSize}
        ]];
        let totalExecutionTime = 0;
        for(let i=0; i < data.length; i++){
            let tInstruction = '';
            if(data[i].description && data[i].description.length > 50) {
                tInstruction = data[i].description.substring(0,50) + "...";
            }else{
                tInstruction = data[i].description;
            }
            let assignee = (data[i].first_name || localizedData[globalLanguage]['Non']) + ' ' + (data[i].last_name || localizedData[globalLanguage]['- existent']);
            let status = data[i].status;
            let sequence = data[i].sequence + (data[i].sub_sequence ? data[i].sub_sequence : '');
            let styleClass = (i + 1)%2 === 0 ? 'evenRow' : 'oddRow'
            tableContent.push([
                    {
                        text: `${sequence}`, style: styleClass, fontSize: tableContentFontSize
                    },
                    {
                        text: `${tInstruction}`, style: styleClass, fontSize: tableContentFontSize
                    },
                    {
                        text: `${assignee}`, style: styleClass, fontSize: tableContentFontSize
                    },
                    {
                        text: `${statusObj[status]}`, style: styleClass, fontSize: tableContentFontSize
                    }
                ])
            totalExecutionTime += moment(data[i].execution_start_time).isValid() && moment(data[i].execution_end_time).isValid() ? (moment(data[i].execution_end_time) - moment(data[i].execution_start_time)) : 0;
        }

        let summaryData = [
            {text: `${localizedData[globalLanguage]["Summary"]}`, style: "summaryHeader", fontSize: ["NotoSansSC", "NotoSans", "NotoSansOriya"].includes(fontStyle) ? 35 : 40, color: '#2d3858'},
            {text : [{text: `${localizedData[globalLanguage]["Scheduled to start"]}:`, style:"header", italics: true}, {text: ` ${moment(data[0].job_start_date).format("DD MMMM YYYY, h:mm a")}`, style:'header', italics: true}]},//TP-4867
            {text : [{text: `${localizedData[globalLanguage]["Scheduled to end"]}:`, style:"header", italics: true}, {text: ` ${moment(data[0].job_end_date).format("DD MMMM YYYY, h:mm a")}`, style:'header', italics: true}]},//TP-4867
            {text: jobStatusSummary(data[0].job_status, totalExecutionTime), style: 'statusSummary'},//TP-3147
            {
                columns: [
                    {
                        style: 'tableExample',
                        table: {
                            widths: [30, 480, 135, 65],
                            body: tableContent
                        }
                    },
                ]
            
            },
            {text: '',pageBreak: 'after'}
        ];

        return summaryData.flat();
    }catch(error){
        console.error(`errror in _summary ${error.message}`)
        return [];
    }
};

//TP-3993
const setFont = (language) => {
    if (language === "zh-cn" || language === "zh-CN" || language === "ja-JP"){
        fontStyle = "NotoSansSC"
    }else if(language === "hi"){
        fontStyle = "NotoSans";
    }else if(language === "or"){
        fontStyle = "NotoSansOriya";
    }else{
        fontStyle = "Roboto";
    }
}

//TP-2765
const _assetSummary = async (data) => {
    try{
        const asset = data[0];
        let tableContent = [], description = '', comments = '';

        if(asset.asset_description && asset.asset_description.length > 50) {
            description = asset.asset_description.substring(0,50) + "...";
        }else{
            description = asset.asset_description;
        }
        if(asset.comments && asset.comments.length > 50) {
            comments = asset.comments.substring(0,50) + "...";
        }else{
            comments = asset.comments;
        }
        let asset_class_name = asset.asset_class_name;
        let rowFontSize = ["NotoSansSC", "NotoSans", "NotoSansOriya"].includes(fontStyle) ? 15 : 17

        tableContent.push(
            [{text: localizedData[globalLanguage][`Name`]+`:`, style: 'oddRow', fontSize: rowFontSize}, {text: `${asset.asset_name}`, style: 'oddRow', fontSize: rowFontSize}],
            [{text: localizedData[globalLanguage][`Description`]+`:`, style: 'evenRow', fontSize: rowFontSize}, {text: `${description}`, style: 'evenRow', fontSize: rowFontSize}],
            [{text: localizedData[globalLanguage][`Asset Class`]+`:`, style: 'oddRow', fontSize: rowFontSize}, {text: `${asset_class_name}`, style: 'oddRow', fontSize: rowFontSize}],
            [{text: localizedData[globalLanguage][`Geo Location`]+`:`, style: 'evenRow', fontSize: rowFontSize}, {text: `${asset.geo_location || ''}`, style: 'evenRow', fontSize: rowFontSize}],
            [{text: localizedData[globalLanguage][`Installed Location`]+`:`, style: 'oddRow', fontSize: rowFontSize}, {text: `${asset.asset_installed_at || ''}`, style: 'oddRow', fontSize: rowFontSize}],
            [{text: localizedData[globalLanguage][`Installed On`]+`:`, style: 'evenRow', fontSize: rowFontSize}, {text: `${moment(asset.asset_installed_on).format("DD MMMM YYYY")}`, style: 'evenRow', fontSize: rowFontSize}],
            [{text: localizedData[globalLanguage][`Installation End Date`]+`:`, style: 'oddRow', fontSize: rowFontSize}, {text: `${moment(asset.asset_installation_end_date).format("DD MMMM YYYY")}`, style: 'oddRow', fontSize: rowFontSize}],
            [{text: localizedData[globalLanguage][`Comment`]+`:`, style: 'evenRow', fontSize: rowFontSize}, {text: `${comments}`, style: 'evenRow', fontSize: rowFontSize}],
            [{text: localizedData[globalLanguage]['Asset Reference Material']+':', style: 'oddRow', fontSize: rowFontSize}, {stack:
                [
                    {
                        ul: [
                                ...getAssetReferenceFiles({files: data, type: 'asset_reference'})
                        ]
                    }
                ] ,style: 'oddRow'}
            ],
            [{text: localizedData[globalLanguage]['Look Up Image']+':', style: 'evenRow', fontSize: rowFontSize}, {stack:
                [
                    {
                        ul: [
                                ...getAssetReferenceFiles({files: data, type: 'look_up_reference'})
                        ]
                    }
                ] ,style: 'evenRow'}
            ],
        )

        let summaryData = [
            {text: localizedData[globalLanguage]['Asset Details'], style: 'header', fontSize: 50, color: '#2d3858'},
            {
                columns: [
                    {
                        style: 'tableExample',
                        table: {
                            widths: [175, '*'],
                            body: tableContent
                        }
                    },
                ]
            
            },
            {text: '',pageBreak: 'after'}
        ];

        return summaryData.flat();
    }catch(error){
        console.error(`errror in _assetSummary ${error.message}`)
        return [];
    }
};

const _introduction = async ({jobName, company_name, creater_name , job_created_on, last_updated_on, customer}) => {
    try{
        let introData = [], logo_url, headerFontSize = ["NotoSansSC", "NotoSans", "NotoSansOriya"].includes(fontStyle) ? 45 : 50,
        logoSize = ["NotoSansSC", "NotoSans", "NotoSansOriya"].includes(fontStyle) ? [150, 100] : [200, 150];
        if (customer.allow_custom_logo && customer.jobs_logo_url && customer.jobs_logo_url !== ''){
            logo_url = JSON.parse(customer.jobs_logo_url).location;//T32-10
        }
        let imgData = logo_url && logo_url !== '' ? await getImageDataUrl(logo_url) : await getImageDataUrl(siteLogo);
        introData.push(
            {
                text:[
    
                    {text: localizedData[globalLanguage][`Job Report`]+`\n\n`, style:'header', fontSize: headerFontSize, alignment: 'center'},
                    {text: localizedData[globalLanguage][`Job Name`]+`: ${jobName}\n\n`, style:'header', alignment: 'center'},
                    {text: localizedData[globalLanguage][`Account Name`]+`: ${company_name}\n\n`, style:'header', alignment: 'center'},
                    {text: localizedData[globalLanguage][`Created by`]+`: ${creater_name}\n\n`, style:'header', alignment: 'center'},
                    {text: localizedData[globalLanguage][`Created On`]+`: ${job_created_on}\n\n`, style:'header', alignment: 'center'},
                    {text: localizedData[globalLanguage][`Updated On`]+`: ${last_updated_on}\n\n`, style:'header', alignment: 'center'},
                    
                ],
                margin: [ 0, 10, 0, 0 ]
            },
            {
                image: imgData,
                fit: logoSize,
                alignment: 'center',
                margin: [ 5, 10, 0, 0 ],
                pageBreak: 'after'
            }
        )
        return introData;
    }catch(error){
        console.log(error)
    }
}

//TP-5930
const replaceLocalisedGenericName = (key, defaultValue) => {
    try{
        const {generic_name} = customerObj
        const value = replaceGenericName(generic_name, key, defaultValue);
        if(value && localizedData[globalLanguage][value]){
            return `${localizedData[globalLanguage][value]}`
        }else{
            return `${localizedData[globalLanguage][defaultValue]}`
        }
    }catch(error){
        console.error(`error in replaceLocalisedGenericName ${error}`)
        return `${localizedData[globalLanguage][defaultValue]}`
    }
}

export default async ({subTasks, modelAssets:assets, selectedJob, customer, language}) => {
    try{
        customerObj = customer;
        globalLanguage = includedLanguages.includes(language) === false ? "en" : (language === "zh-cn" || language === "zh-CN" ) ? "zh-cn" : language;
        statusObj = {'created': localizedData[globalLanguage]['Created'], 'assigned': localizedData[globalLanguage]['Assigned'],'in_progress': localizedData[globalLanguage]['In Progress'], 'finished': localizedData[globalLanguage]['Finished'], 'skipped': localizedData[globalLanguage]['Skipped']}
        const data = subTasks;
        const {vfs} = vfsFonts.pdfMake;
        pdfMake.vfs = vfs;
        const fontBaseUrl = window._env_.REACT_APP_APP_MODE === "LAN" ? `https://${window._env_.REACT_APP_API_SOCKET_URL}/fonts` : "https://telepresenz-20-p2pfiles.s3.us-east-2.amazonaws.com"
        pdfMake.fonts = {
            NotoSans: {
                normal: `${fontBaseUrl}/NotoSans-Regular.ttf`,//TP-5907
                bold: `${fontBaseUrl}/NotoSans-Medium.ttf`,
                italics: `${fontBaseUrl}/NotoSans-Regular.ttf`,
                bolditalics: `${fontBaseUrl}/NotoSans-Medium.ttf`,
            },
            NotoSansOriya: {
                normal: `${fontBaseUrl}/NotoSansOriya-Regular.ttf`,//TP-5907
                bold: `${fontBaseUrl}/NotoSansOriya-Medium.ttf`,
                italics: `${fontBaseUrl}/NotoSansOriya-Regular.ttf`,
                bolditalics: `${fontBaseUrl}/NotoSansOriya-Medium.ttf`,
            },
            NotoSansSC: {
                normal: `${fontBaseUrl}/NotoSansSC-Regular.otf`,
                bold: `${fontBaseUrl}/NotoSansSC-Medium.otf`,
                italics: `${fontBaseUrl}/NotoSansSC-Regular.otf`,
                bolditalics: `${fontBaseUrl}/NotoSansSC-Medium.otf`
            },
            Roboto: {
                normal: 'Roboto-Regular.ttf',
                bold: 'Roboto-Medium.ttf',
                italics: 'Roboto-Italic.ttf',
                bolditalics: 'Roboto-Italic.ttf'
            }
        }        
        setFont(language);
        //TP-502
        let downloaded_on =   moment(new Date()).format('DD MMMM YYYY HH:mm:ss');
        let generated_by = customer && customer.allow_company_name_as_report_author ? customer.company_name : 'Telepresenz ™';
        generated_by += (customer && customer.allow_company_name_as_report_author && customer.company_url && customer.company_url !== '' ? ` - ${customer.company_url}` : ''); 
        generated_by += " "+localizedData[globalLanguage]["on"]+" "+downloaded_on; 
        const summaryData = data.length> 0 ? await _summary(data) : '';
        const additional_information = data.length > 0 && customer.behaviour === "hindalco" ? await _additionaInfo(data) : '';
        const assetSummaryData = customer && customer.enable_asset_class !== "disabled" && assets && assets.length > 0 ? await _assetSummary(assets) : '';
        let company_name = customer.company_name ? customer.company_name : '';
        let creater_name = (data[0].creater_fname || `${localizedData[globalLanguage]["Non"]}`) + ' ' + (data[0].creater_lname || `${localizedData[globalLanguage]["- existent"]}`);
        let job_created_on = data[0] ? moment(data[0].job_created_on).format("DD MMMM YYYY") : '';
        let last_updated_on = data[0] ? moment(data[data.length-1].job_updated_on).format("DD MMMM YYYY") : '';
        

        const introData = await _introduction({jobName: selectedJob.name, company_name, creater_name, job_created_on, last_updated_on, customer});
        const formattedData = await _format(data, customer);

        const documentDefinition = {
                pageSize: 'A4',
                pageMargins: [ 40, 60, 40, 60 ],
                pageOrientation: 'landscape',
                header: function(currentPage, pageCount) {
                    if(currentPage != 1 && currentPage != 2 && currentPage != 3){
                        return([
                            { canvas: [{ type: 'line', x1: 40, y1: 40, x2: 800, y2: 40, lineWidth: 0.5 }] },
                            {text: '\n'},
                        ])
                    }else{
                        return null;
                    }
                },
                footer: function(currentPage, pageCount) {
                    if(currentPage != 1 && currentPage != 2 && currentPage != 3){
                        return ([
                            { canvas: [{ type: 'line', x1: 40, y1: 10, x2: 800, y2: 10, lineWidth: 0.5 }] },
                            {
                                alignment: 'justify',
                                columns: [
                                    { text: localizedData[globalLanguage]['Proprietary & Confidential'], margin: [40, 5, 0, 0],fontSize: 10 },
                                    { text: localizedData[globalLanguage]['Page']+' | '+ currentPage.toString(), alignment: 'center', margin: [0, 5, 0, 0],fontSize: 10 },
                                    { text: `${company_name}`, alignment: 'right' , margin: [0, 5, 40, 0],fontSize: 10 },
                                ],
                            },

                        ])
                    }else if(currentPage === 1){
                        return {text: localizedData[globalLanguage][`Generated By`]+` ${generated_by}`, style:'tableHeader', margin: [0, 30, 0, 0], alignment: 'center', fontSize: 13};
                    }else{
                        return null;
                    }
                },
                // watermark: { text: 'test watermark', angle: 70, color: 'blue', opacity: 0.3, bold: true, italics: false  },
                content: [
                    ...introData,
                    ...additional_information,
                    ...summaryData,
                    ...assetSummaryData,
                    ...formattedData
                ],
                defaultStyle: {
                    font: fontStyle
                },
                styles: {
                    header: {
                    fontSize: ["NotoSansSC", "NotoSans", "NotoSansOriya"].includes(fontStyle) ? 14 : 16,
                    bold: true,
                    margin: [0, 0, 0, 0]
                },
                subheader: {
                    fontSize: ["NotoSansSC", "NotoSans", "NotoSansOriya"].includes(fontStyle) ? 14 : 16,
                    bold: true,
                    margin: [0, 10, 0, 5],
                    color:'#2d3858'
                },
                tableExample: {
                    margin: [0, 5, 0, 5]
                },
                tableHeader: {
                    bold: true,
                    fontSize: ["NotoSansSC", "NotoSans", "NotoSansOriya"].includes(fontStyle) ? 11 : 13,
                    color: 'black'
                },
                oddRow: {
                    fillColor: '#384571',
                    color:'#FFFFFF'
                },
                evenRow: {
                    fillColor: '#2D3858',
                    color:'#FFFFFF'
                },
                dataOddRow: {
                    bold: true,
                    fontSize: ["NotoSansSC", "NotoSans", "NotoSansOriya"].includes(fontStyle) ? 11 : 13,
                    fillColor: '#384571',
                    color:'#FFFFFF'
                },
                dataEvenRow: {
                    bold: true,
                    fontSize: 13,
                    fillColor: '#2D3858',
                    color:'#FFFFFF'
                },
                blueFont: {
                    color:'#2d3858'
                },
                recordedLink: {
                    margin: [0, 10, 0, 5],
                },
                statusSummary: {
                    bold: true,
                    italics: true,
                    fontSize: ["NotoSansSC", "NotoSans", "NotoSansOriya"].includes(fontStyle) ? 18 : 20,
                    color: '#2d3858',
                    margin: [0, 5, 0, 5],
                },
                summaryHeader: {
                    fontSize: ["NotoSansSC", "NotoSans", "NotoSansOriya"].includes(fontStyle) ? 14 : 16,
                    bold: true,
                    margin: [0, 0, 0, 5]
                },
            },            
        };

        return documentDefinition;
    }catch(error){
        console.error(`report not generated ${error.message}`)
        return {}
    }       
}

