import React from 'react';
import './session.css';
import { printConsole } from '../helpers';
import WebrtcSessionUser from './WebrtcSessionUser';
import { I18n } from "@lingui/react";
import WebrtcVideoTile from './WebrtcVideoTile';
import WebrtcSideBar from './WebrtcSideBar';
import StreamActions from '../StreamActions/StreamActions';
import { Modal, ModalHeader, ModalBody, Button, ModalFooter, Col, Row, UncontrolledTooltip } from 'reactstrap';
import WebrtcErrorMessage from './WebrtcErrorMessage';
import SessionChat from './SessionChat';
import SessionTimer from './SessionTimer';
import FlashMessage from '../FlashMessage/FlashMessage';
import OTDevices from './OTDevices';
import SessionIcon from './SessionIcon.jsx';
import BubbleElement from '../BubbleElement/BubbleElement'; //TP-3423

import { Trans, t } from '@lingui/macro';
import SidePanel from './SidePanel'; //TP-3213

const ResolutionsToCheck = [
    {width: {exact: 120}, height: {exact: 160}},
    {width: {exact: 320}, height: {exact: 240}},
    {width: {exact: 640}, height: {exact: 480}},
    {width: {exact: 1280}, height: {exact: 720}},
    {width: {exact: 1920}, height: {exact: 1080}},
    {width: {exact: 3840}, height: {exact: 2160}},
    {width: {exact: 4096}, height: {exact: 2160}},
    {width: {exact: 7680}, height: {exact: 4320}}
];

var mid = ResolutionsToCheck.length-1;

class WebrtcTech extends WebrtcSessionUser {
    constructor (props) {
        super(props);
        this.state = {
            ...this.state,
            participants: [],
            startSession: false,
            sessionCmd: this.props.sessionCmd,
            showRc: false,
            shouldStartStopChange: true,
            headerMuteMike: false,
            videoSource: "",
            audioSource: "",
            defAudioId: "", 
            defVideoId: "",
            changeCamera: true,
            cameraSwitched: false,
            cameraCount: 1,
            showCameraSelectionDecisionBox: false,
            triggerCameraDetect: false,
            maxDivIconClicked: false,
            disableMaxDivControls: false,
            publishVideo: true,
            maxUserName: this.state.user.firstname + ' ' + this.state.user.lastname, //TP-2539
            is_grid: true, max_view_user: '',//TP-3551
            hasMaxViewVideoTracks: true, //TP-3701
            expertSSFrom: '',//TP-3844
            showPanel: false, //TP-3213
            maxVideoPaused: false, /**TP-5053*/
            maxVideoPoster: '', /**TP-5053*/
            maxVideoPauseReason: "" /**TP-5053*/
        };
        this.localVideoRef = React.createRef();        
    }

    componentDidMount() {
        const hideButton = this.getDeviceClassname();
        this.setState({hideButton});
        this.sessionSignal.listener('end-group-call', this.subscribeToGlobalEndCall); //TP-2131
        this.sessionSignal.listenerWebsocket(this.subscribeToGrpSignal);
        this.sessionSignal.listener('update-user-list', this.updateUserList);
        this.sessionSignal.listener('call-user', this.incomingCallForTech);
        this.sessionSignal.listener('new-ice-candidate', this.onRecieveIceCandidate);
        this.sessionSignal.listener('end-call', this.userLeft);
        this.sessionSignal.listener('answer-user', this.callAnswered); //TP-3052
        this.sessionSignal.listenerUserinsession(this.subscribeToUserIsInSession); //TP-2832
        //this.initMedia();
        this.initGetMedia();
        this.onCommandChange();
        this.calVdoHeightWidth();     
        window.addEventListener("resize", this.updateTechScreenResize);//TP-4770 Mb2-308
        this.authService.socket.on('isOffline',  this.checkUserOffline); //MB2-661
        this.sessionSignal.listenerIsOfflineNotification(this.subscribeToOffline);//MB2-441
        //this.props.subscribeToNewImageUploadNotification();
        // TP-991, FQ3-318
        if (typeof(Storage) !== "undefined") {
            printConsole(`setting the group id in session storage =======================================>${this.props.selectedGroupId}`)
            sessionStorage.setItem("groupId", this.props.selectedGroupId); sessionStorage.setItem("isonprem", true);
        }else{
            printConsole(`Sorry, your browser does not support Web Storage...`)
        }
    }

    componentWillUnmount = () => {
        if(this.volumeInterval !== null) {
            clearInterval(this.volumeInterval);
            this.volumeInterval = null;
        }
        this.sessionSignal.removeListener('end-group-call',this.subscribeToGlobalEndCall); //TP-2131
        this.sessionSignal.removeWebsocket(this.subscribeToGrpSignal);
        this.sessionSignal.removeListener('update-user-list', this.updateUserList);
        this.sessionSignal.removeListener('call-user', this.incomingCallForTech);
        this.sessionSignal.removeListener('new-ice-candidate', this.onRecieveIceCandidate);
        this.sessionSignal.removeListener('end-call', this.userLeft);
        this.sessionSignal.removeListener('answer-user', this.callAnswered); //TP-3052
        this.sessionSignal.removeUserinsession(this.subscribeToUserIsInSession); //TP-2832
        window.removeEventListener("resize", this.updateTechScreenResize);//TP-4770 Mb2-308
        this.authService.socket.removeListener('isOffline', this.checkUserOffline); //MB2-661
        this.sessionSignal.removeIsOfflineNotification(this.subscribeToOffline);//MB2-441
        clearTimeout(this.timeOut);
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevState.sessionCmd !== this.state.sessionCmd){
            this.onCommandChange();
        }

        if(this.props.rcStart !== prevProps.rcStart){
            this.muteHeaderVideoForAll(!this.props.localRc);//MB2-512
        }

        if ((this.state.videoSource !== prevState.videoSource && this.state.videoSource !== "") /* || (this.state.audioSourcecameraSwapEventType !== prevState.audioSource && this.state.audioSource !== "") */) {
            printConsole("Swap camera sent signal")
            printConsole("Camera Swap event type::::", this.state.cameraSwapEventType);
            //this.sendSignalSwapCamera(this.state.cameraCount);   
            //this.reInitMedia();
            this.reInitGetMedia(); // FQ3-333

        }
        else if (this.state.cameraCount !== prevState.cameraCount && this.state.cameraCount > prevState.cameraCount){
            const message = "New external camera has been connected.."
            this.triggerMessage(message, "camera-connect", '4%', '35%');
            /* this.setState({ 
                flashMsgText: "New external camera has been connected..", 
                showFlashMessage: true, flashLeft:"4%", flashTop:"35%" 
            }); */
        }
        else if (this.state.cameraCount !== prevState.cameraCount && this.state.cameraCount < prevState.cameraCount){
            const message = "External camera has been disconnected.."
            this.triggerMessage(message, "camera-disconnect", '4%', '35%');
            /* this.setState({ 
                flashMsgText: "External camera has been disconnected..", 
                showFlashMessage: true, flashLeft:"4%", flashTop:"35%" 
            }); */
        }
        //TP-3844
        if (prevState.expertVideoArr.length !== this.state.expertVideoArr.length && this.state.expertSSFrom !== ''){
            const [participant] = this.state.expertVideoArr.filter(p => p.email === this.state.expertSSFrom && p.type === "screen");
            console.log("expert's Screen cast feed has been received later for: ", participant);
            if (participant){
                this.processSignalSSPilot({from: this.state.expertSSFrom, type: 'START_EXPERT_SS'});
            }
        }
        //TP-4261 -- Handling the trigger to show the LC button on Webapp technician 
        //(if it in Max-view of Primary expert) after the shared Artifact is closed by the tech
        if (prevProps.triggershowRC !== this.props.triggershowRC && this.props.triggershowRC === true) {
            this.startShowingLConTech();
        }
        //TP-5387 -- Profile picture updating implemented on Tech side as well
        if (this.props.userPictures && this.props.userPictures !== prevProps.userPictures && this.props.isGuest && this.props.userPictures.length > 0 && this.state.participants.length > 0){
            printConsole("In Guest user Participants image changed");
            this.updateParticipantsImages()
        }
    }

    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.sessionCmd!==prevState.sessionCmd){
            return { sessionCmd: nextProps.sessionCmd };
        }else{
            return null;
        }
    }

    onCommandChange = () => {
        let {sessionCmd, startSession} = this.state;
        let updatedStateVar = {};
        printConsole(`....session cmd tech...., ${sessionCmd}`)
        switch (sessionCmd) {
            case 'session_started':
                updatedStateVar.startSession = !startSession ;
                break;
            case 'session_stopped':
                updatedStateVar.startSession = !startSession ;
                this.hangUp();
                break;
            default:
                break;                
        }
        this.setState((prev) => ({
            ...prev,
            ...updatedStateVar
        }));
    }

    // FQ3-334
    getShowButtonsData = () => {
        let data = "tech_ss:::expert_ss"; //NS2-119
        const ua = navigator.userAgent.toLowerCase();
        if (/chrome/.test(ua)) {
            if (/android/.test(ua)) {
                data = "expert_ss"; // NS2-210
            }
        } else if (/safari/.test(ua)) {
            data = "expert_ss"; // NS2-210
        }
        data = data+":::"+this.getCameraResolution();
        return data;
    }

    //TP-4770
    updateTechScreenResize = () => {
        const [meParticipant] = this.state.expertVideoArr.filter (e => e.email === this.props.user.email && e.type === "camera");
        if (meParticipant && meParticipant.aspect_ratio)
            this.calVdoHeightWidth(meParticipant.aspect_ratio, true); //TP-4769
        else
            this.calVdoHeightWidth("16:9", true);//TP-4769
    }

    // TP-3052 -- call the user when they join the group call
    callUserForTech = () => {
        const {participants, connections, user} = this.state;
        const peerEmails = connections.map(peer => peer.email);//find the emails for all the participants for whom peerconnection is already established
        printConsole(`the peer connection emails in call user ===============================> ${JSON.stringify(peerEmails)}`);
        participants.length > 0 && participants.forEach((participant) => {
            printConsole("Current Participant list");
            printConsole(participants);
            // check whether peer is already connected. Ideally this condition should always pass
            if(!peerEmails.includes(participant.email)){
                /* 1. If the current user is the group primary expert then he will send any offer to other participant depending on the time stamp
                2. if the current user has joined before the expert participant then he will not send any offer
                3. In multi expert scenario only the expert joining first will send invite to other experts who have joined later */
                printConsole(`checking the condition for sending the offer connection for ${participant.email}, ${this.state.user.joinTimeStamp < participant.joinTimeStamp}`)
                //printConsole(this.state.user.joinTimeStamp);
                //printConsole(participant.joinTimeStamp);
                //printConsole(`isOngoingPeer: ${this.state.isOngoingPeer} and for user ${this.state.isOngoingPeerEmail}`);
                if(!participant.isexpert && this.state.user.joinTimeStamp < participant.joinTimeStamp) {
                    printConsole(`trying to call user =======================>,${JSON.stringify(participant)}`)
                    //this.setState({ isOngoingPeer: true, isOngoingPeerEmail: participant.email }, ()=> {
                        //TP-2618
                        let {localStream} = this.state;
                        //console.log(localStream.getVideoTracks());
                        const connection = this.createPeerConnection({to: participant.email, localStream});
                        connection.peerConnection.offerConnection();
                        //this.startPeerConnectionTimer();//TP-1922
                    //});
                } else {
                    printConsole("Newer technician in session ");
                }
            }
        })
    };

    // TP-3052-- the recipient has responded with answer
    callAnswered = (data) => {
        let {connections, socketId} = this.state;
        if(data.recipientSocketId && socketId !== data.recipientSocketId){
            return;
        }
        /* if (isOngoingPeer === true && isOngoingPeerEmail !== '' && data.from === isOngoingPeerEmail) {
            this.stopPeerConnectionCounter();
            this.setState({ isOngoingPeer: false, isOngoingPeerEmail: '' });
        } */
        // update the stream id that has been sent in data
        this.updateParticipantStreamId({data});
        this.updateMediaStreamMetaData({data}); //TP-2539
        // find the exact peerconnection for the participant
        const connection = connections.find((connection) => connection.email === data.from);
        console.log(connection);
        try{
            connection.peerConnection.connectionAnswer(data);
        }catch(e){
            printConsole('answer made error', e)
        }
    }

    //TP-3052 -- incoming call from expert/other technician
    incomingCallForTech = (data) => {
        console.log("Here for tech-------------->", data);
        let {localStream, socketId} = this.state;
        // update the stream id that has been sent in data
        if(data.recipientSocketId && socketId !== data.recipientSocketId){
            return;
        }
        this.updateParticipantStreamId({data})
        this.updateMediaStreamMetaData({data}); //TP-2539
        //console.log("local stream-->", localStream);
        //console.log(localStream.getTracks());
        const connection = this.createPeerConnection({to: data.from, localStream})
        console.log("Connection array---------->", this.state.connections);
        connection.peerConnection.connectionOffer(data);
    }

    // when tech is in max view he/she will get this button
    processSignalMaxView = ({data: current_state, from}) => {
        //printConsole('signal for max view')
        const [primaryExpert] = this.state.participants.filter(e => e.email === from.email);
        const cameraRes = this.getCameraResolution();
        //printConsole(primaryExpert);
        //printConsole(this.state.currentPrimaryExpert)
        if(primaryExpert && (this.state.maxView !== current_state || this.state.currentPrimaryExpert && this.state.currentPrimaryExpert.email !== primaryExpert.email)){
            this.props.setPrimaryExpert({primaryExpert});
            this.setState({ maxView: current_state, currentPrimaryExpert: primaryExpert });// related  to NS2-296
            if (current_state && (this.state.expertShareScreenMaxDiv || this.state.techShareScreen /*TP-1329*/|| this.props.isHeaderFileShare || this.props.isOneToOneFileShare)) {
                this.setState({showRc: false});
            } else{
                this.setState({ showRc: current_state });
            }

            //NS2-210, If the technician is in the Max view of the Expert and is on a safari browser/Android device then remove tech_ss
            // into the signal payload else dont for the signal is True 
            let data = "tech_ss:::expert_ss"; //NS2-119
            const ua = navigator.userAgent.toLowerCase();
            if (current_state && /chrome/.test(ua)) {
                if (/android/.test(ua)) {
                    data = "expert_ss"; // NS2-210
                }
            } else if (current_state && /safari/.test(ua)) {
                data = "expert_ss"; // NS2-210
            }
            data = data+":::"+ cameraRes; //FQ3-334
            // This condition was added for NS2-202
            if (current_state) {
                /** Payload info: "tech_ss:::expert_ss:::zoom:::torch"
                 * tech_ss for when technician Screen share supported by technician
                 * expert_ss for when expert Screen share can be subscribed by technician 
                 * zoom for when increasing/decreasing zoom levels is supported by the technician
                 * torch for when turning the remote torch on/off on that technician is supported
                 */
                // condition added into the if statement, to send the signal to only 
                // the sender of the MAX_VIEW signal
                // for NS2-119
                this.sessionSignal.sendSignalMaxDivShowButtons(data,from.email)
            }
        } else {
            printConsole("MAX_VIEW signal is received with same payload");
            if (current_state && (this.state.expertShareScreenMaxDiv || this.state.techShareScreen /*TP-1329*/|| this.props.isHeaderFileShare || this.props.isOneToOneFileShare)) {
                this.setState({showRc: false});
            } else{
                this.setState({ showRc: current_state });
            }
            let data = "tech_ss:::expert_ss"; //NS2-119
            const ua = navigator.userAgent.toLowerCase();
            if (current_state && /chrome/.test(ua)) {
                if (/android/.test(ua)) {
                    data = "expert_ss"; // NS2-210
                }
            } else if (current_state && /safari/.test(ua)) {
                data = "expert_ss"; // NS2-210
            }
            data = data+":::"+ cameraRes; //FQ3-334
            // This condition was added for NS2-202
            if (current_state) {
                /** Payload info: "tech_ss:::expert_ss:::zoom:::torch"
                 * tech_ss for when technician Screen share supported by technician
                 * expert_ss for when expert Screen share can be subscribed by technician 
                 * zoom for when increasing/decreasing zoom levels is supported by the technician
                 * torch for when turning the remote torch on/off on that technician is supported
                 */
                // condition added into the if statement, to send the signal to only 
                // the sender of the MAX_VIEW signal
                // for NS2-119
                this.sessionSignal.sendSignalMaxDivShowButtons(data,from.email)
            }
        }
    }

    //TP-3114 -- sync up the Technician user's screen with that of Primary Expert's
    // when screenshare is not ongoing if Video enabled mode/tech2tech is enabled for that account
    processSignalCurrentMaxViewUser = ({data, from}) => {   
        const {user} = this.props;
        //TP-3551 & TP-3156
        if(this.state.groupInfo.session_type === "CM" || (this.state.groupInfo.session_type === "RM" && this.props.enable_user_video !== "my_camera_disabled" /**TP-4077*/) || this.props.audio_mode === "tech2Tech" /**TP-4270 && TP-4378*/) {
            const { expertShareScreenMaxDiv } = this.state;
            const {is_grid, max_view_user} = data;
            this.setState({is_grid, max_view_user});//TP-4422
            if (expertShareScreenMaxDiv) return;
            if (is_grid === false && max_view_user) {
                const [participant] = this.state.expertVideoArr.filter(p=> p.email === max_view_user);
                if (participant) {
                    //TP-3551
                    if (participant.email === user.email) {
                        this.triggerMessage("Switching to my camera preview mode", "switch-to-preview");
                        this.setState({showPanel: false}) //TP-4379
                    }
                    else {
                        const connData = participant.fullName;
                        this.triggerMessage(connData, "switch-to-other-max");
                    }
                    //TP-2724
                    if (this.state.setTechMaxStreamid !== participant.stream.id)
                        this.onClickMaximize(participant.email, "camera");
                    let newVideoArr = [];
                    if (participant.email === user.email){
                        newVideoArr = this.state.expertVideoArr.filter(p => p.isexpert || (p.email === user.email))
                        this.setState({ newVideoArr });
                    } else {
                        newVideoArr = this.state.expertVideoArr.filter(p => p.isexpert || p.email === user.email || p.email === participant.email)
                        this.setState({ newVideoArr });
                    }
                }
                else {
                    //TP-3551
                    printConsole("This user Video does not exist in technician's participant list");
                }
            } else {
                //TP-4265 -- commented out the changes done for this ticket
                /* const [participant] = this.state.expertVideoArr.filter(p=> p.email === user.email);
                if (participant) {
                    this.triggerMessage("Switching to my camera preview mode", "switch-to-preview");
                    if (this.state.setTechMaxStreamid !== participant.stream.id)
                        this.onClickMaximize(participant.email, "camera");
                } */
                console.log("Grid View in Expert means no change in Technician");
                //this.setState({ newVideoArr: this.state.expertVideoArr });
            }
        } else {
            printConsole("Collaboration mode is not set for this account")
        }
        //TP-3551 -- Store the last state of the Primary expert's screen
        if (this.state.expertShareScreenMaxDiv) return;
        let {is_grid, max_view_user} = data;
        this.setState({is_grid, max_view_user});
    }

    //TP-4683 -- To make the technicians move to their own preview mode during RC, Annotate & Share Artifacts
    processAutoPreviewMode = () => {
        if(this.state.groupInfo.session_type === "CM" || (this.state.groupInfo.session_type === "RM" && this.props.enable_user_video !== "my_camera_disabled" /**TP-4077*/) || this.props.audio_mode === "tech2Tech" /**TP-4270 && TP-4378*/) {
            const {user} = this.props;
            const [participant] = this.state.expertVideoArr.filter(p=> p.email === user.email)
            if(participant && (this.state.setTechMaxStreamid !== participant.stream.id)){
                this.triggerMessage("Switching to my camera preview mode", "switch-to-preview");
                this.setState({showPanel: false}) //TP-4379                
                this.onClickMaximize(participant.email, "camera");
                let newVideoArr = [];
                if (participant.email === user.email){
                    newVideoArr = this.state.expertVideoArr.filter(p => p.isexpert || (p.email === user.email))
                    this.setState({ newVideoArr });
                }
            }
        } else {
            printConsole("RM with Expert Video enabled/Tech2tech mode is not set for this account")
        }
    }
    

    // TP-359 -- When the technician receives the Current Primary expert's Identity
    // Updates the same
    setPrimaryExpertForTech = ({from}) => {
        const [primaryExpert] = this.state.participants.filter(e => e.email === from.email);
        if (primaryExpert && this.state.currentPrimaryExpert && this.state.currentPrimaryExpert.email !== primaryExpert.email) {
            printConsole(`Primary expert ID has been changed for technician`);
            printConsole(primaryExpert);
            this.props.setPrimaryExpert({primaryExpert});
            this.setState({ currentPrimaryExpert: primaryExpert });// related  to NS2-296
        }
    }

    // When the Swap Camera Signal is acknowledged by the Primary Expert
    processSignalSwapCameraReceived = ({data, eventType}) => {
        const {showCameraSelectionDecisionBox} = this.state;
        printConsole(eventType);
        printConsole("is ongoing session Features? ", this.isOngoingSessionFeatures());
        /* if (this.isOngoingSessionFeatures() === true && eventType === "click" && !showCameraSelectionDecisionBox){
            this.setState({ showCameraSelectionDecisionBox: true });
        } else */ if (eventType === "auto") {
            //printConsole("starting re-publishing!!")            
            //this.reInitMedia();
            this.reInitGetMedia();
            this.sendSignalConfirmSwapCamera(true, eventType); 
        } else {
            this.reInitGetMedia();
            //this.reInitMedia();            
        }
    }

    // When the turn on/off Technician Video signal is received
    processSignalTurnOnUserVideo = ({data, to}) => {
        printConsole(data);
        const {connections, participants, currentPrimaryExpert, user, ssStreamId /**TP-3342*/} = this.state;        
        let {localStream} = this.state; 
        let stream = localStream;       
        const experts = participants.filter(p => p.isexpert);
        let expertsConnections = [];
        let expertsConnection;
        experts && experts.forEach((e) => {
            expertsConnection = connections.filter(c => c.email === e.email);
            expertsConnections.push(expertsConnection);
        })

        // Turn on/off the video track of the technician & enable/disable its Max-div buttons
        stream.getVideoTracks()[0].enabled = data;
        //TP-3213
        if (/* stream.id === this.state.setTechMaxStreamid 	TP-6118*/ to.email === user.email) {
            this.setState({ disableMaxDivControls: !data });
        }
        printConsole(`preview streamid ${localStream.id}`);
        var vid = document.getElementById(localStream.id);
        printConsole(vid);
        this.setState({publishVideo: data}); 
        //TP-3213
        if (this.state.groupInfo.session_type === "CM" || (this.state.groupInfo.session_type === "RM" && this.props.enable_user_video !== "my_camera_disabled") || this.props.audio_mode === "tech2Tech") {
            const [me] = this.state.expertVideoArr.filter(e => e.stream.id === stream.id);
            let expertVideoArr = [...this.state.expertVideoArr];//TP-3737
            const index = this.state.expertVideoArr.findIndex(e => e.stream.id === stream.id);
            me.stream = stream;
            expertVideoArr[index] = me;
            this.setState({ expertVideoArr }, () => {
                //TP-4319
                if (stream.id !== this.state.setTechMaxStreamid && expertVideoArr.length > 0) {
                    this.setState({triggeruiRedraw: true}, () => {
                        this.setState({ triggeruiRedraw: false });
                    })
                }
            });
        }

        if (data === false) {
            // Pause the Technician camera when Video is to be turned off           
            if (vid && vid.localName.toLowerCase() !== "img") vid.pause(); //TP-3213
            if (stream.id === this.state.setTechMaxStreamid) {
                //TP-3213
                let paramElemVdoChild = (ssStreamId !== '') ? document.getElementById(ssStreamId) : vid; //TP-3342
                //console.log(paramElemVdoChild)
                //TP-4906 -- Handling "Something went wrong" issues
                if (paramElemVdoChild && paramElemVdoChild !== null) {
                    const rect = paramElemVdoChild.getBoundingClientRect();
                    //console.log(rect);
                    const sideBar = document.getElementById("sideBar");
                
                    const videoContainer = document.getElementById("allVideos");
                    const videoContainerPadding = window.getComputedStyle(videoContainer).getPropertyValue('padding-left')
                    //console.log(sideBar.offsetWidth, videoContainerPadding);
                    let videoFreeze = document.getElementById("video-freeze");
                    //TP-6118
                    if (videoFreeze) {
                        videoFreeze.style.position = 'absolute';
                        videoFreeze.style.left = (rect.x- sideBar.offsetWidth - parseInt(videoContainerPadding)) + 'px';
                        videoFreeze.style.width = Math.floor(rect.width) + 'px'; //TP-3342
                    }
                    //console.log(videoFreeze.width);
                    //const stream = this.stopVideoOnly(localStream); 
                    /* expertsConnections && expertsConnections.forEach((connection) => {
                        connection && connection.forEach((conn) => {
                            conn && conn.peerConnection.replaceVideoTrackWithAnotherVideo({displayMediaStream: stream});
                        })
                    }) */
                }
            }
        } else {
            // Play the Tech camera again when Video is to be turned on again
            if (vid) vid.play(); 

            /* expertsConnections && expertsConnections.forEach((connection) => {
                connection && connection.forEach((conn) => {
                    conn && conn.peerConnection.replaceVideoTrackWithAnotherVideo({displayMediaStream: stream});
                })
            }) */
        }
    }

    //TP-3205 -- handler for "TURN_ON_MAX_VIEW_USER_VIDEO" 
    processSignalTurnOnMaxViewUserVideo = ({data, to}) => {
        //TP-3551
        if (this.props.session_type === "CM" || (this.props.session_type === "RM" && this.isExpertVideo() === true) || this.props.audio_mode === "tech2Tech") {
            let {participants, expertVideoArr} = this.state;        
            const { user } = this.props;//TP-6513
            let userVideoCount = 0;
            let changedParticipant = null;
            participants && participants.forEach((p,i) => {
                const [datObj] = data.filter(d => d.userId === p.userId);
                printConsole(datObj);
                if (datObj !== undefined && datObj !== null) {   
                    //TP-3213
                    if (participants[i].disableVideo === !datObj.flag) changedParticipant = participants[i];
                    participants[i].disableVideo = datObj.flag; 
                    participants[i].streams && participants[i].streams[0].getVideoTracks().forEach(track => {
                        track.enabled = !datObj.flag
                    })               
                }            
            });
            //TP-3213
            if (expertVideoArr && expertVideoArr.length > 0) {
                expertVideoArr.forEach((e,i) => {
                    const [dataObj] = data.filter(d => d.userId === e.id && e.type === "camera");
                    if (dataObj !== undefined && dataObj !== null) {
                        printConsole(`for user ...${dataObj.email} was updated to video enabled ${!dataObj.flag}`);
                        if (dataObj.flag === true && /* expertVideoArr[i].stream.id === this.state.setTechMaxStreamid TP-6513*/ expertVideoArr[i].email === user.email) {
                            this.setState({ disableMaxDivControls: true }, () => {
                                this.positionFreezeIcon();
                            });
                            
                        } else if (dataObj.flag === false && /* expertVideoArr[i].stream.id === this.state.setTechMaxStreamid TP-6513*/ expertVideoArr[i].email === user.email) {
                            this.setState({ disableMaxDivControls: false })
                        }
                        expertVideoArr[i].stream.getVideoTracks().forEach(track => {
                            track.enabled = !dataObj.flag;
                        })
                    }
                })
            }
            printConsole("changed subscriber list: ");
            printConsole(participants);
            const list = participants.filter(p => p.disableVideo === false);
            printConsole("list ---------------------------");
            printConsole(list);
            if (list !== undefined) {
                printConsole(`Length of Users with Video on -- ${list.length}`)
                userVideoCount = list.length;
            }
            //this.updateJoinees({other_participants: participants})
            this.setState({expertVideoArr, participants, userVideoCount}, () => {
                //TP-3213
                if (expertVideoArr.length > 0) {
                    this.setState({triggeruiRedraw: true}, () => {
                        this.setState({ triggeruiRedraw: false });
                    })
                }
                if (this.state.setMaxView === false && userVideoCount > 1)
                    this.onClickMultiGridView();
                else {
                    if (userVideoCount === 0) this.setState({ showHideDivIconAudioOnly: true });
                    else this.setState({ showHideDivIconAudioOnly: false });
                }
            })    
        }
    }

    //TP-4483
    processSignalLiveVideoPaused = ({data, from}) => {
        if(this.props.audio_mode === "tech2Tech") {
            let {participants, expertVideoArr} = this.state;        
            let userVideoCount = 0;
            let changedParticipant = null;
            let isOnMaxview = false;
            if (expertVideoArr && expertVideoArr.length > 0) {
                expertVideoArr.forEach((e,i) => {
                    if (e.email === from.email && e.type === "camera") {
                        printConsole(`for user ...${from.email} was updated to video paused ${data.isPaused}`);
                        const vidElem = document.getElementById(e.stream.id)
                        const contentType = 'image/'+data.format;
                        e.isPaused = data.isPaused;
                        if (data.isPaused === true) {
                            if (expertVideoArr[i].stream.id === this.state.setTechMaxStreamid) {
                                /* this.setState({ disableMaxDivControls: true }, () => {
                                    this.positionFreezeIcon();
                                }); */
                            }
                            e.poster = 'data:'+contentType+';base64,'+data.imageframe;       
                            e.pausedReason = data.reason;//TP-4572                      
                        } else if (data.isPaused === false) {
                            if (expertVideoArr[i].stream.id === this.state.setTechMaxStreamid)
                                isOnMaxview = true;
                            e.poster = '/'+window._env_.REACT_APP_ASSET_FOLDER+'/session_bg_logo.png';
                            e.pausedReason = data.reason;//TP-4572
                        }
                        /* expertVideoArr[i].stream.getVideoTracks().forEach(track => {
                            track.enabled = !data.isPaused;
                        }) */
                    }
                })
            }
            
            this.setState({expertVideoArr}, () => {
                if (expertVideoArr.length > 0) {
                    this.setState({triggeruiRedraw: true}, () => {
                        this.setState({ triggeruiRedraw: false });
                    })
                }
                if (this.state.setMaxView === false && userVideoCount > 1)
                    this.onClickMultiGridView();
                if (isOnMaxview === true) {
                    this.onClickMaximize(from.email, "camera");
                }                
            }) 
        }
    }

    // handler for STOP_SESSION_USER - FQ3-30
    processSignalStopSessionUser = ({to}) => {
        const {user} = this.props;
        if (user.email === to) {
            const message = "You have been disconnected from the session remotely."
            this.triggerMessage(message, "stopsession", '', '');
            /* this.setState({ 
                flashMsgText: "You have been disconnected from the session remotely.", 
                showFlashMessage: true, flashLeft:"42%", flashTop:"50%" 
            }); */
            this.timeOut = setTimeout(() => {
                this.props.onSessionCmdChange('session_stopped');
                clearTimeout(this.timeOut);
            }, 2000);            
        }
    }

    // set the max div action indicator to true/false
    maxDivActionGoingOn = ({flag}) => {
        this.setState({maxDivIconClicked: flag});//MB2-491,739       
    }

    setDeviceCookie = (name, val) => {
        // "username=John Doe; expires=Thu, 18 Dec 2013 12:00:00 UTC; path=/";
        let cookieText = name+"="+val+"; path=/";
        //printConsole(cookieText);
        document.cookie = cookieText;
    }

    isOngoingSessionFeatures = () => {
        const { expertShareScreenMaxDiv } = this.state;
        const {rcStart, bAnnotate, externalAssets} = this.props;
        let flag = false;
        printConsole(`rcStart: ${rcStart}`);
        printConsole(`bAnnotate: ${bAnnotate}`);        
        printConsole(`expertShareScreenMaxDiv: ${expertShareScreenMaxDiv}`);
        printConsole(`externalAssets: ${externalAssets}`);
        printConsole(document.getElementById('canavas-3d-container'));
        if (rcStart === true) flag = true;
        if (bAnnotate === true) flag = true;
        //if (techShareScreen) flag = true;
        //if (expertShareScreenMaxDiv === true) flag = true;//NS2-457     
        //if (externalAssets !== []) flag = true;
        //if (document.getElementById('canvas') !== null && strCanvasJSON !== null && strCanvasJSON !== "") flag = true;  
        //if (document.getElementById('canavas-3d-container') !== null) flag = true;
        return flag;
    }

    /**Camera Selection Feature Methods */
    updateCameraSelected = (val) => {
        this.setState({ videoSource: val }) 
    }
     
    updateMicSelected = (val) => {
        this.setState({ audioSource: val });
    }

    updateDefaultDevices = (audioId, videoId) => {
        this.setState({ defAudioId: audioId, defVideoId: videoId });
    }

    updateCameraDeviceCount = (val) => {
        this.setState({ cameraCount: val });
        //this.props.updateCameraCount(val);
    }

    startCameraDetection = () => {
        this.setState({ triggerCameraDetect: true });
    }

    openChangeCameraDialog = () => {        
        this.setState({ cameraSwapEventType: "click" });        
        //printConsole("change camera? true ");
        this.setState({ changeCamera: false });
        //this.props.updateCameraSwapEventType("click");
    }

    closeChangeCameraDialog = () => {       
        //printConsole("change camera? false");
        this.setState({ changeCamera: true });
        //this.props.updateChangeCamera(true);
    }

    confirmSwapCamera = (val) => {
        if (val === false) {
            this.setState({ videoSource: "", audioSource: "" }) 
            this.setDeviceCookie("camera", "");
            this.setDeviceCookie("microphone", "");
        }         
        this.sendSignalConfirmSwapCamera(val, "click");
        this.hideCameraSelectionDecisionBox();
    }

    hideCameraSelectionDecisionBox = () => {
        this.setState({ showCameraSelectionDecisionBox: false });
    }

    // FQ3-333
    reInitGetMedia = () => {
        let deviceId = "";
        var constraints = {
            "video": ResolutionsToCheck[mid]
        }
        if (this.getCookie("camera") !== "") {
            deviceId = this.getCookie("camera");
            constraints = {
                "video": {
                    width : ResolutionsToCheck[mid].width,
                    height : ResolutionsToCheck[mid].height,
                    deviceId: {exact: deviceId}
                }
                    
            }
        }
        printConsole("Constraints ------>");
        printConsole(constraints);
        this.reGetMedia(constraints); 
    }

    reGetMedia = (constraints)=> {
        navigator.mediaDevices.getUserMedia(constraints)
            .then(this.reCheckSuccess)
            .catch(this.reCheckError);
    }

    reCheckSuccess = (stream) => {
        printConsole("re Success for --> ", mid, " ", ResolutionsToCheck[mid]);
        this.setState({
            selectedWidth: ResolutionsToCheck[mid].width,
            selectedHeight: ResolutionsToCheck[mid].height
        }, () => {
            printConsole(this.state.selectedWidth);
            printConsole(this.state.selectedHeight);
            mid = ResolutionsToCheck.length-1;

            for (let track of stream.getTracks()) {
                track.stop()
            }

            this.reInitMedia();
        });

    }

    reCheckError = (error) => {
        printConsole("re Failed for --> " + mid, " ", ResolutionsToCheck[mid], " ", error);
        mid = mid - 1;
        if (mid > 0) this.reInitGetMedia();
        else printConsole(error);
    }

    reInitMedia = async () => {   
        let {localStream} = this.state;     
        if (localStream === "") return;
        localStream.getTracks().forEach(function(track) {
            if (track.readyState == 'live') {
                track.stop();
            }
        });     
        let videoConfig = {
            width: this.state.selectedWidth, height: this.state.selectedHeight,
            deviceId: {exact: this.state.videoSource} 
        };
        let audioConfig = {
            deviceId: this.state.audioSource
        }
        printConsole("Video config after camera swap================>", videoConfig);
        navigator.mediaDevices.getUserMedia({
            video: videoConfig,
            audio: audioConfig
        })
        .then((stream) => {
            this.switchVideoStream({flag: true, stream});
            const { currentPrimaryExpert } = this.state;   
            const data = this.getShowButtonsData();
            this.sessionSignal.sendSignalMaxDivShowButtons(data,currentPrimaryExpert.email)         
        })
        .catch(err => {
            printConsole('Camera Selection error', err)
        });
    }

    switchVideoStream = ({flag, stream}) => {
        const {connections, participants, currentPrimaryExpert, user} = this.state;        
        const experts = participants.filter(p => p.isexpert);
        let expertsConnections = [];
        let expertsConnection;
        experts && experts.forEach((e) => {
            expertsConnection = connections.filter(c => c.email === e.email);
            expertsConnections.push(expertsConnection);
        })
        printConsole(expertsConnections);
        if (flag){
            let {localStream} = this.state;
            localStream.getTracks().forEach(function(track) {
                if (track.readyState == 'live') {
                    track.stop();
                }
            });
            this.localVideoRef.current.srcObject = stream;
            this.localVideoRef.current.id = stream.id;
            // T32-127 -- Checking if the technician's Video is turned off or not
            if (this.state.disableMaxDivControls) {
                stream.getVideoTracks()[0].enabled = false;                
            }
            this.setState({
                localStream: stream
            },() => {
                this.props.setMaxDivId({g_maxDivId: stream.id})// set maxdiv id to be used for 3d file rendering for tech
                // T32-127
                if (this.state.disableMaxDivControls) {
                    var vid = document.getElementById(stream.id);
                    printConsole(vid);
                    // Pause the Technician camera when Video is to be turned off           
                    if (vid) vid.pause();
                }
            });            
            expertsConnections && expertsConnections.forEach((connection) => {
                connection && connection.forEach((conn) => {
                    conn && conn.peerConnection.replaceVideoTrackWithAnotherVideo({displayMediaStream: stream});
                })
            })
        }
    }

    //To be called when the Technician camera gets switched to another device connected
    sendSignalSwapCamera = (data) => {
        try {
            const { cameraSwapEventType, maxView, currentPrimaryExpert } = this.state;
            if (currentPrimaryExpert !== undefined ){    //&& maxView === true             
                this.sessionSignal.sendSignalSwapCamera(
                    data,
                    cameraSwapEventType === "" ? "auto" : cameraSwapEventType,
                    currentPrimaryExpert.email
                    //type:"SWAP_CAMERA"
                );
                printConsole(`signal sent for camera switch to ${currentPrimaryExpert.email}`);
            }
            
        } catch (exception) {
            printConsole('exception occured in sendSignalSwapCamera');
            printConsole(exception);
        }
    }

    //To be called when the Technician camera switch gets confirmed by the user
    sendSignalConfirmSwapCamera = (data, eventType) => {
        try {
            const { maxView, currentPrimaryExpert } = this.state;
            if (currentPrimaryExpert !== undefined ){           //&& maxView === true      
                this.sessionSignal.sendSignalConfirmSwapCamera(
                    data,
                    eventType,
                    currentPrimaryExpert.email
                    //type:"CONFIRM_SWAP_CAMERA"

                );
                printConsole(`signal sent for confirm camera switch to ${currentPrimaryExpert.email}`);                        
            }
            
        } catch (exception) {
            printConsole('exception occured in sendSignalConfirmSwapCamera');
            printConsole(exception);
        }
    }

    /** Camera Selection Decision box */
    cameraSelectionDecisionDialog = () => {        
        return (
            <>
                <Modal size="sm" isOpen={this.state.showCameraSelectionDecisionBox} toggle={() => this.confirmSwapCamera(false)}>
                    <ModalHeader toggle={() => this.confirmSwapCamera(false)} cssModule={{ 'modal-title': 'w-100 text-center' }}><Trans>Camera is swapped!</Trans></ModalHeader>
                    <ModalBody className="d-flex justify-content-start align-items-center row">
                        <div className="col-sm-12">
                            <Trans id="Camera Selection Decision" values={{ 'name': `${this.state.maxViewExpertName}`}}></Trans>                                
                        </div>                        
                    </ModalBody>
                    <ModalFooter>
                        <div className="d-flex justify-content-end">
                            <Button size="sm" className="mr-1 btn-r" onClick={() => this.confirmSwapCamera(false)}><Trans>No</Trans></Button>
                            <Button size="sm" className=" btn-g" onClick={() => this.confirmSwapCamera(true)}><Trans>Yes</Trans></Button>
                        </div>
                    </ModalFooter>
                </Modal>
            </>
        );
    }

    //TP-2539 & TP-3074 -- Method to cycle the View Mode of the Technician when session_type = "CM"/ session_type = "RM" with Expert's Video enabled/ audio_mode = "tech2Tech" for that account
    cycleViewMode = () => { 
        printConsole("swap technician Max view participant..");
        const {user, groupInfo} = this.state;
        const {setTechMaxStreamid, localStream, expertVideoArr, newVideoArr} = this.state;
        //TP-3551
        if (groupInfo.session_type === "CM" || (groupInfo.session_type === "RM" && this.isExpertVideo() === true) || groupInfo.audio_mode === "tech2Tech") {            
            const indexParticipant = newVideoArr.findIndex(p => (p.stream.id === setTechMaxStreamid));
                if (indexParticipant === (newVideoArr.length - 1)) {
                    console.log("1111");
                    this.setState({ setMaxView: false }, () => {
                        this.onClickMaximize(newVideoArr[0].email, newVideoArr[0].type);
                    })
                    /* this.setState({ setMaxView: true }, () => {
                        this.localVideoRef.current.srcObject = newVideoArr[0].stream;
                        this.localVideoRef.current.id = newVideoArr[0].stream.id;
                        this.setState({ maxUserName: newVideoArr[0].fullName, setTechMaxStreamid: newVideoArr[0].stream.id })
                        this.props.setMaxDivId({g_maxDivId: newVideoArr[0].stream.id})// set maxdiv id to be used for 3d file rendering for tech
                        this.calVdoHeightWidth();
                    }); */
                } else {
                    console.log("2222");
                    this.setState({ setMaxView: false }, () => {
                        const participant = newVideoArr[indexParticipant + 1];
                        this.onClickMaximize(participant.email, participant.type);
                    })
                    /* this.setState({ setMaxView: true }, () => {
                        const participantStream = newVideoArr[indexParticipant + 1];
                        participantStream.stream.getVideoTracks()[0].enabled = true;
                        this.localVideoRef.current.srcObject = participantStream.stream;
                        this.localVideoRef.current.id = participantStream.stream.id;
                        this.setState({ maxUserName: participantStream.fullName, setTechMaxStreamid: participantStream.stream.id })
                        this.props.setMaxDivId({g_maxDivId: participantStream.stream.id})// set maxdiv id to be used for 3d file rendering for tech
                        this.calVdoHeightWidth();
                    }); */
                }
        } else {
            printConsole("This feature is disabled for behaviour other that 'peer-to-peer'");
        }
    }

    //TP-3213
    toggleShowPanel = () => {
        this.setState((prevState) => ({
            showPanel: !prevState.showPanel           
        }));
    }

    //TP-2539 process screen share signal  MB2-550, 614
    processSignalSSPilot = ({from, type}) => {
        printConsole("Start/Stop Expert SS when CM or tech to tech is turned on");
        const {expertShareScreenMaxDiv, user, localStream} = this.state
        if(!expertShareScreenMaxDiv && type === 'START_EXPERT_SS'){
            const [participant] = this.state.expertVideoArr.filter(p => p.email === from.email && p.type === "screen");
            printConsole(participant);            
            if (participant){  
                console.log("Found the participant in Video array....."); //TP-3343
                console.log(participant.stream.getVideoTracks()[0]);//TP-4077
                if (this.state.expertSSFrom !== '') this.setState({expertSSFrom: ''});//TP-3844
                    this.setState({ expertShareScreenMaxDiv: true, maxUserName: participant.fullName, /*ssFrom: from.email, hideLocalVdoClass: 'd-none', */ showRc: false }, () => {
                        this.props.setMaxDivSS({ expertShareScreenMaxDiv: this.state.expertShareScreenMaxDiv });
                    });
                    console.log("Technician currently in Max-view?? ",this.state.setMaxView);//TP-3343
                    if (this.state.setMaxView === false) {
                        console.log("Draw the Expert's screen share as the max-view"); //TP-3343
                        this.setState({prevMaxViewState: false, setMaxView: true}, () => {
                            if (this.localVideoRef.current !== null) {
                                console.log("set the local video reference to screen share", participant.stream.id) //TP-3343
                                this.localVideoRef.current.srcObject = participant.stream;
                                this.localVideoRef.current.id = participant.stream.id;
                            } else {
                                this.localVideoRef.current = {
                                    srcObject: participant.stream,
                                    id: participant.stream.id
                                }
                            }
                            this.setState({ maxUserName: participant.fullName, setTechMaxStreamid: participant.stream.id }, () => {
                                //TP-3213
                                if (this.state.disableMaxDivControls === true) this.setState({disableMaxDivControls: false});
                                this.setState({triggeruiRedraw: true}, () => {
                                    this.setState({triggeruiRedraw: false})
                                })
                            })
                            this.props.setMaxDivId({g_maxDivId: participant.stream.id})// set maxdiv id to be used for 3d file rendering for tech
                            this.calVdoHeightWidth();
                        })
                    } else {
                        this.setState({prevMaxViewState: true, prevMaxViewStreamId: this.state.setTechMaxStreamid}, () => {
                            if (this.localVideoRef.current !== null) {
                                console.log("set the local video reference to screen share", participant.stream.id) //TP-3343
                                this.localVideoRef.current.srcObject = participant.stream;
                                this.localVideoRef.current.id = participant.stream.id;
                            } else {
                                this.localVideoRef.current = {
                                    srcObject: participant.stream,
                                    id: participant.stream.id
                                }
                            }
                            //TP-3213
                            this.setState({ maxUserName: participant.fullName, setTechMaxStreamid: participant.stream.id }, () => {
                                //TP-3213
                                if (this.state.disableMaxDivControls === true) this.setState({disableMaxDivControls: false});
                                this.setState({triggeruiRedraw: true}, () => {
                                    this.setState({triggeruiRedraw: false})
                                })
                            })
                            this.props.setMaxDivId({g_maxDivId: participant.stream.id})// set maxdiv id to be used for 3d file rendering for tech
                            this.calVdoHeightWidth();                            
                        })
                    }                    
                    this.props.checkAnnotation();//check annotation on/off in webrtc session
            } else {
                //TP-3844
                const [expertParticipant] = this.state.expertVideoArr.filter(p => p.email === from.email);
                if (expertParticipant){
                    //TP-3213
                    if (this.state.disableMaxDivControls === true) this.setState({disableMaxDivControls: false});
                    this.setState({expertSSFrom: expertParticipant.email});
                } else {
                    printConsole("The participant does not exist!!"); //TP-3343
                }
            }
        }else{
            this.setState({ expertShareScreenMaxDiv: false, maxUserName: user.firstname + " " + user.lastname, /* ssFrom: '', hideLocalVdoClass: '', */ showRc: (this.state.maxView === true) ? true : false}, ()=> {
                this.props.setMaxDivSS({ expertShareScreenMaxDiv: this.state.expertShareScreenMaxDiv });
            });
            if (this.state.prevMaxViewState === true && this.state.prevMaxViewStreamId && this.state.prevMaxViewStreamId !== '') {
                const [participant] = this.state.expertVideoArr.filter(p => p.stream.id === this.state.prevMaxViewStreamId && p.type !== "screen");
                printConsole(participant);
                if (participant){
                    if (this.localVideoRef.current !== null) {
                        this.localVideoRef.current.srcObject = participant.stream;
                        this.localVideoRef.current.id = participant.stream.id;
                    } else {
                        this.localVideoRef.current = {
                            srcObject: participant.stream,
                            id: participant.stream.id
                        }
                    }
                    this.setState({ maxUserName: participant.fullName, setTechMaxStreamid: participant.stream.id }, () => {
                        //
                        if (participant.stream && participant.stream.getVideoTracks().length > 0) {
                            participant.stream.getVideoTracks().forEach(track => {
                                if (track.enabled === false) this.setState({disableMaxDivControls: true}, () => {
                                    this.positionFreezeIcon();
                                });
                                else this.setState({disableMaxDivControls: false});
                            })
                        }
                        //	TP-3213 -- To handle the Video stream not rendering after Stop SS issue
                        this.stopSSTimer = setTimeout(() => {
                            this.setState({triggeruiRedraw: true}, () => {
                                this.setState({triggeruiRedraw: false})
                            })
                            clearTimeout(this.stopSSTimer);                            
                        }, 2000);
                    })
                    this.props.setMaxDivId({g_maxDivId: participant.stream.id})// set maxdiv id to be used for 3d file rendering for tech
                    this.calVdoHeightWidth();
                } else {
                    //TP-3343
                    const [meParticipant] = this.state.expertVideoArr.filter(p => p.email === user.email);
                    if (meParticipant) {
                        if (this.localVideoRef.current !== null) {
                            this.localVideoRef.current.srcObject = meParticipant.stream;
                            this.localVideoRef.current.id = meParticipant.stream.id;
                        } else {
                            this.localVideoRef.current = {
                                srcObject: meParticipant.stream,
                                id: meParticipant.stream.id
                            }
                        }
                        this.setState({ maxUserName: meParticipant.fullName, setTechMaxStreamid: meParticipant.stream.id }, () => {
                            //
                            if (meParticipant.stream && meParticipant.stream.getVideoTracks().length > 0) {
                                meParticipant.stream.getVideoTracks().forEach(track => {
                                    if (track.enabled === false) this.setState({disableMaxDivControls: true}, () => {
                                        this.positionFreezeIcon();
                                    });
                                    else this.setState({disableMaxDivControls: false});
                                })
                            }
                            //this.setState({ maxView: true, showRc: true});
                            //TP-3213 -- To handle the Video stream not rendering after Stop SS issue
                            this.stopSSTimer = setTimeout(() => {
                                this.setState({triggeruiRedraw: true}, () => {
                                    this.setState({triggeruiRedraw: false})
                                })
                                clearTimeout(this.stopSSTimer);                            
                            }, 2000);
                        })
                        this.props.setMaxDivId({g_maxDivId: meParticipant.stream.id})// set maxdiv id to be used for 3d file rendering for tech
                        this.calVdoHeightWidth();
                    } else {
                        printConsole("Not found my own preview video");
                    }
                    //this.onClickMultiGridView(); //TP-3343
                }
            } else {
                //this.onClickMultiGridView(); //TP-3343
            } 
            //TP-3343
            const [participant] = this.state.expertVideoArr.filter(p => p.email === from.email && p.type === "screen");
            let ii = this.state.expertVideoArr.findIndex(p => p.email === from.email && p.type === "screen");
            if (participant) {
                console.log("Found the expert in Video array. Removing screen video for this expert",participant.email)
                let expertVideoArr = [...this.state.expertVideoArr];
                expertVideoArr.splice(ii,1);
                console.log(expertVideoArr);
                this.setState({expertVideoArr});
            }  
            this.setState({ isSSOngoing: false });
        }
    }

    //TP-4849
    processSignalUserInfo = ({data, from}) => {
        printConsole("IM signal received with JSON data");
        let participants = [...this.state.participants];
        let expertVideoArr = [...this.state.expertVideoArr];
        //TP-263
        const userObj = {
            data,
            id: from.userId
        }
        let userInfoArray = [...this.state.userInfoArray];
        userInfoArray.push(userObj);
        this.setState({ userInfoArray });

        let flag = false;

        if (participants === undefined || participants === null || participants.length === 0) return;
        
        if (participants !== undefined && participants !== null && participants.length >0) {
            let [user] = participants.filter(p => from.userId === p.userId);
            const index = this.state.participants.findIndex(e => e.userId === from.userId);
            //TP-3611 & TP-3573
            //if (this.props.session_type === "RM" && this.checkIfExpertCameraEnabled() === true) {
                const ii = this.state.participants.findIndex(e => e.email === from.email);
                
                //TP-3788 -- Zoom, Torch buttons in Grid View controls for my camera enabled state of RM sessions
                let [exuser] = expertVideoArr.filter(p => from.userId === p.userId);
                const exindex = this.state.expertVideoArr.findIndex(up => up.userId === from.userId);
                if (exuser !== null && exuser !== undefined) {
                    exuser.aspect_ratio = data.aspectratio ? (data.aspectratio === "1.33" || data.aspectratio === "1.34") ? "4:3" : data.aspectratio === "1.77" ? "16:9" : "16:9" : "16:9"; //TP-4837
                    expertVideoArr[exindex] = exuser;
                }
            //}
            if (user.userId === from.userId) {
                printConsole("Max-view participant's Control Icons changed");                
                flag = true;
            }
            //printConsole("Inside the participants list");
            //printConsole(user);
            if (user === null || user === undefined) return;             
            user.aspect_ratio = data.aspectratio ? (data.aspectratio === "1.33" || data.aspectratio === "1.34") ? "4:3" : data.aspectratio === "1.77" ? "16:9" : "16:9" : "16:9"; //TP-4837
    
            participants[index] = user;
            this.setState({participants, expertVideoArr}, () => {
                if (this.state.setMaxView && this.state.setTechMaxStreamid === user.stream_id && flag === true) {
                    printConsole("Re-draw the Technician's Max-view UI");
                    //if (maxDivParticipant.disableVideo === false) this.setState({ showHideDivIconAudioOnly: false, hideExpertSSAll: false })
                    this.onClickMaximize(user.email, "camera");
                }
            })
        } 
    }

    getDeviceClassname = () => {
        var ua = navigator.userAgent.toLowerCase();
        var isiPad = /ipad/i.test(ua) || /iphone/i.test(ua) || /iphone /i.test(ua);
        var isAndroid = /android/.test(ua);
        //printConsole(isiPad);
        let hideButton = "d-blk";
        if (isiPad || isAndroid) {
            hideButton = "d-none";
        } 
        return hideButton;
    }

    onClickMaximize = (maxDivParticipantEmail, type, clearAnnotation = false /**TP-5052*/) => {
        printConsole(`maximize div is called for user ${maxDivParticipantEmail}, type ${type}`);
        const {expertVideoArr} = this.state;
        const {rcStart, bAnnotate, user} = this.props;
        let hasVideoTracks = false; //TP-3701
        const [maxViewParticipant] = expertVideoArr.filter (e => e.email === maxDivParticipantEmail && e.type === type);
        printConsole(maxViewParticipant);
        if (maxViewParticipant) {        
            this.setState({ setMaxView: true, maxViewPicture: maxViewParticipant.myPicture, /**TP-5053*/ maxVideoPaused: maxViewParticipant.isPaused,
                maxVideoPoster: maxViewParticipant.poster, maxVideoPauseReason: maxViewParticipant.pausedReason }, () => {
                this.setState({is_grid: false, max_view_user: maxDivParticipantEmail}); //TP-4270
                //TP-3213 & TP-3701 -- To show or hide the Video tag of the max-view user depending on there are any Video tracks incoming for that user or not
                if (maxViewParticipant.stream && maxViewParticipant.stream.getVideoTracks().length > 0) {
                    maxViewParticipant.stream.getVideoTracks().forEach(track => {
                        if(track.enabled === true)
                            hasVideoTracks = true;
                    })
                } 
                if (maxViewParticipant.isThisMe === true) {
                    //TP-5442
                    if (maxViewParticipant.stream.getVideoTracks().length > 0) {
                        maxViewParticipant.stream.getVideoTracks().forEach(track => {
                            track.addEventListener(
                                "ended",
                                (event) => {
                                  console.log("My video is ended");
                                  this.triggerDeviceErrorAndEndSession("camera");
                                },
                                false,
                            );
                        })
                    }
                    //TP-5441
                    if (maxViewParticipant.stream.getAudioTracks().length > 0) {
                        maxViewParticipant.stream.getAudioTracks().forEach(track => {
                            track.addEventListener(
                                "ended",
                                (event) => {
                                  console.log("My audio is ended");
                                  this.triggerDeviceErrorAndEndSession("microphone");
                                },
                                false,
                            );
                        })
                    }
                }
                //TP-4770 -- Show Hide the RC/Annotation canvas 
                // based on whether My preview is being put on Technician's Max-view or not
                printConsole(`rcStart: ${rcStart}`);
                printConsole(`bAnnotate: ${bAnnotate}`);
                if (maxDivParticipantEmail === user.email && rcStart === true) {
                    hasVideoTracks = true;
                } 
                let flag = false;//TP-4769
                const canvasDiv = document.getElementById("canvas");
                if (canvasDiv && canvasDiv !== null && clearAnnotation === false /**TP-5052*/ && maxDivParticipantEmail === user.email) {   
                    //TP-4769
                    this.redrawCnvsTimer = setTimeout(() => {
                        this.props.triggerRedrawTechCanvas();                        
                        clearTimeout(this.redrawCnvsTimer);
                    }, 500)   
                    flag = true;    
                } else if (canvasDiv && canvasDiv !== null && clearAnnotation === false /**TP-5052*/ && maxDivParticipantEmail !== user.email) {
                    canvasDiv.style.display = "none";
                    flag = false; //TP-4769
                }
                //console.log(hasVideoTracks);
                this.setState({hasMaxViewVideoTracks: hasVideoTracks}, () => {
                    this.setState({disableMaxDivControls: !hasVideoTracks && maxViewParticipant.email === user.email}) //TP-6118 & TP-3213
                    if (this.localVideoRef.current !== null) {
                        this.localVideoRef.current.srcObject = maxViewParticipant.stream;
                        this.localVideoRef.current.id = maxViewParticipant.stream.id;
                    } else {
                        this.localVideoRef.current = {
                            srcObject: maxViewParticipant.stream,
                            id: maxViewParticipant.stream.id
                        }
                    }
                    this.setState({ maxUserName: maxViewParticipant.fullName, setTechMaxStreamid: maxViewParticipant.stream.id }, () => {
                        //TP-5496
                        if (this.state.expertVideoArr.length === 1 && maxDivParticipantEmail === this.state.expertVideoArr[0].email) {
                            this.setState({ showPanel: false });
                        }
                        //TP-3703
                        this.props.setMaxDivId({g_maxDivId: maxViewParticipant.stream.id})// set maxdiv id to be used for 3d file rendering for tech
                        //TP-4849
                        if (maxViewParticipant.aspect_ratio) {
                            this.calVdoHeightWidth(maxViewParticipant.aspect_ratio);
                        } else
                            this.calVdoHeightWidth();                        
                        const freezeElem = document.getElementById("video-freeze");
                        if (freezeElem && freezeElem !== null){
                            /* if (newVideoArr[0].email !== user.email) {
                                freezeElem.style.display = "none";
                            } else { */
                            const {ssStreamId, vdowidth} = this.state;
                            //console.log(vdowidth);
                                var vid = document.getElementById(maxViewParticipant.stream.id);
                                //console.log(vid, ssStreamId);
                                let paramElemVdoChild = (ssStreamId !== '') ? document.getElementById(ssStreamId) : vid; //TP-3342
                                //console.log(paramElemVdoChild)
                                const rect = paramElemVdoChild.getBoundingClientRect();
                                //console.log(rect);
                                const sideBar = document.getElementById("sideBar");
                            
                                const videoContainer = document.getElementById("allVideos");
                                const videoContainerPadding = window.getComputedStyle(videoContainer).getPropertyValue('padding-left')
                                //console.log(sideBar.offsetWidth, videoContainerPadding);
                                freezeElem.style.position = 'absolute';
                                freezeElem.style.left = (rect.x) + 'px';
                                freezeElem.style.width = Math.floor(vdowidth) + 'px'; //TP-3342
                                //freezeElem.style.display = "block";
                            //}
                        }
                        //TP-3213
                        this.setState({triggeruiRedraw: true}, () => {
                            this.setState({triggeruiRedraw: false});
                        })   
                        if (flag === true) {
                            //TP-4769
                            this.showCanvsTimer = setTimeout(() => {
                                canvasDiv.style.display = "block";
                                clearTimeout(this.showCanvsTimer);
                            }, 1000);
                        }                     
                    })
                });
                
            });                
        }
    }

    onClickMultiGridView = () => {
        this.setState({ setMaxView: false }, () => {
            if (this.localVideoRef.current) {
                this.localVideoRef.current.srcObject = null;
                this.localVideoRef.current.id = '';
            }
            this.setState({ maxUserName: '', setTechMaxStreamid: '' })
            this.props.setMaxDivId({g_maxDivId: ''})// set maxdiv id to be used for 3d file rendering for tech            
        });
    }

    render(){
        const {user, participants, vdoheight, vdowidth, participantNames, 
            bMaxChat, bAllChat, chatMsgInfoArr, hideLocalVdoClass, ssFrom, 
            shouldStartStopChange, hideButton, expertVideoArr, connections,
            setMaxView, setTechMaxStreamid, groupInfo, maxViewPicture, 
            triggeruiRedraw, hasMaxViewVideoTracks, /**TP-3701*/ 
            maxVideoPaused, maxVideoPoster, maxVideoPauseReason /**TP-5053*/} = this.state; //TP-3213
        const {enable_user_video} = this.props; //TP-3701
        //const maxView = false;
        const [closeIcon] = document.getElementsByClassName('asset-3d'); // TP-841
        const sideBar = document.getElementById("sideBar");
        let sliderRight, isThisMe = true; //TP-4572 & TP-4483 & TP-3657
        if (this.localVideoRef.current) {
            const paramElem = document.getElementById(this.localVideoRef.current.id);
            if (paramElem) {
                sliderRight = Math.floor((window.innerWidth - sideBar.offsetWidth - paramElem.offsetWidth + 40)/2) + 'px';
                //console.log("sliderright --- ", sliderRight);
            }   
            //TP-3657
            expertVideoArr && expertVideoArr.forEach(videoObject => {
                if (this.localVideoRef.current.id === videoObject.stream.id && videoObject.isThisMe === true) {
                    isThisMe = true;
                } else if (this.localVideoRef.current.id === videoObject.stream.id && videoObject.isThisMe !== true) {
                    isThisMe = false;//TP-4277
                }
                //TP-4483
                if (this.localVideoRef.current.id === videoObject.stream.id && videoObject.isThisMe === true) {
                    //TP-5442
                    if (this.localVideoRef.current.srcObject.getVideoTracks().length > 0) {
                        this.localVideoRef.current.srcObject.getVideoTracks().forEach(track => {
                            track.addEventListener(
                                "ended",
                                (event) => {
                                  console.log("My video is ended");
                                  this.triggerDeviceErrorAndEndSession("camera");
                                },
                                false,
                            );
                        })
                    }
                    //TP-5441
                    if (this.localVideoRef.current.srcObject.getAudioTracks().length > 0) {
                        this.localVideoRef.current.srcObject.getAudioTracks().forEach(track => {
                            track.addEventListener(
                                "ended",
                                (event) => {
                                  console.log("My audio is ended");
                                  this.triggerDeviceErrorAndEndSession("microphone");
                                },
                                false,
                            );
                        })
                    }
                }
            })
        }
        //console.log(expertVideoArr, isThisMe);
        let fileNameTopMargin = (window.innerWidth > 1299 && window.innerWidth <= 1559) ? "13%" : (window.innerWidth > 1599) ? "10%" : "15%";
        if (this.props.isPDFFile === true) {
            fileNameTopMargin = (window.innerWidth > 1559) ? "12%" : "15%";
        }
        //TP-3423 -- Bubble element user info object
        let userdetails = null;
        if (maxViewPicture !== undefined && maxViewPicture !== null && setTechMaxStreamid) {
            [userdetails] = expertVideoArr.filter(e => e.stream.id === setTechMaxStreamid);
        }
        //TP-3701 -- Set the dimensions & placement of the Max-view div when No Video tarcks are there
        const logoHeight = Math.floor(vdoheight - ((vdoheight * 10)/100));
        const profileHeight = Math.floor(vdoheight - ((vdoheight * 60)/100)); //TP-3609
        const profileMarginTop = Math.floor((window.innerHeight - profileHeight)/2);
        const parentClass = setMaxView ? "no-gutters" : ""; //TP-3699
        //TP-4629 & TP-4589 -- position setting for Paused Video on Expert's Max-view
        const pausedTextContainerWidth = vdowidth + "px";
        const pausedTextleft = maxVideoPauseReason && (maxVideoPauseReason === "FILESHARE_ONGOING" || maxVideoPauseReason === "RC_ONGOING") ? Math.floor((vdowidth - 404)/2) + "px" : Math.floor((vdowidth - 202)/2) + "px";//TP-4948
        const pausedIconWidth= vdowidth  + "px";
        const muteAudioSpeakingLeft = Math.floor((vdowidth - 400)/2) + "px";
        //TP-3213 -- Set the height of the side panel for Video thumbnails
        let panelHeight = Math.floor((window.innerHeight * 1)/4);
        //TP-3877 -- removing the pointer cursor for the Profile pictures
        //console.log(expertVideoArr);
        return (
            <>
                { this.state.groupInfo.swap_camera_enabled ? 
                    <OTDevices updateCameraSelected = {this.updateCameraSelected}
                            updateMicSelected = {this.updateMicSelected}
                            changeCamera = {this.state.changeCamera}
                            triggerCameraDetect={this.state.triggerCameraDetect}
                            openChangeCameraDialog={this.openChangeCameraDialog} 
                            closeChangeCameraDialog = {this.closeChangeCameraDialog}
                            updateCameraDeviceCount={this.updateCameraDeviceCount}
                            updateDefaultDevices={this.updateDefaultDevices}
                        ></OTDevices>
                    :
                    null
                }
            <div className="d-flex">
                {/* MB2-431 */}
                <WebrtcErrorMessage showWebrtcErrorMessage={this.state.showWebrtcErrorMessage} text={this.state.webrtcErrorType} onClickClose={this.onCloseWebrtcMessage}/>
                <div className='flex-shrink-1' id="sideBar">
                    <WebrtcSideBar
                        {...this.props}
                        participants={this.state.participants}
                        participantNames={participantNames}
                        cameraCount={this.state.cameraCount} 
                        muteHeaderMikeForAll={this.muteHeaderMikeForAll}
                        muteHeaderSpeakerOfAll={this.muteHeaderSpeakerOfAll}
                        changeCamera={this.state.changeCamera}
                        openChangeCameraDialog={this.openChangeCameraDialog}
                        shouldStartStopChange={shouldStartStopChange}
                        isOnprem={true}
                        swap_camera_enabled={this.state.groupInfo.swap_camera_enabled}
                        maxView={this.state.maxView}
                        publishVideo={this.state.publishVideo}
                        isSmallFormFactor={this.state.hideButton === "d-none" ? true : false}
                        sessionType={this.state.groupInfo.session_type}
                    />
                </div>
                {/**TP-3699*/}
                <div className="w-100 p-2 row min-vh-100 no-gutters justify-content-center align-items-center" id="allVideos">
                    {/**TP-3341 -- Changed the position of timer in the HTML DOM regardless of the current view of Technician */}
                    <div className="position-absolute nameDiv" style={{ left: '52.75%', zIndex:1, bottom: '2%'}}>
                        <SessionTimer
                            credit_limits={this.state.groupInfo.credit_limits}
                            startTimer={this.state.startTimer}
                        />
                    </div>
                    {setMaxView ? 
                        /* In case Tech is in Max-view Mode ------------------*/
                        <div className="col-sm-12 text-center">
                            { this.state.disableMaxDivControls === true ?
                                <div id="video-freeze" className="video-freeze" style={{height: vdoheight}}>
                                    <span className="video-icon fa-stack fa-4x" style={{height: vdoheight}} /**TP-3342 */>
                                        <i className="fas fa-circle fa-stack-2x"></i>
                                        <i className="fas fa-video-slash fa-stack-1x font-awesome-red"></i>
                                    </span>
                                </div>  
                                :                                                  
                                ''
                            }
                            {/**TP-2539 & TP-3213 */}
                            <div id="max-view-name" className={/*TP-5096*/ this.state.expertShareScreenMaxDiv === true && groupInfo.session_type !== "CM" && (groupInfo.session_type === "RM" && enable_user_video === "my_camera_disabled") && groupInfo.audio_mode !== "tech2Tech" ? "d-none" : "position-absolute w-100 text-center nameDiv"} style={{ color: 'white' }}>
                                { /*TP-3497*/ (maxViewPicture !== undefined && maxViewPicture !== null && userdetails && userdetails !== null && maxViewPicture !== "" /*TP-4277 && hasMaxViewVideoTracks === true TP-3701*/) ?
                                    /*TP-3423*/<span className="position-relative">
                                        <img id={"logo-pic-"+userdetails.id} /* onClick={() => this.openBubble(userdetails)}  */className="session-profile-pic logo-profile-pic  " src={maxViewPicture} alt='profile_logo' />
                                        { /* TP-3704 userdetails ?
                                            <BubbleElement index={userdetails.id} row={userdetails} className={"high-zIndex d-none"} /**TP-3480 ></BubbleElement>                                
                                            :
                                            ''
                                        */}
                                    </span>
                                    :
                                    /*TP-3423*/userdetails /*TP-4277 && hasMaxViewVideoTracks === true TP-3701*/ ?
                                    <span>
                                        <img id={"logo-pic-"+userdetails.id} /* onClick={() => this.openBubble(userdetails)} */ className="session-profile-pic logo-profile-pic  " src={"/profile.png"} alt='profile_logo' />                                        
                                        {/* TP-3704 <BubbleElement index={userdetails.id} row={userdetails} className={"high-zIndex d-none"} /**TP-3480 ></BubbleElement> */}
                                    </span>
                                    :
                                    ''                                   
                                }
                                { /**TP-3657 */ userdetails && userdetails.isexpert ?
                                    <>
                                        <i className="fas fa-asterisk fa-xs"></i>&nbsp;&nbsp;
                                        {this.state.maxUserName}
                                    </>
                                    :
                                    userdetails && userdetails.isThisMe ?
                                        "You" : this.state.maxUserName
                                }
                            </div>
                            <div className="position-absolute nameDiv" style={{ color: 'white', right: '10%', zIndex:1, bottom: '2%' }}>
                                {this.props.downLoadSpeed ? <>{this.props.downLoadSpeed+' MBPS'}&darr;:</> : ''}
                                {this.props.uploadSpeed ? <>{this.props.uploadSpeed+' MBPS'}&uarr;</> : ''}
                            </div> 
                            { (this.props.isHeaderFileShare || this.props.isOneToOneFileShare) && !this.props.is3DFile && this.props.shareFileName ?
                                <div className="position-fixed w-100 file-name" style={{ color: 'white', zIndex: '1060', background: '#D3D3D300', top: fileNameTopMargin}}>
                                    {this.props.shareFileName}
                                </div>
                                :
                                ''
                            }
                            { (this.props.isHeaderFileShare || this.props.isOneToOneFileShare) && !this.props.is3DFile ?
                                <div className="position-fixed" style={{ zIndex: '1060' }}>
                                    <span className={(window.innerWidth > 1299) ? "fa-stack fa-lg close-modal ": "fa-stack fa-sm close-modal "}
                                        style={{ right: sliderRight, top: fileNameTopMargin}}>
                                        <SessionIcon id="closeAsset" circleClass="fas fa-circle fa-stack-2x"
                                            iconClass="fas fa-times fa-stack-1x close-file-icon" tooltipPlament="top"
                                            innerClass="tip-max no-pointer-events" tooltip="Close Asset" tooltipStyle="Trans"
                                            onClickMethod = {() => this.props.closeFileViewer()} isSmallFormFactorDevice={hideButton === "d-none" ? true : false} />
                                        {/* <a id="closeAsset" onClick={this.props.closeFileViewer}>
                                            <i className="fas fa-circle fa-stack-2x"></i>
                                            <i className="fas fa-times fa-stack-1x close-file-icon" ></i>                    
                                            <UncontrolledTooltip innerClassName="tip-max" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="closeAsset">                                            
                                                <Trans id="Close Asset" ></Trans>                                            
                                            </UncontrolledTooltip>
                                        </a> */}
                                    </span>
                                </div>
                                :
                                ''
                            }                           
                            {/**TP-3551 */ (groupInfo.session_type === "CM" || (groupInfo.session_type === "RM" && enable_user_video !== "my_camera_disabled") || groupInfo.audio_mode === "tech2Tech") && expertVideoArr.length > 0 ?
                                    /* TP-3114 Code for CM or RM with Expert Video enabled or Tech2tech mode enabled */
                                    <>                                            
                                        {/*TP-3701, TP-3201 & TP-3497 the max view image when Video is turned off */}
                                        {/**TP-4277	-- Commented out this part for when Video is turned off/disabled from preference */}
                                        {/* hasMaxViewVideoTracks === false ?
                                            (maxViewPicture !== undefined && maxViewPicture !== null && maxViewPicture !== "") ?
                                                <img className="session-profile-pic" alt="User profile" src={maxViewPicture} style={{height: profileHeight, width: profileHeight, marginTop: profileMarginTop}}></img>
                                                :
                                                <img alt="User profile" src={"/profile.png"} style={{height: logoHeight}}></img>
                                            /* the max view image when Video is turned off ended 
                                            :
                                            ''
                                        */}
                                        {/* the max div video ------------------*/}
                                        { /**TP-4629 & TP-4572 & TP-4483 */ maxVideoPaused === true ?
                                            <>
                                                <span style={{width: pausedTextContainerWidth}}><span className='paused-text' style={{marginLeft: pausedTextleft}}>{maxVideoPauseReason && maxVideoPauseReason === "RC_ONGOING" ? <Trans>session.pausedRCVideo</Trans>: /**TP-4948*/ maxVideoPauseReason && maxVideoPauseReason === "FILESHARE_ONGOING" ? <Trans>session.pausedVideo</Trans>: <Trans>session.pausedVideoDefault</Trans>}</span></span>
                                                <span className={this.checkSmallScreenDevice() === true ? 'paused-icon fa-stack fa-2x' : 'paused-icon fa-stack fa-4x'} style={{width: pausedIconWidth}}>                                    
                                                    <i className="fas fa-circle fa-stack-2x"></i>
                                                    <i className="fas fa-video-slash fa-stack-1x font-awesome-red"></i>
                                                </span>
                                                { /* this.state.showIsMutedbutSpeaking === true ?
                                                    <span style={{width: pausedTextContainerWidth}}><span className='paused-text-other' style={{marginLeft: muteAudioSpeakingLeft}}><Trans>session.mutedbuttalking</Trans></span></span>
                                                    :
                                                    ''
                                                 */}
                                                <img className={"session-profile-pic-position video-stream"} width={vdowidth} height={vdoheight} src={maxVideoPoster} alt="Max view Stream frame" />
                                                <video id={this.state.setTechMaxStreamid} height={vdoheight} ref={this.localVideoRef} autoPlay className=" video-stream d-none" muted={isThisMe} />
                                            </>
                                            :
                                            <>
                                                {/*  this.state.showIsMutedbutSpeaking === true ?
                                                    <span style={{width: pausedTextContainerWidth}}><span className='paused-text-other' style={{marginLeft: muteAudioSpeakingLeft}}><Trans>session.mutedbuttalking</Trans></span></span>
                                                    :
                                                    ''
                                                 */}
                                                <video /**TP-4319*/ id={this.state.setTechMaxStreamid} height={vdoheight} ref={this.localVideoRef} muted={isThisMe} autoPlay style={/*TP-3701hasMaxViewVideoTracks === true ?*/ {height: vdoheight}/*: {height: "0px"}*/} className={hideLocalVdoClass + " video-stream"}></video>                                            
                                            </>
                                        }
                                        {/* max div video end ------------------------*/}
                                        <div id="canvasDiv" ></div>  
                                    </>
                                    /* Code for CM or RM with Expert Video enabled or Tech2tech mode enabled ends */
                                :
                                    /* Code for RM mode with Expert Video disabled && Tech2Tech disabled */
                                    <>
                                        {/* the max div video ------------------*/}
                                            {/*  this.state.showIsMutedbutSpeaking === true ?
                                                <span style={{width: pausedTextContainerWidth}}><span className='paused-text-other' style={{marginLeft: muteAudioSpeakingLeft}}><Trans>session.mutedbuttalking</Trans></span></span>
                                                :
                                                ''
                                             */}
                                            <video /**TP-4319*/ id={this.state.setTechMaxStreamid} ref={this.localVideoRef} muted={isThisMe} /**TP-3608 */ autoPlay style={{height: vdoheight}} className={hideLocalVdoClass + " video-stream"}></video>
                                        {/* max div video end ------------------------*/}  
                                            <div id="canvasDiv" ></div>   
                                    </>
                                    /* Code for RM mode with Expert Video disabled && Tech2Tech disabled */
                            }
                            <StreamActions 
                                {...this.props} 
                                showRc={this.state.showRc}
                                is_grid={this.state.is_grid} //TP-3551
                                max_view_user={this.state.max_view_user} //TP-3551
                                groupInfo={this.state.groupInfo}
                                onClickMaxChat= {this.onClickChat}
                                bMaxChat={bMaxChat}
                                disableMaxDivControls= {this.state.disableMaxDivControls}
                                enable_remote_capture ={this.state.groupInfo.enable_remote_capture}      
                                cycleViewMode={this.cycleViewMode} //TP-2539
                                toggleShowPanel={this.toggleShowPanel} //TP-3213
                                participants={this.state.participants} //TP-2539
                                expertVideoArr={this.state.expertVideoArr}//TP-4319
                                onClickMultiGridView={this.onClickMultiGridView}
                                setMaxView={setMaxView}
                                showPanel={this.state.showPanel} //TP-3213
                                showToggleIcon={groupInfo.session_type === "CM" || (groupInfo.session_type === "RM" && enable_user_video !== "my_camera_disabled") /**TP-4274*/ || (groupInfo.audio_mode === "tech2Tech" && this.isOtherTechVideo() === true)} /**TP-3698*/
                                sessionType={groupInfo.session_type === "CM" || (groupInfo.session_type === "RM" && this.isExpertVideo() === true) || groupInfo.audio_mode === "tech2Tech"} /**TP-3551 */
                                isSmallFormFactorDevice={this.state.hideButton === "d-none" ? true : false} //TP-3213
                            />
                            { /**TP-3872 TP-3551 TP-3701 */ (groupInfo.session_type !== "CM" && (groupInfo.session_type === "RM" && enable_user_video === "my_camera_disabled") && groupInfo.audio_mode !== "tech2Tech") ?
                                /* Code for RM mode with Expert's Video disabled and Tech2Tech disabled */
                                <I18n>
                                    {({i18n}) =>
                                        participants.map((participant, index, participants) => {
                                            if(participant.streams && participant.streams.length > 0){
                                                return (
                                                    <WebrtcVideoTile
                                                        key={index}
                                                        participants= {participants}
                                                        participant={participant}
                                                        user = {user}
                                                        index= {index}
                                                        vdoheight= {vdoheight}
                                                        ssFrom={ssFrom}
                                                        setMaxView={setMaxView}
                                                        setTechMaxStreamid={setTechMaxStreamid}
                                                        sessionType={groupInfo.session_type === "CM" || (groupInfo.session_type === "RM" && this.isExpertVideo() === true) || groupInfo.audio_mode === "tech2Tech"} /**TP-3551 */
                                                    />
                                                )
                                            }else{
                                                return null;
                                            }
                                        })
                                    }
                                </I18n> 
                                /* Code for RM mode with Expert's Video disabled and Tech2Tech disabled */
                                :
                                ''                                                                    
                            }
                            { this.cameraSelectionDecisionDialog() }
                            <SessionChat 
                                onSendChatMsg={this.sendChatMsg}
                                bPrivate={bMaxChat}
                                bShowChat={bMaxChat || bAllChat}
                                chatMsgInfoArr={chatMsgInfoArr}
                                elemId={user.email}
                                startTimer={this.state.startChatTimer}
                                updateChatFlags={this.updateChatFlags}
                            />
                            <div id="sidePanel" className="position-absolute panel-background" style={this.state.showPanel ? {display: "block", height: panelHeight + "px", width: "100%", bottom: "0px"} : {display: "none", height: panelHeight + "px", width: "100%", bottom: "0px"}}>
                                {/*TP-3213 Side panel on the bottom of the Maximized Video tile -----------*/}
                                    <SidePanel
                                        participants={expertVideoArr}
                                        changedParticipant={this.state.changedParticipant}
                                        connections={connections}
                                        isAccountLevelExpertVideoDisabled={false}
                                        user={user}
                                        ssFrom={ssFrom}
                                        triggeruiRedraw={triggeruiRedraw}
                                        setTechMaxStreamid={setTechMaxStreamid}
                                        onClickMaximize={this.onClickMaximize}
                                        showPanel={this.state.showPanel}
                                        isSmallFormFactorDevice={hideButton === "d-none" ? true : false}
                                        triggerDeviceErrorAndEndSession={this.triggerDeviceErrorAndEndSession} /**TP-5442 & TP-5441 */
                                    />
                                {/* Side panel on right ends ---------------------------------- */}                                        
                            </div>
                            <FlashMessage flashMsgText={this.state.flashMsgText} flashValues={this.state.flashValues} showMessage={this.state.showFlashMessage} updateShowMessage={this.updateShowMessage}
                                flashLeft={this.state.flashLeft} flashTop={this.state.flashTop} isTimerEnabled={this.state.flashTimer} /**TP-5986*/
                            />
                        </div>
                        /** Technician Max-view Mode ends */
                    :
                        /* In case Tech is in Grid-view Mode ------------------*/
                        <>
                            { this.state.disableMaxDivControls === true ?
                                <div id="video-freeze" className="video-freeze" style={{height: vdoheight}}>
                                    <span className="video-icon fa-stack fa-4x" style={{height: vdoheight}} /**TP-3342 */>
                                        <i className="fas fa-circle fa-stack-2x"></i>
                                        <i className="fas fa-video-slash fa-stack-1x font-awesome-red"></i>
                                    </span>
                                </div>  
                                :                                                  
                                ''
                            }
                            {/**TP-2539 */}
                            <div className="position-absolute nameDiv" style={{ color: 'white', right: '10%', zIndex:1, bottom: '2%' }}>
                                {this.props.downLoadSpeed ? <>{this.props.downLoadSpeed+' MBPS'}&darr;:</> : ''}
                                {this.props.uploadSpeed ? <>{this.props.uploadSpeed+' MBPS'}&uarr;</> : ''}
                            </div>
                            { (this.props.isHeaderFileShare || this.props.isOneToOneFileShare) && !this.props.is3DFile && this.props.shareFileName ?
                                <div className="position-fixed w-100 file-name" style={{ color: 'white', zIndex: '1060', background: '#D3D3D300', top: fileNameTopMargin}}>
                                    {this.props.shareFileName}
                                </div>
                                :
                                ''
                            }
                            { (this.props.isHeaderFileShare || this.props.isOneToOneFileShare) && !this.props.is3DFile ?
                                <div className="position-fixed" style={{ zIndex: '1060' }}>
                                    <span className={(window.innerWidth > 1299) ? "fa-stack fa-lg close-modal ": "fa-stack fa-sm close-modal "}
                                        style={{ right: sliderRight, top: fileNameTopMargin}}>
                                        <SessionIcon id="closeAsset" circleClass="fas fa-circle fa-stack-2x"
                                            iconClass="fas fa-times fa-stack-1x close-file-icon" tooltipPlament="top"
                                            innerClass="tip-max no-pointer-events" tooltip="Close Asset" tooltipStyle="Trans"
                                            onClickMethod = {() => this.props.closeFileViewer()} isSmallFormFactorDevice={hideButton === "d-none" ? true : false} />
                                        {/* <a id="closeAsset" onClick={this.props.closeFileViewer}>
                                            <i className="fas fa-circle fa-stack-2x"></i>
                                            <i className="fas fa-times fa-stack-1x close-file-icon" ></i>                    
                                            <UncontrolledTooltip innerClassName="tip-max" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="closeAsset">                                            
                                                <Trans id="Close Asset" ></Trans>                                            
                                            </UncontrolledTooltip>
                                        </a> */}
                                    </span>
                                </div>
                                :
                                ''
                            }
                            { /**TP-3551 */ (groupInfo.session_type === "CM" || (groupInfo.session_type === "RM" && this.isExpertVideo() === true) || groupInfo.audio_mode === "tech2Tech") && expertVideoArr.length > 0 ?  
                                <>
                                    <I18n>
                                        {({i18n}) =>
                                            expertVideoArr.map((participant, index, expertVideoArr) => {
                                                const [videoConnection] = connections.filter(conn => participant.email !== user.email && conn.email === participant.email);
                                                if(participant.stream){
                                                    return (
                                                        <WebrtcVideoTile
                                                            key={index}
                                                            participants= {expertVideoArr}
                                                            videoConnection={videoConnection}
                                                            participant={participant}
                                                            user = {user}
                                                            index= {index}
                                                            vdoheight= {vdoheight}
                                                            ssFrom={ssFrom}
                                                            onClickMaximize={this.onClickMaximize}
                                                            setMaxView={setMaxView}
                                                            setTechMaxStreamid={setTechMaxStreamid}
                                                            sessionType={groupInfo.session_type === "CM" || (groupInfo.session_type === "RM" && this.isExpertVideo() === true) || groupInfo.audio_mode === "tech2Tech"} /**TP-3551 */
                                                        />
                                                    )
                                                }else{
                                                    return null;
                                                }
                                            })
                                        }
                                    </I18n> 
                                </>
                                :
                                <>
                                    {/*  this.state.showIsMutedbutSpeaking === true ?
                                        <span style={{width: pausedTextContainerWidth}}><span className='paused-text-other' style={{marginLeft: muteAudioSpeakingLeft}}><Trans>session.mutedbuttalking</Trans></span></span>
                                        :
                                        ''
                                     */}
                                    <video ref={this.localVideoRef} autoPlay muted style={{height: vdoheight}} className={hideLocalVdoClass + " video-stream"}></video>
                                    <div id="canvasDiv" ></div>                                                      
                                </>
                            }
                            <StreamActions 
                                {...this.props} 
                                showRc={this.state.showRc}
                                groupInfo={this.state.groupInfo}
                                onClickMaxChat= {this.onClickChat}
                                bMaxChat={bMaxChat}
                                disableMaxDivControls= {this.state.disableMaxDivControls}
                                enable_remote_capture ={this.state.groupInfo.enable_remote_capture}      
                                cycleViewMode={this.cycleViewMode} //TP-2539
                                participants={this.state.participants} //TP-
                                expertVideoArr={this.state.expertVideoArr} //TP-4319
                                onClickMultiGridView={this.onClickMultiGridView}
                                setMaxView={setMaxView}
                                showToggleIcon={groupInfo.session_type === "CM" || (groupInfo.session_type === "RM" && this.isExpertVideo() === true) || (groupInfo.audio_mode === "tech2Tech" && this.isOtherTechVideo() === true)} /**TP-3698*/
                                sessionType={groupInfo.session_type === "CM" || (groupInfo.session_type === "RM" && this.isExpertVideo() === true) || groupInfo.audio_mode === "tech2Tech"} /**TP-3551 */
                            />
                            { /**TP-3551 */ (groupInfo.session_type !== "CM" && !(groupInfo.session_type === "RM" && this.isExpertVideo() === true) && groupInfo.audio_mode !== "tech2Tech") ?
                                <I18n>
                                    {({i18n}) =>
                                        participants.map((participant, index, participants) => {
                                            if(participant.streams && participant.streams.length > 0){
                                                return (
                                                    <WebrtcVideoTile
                                                        key={index}
                                                        participants= {participants}
                                                        participant={participant}
                                                        user = {user}
                                                        index= {index}
                                                        vdoheight= {vdoheight}
                                                        ssFrom={ssFrom}
                                                        setMaxView={setMaxView}
                                                        setTechMaxStreamid={setTechMaxStreamid}
                                                        sessionType={groupInfo.session_type === "CM" || (groupInfo.session_type === "RM" && this.isExpertVideo() === true) || groupInfo.audio_mode === "tech2Tech"} /**TP-3551 */
                                                    />
                                                )
                                            }else{
                                                return null;
                                            }
                                        })
                                    }
                                </I18n> 
                                :
                                ''                                                                    
                            }
                            { this.cameraSelectionDecisionDialog() }
                            <SessionChat 
                                onSendChatMsg={this.sendChatMsg}
                                bPrivate={bMaxChat}
                                bShowChat={bMaxChat || bAllChat}
                                chatMsgInfoArr={chatMsgInfoArr}
                                elemId={user.email}
                                startTimer={this.state.startChatTimer}
                                updateChatFlags={this.updateChatFlags}
                            />
                            <FlashMessage flashMsgText={this.state.flashMsgText} flashValues={this.state.flashValues} showMessage={this.state.showFlashMessage} updateShowMessage={this.updateShowMessage}
                                flashLeft={this.state.flashLeft} flashTop={this.state.flashTop} isTimerEnabled={this.state.flashTimer} /**TP-5986*/
                            />
                        </>
                        /* Technician Grid-view Mode ends */
                    } 
                </div>
            </div>                
            </>
        )
    }
}

export default WebrtcTech;

/* TP-3701 --- Handled the Audio Tracks issues for Max-vew of Webapp Tech during the Non-default states*/
class OtherParticipantAudioTrack extends React.Component {
    constructor (props) {
        super(props);
        this.videoRef = React.createRef(); //TP-3608
    } 
    render() {
        const {participantsArr, participant, index, setTechMaxStreamid} = this.props;
        //console.log("draw other participants Audio Track", index, participant, setTechMaxStreamid, participantsArr);
               
            if (participant.stream) {
                //console.log(this.videoRef.current);
                if(this.videoRef.current){
                    //console.log("set Audio Reference..");
                    this.videoRef.current.srcObject = participant.stream
                }                 
            }
            return (
                <video key={index} ref={this.videoRef} muted={participant.isThisMe} id={participant && participant.stream.id} autoPlay className="d-none" />
            )
        /* });
    
        return videoElements; */
    }
}