import React from 'react';
import { Form, Row, Col, UncontrolledTooltip } from 'reactstrap';
import { Trans, t } from '@lingui/macro';
import { I18n,i18nMark } from "@lingui/react";
import '../Landing/Groups.scss';
import DatePicker from "react-datepicker";
import SimpleReactValidator from 'simple-react-validator';
import _ from 'lodash';
import Select from 'react-select';
import makeAnimated from 'react-select/lib/animated';
import moment from 'moment';
import SubTask from './sub_task';
import InspectionSubTask from './inspection_subtask';
import Loader from '../Loader/Loader';
import FlashMessage from '../FlashMessage/FlashMessage';
import { printConsole, getWeekdayCountBetweenDates, getMonthCountBetweenDates } from '../helpers';
import { nanoid } from 'nanoid';
import Scheduler from './scheduler';

const AWS = require('aws-sdk');
AWS.config.update({
    region: process.env.REACT_APP_S3_REGION,
    accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY
});
const s3 = new AWS.S3();//instantiate s3

class CloneJobForms extends React.Component{
    constructor(props){
        super(props);
        this.authService = props.authService;
        //TP-3220
        this.cardColor = {
            created: 'bg-created',
            assigned: 'bg-assigned',
            in_progress: 'bg-in-progress',
            finished: 'bg-finished',
            skipped: 'bg-finished'
        };
        this.state = {
            isLoading: false,
            retCode: 400,
            errStrReturned: '',
			bSubmitted: false,
            bResponded: false,
            selectedJob: {accountId: props.accountId, reference_id: nanoid(), asset_id: 0,
                technician_id: !props.customer.mandatory_assignee_jobs && (props.customer.enable_asset_class !== "disabled" && props.isadmin || 
                (props.isexpert && ((props.enable_asset && props.customer.enable_asset_class === "enabled_on_demand") || props.customer.enable_asset_class === "enabled_for_all" ))) ? 0 : null
            },
            geoFences: [],
            equipments: [],
            selectedGeofences:[],
            selectedEquipments: [],
            groupUsers:[],
            selectedUser: {},   selectedTechnician: '',
            selectedExpert: '', accountExperts: [],
            startDate: '',
            endDate: '',
            unFormatedStartDate: '',
            unFormatedEndDate: '',
            isJobExists: false,
            isJobReferenceIdExists: false,
            subTasks:[],
            subTaskFields: ['task_instruction', 'sequence', 'expected_result', 'take_image'],
            subTaskActions: [],
            unformattedSubTasks:[],
            isAddProcedure: true,
            isJobLoading: true,
            flashMsgText: "",
            showFlashMessage: false,
            flashLeft: "",
            flashTop: "",
            loadingText: "Loading",
            allAssetData:[], selectedAsset: '', copyOfAllAssets:[],
            assetDetails: {},
            isSmartImageTypeJob: false,
            bShowSubTask: false, //TP-3220
            index: 0, //TP-3220
            additional_information: null, //TP-4607
            rooms: ["Room 1", "Room 2", "Room 3", "Room 4", "Room 5", "Room 6"],
            disciplines: ["Mechanical", "Plumbing", "Electrical"],
        }

		// initialize validator
        SimpleReactValidator.addLocale(props.language, require(`../../locales/${props.language}/messages.json`));
		this.validator = new SimpleReactValidator({
            locale: props.language,
            autoForceUpdate: this,
            element: message => <div className="job-form-validation-msg">{message.replace(/field|The|[0-9]/g, '').capitalize()}</div>,
            validators: {
                minArrayLength: {
                    message: 'The :attribute is required.',
                    rule: (val, params, validator) => {
                        return (val.length > 0)
                    },
                    required: true
                },
                maxCharLength: {
                    rule: (val, params, validator) => {
                      return (val.length <= parseInt(params[0]))
                    }
                },
                minCharLength: {
                    rule: (val, params, validator) => {
                      return (val.length >= parseInt(params[0]))
                    }
                },     
                checkStartDate: {
                    message: 'Start date cannot be less than todays date.',
                    rule: (val, params, validator) => {
                        if(props.action !== 'clone_evidence_template'){
                            return new Date(val) >= new Date();//TP-4898
                        }else{
                            return true;
                        }
                    },
                },
                beforEndDate: {
                    message: 'Start date cannot be greater than end date.',
                    rule: (val, params, validator) => {
                        return new Date(val) <= this.state.unFormatedEndDate;//TP-4898
                    },
                },
                checkEditStartDate:{
                    message: 'Cannot be less than existing start date.',
                    rule: (val, params, validator) => {
                        if(props.action !== 'clone_evidence_template'){
                            return true;
                        }else{
                            return new Date(val) >= new Date(props.selectedJob.start_date);//TP-4898
                        }
                    },
                },
                afterStartDate: {
                    message: 'End date cannot be less than start date.',
                    rule: (val, params, validator) => {
                        return new Date(val) >= this.state.unFormatedStartDate;//TP-4898
                    },
                },
                afterStartDateForScheduler: {
                    message: 'Cannot be less than start date.',
                    rule: (val, params, validator) => {
                        return new Date(val) >= this.state.unFormatedStartDate;//TP-4898
                    },
                },
                checkDateRange: {
                    message: 'Date cannot be beyond Job start and end date.',
                    rule: (val, params, validator) => {
                        let sd = this.state.selectedJob.start_date||this.state.unFormatedStartDate,
                        ed = this.state.selectedJob.end_date || this.state.unFormatedEndDate;
                        let flag = (
                            (moment(val).format('YYYY-MM-DD') >= moment(sd).format('YYYY-MM-DD'))
                            &&
                            (moment(val).format('YYYY-MM-DD') <= moment(ed).format('YYYY-MM-DD'))
                        );
                        return flag;

                    },
                },
                afterTaskStartDate: {
                    message: 'End date cannot be less than start date.',
                    rule: (val, params, validator) => {
                      return (moment(val).format('YYYY-MM-DD') >= moment(this.state.subTasks[params[0]].task_startdate).format('YYYY-MM-DD'))
                    },
                },
                checkSequence: {
                    message: 'Sequence is not valid',
                    rule: (val, params, validator) => {
                        return (val >= 1 && val <= this.state.subTasks.length) //TP-3861
                    }
                },
                checkInterval: {
                    message: 'Interval is not valid',
                    rule: (val, params, validator) => {
                      return (val >= 1)
                    }
                },
                checkScanQRFormat: {
                    rule: (val, params, validator) => {
                        const regex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm;
                        if(params[0]=== 'Ctrl+A'){
                            let strArr = val.split(':::');
                            if(strArr.length === 4 && strArr[0] === 'jobs/renderAsset' && 
                                validator.helpers.testRegex(strArr[1],regex) && strArr[3] != ''){
                                return true;
                            }else{
                                return false;
                            }
                        }else{
                            return  true;
                        }
                    }
                },
                alpha_num_dash_underscore: {
                    rule: (val, params, validator) => {
                        const regex = /^[A-Za-z0-9_-]+$/g;
                        return validator.helpers.testRegex(
							val,
							regex
						);
                    }
                },
                check_technician_asset: {
                    rule: (val, params, validator) => {
                        return (this.state.selectedJob.technician_id !==0 || this.state.selectedJob.asset_id !== 0)
                    },
                    messageReplace: (message, params) => message.replace('{role}', params[0]) /**TP-6723*/
                }
            }
		});
        
    }

    componentDidMount(){
        this.setState({
            unFormatedStartDate: new Date(),
            unFormatedEndDate: new Date((new Date()).setDate((new Date()).getDate() +1)),
            startDate: new Date(),// MB2-175
            endDate: new Date((new Date()).setDate((new Date()).getDate() +1))// MB2-175
        },() => {
            this.fetchAsset();
            this.fetchAllSubTaskActions();
        });
		this.delayedCallback = _.debounce(this.checkJobExists, 500);
		this.delayedCallbackForReferenceId = _.debounce(this.checkReferenceIdExists, 500);//TP-795
    }

    //TP-2535
    fetchAsset = () => {
        let fetchString = 'jobs/getAssetsOfAccount/';
        this.authService.fetch(fetchString, {
            method: 'get'
        })
        .then(response => response.json())
        .then(data => {
            if(data !== undefined && data !== null){
                const filteredAssets = data.filter((asset) => asset.asset_class_name)//TP-2580
                this.setState({ allAssetData: filteredAssets, copyOfAllAssets: filteredAssets});
            }
        })
        .catch(err => {
            printConsole(`error in fetchAsset ===========> ${JSON.stringify(err)}`)
        })
        .finally(() => {
            if(this.props.action=== 'clone_evidence_job'){
                this.fetchAllTech()
            }else{
                this.fetchAllExpert();//TP-3566
            }
        })
    }

    fetchAllTech = () => {
        let {selectedJob} = this.props;

        let fetchString = 'getusersByGroups/';
        this.props.authService.fetch(fetchString, {
            method: 'get'
        })
        .then(response => response.json())
        .then(data => {
            if(data !== undefined && data !== null){
               this.setState({
                   groupUsers: data,
                });
            }
       })
       .then(() => {
            this.initialiseJobForCloning(selectedJob);
       })
       .catch(error => {
            this.setState({
                isJobLoading: false,
                bResponded: true,
                retCode: error.status,
                flashLeft: "45%", 
                flashTop: "",
                flashMsgText: "Failed to process request",
                showFlashMessage: true
                //errStrReturned: i18nMark('Failed to process request.')
            });
        })
    };

    //TP-3566
    fetchAllExpert = () => {
        try{
            let { accountId: account_id, action, selectedJob, isadmin, userid, customer} = this.props;

            let fetchString = 'getusers/';
            fetchString += account_id;
    
            this.props.authService.fetch(fetchString, {
                method: 'get'
            })
            .then(response => response.json())
            .then(data => {
                if(data !== undefined && data !== null){
                    //only for expert
                    data = data.filter((d) => (d.isexpert && d.enable_job_template) || (d.isexpert && d.isadmin) || (d.isexpert && customer.enable_job_template === "enabled_for_all"))//TP-3635,3738,5239 all expert and admin expert himself
                    this.setState((prevState) => ({accountExperts: data, 
                        selectedJob: {...prevState.selectedJob, job_type: 'work_instruction_template'}
                    }))
                    //TP-6603, TP-6853
                    // if(!isadmin || (customer.behaviour === "honeywell" && this.state.selectedJob.job_type === "work_instruction_template")){
                    //     this.setState((prevState) => ({selectedJob: {...prevState.selectedJob, assigned_by: userid}}))
                    // }
                    this.initialiseJobForCloning(selectedJob)
                }
            })
            .catch(err => {
                this.setState({
                    isJobLoading: false, bResponded: true, retCode: err.status,
                    flashLeft: "45%", flashTop: "", flashMsgText: "Failed to process request", showFlashMessage: true
                });
                printConsole(err.message)
            })
        }catch(error){
            printConsole(error)
        }
    };

    fetchAllSubTaskActions = () => {
        let {selectedJob, customer} = this.props;

        let fetchString = 'jobs/getSubTaskActions/';

        this.props.authService.fetch(fetchString, {
            method: 'get'
        })
        .then(response => response.json())
        .then(data => {
            if(data !== undefined && data !== null){
                let subTaskActions = data.filter(action => action.command !== "Ctrl+A");//MB2-892
                if(customer.behaviour !== "honeywell"){
                    subTaskActions = subTaskActions.filter(action => action.command !== "Ctrl+IP");//TP-6875,TZ-597
                }
                //TP-4657 disabling the check to enable the pass/fail
                // if(!customer.enable_checklist){
                //     subTaskActions = subTaskActions.filter(action => action.command !== "Ctrl+P" && action.command !== "Ctrl+S" )//TP-2742
                // }
                this.setState({
                   subTaskActions: subTaskActions,
                },() => {
                    this.fetchAllSubTasks(selectedJob);
                });
            }
        })
        .catch(error => {
            this.setState({
                isJobLoading: false,
                bResponded: true,
                retCode: error.status,
                flashLeft: "45%", 
                flashTop: "",
                flashMsgText: "Failed to process request",
                showFlashMessage: true
                //errStrReturned: i18nMark('Failed to process request.')
            });
        })
    };

    fetchAllSubTasks = (selectedJob) => {

        let fetchString = 'jobs/getAllSubTasks/';
        fetchString += selectedJob.id;

        this.props.authService.fetch(fetchString, {
            method: 'get'
        })
        .then(response => response.json())
        .then(data => {
            if(data !== undefined && data !== null){
                this.setState({
                    unformattedSubTasks: data,
                    isJobLoading:false
                });
            }
        })
        .then(() => {
            this.mapTasks()
        })
        .catch(error => {
            this.setState({
                isJobLoading: false,
                bResponded: true,
                retCode: error.status,
                flashLeft: "45%", 
                flashTop: "",
                flashMsgText: "Failed to process request",
                showFlashMessage: true,
                //errStrReturned: i18nMark('Failed to process request.')
            });
        })
    };

    // TP-2716
    fetchIndividualAsset = () => {
        const {selectedJob} = this.state;
        if(!selectedJob.asset_id){
            this.setState({assetDetails: {}})
            return;
        }
        this.setState({ isLoading: true});

        let fetchString = `jobs/getAssetDetails`;
        this.authService.fetch(fetchString, {
            method: 'post',
            body: JSON.stringify({id: selectedJob.asset_id, account_id: this.props.accountId})
        })
        .then(response => response.json())
        .then(data => {
            if(data !== undefined && data !== null){
                this.setState((prevState) => ({ assetDetails: {...prevState.assetDetails, ...data}, isLoading: false}));
            }
        })
        .catch(err => {
            this.setState({ isLoading: false });
            printConsole(`error in fetchAssetClass ===========> ${JSON.stringify(err)}`)
        })
    }

    mapTasks = () =>{
        try{
            let {unformattedSubTasks, subTaskActions, subTasks} = this.state;
            if(unformattedSubTasks && unformattedSubTasks.length > 0){
                //TP-3838,3812
                subTasks = unformattedSubTasks.filter(t => !t.sub_sequence).map((t) => {
                    let [subTaskAction] = subTaskActions.filter(st => st.id === t.action_id);
                    let job_assets_tasks = t.reference_materials.map( a => {return {job_assets_id: a.job_assets_id}});//build the array of the reference to be used in the back end to save assets reference for tasks
                    return{
                        task_instruction: t.description,
                        expected_result: t.expected_result,
                        // task_startdate: this.state.startDate,TP-4866
                        // task_enddate: this.state.endDate,TP-4866
                        selectedAction: {
                                    value: subTaskAction ? subTaskAction.id : '',
                                    label: subTaskAction ? subTaskAction.name: ''
                                },
                        action_id: subTaskAction ? t.action_id: '',
                        collapse:true,
                        sequence:t.sequence,
                        action_data: t.action_data || [JSON.stringify({})],
                        action_command: subTaskAction ? subTaskAction.command : '',
                        reference_materials: t.reference_materials,//T32-131
                        job_assets_tasks: job_assets_tasks,
                        take_image: t.take_image,//TP-2480
                        isAdded: true,//TP-3491
                    }
                });
                this.setState({ subTasks },() =>{
                    this.checkIsAddProcedure();
                    this.fetchIndividualAsset();//TP-2716
                    this.checkSmartImageTypeJob();
                })
            }

        }catch(error){
            console.error(error)
        }
    }

    checkJobExists = (jobName,jobField) => {
		if (!this.validator.fieldValid(jobField)){
			this.setState({ isJobExists: false });
			return
        };
        let fetchString = 'jobs/checkJobExists/';
        fetchString += jobName;

        this.props.authService.fetch(fetchString, {
            method: 'get'
        })
		.then(response => {
			if(response.status === 200) 
				return response.json();
			else
				throw new Error(response.statusText);
		})
		.then(isExists => {
			this.setState({
				isJobExists: isExists
			}, () => this.checkIsAddProcedure());//Mb2-935
		})
		.catch((error) => {
			console.error(error);
		})
    }

    //TP-795
    checkReferenceIdExists = ({referenceId, jobField}) => {
		if (!this.validator.fieldValid(jobField)){
			this.setState({ isJobReferenceIdExists: false });
			return
        };
        let fetchString = 'jobs/checkReferenceIdExists/';
        fetchString += referenceId;

        this.props.authService.fetch(fetchString, {
            method: 'get'
        })
		.then(response => {
			if(response.status === 200) 
				return response.json();
			else
				throw new Error(response.statusText);
		})
		.then(isExists => {
			this.setState({
				isJobReferenceIdExists: isExists
			}, () => this.checkIsAddProcedure());//Mb2-935
		})
		.catch((error) => {
			console.error(error);
		})
    }

    deleteNewSubTask = async ({e, index}) => {
        try{
            if(e){
                e.preventDefault();
            }
            let { subTasks } = this.state;
            await this.detachFilesForUnsavedcloneTask({e: null, idx: index, fieldName: 'reference_materials'});//T32-131
            subTasks.splice(index,1);
            // MB2-900
            if(Array.isArray(subTasks) && subTasks.length > 0){
                subTasks= subTasks.map((task, i) => {
                    return {...task, sequence: (i+1)} // update the sequence of the tasks
                });
            }
            this.setState({
                    subTasks: subTasks,
                    /**TP-3220 */
                    index: 0,
                    isAddProcedure: false, // status of addtask button
                    bShowSubTask: false
            },()=>{
                // check the add task button state
                this.checkIsAddProcedure();
                this.checkSmartImageTypeJob();
            });
        }catch(error){
            console.error(error)//TP-3220
        }
    }

    onChangeJobForm = (fieldName) => ({ target }) => {
        (fieldName === 'name') && this.delayedCallback(target.value,target.name);
        (fieldName === 'reference_id') && this.delayedCallbackForReferenceId({referenceId:target.value, jobField:'identifier'});//TP-795
        //'task_instruction', 'sequence', 'expected_result', 'take_image'
        if (this.state.subTaskFields.includes(fieldName) ) {
            let subTasks = [...this.state.subTasks]  
            if(fieldName === 'sequence' && target.value && target.value.length > 0){
                subTasks = subTasks.map(t => {
                    if(t.sequence == target.value){
                        return {...t, sequence: parseInt(target.dataset.id) + 1}
                    }else{
                        return t
                    }
                })
            }
            subTasks[target.dataset.id][fieldName] = target.type === 'checkbox' ? target.checked : target.value.trimStart().replace(/(<([^>]+)>)/ig,"");//except checkbox
            if(fieldName === 'sequence' && target.value && target.value > 0){
                subTasks.sort((a,b) => (a.sequence > b.sequence) ? 1 : ((b.sequence > a.sequence) ? -1 : 0)); //TP-3508
                if(parseInt(target.value - 1) <  subTasks.length){
                    this.updateSelectedTask(parseInt(target.value - 1))//TP-3539
                }
            }
            this.setState({ subTasks },() =>{
                // check the add task button state
                this.checkIsAddProcedure();
            })
        } else {
            this.setState(prevState => ({
                selectedJob: {
                    ...prevState.selectedJob,
                    [fieldName]: (fieldName === "is_recurring") ? target.checked : target.value.trimStart().replace(/(<([^>]+)>)/ig,""),
                    mode_of_recurrence: (fieldName === "is_recurring" && !target.checked) ? null : prevState.selectedJob.mode_of_recurrence//TP-4891
                }
            }),() =>{
                // check the add task button state
                this.checkIsAddProcedure();
            });
        }
    };

    //TP-4891
    onChangeJobSchedule = (fieldName) => ({ target }) => {
        try{
            let mode_of_recurrence = this.state.selectedJob && this.state.selectedJob.mode_of_recurrence ? JSON.parse(this.state.selectedJob.mode_of_recurrence) : {}
            let days_of_week = mode_of_recurrence.days_of_week ? mode_of_recurrence.days_of_week : []
            if(fieldName === "days_of_week"){
                //array
                if(target.checked){
                    days_of_week.push(parseInt(target.dataset.id))//push the day selected sun-0, mon-1, tue-2
                }else{
                    days_of_week = days_of_week.filter(day => day !== (parseInt(target.dataset.id)))//remove the day
                }
            }

            // days of week will be array
            const value = fieldName === "days_of_week" ? days_of_week : target.value

            if(fieldName === "mode"){
                //reset interval, weekday
                if(value !== "daily"){
                    delete mode_of_recurrence.interval;
                    delete mode_of_recurrence.weekday;
                }
                //reset weekly_interval, days_of_week
                if(value !== "weekly"){
                    delete mode_of_recurrence.weekly_interval;
                    delete mode_of_recurrence.days_of_week;
                }
                //set weekday
                if(value === "daily"){
                    mode_of_recurrence.weekday = "false";
                    mode_of_recurrence.interval = 1
                }
                //set the day of the week 
                if(value === "weekly"){
                    // mode_of_recurrence.weekly_interval = 1
                    let day = this.state.unFormatedStartDate.getDay();
                    days_of_week.push(parseInt(day));
                    mode_of_recurrence.days_of_week = days_of_week;
                }
            }
            if(fieldName === "weekday"){
                if(value === "false"){
                    mode_of_recurrence.interval = mode_of_recurrence.interval ? mode_of_recurrence.interval : 1
                }else{
                    delete mode_of_recurrence.interval;
                }
            }

            const modifiedModeOfRecurrence = {...mode_of_recurrence, [fieldName]: value }//first assign the the key value then pass it for no of ocurrence
            const no_of_occurences = this.noOfOccurences({startDate: this.state.unFormatedStartDate, endDate: mode_of_recurrence.recurrence_end_date,
                mode_of_recurrence: modifiedModeOfRecurrence
            })

            this.setState(prevState => ({
                selectedJob: {
                    ...prevState.selectedJob, mode_of_recurrence: JSON.stringify({...modifiedModeOfRecurrence, no_of_occurences})
                }
            }),() =>{
                this.checkIsAddProcedure();
            });
        }catch(error){
            printConsole(`onChangeJobSchedule ==============> ${error}`)                
        }
    };

    //TP-4891
    onChangeScheduleEndDate = (date) =>{
        try{
            const mode_of_recurrence = this.state.selectedJob && this.state.selectedJob.mode_of_recurrence ? JSON.parse(this.state.selectedJob.mode_of_recurrence) : {}
            const no_of_occurences = this.noOfOccurences({startDate: this.state.unFormatedStartDate, endDate: date, mode_of_recurrence})
            this.setState(prevState => ({
                selectedJob: {
                        ...prevState.selectedJob, mode_of_recurrence: JSON.stringify({...mode_of_recurrence, ["recurrence_end_date"]: date,
                        no_of_occurences
                    })
                }
            }),() =>{
                this.checkIsAddProcedure();
            });
        }catch(error){
            printConsole(`onChangeScheduleEndDate ==============> ${error}`)                
        }
    }

    noOfOccurences = ({startDate, endDate, mode_of_recurrence}) => {
        try{
            const {mode, interval, weekday, days_of_week} = mode_of_recurrence;
            if(mode === "daily"){
                if(weekday === "false" && interval > 0){
                    const cd = 24 * 60 * 60 * 1000, modifiedEndDate = new Date(endDate), modifiedStartDate = new Date(startDate);//TP-5020
                    modifiedStartDate.setHours(0, 0, 0, 0);//set the beginning of the day
                    modifiedEndDate.setHours(23, 59, 59, 999);//set the end of the day
                    let dateDiff = modifiedEndDate - modifiedStartDate;

                    if(dateDiff){
                        return Math.floor(dateDiff/(parseInt(interval) * cd) +1)//includes the job itself
                    }else{
                        return 0
                    }
                }

                if(weekday === "true"){
                    const dateDiff = new Date(endDate) - startDate
                    if(dateDiff){
                        const noOfOccurences = getWeekdayCountBetweenDates(startDate, endDate, [1,2,3,4,5])//mon,tue,wed,thu,fri
                        return noOfOccurences
                    }else{
                        return 0
                    }
                }
            }

            if(mode === "weekly"){
                if(days_of_week && days_of_week.length > 0){
                    const dateDiff = new Date(endDate) - startDate
                    if(dateDiff){
                        const noOfOccurences = getWeekdayCountBetweenDates(startDate, endDate, days_of_week)
                        return noOfOccurences
                    }else{
                        return 0
                    }
                }
            }

            if(mode === "monthly"){
                const dateDiff = new Date(endDate) - startDate
                if(dateDiff){
                    const noOfOccurences = getMonthCountBetweenDates(startDate, endDate)
                    return noOfOccurences
                }else{
                    return 0
                }
            }

        }catch(error){
            printConsole(`noOfOccurences ==============> ${error}`)                
        }
    }

    // TP-4607
    onChangeSubtask = (fieldName) => ({ target }) => {
        try{
            let {subTasks, subTaskActions} = this.state;
            let [fileteredSubTaskActions] = subTaskActions.filter(action => action.command === "Ctrl+P" )//TP-2716
            subTasks[target.dataset.id][fieldName] = target.value.replace(/(<([^>]+)>)/ig,"");
            subTasks[target.dataset.id]["take_image"] = true;
            if(fileteredSubTaskActions){
                subTasks[target.dataset.id]['action_id'] = fileteredSubTaskActions.id;
            }
            
            this.setState({ subTasks },() =>{
                this.checkIsAddProcedure();
            })
        }catch(error){
            printConsole(`error in onChangeSubtask`)
        }
    };

    // TP-4607
    onChangeAdditionalInfo = (fieldName) => ({ target }) => {
        this.setState(prevState => ({
            additional_information: {
                ...prevState.additional_information, [fieldName]: target.value.replace(/(<([^>]+)>)/ig,"")
            }
        }),() => {
            this.checkIsAddProcedure();
        });
    };

    //MB2-775
    attachFile = ({fieldName, id, fileNameArr}) => {
        try{
            let subTasks = [...this.state.subTasks]  
            subTasks[id]['job_assets_tasks'] = subTasks[id]['job_assets_tasks'] ? subTasks[id]['job_assets_tasks'] : [] ;//T32-96 will be used in the back end to save the reference of assets for tasks
            subTasks[id][fieldName] = subTasks[id][fieldName] ? subTasks[id][fieldName] : [] ;//T32-131 array that will be looped to show the assets reference material on the ui in SUbtask component
            this.props.authService.fetch('jobs/saveJobAssets', {
                method: 'post',
                body: JSON.stringify({fileNameArr})
            })
            .then(response => {
                if(response.status === 200){
                    return response.json();
                } else {
                    throw new Error(response.body);
                }
            })
            .then(assetId => {
                if(assetId){
                    subTasks[id]['job_assets_tasks'] = [...subTasks[id]['job_assets_tasks'], {subtasks_id: subTasks[id]['id'], job_assets_id: assetId}];//build the array of the reference to be used in the back end to save assets reference for tasks
                    subTasks[id][fieldName] = [...subTasks[id][fieldName], {file_info: JSON.stringify(fileNameArr[0]) ,subtasks_id: subTasks[id]['id'], job_assets_id: assetId}];
                    this.setState({ subTasks });           
                }
            })
            .catch((error) => {
                printConsole("save assets attach file error ------>", error);
            });            
        }catch(error){
            printConsole("attach file error ------>", error);
        }
    }

    //MB2-131
    detachFiles = async ({e, idx, fieldName, fieldValue}) => {
        try{
            e && e.preventDefault();
            let subTasks = [...this.state.subTasks];
            this.setState({isJobLoading: true, loadingText: "Deleting"});
            this.props.authService.fetch(`jobs/deleteJobAssets`, {
                method: 'delete',
                body: JSON.stringify({file_info: fieldValue.file_info, job_assets_id: fieldValue.job_assets_id})//pass only file info and asset id not subtask id
            })
            .then(response => {
                this.setState({isJobLoading: false, loadingText: "Loading"});//TP-1274
                if(response.status === 200){
                    return response.json();
                } else {
                    throw new Error(response.body);
                }
            })
            .then(async (job_assets_id) => {
                if(job_assets_id){
                    let newTaskAssets = subTasks[idx][fieldName].filter(a => a.job_assets_id !== fieldValue.job_assets_id);//filter out the assets just deleted
                    subTasks[idx][fieldName]= newTaskAssets;
                    //unless the cloned job has assets job_assets_tasks will be empty
                    if(subTasks[idx]['job_assets_tasks']){
                        let job_assets_tasks = subTasks[idx]['job_assets_tasks'].filter( a => a.job_assets_id !== job_assets_id);//TP-734
                        subTasks[idx]['job_assets_tasks']= job_assets_tasks;
                    }
                    this.setState({ subTasks });           
                }
            })
            .catch((error) => {
                this.setState({isJobLoading: false, loadingText: "Loading"});//TP-1274
                printConsole("detach assets file error ------>", error);
            });            
        }catch(error){
            this.setState({isJobLoading: false, loadingText: "Loading"});//TP-1274
            printConsole("detach file error ------>", error);
        }
    }

    //T32-96
    detachFilesForUnsavedcloneTask = async ({e, idx, fieldName}) => {
        try{
            e && e.preventDefault();
            let subTasks = [...this.state.subTasks];
            let assets = subTasks[idx][fieldName];//clear all the multiple assets for all the subtask
            this.setState({isJobLoading: true, loadingText: "Deleting"});
            assets.forEach(element => {
                this.props.authService.fetch(`jobs/deleteJobAssets`, {
                    method: 'delete',
                    body: JSON.stringify({file_info: element.file_info, job_assets_id: element.job_assets_id})//pass only file info and asset id not subtask id
                })
                .then(response => {
                    this.setState({isJobLoading: false, loadingText: "Loading"});//TP-1274
                    if(response.status === 200){
                        return response.json();
                    } else {
                        throw new Error(response.body);
                    }
                })
                .then(async (job_assets_id) => {
                    printConsole('deleted asset id', job_assets_id)
                })
                .catch((error) => {
                    this.setState({isJobLoading: false, loadingText: "Loading"});//TP-1274
                    printConsole("unsaved assets detach file error ------>", error);
                });
            });
            if(assets.length === 0){
                this.setState({isJobLoading: false, loadingText: "Loading"});//TP-1503
            }
        }catch(error){
            this.setState({isJobLoading: false, loadingText: "Loading"});//TP-1274
            printConsole("detach file error ------>", error);
        }
    }

    //MB2-775
    deleteS3Files = async ({filesToDelete}) => {
        try{
            s3.deleteObjects({
                Bucket: process.env.REACT_APP_S3_BUCKET,
                Delete: {Objects: filesToDelete, Quiet: false }
                }, (err, data) => {
                    if(err){
                        console.error(err, err.stack); // an error occurred
                    }else{
                        // console.info(`file deleted ${JSON.stringify(data)}`); // successful response
                    }
                }
            );        
        }catch(error){
            console.error(error);
        }
    }
    
    getTechNameOptions = (i18n) => {
        let users = this.state.groupUsers;
        const {customer, isadmin, isexpert, enable_asset} = this.props;//TP-2721
        users = users.filter(obj => (!obj.isadmin && !obj.isexpert))
        const techNameOptions = users.map(p => {
            return {
                value: p.user_id,
                label: `${p.first_name} ${p.last_name}`
            }
        });
        //add the option when technician is optional and asset class is enabled
        if(!customer.mandatory_assignee_jobs && (customer.enable_asset_class !== "disabled" && isadmin || (isexpert && ((enable_asset && customer.enable_asset_class === "enabled_on_demand") || customer.enable_asset_class === "enabled_for_all" )))){
            techNameOptions.unshift({value: 0, label: i18n._(t`Clear Assignee`)});//TP-2579,2673,6687
        }
        return techNameOptions;
    }

    //TP-3566
    getExpertNameOptions = () => {
        let users = this.state.accountExperts;
        const expertNameOptions = users.map(p => {
            return {
                value: p.id,
                label: `${p.first_name} ${p.last_name}`
            }
        });
        return expertNameOptions;
    }
    
    actionNames = (obj, i18n) => {
        if(obj){
            let {value, label} = obj;
            switch (label) {
                case 'Take Image':
                    return {value,label: i18n._(t`Take Image`)};
                case 'Take Video':
                    return {value,label: i18n._(t`Take Video`)};
                case 'Scan IIOT QR':
                    return {value,label: i18n._(t`Scan IIOT QR`)};
                case 'No Evidence':
                    return {value,label: i18n._(t`No Evidence`)};
                case 'Manual Entry':
                    return {value,label: i18n._(t`Manual Entry`)};
                case 'Scan Asset QR':
                    return {value,label: i18n._(t`Scan Asset QR`)};
                case 'Pass Fail':
                    return {value,label: i18n._(t`Pass Fail`)};//TP-2480
                case 'Smart Image':
                    return {value,label: i18n._(t`Smart Image`)};//TP-2554
                case 'Inspected/Not Inspected':
                    return {value,label: i18n._(t`Inspected/Not Inspected`)};//TP-6603
                default:
                    return '';
            } 
        }else{
            return '';
        }
    }

    getSubTaskActionNameOptions = (i18n) => {
        let {assetDetails, subTaskActions} = this.state, fileteredSubTaskActions;
        if(assetDetails && assetDetails.model && assetDetails.model.length > 0){
            fileteredSubTaskActions = subTaskActions
        }else{
            fileteredSubTaskActions = subTaskActions.filter(action => action.command !== "Ctrl+S" )//TP-2716
        }
        return fileteredSubTaskActions.map(p => {
            let action = this.actionNames({value: p.id,label: p.name}, i18n)
            return action;
        });
    }

    //TP-2535
    getAssetOptions = (i18n) => {
        const assetOptions = this.state.allAssetData.map(p => { return {
                value: p.id, label: p.asset_name
            }
        });
        assetOptions.unshift({value: 0, label: i18n._(t`Clear Asset`)});//TP-2579
        return assetOptions;
    }
    //TP-2535
    handleAssetChange = (selectedAsset) => {
        this.setState((prevState) =>({ selectedJob: {...prevState.selectedJob, asset_id: selectedAsset.value}, selectedAsset}),()=>{ 
            this.checkIsAddProcedure();
            this.fetchIndividualAsset();//TP-2716
            if(selectedAsset.value === 0){
                this.clearSmartSubtasks();//clear if previously associated with smart image
            }
        });
    }

    formatDate = (str) => {
        let date = str,
            mnth = ("0" + (date.getMonth()+1)).slice(-2),
            day  = ("0" + date.getDate()).slice(-2);
        return [ date.getFullYear(), mnth, day ].join("-");
    }

    onStartDateChange = (date, e) => {
        if (e && typeof e.preventDefault === 'function') {
            e.preventDefault();
        }
        // let startDate = this.formatDate(date);
        const mode_of_recurrence = this.state.selectedJob && this.state.selectedJob.mode_of_recurrence ? JSON.parse(this.state.selectedJob.mode_of_recurrence) : {}
        let startDate = date;
        const no_of_occurences = this.noOfOccurences({startDate, endDate: mode_of_recurrence.recurrence_end_date, mode_of_recurrence })
        this.setState(prevState => ({
            startDate: startDate,
            unFormatedStartDate: date,
            selectedJob: {
                ...prevState.selectedJob,
                ['start_date']: startDate,
                mode_of_recurrence: prevState.selectedJob.is_recurring? JSON.stringify({...mode_of_recurrence, no_of_occurences }) : null//TP-4891
            }

        }),() => {
            this.checkIsAddProcedure();// check status of addtask button
        });
    }

    onEndDateChange = (date, e) => {
        if (e && typeof e.preventDefault === 'function') {
            e.preventDefault();
        }
        // let endDate = this.formatDate(date);
        let endDate = date;
        this.setState(prevState => ({
             endDate: endDate,
             unFormatedEndDate: date,
             selectedJob: {
				...prevState.selectedJob,
				['end_date']: endDate
            }
        }),() => {
            this.checkIsAddProcedure();// check status of addtask button
        });
    }

    onChangeTaskForm = ({fieldName,index}) => ({target}) => {
        let subTasks = [...this.state.subTasks];
        let prevVal = JSON.parse(subTasks[index]['action_data'][target.dataset.id]);
        if(fieldName === 'parameter_name'){
            prevVal['type']= 'Number'; //For now only this value is allowed TP-2470
            prevVal['task_id']= target.dataset.id + 1;//  will be supported later on having multiple manual entry
        }
        prevVal[fieldName]= target.value;
        subTasks[index]['action_data'][target.dataset.id] = JSON.stringify(prevVal);
        this.setState({ subTasks },() => {
            this.checkIsAddProcedure();
        });
    }

    onSubTaskStartDateChange = (idx) => (date) => {
        // let startDate = this.formatDate(date);
        let startDate = date;
        let subTasks = [...this.state.subTasks]   
        subTasks[idx]['task_startdate'] = startDate;
        this.setState({ 
            subTasks,
            
        },() => {
            this.checkIsAddProcedure();// check status of addtask button
        })

    }

    onSubTaskEndDateChange = (idx) => (date) => {
        // let endDate = this.formatDate(date);
        let endDate = date;
        let subTasks = [...this.state.subTasks]   
        subTasks[idx]['task_enddate'] = endDate;
        this.setState({ 
            subTasks,
            
        },() => {
            this.checkIsAddProcedure();// check status of addtask button
        })

    }

    onAddCloneJob = (e) => {
        e.preventDefault();
        if (!this.validator.allValid() || this.state.isJobExists || this.state.isJobReferenceIdExists) {
            this.openInvalidProcedure(this.validator.fields);
			this.validator.showMessages();
			return;
		}

        const { selectedJob, startDate, endDate, subTasks, additional_information } = this.state;
        selectedJob.start_date = selectedJob.start_date || startDate; selectedJob.end_date = selectedJob.end_date || endDate; selectedJob.subTasks = subTasks;
        selectedJob.technician_id = selectedJob.technician_id === 0 ? null : selectedJob.technician_id;//the validation is not triggering if set to '', or null
        selectedJob.asset_id = selectedJob.asset_id === 0 ? null : selectedJob.asset_id;//the validation is not triggering if set to '', or null
        selectedJob.additional_information = additional_information ? JSON.stringify(additional_information) : additional_information;//TP-4607
        
        if(subTasks.length === 0 && selectedJob.job_type !== 'work_instruction_template'){
            selectedJob.status = 'draft'//TP-3445
        }

		this.setState({
            bSubmitted: true,
            errStrReturned: selectedJob.job_type === 'work_instruction_template' ? i18nMark('Submitting new Template details') : i18nMark('Submitting new Job details')//TP-6460
        });
        this.props.authService.fetch('jobs/addJob', {
            method: 'post',
            body: JSON.stringify(selectedJob)
        })
        .then(response => {
			this.setState({retCode: response.status});
			if(response.status === 200){
                this.props.fetchJobs();//reload the jobs
				return response.json();
			} else {
				throw new Error(response.body);
            }
        })
        .then(id => {
			if(id){
				this.setState({
                    bSubmitted: false,
                    //errStrReturned: i18nMark('New Job successfully added!')//MB2-923
                });
                this.props.changeJobView({action:'', showJobForm: false});//MB2-923
			}
        })
        .catch((error) => {
            console.error(error)
            this.setState({
                retCode: error.status,
                errStrReturned: i18nMark('Failed to add the new job. Please review the entries and resubmit.')
            });

        });
    };

    initialiseJobForCloning = (selectedJob) => {
        let {allAssetData, selectedAsset } = this.state;
        if(selectedJob.asset_id && allAssetData && allAssetData.length > 0){
            selectedAsset = allAssetData.find((asset) => asset.id === selectedJob.asset_id);
        }        
        this.setState((prevState) => ({
            selectedJob: {...prevState.selectedJob, source_id: selectedJob.id,//TP-3599
                description: selectedJob.description, asset_id: selectedJob.asset_id && selectedAsset ? selectedJob.asset_id : 0,//TP-2552
            },
            selectedAsset: selectedAsset ? {value: selectedAsset.id, label: selectedAsset.asset_name}: selectedAsset
        }));
    }

    //TP-3220
    updateSelectedTask = (index) => {
        this.setState({index, bShowSubTask: true}, () => {
            let subTasks = [...this.state.subTasks] 
            //TP-4123
            if(subTasks.length>0 && subTasks[index]){
                subTasks[index]['collapse'] = false;
            }
            this.setState({
                subTasks
            });
        });
    }

    //TP-3220
    showNextSeries = (e) => {
        e.preventDefault();
        //Hide the "..." before showing the new set of Procedures
        e.target.parentNode.classList.add("d-none");

        //Show the next set of Procedures
        const index = parseInt(e.target.parentNode.id.split("-")[1]);
        const elements = document.querySelectorAll(`[id^="pro-${index}"]`);
        for(let ii=0; ii<elements.length; ii++) {
            elements[ii].classList.remove("d-none");
        }

        //Show the next "..." if there
        const nextBreakElem = document.querySelector(`[id^="break-${index+1}"]`);
        if (nextBreakElem) {
            nextBreakElem.classList.remove("d-none");
        }
    }

    //TP-3220 -- Invoke when user click to request another page.
    handleProcedureClick = (event) => {
        const newOffset = event.selected;
        console.log(
        `User requested page number ${event.selected}`
        );
        this.updateSelectedTask(newOffset);
    };

    addTask = (e) => {
        try{
            e.preventDefault();
            
            const {customer} = this.props;
            let subTasks = [...this.state.subTasks] 
            if(subTasks.length>0){
                subTasks[subTasks.length-1]['collapse'] = true; //TP-3220
            }  
            subTasks.push({
                task_instruction:'',
                expected_result: '',
                // task_startdate: this.state.selectedJob.start_date || this.state.startDate,TP-4866
                // task_enddate: this.state.selectedJob.end_date || this.state.endDate,TP-4866
                collapse: false,
                sequence: (subTasks.length +1),
                user_id: '',
                action_data: [JSON.stringify({})]
            });

            //TP-6629,TZ-597 auto select the evidence for HW
            if(customer.behaviour === "honeywell"){
                let {subTaskActions} = this.state;
                const selectedAction = subTaskActions.find((a) => a.command === "Ctrl+IP")
                subTasks[subTasks.length - 1]["action_id"] = selectedAction.id;
                subTasks[subTasks.length - 1]["selectedAction"] = {
                    value: selectedAction.id,
                    label: selectedAction.name
                };
            }
            this.setState({
                subTasks,
                    index: subTasks.length - 1, //TP-3220
                isAddProcedure: true // status of addtask button
                }, () => {
                    // this.setState({ bShowSubTask: true }); //TP-3220
            });
        }catch(error){
            printConsole(`error in addTask ${error}`)
        }
    }
    
    //TP-3220
    cancelSubTask = (e) => {
        e.preventDefault();
        let {subTasks, index} = this.state, isAddProcedure = false;
        this.openInvalidProcedure(this.validator.fields);
        if (!subTasks[index].id && !subTasks[index].isAdded){
            this.deleteNewSubTask({e,index})//delete the subtask considering its a new one
        }        
        if (subTasks[index] && !subTasks[index].id && subTasks[index].isValid === false && subTasks[index].isAdded) {
            isAddProcedure = true //disable update button if sub task was added but again opened and updated
        }
        this.setState({
            bShowSubTask: false,
            index: 0,
            isAddProcedure // status of addtask button
        });
    }

    //TP-3220
    updateTask = (e) => {
        e.preventDefault(); 
        if (!this.validator.allValid()) {
            this.validator.showMessages();
			return;
		}
        let {subTasks, index} = this.state;
        subTasks[index].isAdded = true; //TP-3491, mark the task ass added
        this.setState({ bShowSubTask: false, subTasks });
    }
    
    collapseSubTask = (idx) => {
        let subTasks = [...this.state.subTasks]  
        subTasks = subTasks.map((task, i) => {
            if(idx === i){
                return {...task, collapse: !task.collapse}
            }else{
                return {...task, collapse: true}
            }
        }) 
        this.setState({ 
            subTasks,
            
        })
    }

    addActionData = ({index,subIndex}) =>{
        let subTasks = [...this.state.subTasks];
        subTasks[index]['action_data'][subIndex +1] = JSON.stringify({})
        this.setState({
            subTasks
        },() => {
            this.checkIsAddProcedure();
        });
    }

    deleteActionData = ({index,subIndex}) => {
        let subTasks = [...this.state.subTasks];
        subTasks[index]['action_data'].splice(subIndex,1);
        this.setState({
            subTasks
        },() => {
            this.checkIsAddProcedure();
        });
    }

    /* this method will enable/disable the addtask button. This needs to be called in the callback
    of set state or else validator will give inconsistent result
    */
    checkIsAddProcedure = () => {
        if (!this.validator.allValid() || this.state.isJobExists || this.state.isJobReferenceIdExists) {
            this.validator.showMessages();
            this.openInvalidProcedure(this.validator.fields)//TP-4004, show the error text
            this.setState({isAddProcedure: true})
        }else{
            this.setState({isAddProcedure: false})
        }

    }

    openInvalidProcedure = (fields) => {
        let invalidFields = [], matches= [];
        for(let key in fields) {
            if(fields[key] === false && /\d/.test(key)){
                invalidFields.push(key)
            }
        }
        // loop through the invalid fields to find out the field name with number in it
        invalidFields.map((f) => {
            let matchArr = f.match(/\d+/g);
            matches.push(matchArr[0]); 
        })

        matches = [...new Set(matches)];
        let {subTasks} = this.state;
        for(let i = 0; i < matches.length; i++){
            // if not collapsed then showInvalidError is true
            if(subTasks[parseInt(matches[i])]['collapse']){
                subTasks[parseInt(matches[i])]['showInvalidError']= true
            }else{
                subTasks[parseInt(matches[i])]['showInvalidError']= false//once chnanges are made hide the already shown message
            }
            subTasks[parseInt(matches[i])]['isValid'] = false //TP-3450,3455 mark which is invalid
        }
        this.setState({subTasks})
    }

    handleTechnicianChange = (selectedTechnician) => {
        let {selectedJob} = this.state;
        selectedJob.technician_id = selectedTechnician.value;
        this.setState({ 
            selectedTechnician,
            selectedJob
        },()=>{
            this.checkIsAddProcedure();
        })
    }

    //TP-3566
    handleExpertChange = (selectedExpert) => {
        let {selectedJob, allAssetData, selectedAsset, copyOfAllAssets} = this.state, assetSelected;
        selectedJob.assigned_by = selectedExpert.value;
        //TP-3640,4419,TZ-623
        if(copyOfAllAssets.length > 0){
            //load asset as per the expert selected, in edit if different expert is chosen then check the asset selected
            // allAssetData = copyOfAllAssets.filter(asset => asset.owner_id === selectedExpert.value)
            allAssetData = copyOfAllAssets
            if(selectedJob && selectedJob.asset_id){
                [assetSelected] =  allAssetData.filter(asset => asset.id === selectedJob.asset_id)
                if(!assetSelected){
                    selectedJob.asset_id = null;
                    selectedAsset= '';
                }
            }
        }
        this.setState({ selectedExpert, selectedJob, allAssetData, selectedAsset },()=>{
            this.checkIsAddProcedure();
        })
    }

    handleActionChange = (idx) => (selectedAction) => {
        let subTasks = [...this.state.subTasks];
        subTasks[idx]['action_id'] = selectedAction.value;
        let {subTaskActions} = this.state;
        selectedAction = subTaskActions.find((a) => a.id === selectedAction.value)
        subTasks[idx]['selectedAction'] = {
            value: selectedAction.value,
            label: selectedAction.name
        };
        subTasks[idx]['action_command'] = selectedAction.command;
        if(!subTasks[idx]['id']){
            switch(selectedAction.command){
                case "Ctrl+Q":
                    break;
                case "Ctrl+E":
                    break;
                default:
                    subTasks[idx]['action_data'] = [JSON.stringify({})]; //TP-1773
            }
        }
        this.setState({ 
            subTasks,
        },() => {
            this.checkIsAddProcedure();// check status of addtask button
            this.checkSmartImageTypeJob();
        })
    }

    //TP-6629
    handleOptionChange = (index, fieldName) => (selectedOption) => {
        try{
            let subTasks = [...this.state.subTasks];
            if(fieldName === "room"){
                subTasks[index]['task_instruction'] = selectedOption.value;
            }else{
                subTasks[index]['expected_result'] = selectedOption.value;
            }
            
            this.setState({ 
                subTasks,
            },() => {
                this.checkIsAddProcedure();// check status of addtask button
            })
        }catch(error){
            printConsole(`error in handleOptionChange ${error}`)
        }
    }

    // MB2-175
    convertDateToUTC = (date) => {
        return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
    }
    
    //MB2-775 before closing the form check for any unsaved tasks reference material
    oncloseCloneJobForm = async () => {
        try{
            this.state.subTasks.forEach(async (t, index) => {
                if(!t.id && !t.job_id){
                    await this.detachFilesForUnsavedcloneTask({e: null, idx: index, fieldName: 'reference_materials'});//T32-131 delete the reference materials of the unsaved tasks
                }
            })
            this.props.changeJobView({action:'', showJobForm: false});
        }catch(error){
            console.error(error);
            this.props.changeJobView({action:'', showJobForm: false});
        }
    }

    //Flash message specific methods
    updateShowMessage = (val) => {
        if (val === false)
            this.setState({ flashMsgText: "", showFlashMessage: val, flashLeft: "", flashTop: "" });
    }
    
    // TP-2770
    checkSmartImageTypeJob = () => {
        let {subTasks} = this.state;
        let isSmartImageTypeJob = false, i= 0;
        for(i; i < subTasks.length; i++){
            const {selectedAction} = subTasks[i];
            /**TP-2855, 4121 -- Handled the use case when selectedAction is undefined */
            if(selectedAction && selectedAction.label === 'Smart Image'){
                isSmartImageTypeJob = true;
                break;
            }
            
        }
        this.setState({isSmartImageTypeJob});
    }
    
    clearSmartSubtasks = () => {
        let {subTasks} = this.state, i= 0;
        for(i; i < subTasks.length; i++){
            const {selectedAction} = subTasks[i];
            if(selectedAction && selectedAction.label === 'Smart Image'){
                subTasks[i]['selectedAction'] = {};
                subTasks[i]['action_id'] = '';
            }
            
        }
        this.setState({subTasks});
    }

    //TP-6629
    getRooms = () => {
        let {rooms} = this.state;
        return rooms.map(r => {
            let obj = {value: r,label: r}
            return obj;
        });
    }

    //TP-6629
    getDisciplines = () => {
        let {disciplines} = this.state;
        return disciplines.map(d => {
            let obj = {value: d,label: d}
            return obj;
        });
    }

    render(){
        let {selectedTechnician, subTasks, isAddProcedure, selectedAsset, isSmartImageTypeJob, index, selectedExpert, additional_information} = this.state;
        let {action, selectedJob, customer, isadmin, isexpert, enable_asset, user_enable_job_scheduler} = this.props;
        const header = document.getElementById("header_bar");
        const tabHeader = document.getElementById("jobsTab");
        additional_information = additional_information ? additional_information : {}
        //TP-3840
        let areaHeight = 0 + 'px';
        if (header && header !== null && tabHeader && tabHeader !== null)
            areaHeight = Math.round(window.innerHeight - header.offsetHeight - tabHeader.offsetHeight - 30- 20 - 20) + "px";//TP-3710 30-footer height, 20-mt3 height, 20-for the space at the bottom when multiple procedure are there
        const showTech = (action === 'clone_evidence_job' )
        this.validator.purgeFields();
        let brkIdx = -1; //TP-3220
		if(this.state.bSubmitted || this.state.bResponded){
			let colorText = '#485890';
			if(this.state.retCode !== 200) colorText = 'red';
			return(
                <>
                    <div className='modale opened'>
                        <div className='__modal-dialog'>
                            <form>
                                <div className="__modal-header">
                                    <h4 style={{color: colorText}}><Trans id={this.state.errStrReturned}/></h4>
                                </div>
                                <div className="__modal-footer flex-center">
                                    <button className="btn-green" onClick={() =>this.props.changeJobView({action:'', showJobForm: false})}><Trans id='OK'/></button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <FlashMessage 
                        flashMsgText={this.state.flashMsgText} 
                        showMessage={this.state.showFlashMessage} 
                        flashLeft={this.state.flashLeft}
                        flashTop={this.state.flashTop}
                        updateShowMessage={this.updateShowMessage}
                        isTimerEnabled={true} /**TP-5986*/
                    />
                </>
			);
		} else {
            //TP-3220 -- Embedded Forms page
			return(
                    <>
                    <div className={`formmodule ${subTasks.length > 0 ? "row": "center"}`}>
					    <div className='mt-3 form-color width-min col-6' style={{height: areaHeight}}>
                            <div className="formheader flex-center row">
                                {/* TP-2704 */}
                                <h2>
                                    <Trans id={(action === 'clone_evidence_template' && selectedJob) ? 'Add Template': 'Add Job'}></Trans> - <Trans>Evidence</Trans>
                                </h2>
                            </div>
                            <div className="formbody form-text-color position-sticky">
                                <Form>
                                    <div className="jobs-form-body pb-0">
                                        <Row className="margin-top-15 mb-1">
                                            <Col /* className="input-wrapper" */>
                                                
                                                    <div id="job_name" className="input-wrapper">
                                                        <I18n>
                                                            {({ i18n }) =>
                                                                <>
                                                                    <input className='signup-input-field' name={i18n._(t`name`)} type="text" placeholder={showTech? i18n._(t` Enter the job name`) : i18n._(t` Enter the template name`)} value={this.state.selectedJob.name} onChange={this.onChangeJobForm('name')}  autoComplete="off"/>
                                                                    { this.validator.message(i18n._(t`name`), this.state.selectedJob.name, 'required|maxCharLength:30') }
                                                                    { this.state.isJobExists ? <div className="add-users-validation-msg"><Trans>There is a job with this name.</Trans></div> : '' }
                                                                </>									
                                                            }
                                                        </I18n>
                                                        { this.props.isSmallFormFactor === false ? 
                                                            <UncontrolledTooltip placement="top" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="job_name">
                                                                <>{showTech? <Trans>Job</Trans> : <Trans>Template</Trans>} <Trans>Name</Trans></>
                                                            </UncontrolledTooltip>       
                                                            :
                                                            ''
                                                        }
                                                    </div>
                                            </Col>
                                            <Col /* className="input-wrapper" */>
                                                    <div id="unique_id" className="input-wrapper">
                                                        <I18n>
                                                            {({ i18n }) =>
                                                                <>
                                                                    <input className='signup-input-field' name={i18n._(t`reference_id`)} type="text" placeholder={i18n._(t` Enter unique identifier, (atleast 8 characters)`)} value={this.state.selectedJob.reference_id} onChange={this.onChangeJobForm('reference_id')}  autoComplete="off"/>
                                                                    { this.validator.message(i18n._(t`identifier`), this.state.selectedJob.reference_id, 'required|minCharLength:8|maxCharLength:30|alpha_num_dash_underscore') }
                                                                    { this.state.isJobReferenceIdExists ? <div className="add-users-validation-msg"><Trans>There is a job with this identifier</Trans></div> : '' }
                                                                </>									
                                                            }
                                                        </I18n>
                                                        { this.props.isSmallFormFactor === false ? 
                                                            <UncontrolledTooltip placement="top" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="unique_id">
                                                                <Trans>Unique Identifier</Trans>
                                                            </UncontrolledTooltip>       
                                                            :
                                                            ''
                                                        }
                                                    </div>
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col>
                                                    <div id="job_desc" className="input-wrapper">
                                                        <I18n>
                                                            {({ i18n }) =>
                                                            <>
                                                                <textarea rows={4} className='signup-input-field form-control' name={i18n._(t`description`)} 
                                                                    placeholder={showTech? i18n._(t` Enter the job Description`) : i18n._(t` Enter the template Description`)} 
                                                                    value={this.state.selectedJob.description} onChange={this.onChangeJobForm('description')}  autoComplete="off"
                                                                />
                                                                { this.validator.message(i18n._(t`description`), this.state.selectedJob.description, 'required') }

                                                            </>
                                                            }
                                                        </I18n>
                                                        { this.props.isSmallFormFactor === false ? 
                                                            <UncontrolledTooltip placement="top" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="job_desc">
                                                                <>{showTech? <Trans>Job</Trans> : <Trans>Template</Trans>} <Trans>Description</Trans></>
                                                            </UncontrolledTooltip>       
                                                            :
                                                            ''
                                                        }
                                                    </div>
                                            </Col>
                                        </Row>
                                        <I18n>
                                            {({ i18n }) =>
                                                <> 
                                                    {/*TP-6603*/} 
                                                    {
                                                        // customer.behaviour !== "honeywell" ?
                                                            <>
                                                                <Row>
                                                                    <Col className="input-wrapper">
                                                                        {showTech && !customer.mandatory_assignee_jobs && (customer.enable_asset_class !== "disabled" && isadmin || (isexpert && ((enable_asset && customer.enable_asset_class === "enabled_on_demand") || customer.enable_asset_class === "enabled_for_all" )))  ? 
                                                                            <span className='times tm-regular' style={{fontSize: '14px'}}><Trans values={{role: this.props.customerRoles.technician}}>check_technician_asset</Trans></span>
                                                                            : 
                                                                            null
                                                                        }
                                                                    </Col>
                                                                    <Col className="input-wrapper">
                                                                        { isSmartImageTypeJob ? <span className='times tm-regular' style={{fontSize: '14px'}}><Trans>Asset cannot be changed due to Smart Image Procedures</Trans></span> : null}
                                                                    </Col>
                                                                </Row>
                                                                <Row className="mb-1">
                                                                    {showTech? 
                                                                        <Col id="assign_tech" className="input-wrapper">
                                                                                <Select
                                                                                    value={selectedTechnician}
                                                                                    onChange={this.handleTechnicianChange}
                                                                                    placeholder={i18n._({ id: `Select A Technician`, values: {role: this.props.customerRoles.technician}})}
                                                                                    components={makeAnimated()}
                                                                                    options={this.getTechNameOptions(i18n)}
                                                                                    isSearchable={this.props.isSmallFormFactor ? false : true}
                                                                                    className="text-black"
                                                                                />
                                                                                { this.props.isSmallFormFactor === false ? 
                                                                                    <UncontrolledTooltip placement="top" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="assign_tech">
                                                                                        <Trans values={{role: this.props.customerRoles.technician}}>Select A Technician</Trans>
                                                                                    </UncontrolledTooltip>       
                                                                                    :
                                                                                    ''
                                                                                }
                                                                            <input type="text" readOnly value={this.state.selectedJob.technician_id} name={i18n._({id: `technician`, values: {role: this.props.customerRoles.technician}})} className="hidden"/>
                                                                            { (!customer.mandatory_assignee_jobs && (customer.enable_asset_class !== "disabled" && isadmin || (isexpert && ((enable_asset && customer.enable_asset_class === "enabled_on_demand") || customer.enable_asset_class === "enabled_for_all" )))) ? 
                                                                                this.validator.message(i18n._({id: `technician`, values: {role: this.props.customerRoles.technician}}), this.state.selectedJob.technician_id, 'check_technician_asset:'+i18n._({id: `technician`, values: {role: this.props.customerRoles.technician}}))
                                                                                :
                                                                                this.validator.message(i18n._({id: `technician`, values: {role: this.props.customerRoles.technician}}), this.state.selectedJob.technician_id, 'required')
                                                                            }
                                                                        </Col>
                                                                        :
                                                                        isadmin ?
                                                                            <Col id="assign_exp" className="input-wrapper">
                                                                                    <Select
                                                                                        value={selectedExpert}
                                                                                        onChange={this.handleExpertChange}
                                                                                        placeholder={i18n._({ id: `Select An Expert`, values: {role: this.props.customerRoles.expert}})}
                                                                                        components={makeAnimated()}
                                                                                        options={this.getExpertNameOptions(i18n)}
                                                                                        isSearchable={this.props.isSmallFormFactor ? false : true}
                                                                                        className="text-black"
                                                                                    />
                                                                                { this.props.isSmallFormFactor === false ? 
                                                                                    <UncontrolledTooltip placement="top" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="assign_exp">
                                                                                        <><Trans>select_option</Trans> <Trans>An</Trans> <Trans values={{role: this.props.customerRoles.expert}}>Expert</Trans></>
                                                                                    </UncontrolledTooltip>       
                                                                                    :
                                                                                    ''
                                                                                }
                                                                                <input type="text" readOnly value={this.state.selectedJob.assigned_by} name={i18n._({id: `expert`, values: {role: this.props.customerRoles.expert}})} className="hidden"/>
                                                                                {this.validator.message(i18n._({id: `expert`, values: {role: this.props.customerRoles.expert}}), this.state.selectedJob.assigned_by, 'required')}
                                                                            </Col>
                                                                        : null
                                                                    }                                                               
                                                                    { customer.enable_asset_class !== "disabled" && isadmin || (isexpert && ((enable_asset && customer.enable_asset_class === "enabled_on_demand") || customer.enable_asset_class === "enabled_for_all" )) ? 
                                                                    // TP-4451
                                                                        <Col id="asset-dropdown" className="input-wrapper">
                                                                            <Select
                                                                                value={selectedAsset}
                                                                                onChange={this.handleAssetChange}
                                                                                placeholder={i18n._(t` Select An Asset`)}
                                                                                components={makeAnimated()}
                                                                                options={this.getAssetOptions(i18n)}
                                                                                isSearchable={this.props.isSmallFormFactor ? false : true}
                                                                                isDisabled={isSmartImageTypeJob}
                                                                                className="text-black"
                                                                            />
                                                                            { this.props.isSmallFormFactor === false ? 
                                                                                <UncontrolledTooltip placement="top" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="asset-dropdown">
                                                                                    <Trans>Select An Asset</Trans>
                                                                                </UncontrolledTooltip>       
                                                                                :
                                                                                ''
                                                                            }
                                                                            <input type="text" readOnly value={this.state.selectedJob.asset_id} name={i18n._(t`asset`)} className="hidden"/>
                                                                            {showTech && !customer.mandatory_assignee_jobs ? this.validator.message(i18n._(t`asset`), this.state.selectedJob.asset_id, 'check_technician_asset:'+i18n._({id: `technician`, values: {role: this.props.customerRoles.technician}})) : null}
                                                                        </Col>
                                                                        :
                                                                        null
                                                                    }
                                                                </Row>
                                                            </>
                                                    // :
                                                    //         null
                                                    }
                                                    {
                                                        showTech?   
                                                            <Row className="mt-3 mb-1">
                                                                <Col id="start_date" className="input-wrapper">
                                                                    <DatePicker selected={this.state.unFormatedStartDate} className="custom-date" /**TP-3031 */ onFocus={this.props.isSmallFormFactor ? (e) => e.target.readOnly = true : () => {return null;}} 
                                                                        popperPlacement="top-start" onChange={this.onStartDateChange} showTimeSelect timeFormat="HH:mm" dateFormat="Pp" timeIntervals={15} 
                                                                        onKeyDown={(e) => { e.preventDefault();}}/**TP-4847 **/
                                                                    />
                                                                    <input type="date" readOnly value={this.state.selectedJob.start_date} name={i18n._(t`start_date`)} className="hidden"/>
                                                                    { this.validator.message(i18n._(t`start_date`), this.state.selectedJob.start_date, 'checkStartDate|beforEndDate|checkEditStartDate') }
                                                                    { this.props.isSmallFormFactor === false ? 
                                                                        <UncontrolledTooltip placement="top" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="start_date">
                                                                            <><Trans>select_option</Trans> <Trans>Start Date</Trans></>
                                                                        </UncontrolledTooltip>       
                                                                        :
                                                                        ''
                                                                    }
                                                                </Col>
                                                                <Col id="end_date" className="input-wrapper">
                                                                    <DatePicker selected={this.state.unFormatedEndDate} className="custom-date" /**TP-3031 */ onFocus={this.props.isSmallFormFactor ? (e) => e.target.readOnly = true : () => {return null;}} 
                                                                        popperPlacement="top-start" onChange={this.onEndDateChange} showTimeSelect timeFormat="HH:mm" dateFormat="Pp" timeIntervals={15} 
                                                                        onKeyDown={(e) => { e.preventDefault();}}/**TP-4847 **/
                                                                    />
                                                                    <input type="date" readOnly value={this.state.selectedJob.end_date} name={i18n._(t`end_date`)} className="hidden"/>
                                                                    { this.validator.message(i18n._(t`end_date`), this.state.selectedJob.end_date, 'afterStartDate') }
                                                                    { this.props.isSmallFormFactor === false ? 
                                                                        <UncontrolledTooltip placement="top" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="end_date">
                                                                            <><Trans>select_option</Trans> <Trans>End Date</Trans></>
                                                                        </UncontrolledTooltip>       
                                                                        :
                                                                        ''
                                                                    }
                                                                </Col>
                                                            </Row>
                                                        : null
                                                    }

                                                    {showTech && (customer.enable_job_scheduler !== "disabled" && isadmin || (isexpert && ((user_enable_job_scheduler && customer.enable_job_scheduler === "enabled_on_demand") || customer.enable_job_scheduler === "enabled_for_all" )))? 
                                                        <div>
                                                            <label htmlFor="is_recurring" className="h4">
                                                                <input className="checkbox" type="checkbox" name="is_recurring" checked={this.state.selectedJob.is_recurring} 
                                                                    onChange={this.onChangeJobForm("is_recurring")}>
                                                                </input>
                                                                <Trans>Recurring</Trans>
                                                            </label>

                                                            {
                                                                this.state.selectedJob.is_recurring ?
                                                                    <Scheduler 
                                                                        selectedJob={this.state.selectedJob} onChangeJobSchedule={this.onChangeJobSchedule} 
                                                                        isSmallFormFactor={this.props.isSmallFormFactor} onChangeScheduleEndDate={this.onChangeScheduleEndDate}
                                                                        validator={this.validator}
                                                                    />
                                                                :   null
                                                            }
                                                        </div>
                                                    : null}

                                                    {/* TP-4607 */}
                                                    {
                                                        customer.behaviour === "hindalco" && showTech?
                                                            <>
                                                                <Row>
                                                                    <Col>
                                                                        <div id="container_no" className="input-wrapper">
                                                                            <I18n>
                                                                                {({ i18n }) =>
                                                                                    <>
                                                                                        <input className='signup-input-field' name={i18n._(t`container_no`)} type="text" placeholder={ i18n._(t` Enter the Container No.`) } value={additional_information.container_no} onChange={this.onChangeAdditionalInfo('container_no')} autoComplete="off"/>
                                                                                        { this.validator.message(i18n._(t`container_no`), additional_information.container_no, 'required|alpha_num') }{/* TZ-200 changing numeric to alpha num for L&T */}
                                                                                    </>									
                                                                                }
                                                                            </I18n>
                                                                            { this.props.isSmallFormFactor === false ? 
                                                                                <UncontrolledTooltip placement="top" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="container_no">
                                                                                    <Trans>Container No.</Trans>
                                                                                </UncontrolledTooltip>       
                                                                                :
                                                                                ''
                                                                            }
                                                                        </div>
                                                                    </Col>
                                                                    <Col>
                                                                        <div id="grade" className="input-wrapper">
                                                                            <I18n>
                                                                                {({ i18n }) =>
                                                                                    <>
                                                                                        <input className='signup-input-field' name={i18n._(t`grade`)} type="text" placeholder={ i18n._(t` Enter the Grade`) } value={additional_information.grade} onChange={this.onChangeAdditionalInfo('grade')} autoComplete="off"/>
                                                                                        { this.validator.message(i18n._(t`grade`), additional_information.grade, 'required|alpha_num') }
                                                                                    </>									
                                                                                }
                                                                            </I18n>
                                                                            { this.props.isSmallFormFactor === false ? 
                                                                                <UncontrolledTooltip placement="top" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="grade">
                                                                                    <><Trans>Grade</Trans></>
                                                                                </UncontrolledTooltip>       
                                                                                :
                                                                                ''
                                                                            }
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col>
                                                                        <div id="lot_no" className="input-wrapper">
                                                                            <I18n>
                                                                                {({ i18n }) =>
                                                                                    <>
                                                                                        <input className='signup-input-field' name={i18n._(t`lot_no`)} type="text" placeholder={ i18n._(t` Enter the Lot No.`) } value={additional_information.lot_no} onChange={this.onChangeAdditionalInfo('lot_no')} autoComplete="off"/>
                                                                                        { this.validator.message(i18n._(t`lot_no`), additional_information.lot_no, 'required|numeric') }
                                                                                    </>									
                                                                                }
                                                                            </I18n>
                                                                            { this.props.isSmallFormFactor === false ? 
                                                                                <UncontrolledTooltip placement="top" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="lot_no">
                                                                                    <Trans>Lot No.</Trans>
                                                                                </UncontrolledTooltip>       
                                                                                :
                                                                                ''
                                                                            }
                                                                        </div>
                                                                    </Col>
                                                                    <Col>
                                                                        <div id="no_of_bags" className="input-wrapper">
                                                                            <I18n>
                                                                                {({ i18n }) =>
                                                                                    <>
                                                                                        <input className='signup-input-field' name={i18n._(t`no_of_bags`)} type="text" placeholder={ i18n._(t` Enter the No. Of Bags`) } value={additional_information.no_of_bags} onChange={this.onChangeAdditionalInfo('no_of_bags')} autoComplete="off"/>
                                                                                        { this.validator.message(i18n._(t`no_of_bags`), additional_information.no_of_bags, 'required|numeric') }
                                                                                    </>									
                                                                                }
                                                                            </I18n>
                                                                            {   this.props.isSmallFormFactor === false ? 
                                                                                <UncontrolledTooltip placement="top" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="no_of_bags">
                                                                                    <><Trans>No. Of Bags</Trans></>
                                                                                </UncontrolledTooltip>       
                                                                                :
                                                                                ''
                                                                            }
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </>
                                                        :
                                                            null
                                                    }                                                    
                                                </>
                                            }
                                        </I18n>
                                    </div>			
                                    <div className="mt-3">
                                        <Row>
                                            <Col className="d-flex justify-content-center"><button className='btn-red job-button' onClick={() => this.oncloseCloneJobForm()}><Trans>Cancel</Trans></button></Col>
                                            <Col className="d-flex justify-content-center"><button className={isAddProcedure ? "btn-grey job-button": "btn-blue job-button"} disabled={isAddProcedure} onClick={(e) => this.addTask(e)}><Trans>Add</Trans> <Trans>New</Trans> <Trans>Procedure</Trans></button></Col>
                                            <Col className="d-flex justify-content-center"><button className={isAddProcedure ? "btn-grey job-button": "btn-green job-button"} disabled={isAddProcedure} onClick={(e) =>this.onAddCloneJob(e)}><Trans>Create</Trans></button></Col>
                                        </Row>
                                    </div>
                                </Form>
                            </div>
                        </div>
                        {   subTasks.length > 0 ?
                            <div className='mt-3 form-color width-min col-6' style={{height: areaHeight}}>
                                <div className="formheader flex-center row">
                                    {/* TP-2704 */}
                                    <h2>
                                        <Trans id={(action === 'clone_evidence_template' && selectedJob) ? 'Add Template': 'Add Job'}></Trans> - <Trans>Procedure</Trans>
                                    </h2>
                                </div>
                                <div className="mt-4">
                                    {
                                        // customer.behaviour !== "honeywell"?
                                            <SubTask 
                                                subTasks={subTasks} 
                                                selectedSubTask = {subTasks[index]}
                                                idx={index}
                                                onChangeJobForm={this.onChangeJobForm}
                                                onSubTaskStartDateChange={this.onSubTaskStartDateChange}
                                                onSubTaskEndDateChange={this.onSubTaskEndDateChange}
                                                getSubTaskActionNameOptions={this.getSubTaskActionNameOptions}
                                                handleActionChange={this.handleActionChange}
                                                validator={this.validator}
                                                collapseSubTask={this.collapseSubTask}
                                                deleteSubTask={this.deleteSubTask}
                                                selectedUser={this.selectedUser}
                                                handleTechChange={this.handleTechChange}
                                                getTechNameOptions={this.getTechNameOptions}
                                                onChangeTaskForm={this.onChangeTaskForm}
                                                addActionData={this.addActionData}
                                                deleteActionData={this.deleteActionData}
                                                deleteNewSubTask={this.deleteNewSubTask}
                                                selectedJob={this.state.selectedJob}
                                                actionNames={this.actionNames}
                                                convertDateToUTC={this.convertDateToUTC}
                                                attachFile={this.attachFile}
                                                detachFiles={this.detachFiles}
                                                customer={customer}
                                                assetDetails={this.state.assetDetails}
                                                isSmallFormFactor={this.props.isSmallFormFactor} //TP-2947
                                                action={action}//TP-3490
                                                onChangeSubtask={this.onChangeSubtask}//TP-4607
                                            />
                                        // :
                                        //     <InspectionSubTask 
                                        //         subTasks={subTasks} 
                                        //         selectedSubTask = {subTasks[index]}
                                        //         idx={index}
                                        //         onChangeJobForm={this.onChangeJobForm}
                                        //         onSubTaskStartDateChange={this.onSubTaskStartDateChange}
                                        //         onSubTaskEndDateChange={this.onSubTaskEndDateChange}
                                        //         getRooms={this.getRooms}
                                        //         getDisciplines={this.getDisciplines}
                                        //         handleOptionChange={this.handleOptionChange}
                                        //         getSubTaskActionNameOptions={this.getSubTaskActionNameOptions}
                                        //         handleActionChange={this.handleActionChange}
                                        //         validator={this.validator}
                                        //         collapseSubTask={this.collapseSubTask}
                                        //         deleteSubTask={this.deleteSubTask}
                                        //         selectedUser={this.selectedUser}
                                        //         handleTechChange={this.handleTechChange}
                                        //         getTechNameOptions={this.getTechNameOptions}
                                        //         onChangeTaskForm={this.onChangeTaskForm}
                                        //         addActionData={this.addActionData}
                                        //         deleteActionData={this.deleteActionData}
                                        //         deleteNewSubTask={this.deleteNewSubTask}
                                        //         selectedJob={this.state.selectedJob}
                                        //         actionNames={this.actionNames}
                                        //         convertDateToUTC={this.convertDateToUTC}
                                        //         attachFile={this.attachFile}
                                        //         detachFiles={this.detachFiles}
                                        //         customer={customer}
                                        //         assetDetails={this.state.assetDetails}
                                        //         isSmallFormFactor={this.props.isSmallFormFactor} //TP-2947
                                        //         action={action}//TP-3490
                                        //         onChangeSubtask={this.onChangeSubtask}//TP-4607
                                        //     />
                                    }
                                </div>
                            </div>
                            :
                            null
                        }
                    </div>
                <Loader text={this.state.loadingText} isLoading={this.state.isJobLoading} />
                <FlashMessage 
                    flashMsgText={this.state.flashMsgText} 
                    showMessage={this.state.showFlashMessage} 
                    flashLeft={this.state.flashLeft}
                    flashTop={this.state.flashTop}
                    updateShowMessage={this.updateShowMessage}
                    isTimerEnabled={true} /**TP-5986*/
                ></FlashMessage>
                </>
	   		);
		}
    }    
}

export default CloneJobForms;