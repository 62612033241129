export const diagonastics = (authService,data) => {
    /* let fetchString = 'logDiagnostics/';
    authService.fetch(fetchString, {
        method: 'post',
        body: JSON.stringify({
            data
        })
    })
    .then(response => response.json())
    .then(data => {
        // console.info(data)
        return data;
    })
    .catch(err => console.error(err.message)) */
    return;
}

export const diagnostics = (authService, data) => {
    let fetchString = 'logDiagnostics/';
    authService.fetch(fetchString, {
        method: 'post',
        body: JSON.stringify({
            data
        })
    })
    .then(response => response.json())
    .then(data => {
        // console.info(data)
        return data;
    })
    .catch(err => console.error(err.message))
}

//export default diagonastics;
