import React from 'react';
import './session.css';
import './SSLoader.scss'; /**TP-4909*/
import { printConsole } from '../helpers';
import WebrtcSessionUser from './WebrtcSessionUser';
import {diagnostics} from "../DiagonasticHelper";
import { I18n, i18nMark } from "@lingui/react";
import { t, Trans } from '@lingui/macro';
import WebrtcVideoTile from './WebrtcVideoTile';
import WebrtcSideBar from './WebrtcSideBar';
import StreamActions from '../StreamActions/StreamActions';
import { UncontrolledTooltip, Modal, ModalHeader, ModalBody, ModalFooter, Button, Spinner, } from 'reactstrap';
import WebrtcErrorMessage from './WebrtcErrorMessage';
import SessionChat from './SessionChat';
//import NewChat from '../NewChat/NewChat';
import FlashMessage from '../FlashMessage/FlashMessage';
import RangeSlider from '../RangeSlider/RangeSlider';
import SessionTimer from './SessionTimer';
import RecordRTC from 'recordrtc';
import html2canvas from 'html2canvas';
import SessionIcon from './SessionIcon.jsx';
import BubbleElement from '../BubbleElement/BubbleElement'; //TP-3423

let webRtcExpObj = null;
let base64image = "";
let frameNo = 0;
let clipNo = 0;
let audioNo = 0;
let ackClipNo = 0;
let ackFrameNo = 0;
let errAckFrameNo = 0;
let errAudioAckNo = 0;
let count = 0;
let isrecording_active = false; //TP-740
let isSilentRecording = false; //TP-740
let turn_off_frames_signal = false; //TP-2041
let framesPreviousState = 'ok'; //TP-2215
let audioPreviousState = 'ok'; //TP-2215
let restartRecordNo = 0; //TP-4013
let tim_index = 0;
//const fileDialog = require('file-dialog');

const screenSettings = [
    { constraints: {width: 1920 , height: 1080, frameRate: 1}, fps: 1 , timer: 1000, waterMarkFont: "15px" }, //Very High
    { constraints: {width: 1280 , height: 720, frameRate: 1}, fps: 1 , timer: 1000, waterMarkFont: "10px" }, //High
    { constraints: {width: 960, height: 540, frameRate: 6}, fps: 6, timer: 166, waterMarkFont: "10px" }, //Medium
    { constraints: {width: 640, height: 360, frameRate: 10}, fps: 10, timer: 100, waterMarkFont: "5px"}, //Low
]

// function to convert uploaded filebuffer to Base64 URL format
// Uint8array format
function arrayBufferToBase64( buffer ) {
    var binary = '';
    var bytes = new Uint8Array( buffer );
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode( bytes[ i ] );
    }
    return window.btoa( binary );
}

//TP-4822
function processTooltip (status, i18n){
    switch (status) {
        case "session.pausedVideo":
            return i18n._(t`session.pausedVideo`);
        case "session.pausedVideoDefault":
            return i18n._(t`session.pausedVideoDefault`);        
        default:
            break;
    }
}

class WebrtcExpert extends WebrtcSessionUser {
    constructor (props) {
        super(props);
        this.state = {
            ...this.state,
            participants: [],
            otherParticipants: [],
            maxDivParticipant: '',
            startSession: false,
            sessionCmd: this.props.sessionCmd,
            mutedAll: false,
            disabledMutedAll: false, //T32-413
            headerMuteMike: false,
            maxView: false,
            maxDivMikeMuted: false,
            maxDivSpeakerMuted: false,
            maxDivSpeakerDisabled: false, //T32-413
            openMikeDialog: false,
            maxDivIconClicked: false,
            disableGridView: false,
            showHeaderActionButtons: false,
            hideExpertSSAll: true,
            bHideExpertSSButton: true,
            bHideTechnicianSSButton: true,
            bHideZoomButton: true,
            bHideTorchButton: true,
            maxDivParticipantEmail: '',
            startRecording: false,
            showRecordingBeacon: false,
            swapTechCamera: false,
            confirmSwapCamera: false,
            swapTechDetails: '',
            swapCamTechData: '',
            maxDivCameraCount: 1,
            maxDivTechType: "WEB",
            changeHandIcon: false,
            redrawFileShare: false, //TP-1118
            showControlRequestDialog: false, //TP-1199
            changeScreenShareIcon: false, //TP-1042
            audioDataPayload: null,
            redrawGrid: false,            
            stopState: '', //TP-740
            framesErrorTimeout: 0, //TP-1966
            clickHandSignal: false, //TP-1313
            guestUserList: [], //TP-2365
            zoomClass: "oneX", //TP-2933            
            triggerMyMike: false, //TP-3573
            triggerMySpeaker: false, //TP-3573
            gridHandIconHide: false, //TP-3904
            screenCastStarted: false, //TP-2971
            isSilhouetteView: false, //TP-4797
            isAnnotateWExpertSS: false, //TP-4669
            isSSOngoing: false //TP-5397
        };
        this.localVideoRef = React.createRef();
        this.maxVideoRef = React.createRef();
        webRtcExpObj = this;
    }

    componentDidMount() {
        //console.log("account level Expert video setting ?? ", this.props.enable_user_video, " user preference ?? ",this.props.user.my_camera_on);
        const hideButton = this.getDeviceClassname();
        this.setState({hideButton}, () => {
            //TP-2933
            if (this.state.hideButton === "d-none"){
                this.setZoomClass();
            }
        });
        //if (this.state.showMaxDivControls && this.state.currentPrimaryExpert === this.state.user) {
            // TP-991, FQ3-318,295
            if (typeof(Storage) !== "undefined") {
                printConsole(`setting the group id in session storage =======================================>${this.props.selectedGroupId}`)
                sessionStorage.setItem("groupId", this.props.selectedGroupId); sessionStorage.setItem("isonprem", true);
            } else {
                printConsole(`Sorry, your browser does not support Web Storage...`)
            }
        //}
        printConsole(this.props.onlineUsers);
        printConsole(this.props.groupUsers)        
        if (this.props.groupUsers !== undefined)
            this.setOnlineUsers();
        this.sessionSignal.listener('end-group-call', this.subscribeToGlobalEndCall); //TP-2131
        this.sessionSignal.listenerWebsocket(this.subscribeToGrpSignal);
        this.sessionSignal.listener('update-user-list', this.updateUserList);
        this.sessionSignal.listener('new-ice-candidate', this.onRecieveIceCandidate);
        this.sessionSignal.listener('end-call', this.userLeft);
        this.sessionSignal.listener('answer-user', this.callAnswered);
        this.sessionSignal.listener('call-user', this.incomingCall);
        this.sessionSignal.listenerUserinsession(this.subscribeToUserIsInSession);
        this.sessionSignal.listener('fetch-missed-call-notification', this.subscribeToMissedCallNotificationAlert);//TZ-487
        this.initMedia();
        this.onCommandChange();
        this.calVdoHeightWidth();
        this.initprimaryExpertCheck();//Mb2-588
        window.addEventListener("resize", this.updateExpertScreenResize);
        this.sessionSignal.listener('new-image-upload', this.subscribeToImageUpload);//TP-3483
        //this.props.subscribeToNewImageUploadNotification();
        this.sessionSignal.listener('isOnline', this.subscribeToOnlineNotification);
        this.sessionSignal.listener('isOffline',  this.checkUserOffline); //MB2-661
        this.sessionSignal.listener('respond-to-the-call', this.subscribeToRespondToTheCall);
        this.sessionSignal.listenerIsOfflineNotification(this.subscribeToOffline);//MB2-441
    }

    componentWillUnmount = () => {
        if(this.volumeInterval !== null) {
            clearTimeout(this.volumeInterval); //TP-6208
            this.volumeInterval = null;
        }
        if(this.volumeInterval2 !== null) {
            clearTimeout(this.volumeInterval2); //TP-6208
            this.volumeInterval2 = null;
        }
        this.sessionSignal.removeListener('end-group-call',this.subscribeToGlobalEndCall); //TP-2131
        this.sessionSignal.removeWebsocket(this.subscribeToGrpSignal);
        this.sessionSignal.removeListener('update-user-list', this.updateUserList);
        this.sessionSignal.removeListener('new-ice-candidate', this.onRecieveIceCandidate);
        this.sessionSignal.removeListener('end-call', this.userLeft);
        this.sessionSignal.removeListener('answer-user', this.callAnswered);
        this.sessionSignal.removeListener('call-user', this.incomingCall);
        this.sessionSignal.removeListener('new-image-upload', this.subscribeToImageUpload); //TP-3483
        this.sessionSignal.removeUserinsession(this.subscribeToUserIsInSession);
        this.stopprimaryExpertCheck();//Mb2-588
        window.removeEventListener("resize", this.updateExpertScreenResize);
        this.sessionSignal.removeListener('isOnline', this.subscribeToOnlineNotification);
        this.sessionSignal.removeListener('isOffline', this.checkUserOffline); //MB2-661
        this.sessionSignal.removeListener('respond-to-the-call', this.subscribeToRespondToTheCall);
        this.sessionSignal.removeIsOfflineNotification(this.subscribeToOffline);//MB2-441
        this.sessionSignal.removeListener('fetch-missed-call-notification', this.subscribeToMissedCallNotificationAlert);//TZ-487
        //TP-4637 --  handling the mouse move event listener when the Expert unmounts
        const fileElem = document.getElementById('file-viewer');
        if (fileElem && fileElem !==null ){
            fileElem.removeEventListener('mousemove', this.maximizeOpacityPrimary);
        }
        clearTimeout(this.timeOut);//MB2-441
        clearTimeout(this.ssTimer);
        if (this.recStopInterval) clearInterval(this.recStopInterval);
        if (this.frameThread) clearInterval(this.frameThread);
        //TP-2521 -- Use case 2
        if (this.screen && [this.screen]) {
            [this.screen].forEach(function (stream) {
                stream.getTracks().forEach(function (track) {
                    track.stop();
                });
            });
        }
        const canvasArr = document.querySelectorAll('[id=fake]');
        if (canvasArr !== undefined && canvasArr.length > 0) {
            canvasArr.forEach(canvas => {
                canvas.parentNode.removeChild(canvas);
            })
        }
        if (this.recorder && this.recorder[clipNo] && this.recorder[clipNo] !== null) {
            this.recorder[clipNo].onstop = () => {
                //clipNo += 1;
                printConsole(`close the recording file. Last chunk of audio file creating: ${Math.ceil((Date.now() - this.state.recFileName) / 1000)} seconds`);
                this.recorder[clipNo] = null;
                
                
                    printConsole(`Total number of frames = ${Math.ceil((Date.now() - this.state.recFileName) / screenSettings[2].timer)}`);
                    printConsole(`Frames sent to the server side ${count}`);

                    // if instead of clicking on the icon user clicks the popup the state will
                    // change from here
                    if (this.state.startRecording) {
                            isrecording_active = false; 
                            isSilentRecording = false;
                            // Turn on the frames sent to server
                            turn_off_frames_signal = false;
                            clearInterval(this.recStopInterval);
                            clearInterval(this.frameThread);
                            this.recorder[clipNo] = null;
                    } else {
                            isrecording_active = false;
                            isSilentRecording = false;
                            // Turn on the frames sent to server
                            turn_off_frames_signal = false;
                    }
                    this.setState({ showRecordingBeacon: false })// hide the beacon
                    count = 0;
                    printConsole(`Final Audio Clip # ${clipNo}`);                        
                    printConsole(`Converting audio blob to m4a file: ${performance.now()}`)
                    const blob = new Blob(this.chunks, {
                        type: 'audio/m4a'
                    })
                    printConsole(`Finished converting blob to m4a file: ${performance.now()}`);
    
                    let reader = new FileReader();
                    printConsole(`reading the mp4 file as arrayBuffer: ${performance.now()}`)
                    reader.readAsArrayBuffer(blob); // converts the blob to base64 and calls onload
    
                    reader.onload = function() {
                        var srcData = reader.result;
                        // use byteLength to check the size
                        const bytes = srcData.byteLength;
                        printConsole(`byte size of Audio file: ${bytes}`);                            
                        printConsole(`sending the audio clip data No. ${audioNo} as socket signal payload: ${performance.now()}`);
                        // Sending the audio clips as Socket Signal payload to the Server-side
                        webRtcExpObj.sendSignalRecordingUploadAudio(srcData, webRtcExpObj.callBackForFinalAudioRecording);
                        /* if(typeof(Storage) !== "undefined"){
                            printConsole("Clearing the session storage");
                            ['chunk_data', 'folder_name', 'user_id', 'group_id', 'sequence_id'].forEach(key =>  sessionStorage.removeItem(key));
                        }else{
                            printConsole(`Sorry, your browser does not support Web Storage...`)
                        } */
                            
                        clipNo = 0;
                        audioNo = 0;

                        webRtcExpObj.notifyEndCallGroup(); //TP-2209
                    };
            }
            this.recorder[clipNo].stop(); //Stop the recording when user abruptly ends the session
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevState.sessionCmd !== this.state.sessionCmd){
            this.onCommandChange();
        }
        else if(this.props.isImageUploaded !== prevProps.isImageUploaded && this.props.isImageUploaded === true && prevProps.userPictures && this.props.userPictures !== undefined) {            
            //TP-3483 & TP-3468
            this.state.participants && this.state.participants.length > 0 === true && this.updateJoinees({other_participants: this.state.participants});
            this.props.onlineUsers && this.props.onlineUsers.length > 0 && this.props.groupUsers && this.props.groupUsers.length > 0 && this.setOnlineUsers();
        }
        if(prevProps.sendExpertSS !== this.props.sendExpertSS && this.props.sendExpertSS === true && this.props.sSParticipant !== null) {
            //console.log("Participant details ---->", this.props.sSParticipant);
            //TP-5499
            this.expertWaitTimer = setTimeout(() => { 
                this.switchOneVideoStream({flag: true, stream: this.state.screenShareStream, partcipant: this.props.sSParticipant});
                clearTimeout(this.expertWaitTimer);
            }, 10000);            
        }
        if (prevProps.enableGridView !== this.props.enableGridView && this.props.enableGridView === true) {
            this.maxDivActionGoingOn({flag: false}); // enable the Side Bar Grid View Icon
        }
        /* if (this.props.isHeaderFileShare !== prevProps.isHeaderFileShare) {
            this.maxDivActionGoingOn({flag: this.props.isHeaderFileShare});
        } */
        if (this.state.startRecording === true && prevState.participants.length < this.state.participants.length ) {
            //this.stopAndContinueRecording(); // stop the old recording and start a new audio recording when new users join the session
        }
        if (this.state.startRecording === true && isSilentRecording === false && this.props.setStartAssetsShare !== prevProps.setStartAssetsShare) {
            if (this.props.setStartAssetsShare === true && !this.props.isSafari && !this.props.isFirefox && this.state.hideButton !== 'd-none') {//	TP-2262
                this.handlePauseRecordingDuringAnyFeature();
            } else if (this.props.setStartAssetsShare === false && !this.props.isSafari && !this.props.isFirefox && this.state.hideButton !== 'd-none') {//	TP-2262
                this.handleResumeRecordingAfterPause();
            }
        }
        //TP-3573
        if(this.state.localStream !== prevState.localStream && this.state.localStream !== '' && this.state.participants.length > 0 && this.props.session_type === "RM" && this.checkIfExpertCameraEnabled() === true){
            this.getUpdatedParticipantswithPreview();
        } else if (this.state.participants.length !== prevState.participants.length && this.state.participants.length > 0 && this.state.localStream !== '' && this.props.session_type === "RM" && (this.checkIfExpertCameraEnabled() === true || this.isExpertVideo() === true)){
            this.getUpdatedParticipantswithPreview();
        }
        //TP-5387 -- Profile picture updating implemented on Expert side
        if (this.props.userPictures && this.props.userPictures !== prevProps.userPictures && this.props.isGuest && this.props.userPictures.length > 0 && this.state.participants.length > 0){
            printConsole("In Guest user Participants image changed");
            this.updateParticipantsImages()
        }
    }

    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.sessionCmd!==prevState.sessionCmd){
            return { sessionCmd: nextProps.sessionCmd };
        }else{
            return null;
        }
    }

    onCommandChange = () => {
        let {sessionCmd, startSession} = this.state;
        let updatedStateVar = {};
        printConsole(`....session cmd expert...., ${sessionCmd}`)
        switch (sessionCmd) {
            case 'session_started':
                updatedStateVar.startSession = !startSession ;
                break;
            case 'session_stopped':
                updatedStateVar.startSession = !startSession ;
                this.hangUp();
                break;
            default:
                break;                
        }
        this.setState((prev) => ({
            ...prev,
            ...updatedStateVar
        }));
    }

    // set the max div action indicator to true/false
    maxDivActionGoingOn = ({flag}) => {
        //console.log('maxDivActionGoingOn is called with flag value', flag);
        const {user, groupInfo} = this.state;
        this.setState({maxDivIconClicked: flag});//MB2-491,739
        //TP-3156 & TP-3119
        this.setState({ disableGridView: (groupInfo.session_type === "CM" || (groupInfo.session_type === "RM" && this.checkIfExpertCameraEnabled() === true /**TP-3419*/)) ? this.state.participants.length >= 1 ? false : true : this.state.participants.length > 1 ? false : true })
        /* if (groupInfo.session_type === "CM" || (groupInfo.session_type === "RM" && this.checkIfExpertCameraEnabled() === true)) {
            if (this.state.userVideoCount === 1) this.setState({ disableGridView: true });
        } else {
            if (this.state.technicianCount === 1 || this.state.userVideoCount === 1) this.setState({ disableGridView: true });
        } */
    }

    // close the chat MB2-471
    closeChat = () => {
        this.setState({bMaxChat: false, bAllChat: false});
    }

    //TP-4770
    updateExpertScreenResize = () => {
        //const canvas = document.getElementById('canvas');
        if (/* !(canvas && this.state.showMaxDivControls) && */ this.state.maxView) {
            this.calVdoHeightWidth(this.state.aspectRatio); //TP-4666 FQ3-334
        } else {
            //this.onClickMultiGridView();
            printConsole("redraw the Grid View")
            if (!this.props.is3DFile && (this.props.isOneToOneFileShare || this.props.isHeaderFileShare)) {
                printConsole("set the redrawFileShare state to true");
                this.setState({ redrawFileShare: true }, () => {
                    this.setState({ redrawFileShare: false });
                })
            } 
            //TP-4936
            this.setState({ redrawGrid: true }, () => {
                this.setState({ redrawGrid: false });
            });
        }
        //TP-87
        if (this.state.bMaxChat || this.state.bAllChat) {
            if(this.state.bAllChat) {
                this.setState({ bAllChat: false }, () => {
                    this.setState({ bAllChat: true });
                })
            } else {
                this.setState({ bMaxChat: false }, () => {
                    this.setState({ bMaxChat: true });
                })
            }
        }
    }

    // invoked when other participant remote stream is available. Will be invoked for all other partcipants.
    // this get triggered two times for each participant. one video track and the other for audio track
    gotRemoteTrack = async ({track, streams: [stream]}) => {
        printConsole("get the remote tracks");
        printConsole(track);
        printConsole(stream);
        const { user } = this.state;
        const { enable_user_video } = this.props;//TP-3790
        if (stream === undefined) return;
        let [newParticipant, otherTechnicianIndex ]= await this.updateParticipantStream({track, stream})
        //TP-1730--To take care of when a user's Video Track is received by the expert in Grid View
        // trigger redrawing the Grid View UI for that expert
        if (newParticipant && !newParticipant.isexpert && track.kind === 'video' && this.state.maxView === false) {
            this.setState({ redrawGrid: true }, () => {
                this.setState({ redrawGrid: false });
            });
        } 
        if (newParticipant && track.kind === 'audio' && this.state.startRecording === true) {
            printConsole("stop the old recording and start a new audio recording when new users join the session"); //TP-6573
            this.stopAndContinueRecording() //TP-1881 stop the old recording and start a new audio recording when new users join the session
        } 
        //TP-5352
        if (newParticipant && !newParticipant.isexpert && track.kind === 'video') {
            if (this.state.showFlashMessage === true && this.state.flashMsgText === "Waiting for Technician users to join the session") {
                this.setState({ showFlashMessage: false });
            }
        }
        this.updateJoinees({other_participants: this.state.participants});
        let {participants, maxDivParticipant, maxDivParticipantEmail, technicianCount, userVideoCount} = this.state
        //TP-3893
        //console.log(otherTechnicianIndex);
        if (newParticipant && newParticipant.isexpert /* && track.kind === 'video' */ && otherTechnicianIndex === -1) {
            this.setState({ redrawGrid: true }, () => {
                //TP-4012
                //TP-4797
                this.props.setRemoteTrackLoader({flag : false});
                /**TZ-989 */
                if (enable_user_video !== "my_camera_disabled") {
                    const flashleft = window.innerWidth >= 1770 ? "4%" : window.innerWidth >= 1200 ? "5.5%" : "6%";
                    this.triggerMessage("Waiting for Technician users to join the session", "only-experts-remaining", flashleft, "3%");            
                }
                this.setState({ redrawGrid: false });
                //TP-3893
                if (enable_user_video !== "my_camera_disabled") {
                    this.setState({hideExpertSSAll: false})
                    //TP-5459
                    printConsole(`Total number of participants in session ${this.state.participants.length}`);
                    if (this.state.participants.length === 1) {
                        //console.log(maxDivParticipant.email, this.state.currentPrimaryExpert.email, this.state.showMaxDivControls);
                        //TP-6207
                        if(this.state.showMaxDivControls === false && this.state.currentPrimaryExpert.email !== this.state.user.email && maxDivParticipant !== null && maxDivParticipant.email === this.state.user.email) {
                            printConsole(" Do nothing in case the current expert user's Video/Audio tile is already in this Passive expert's Max-vew");
                            this.onClickMaximise({participant: maxDivParticipant, isSame: false})
                        } else 
                            this.onClickMaximise({participant: newParticipant, isSame: false}) //
                    } else 
                        this.onClickMultiGridView();
                }
                else {
                    //TP-5482 const [expert] = this.state.participants.filter(p => p.isexpert); //TP-5468 First expert in the expert list 
                    this.onMaximizeSilhouetteView({participant: newParticipant}) //TP-5468 TP-4797
                    this.setState({ hideExpertSSAll: false, gridHandIconHide: true });//TP-4436 & TP-3904
                }
            });
        } else if (participants.length > 1){
            this.props.setRemoteTrackLoader({flag : false});
            if (enable_user_video === "my_camera_disabled" && otherTechnicianIndex !== -1)
                this.setState({ gridHandIconHide: false }) //TP-5038
            printConsole("Max view participant");
            printConsole(maxDivParticipant);
            if (maxDivParticipant === ''){
                // this is an edge case where the EXPERT_CHANGE_MAX_DIV signal comes before the stream for the same tech reaches the passive expert MB2-608
                if(maxDivParticipantEmail === ''){
                    printConsole(`maxdiv tech email cannot be found +++++++++++++++++++++++++++++++++++++++++++`);
                    // for 2 or 3 expert and 1 technician the max view has to be enabled no matter when technician joins MB2-741
                    // if other technician is present and max div participant is blank means expert is in grid view
                    printConsole("the new participant ============>");
                    printConsole(newParticipant);
                    printConsole(`${otherTechnicianIndex}`);                    
                    printConsole(`technician count -----${technicianCount}`);
                    printConsole(`new Participant email: ${newParticipant.email}`); //TP-5217
                    if((newParticipant && newParticipant.isexpert ) || otherTechnicianIndex !== -1){
                        if (this.state.expertShareScreenMaxDiv){
                            //this.onClickMultiGridView(); 
                        }
                        else if (this.state.maxView || technicianCount === 0) {
                            //TP-5217
                            if (newParticipant.isexpert)
                                this.onClickMaximizeExpert({participant: newParticipant}) //if new participant is expert than no max view. This will be invoked twice for each participant
                            else
                                this.onClickMaximise({participant: newParticipant, isSame: false});
                        }
                        else 
                            this.onClickMultiGridView(); 
                        
                    }else{
                        if (this.state.maxView) {
                            printConsole("Already on Max-view mode");
                            this.onClickMaximise({participant: newParticipant, isSame: false})
                        }
                        else {
                            if(/*TP-3856*/(this.state.groupInfo.session_type === "RM" && enable_user_video === "my_camera_disabled") && newParticipant && !newParticipant.isexpert && technicianCount === 1) {
                                printConsole('only one maxdiv tech found+++++++++++++++++++++++++++++++++++++++++++++', newParticipant);
                                this.onClickMaximise({participant: newParticipant, isSame: false});
                            } else 
                                this.onClickMultiGridView(); 
                        }    
                    }
                }else{
                    const maxDivTech = participants.find((p) => p.email === maxDivParticipantEmail && !p.isexpert)//MB2-608
                    if(maxDivTech){
                        printConsole('maxdiv tech found+++++++++++++++++++++++++++++++++++++++++++++', maxDivParticipantEmail, maxDivTech);
                        this.onClickMaximise({participant: maxDivTech, isSame: false});
                    }else{
                        printConsole('maxdiv tech not found+++++++++++++++++++++++++++++++++++++++++');
                        this.onClickMultiGridView(); 
                    }                    
                }
            }else{
                if (maxDivParticipant.isexpert === true) {
                    printConsole("the Max-view participant is an expert");
                    if (this.state.expertShareScreenMaxDiv || this.state.isScreencasting /**TP-3955*/) {
                        //this.onClickMultiGridView();
                    } /* else if (this.state.groupInfo.session_type === "CM") {
                        //write code handler when CM mode is turned on
                    } */ else {
                        //TP-3790 & TP-3797
                        if (this.state.groupInfo.session_type === "CM" || (this.props.session_type === "RM" && this.checkIfExpertCameraEnabled() === true)) {
                            printConsole ("Draw some other expert in my Max-view during Video enabled state");
                            this.onClickMaximise({participant: maxDivParticipant, isSame: false})
                        } else {
                            const participant = participants.find((p) => p.isexpert === false)// first tech
                            if(participant) {
                                this.onClickMaximise({participant, isSame: false})
                            }else{
                                this.onClickMaximizeExpert({participant: maxDivParticipant})
                            }
                        }
                    }
                } else {
                    printConsole("Current Max-view participant is not an expert")
                    if (maxDivParticipant.disableVideo === true) {
                        // Max view technician Video is disabled so new technician should replace the Max-view
                        printConsole("max view video is turned off")
                        if(newParticipant && !newParticipant.isexpert && userVideoCount === 1) {
                            printConsole('only one maxdiv tech found+++++++++++++++++++++++++++++++++++++++++++++');
                            printConsole(newParticipant);
                            this.onClickMaximise({participant: newParticipant, isSame: false});
                            if (this.state.showHideDivIconAudioOnly === true) this.setState({ showHideDivIconAudioOnly: false, hideExpertSSAll: false })
                        } else if (newParticipant && userVideoCount === 0 && newParticipant.isexpert) {
                            printConsole('New expert is joining a session with all Technician\'s Videos turned off');
                            printConsole(maxDivParticipant);
                            this.onClickMaximise({participant: maxDivParticipant, isSame: true});
                        }else 
                            this.onClickMultiGridView();
                        
                        /* const participant = participants.find((p) => p.isexpert === false && p.disableVideo === false)// first Tech
                        if(participant) {
                            printConsole("new max view technician ---------------------");
                            printConsole(participant);
                            this.onClickMaximise({participant, isSame: false})
                        }else{
                            this.onClickMaximise({participant: maxDivParticipant, isSame: false});
                        } */
                    } else {
                        printConsole("The Expert is on Max-view Mode and new user joined the session");
                        if (this.state.maxDivIconClicked === true) {
                            printConsole("Max-view operations are ongoing so don't redraw the Expert UI");
                            if (this.state.showMaxDivControls && this.state.currentPrimaryExpert && this.state.currentPrimaryExpert.email === this.state.user.email)
                                //this.setState({disableGridView: this.state.participants.length > 1 ? false : true});
                                this.setState({disableGridView: (this.state.groupInfo.session_type === "CM" || (this.state.groupInfo.session_type === "RM" && this.checkIfExpertCameraEnabled() === true)) ? (this.state.participants.length >= 1) ? false : true : (this.state.participants.length > 1) ? false : true}); /**TP-3419 & TP-3119 */
                            //TP-5879
                            if (track.kind === 'audio')
                                this.appendTracksForMaxView({participant: newParticipant});
                            const otherParticipants = this.state.participants.filter((p) => p && (p.stream_id !== this.state.maxDivParticipant.stream_id))
                            this.assignSource({otherParticipants})// assign the source to video element of other participant
                        } else if (this.state.showMaxDivControls && this.state.currentPrimaryExpert && this.state.currentPrimaryExpert.email === this.state.user.email){
                            printConsole("set the GridView Icon based on # of users in session with video on");
                            if (newParticipant && newParticipant.isexpert && !this.state.showControlRequestDialog) {
                                printConsole("Expert user joined the session for Primary Expert");
                                this.onClickMultiGridView();
                                this.onClickMaximise({participant: maxDivParticipant, isSame: false})  
                                //this.setState({disableGridView: this.state.participants.length > 1 ? false : true});
                                this.setState({disableGridView: (this.state.groupInfo.session_type === "CM" || (this.state.groupInfo.session_type === "RM" && this.checkIfExpertCameraEnabled() === true)) ? (this.state.participants.length >= 1) ? false : true : this.state.disableGridView}); /**TP-3419 & TP-3119 */                            
                            } else {
                                if (newParticipant.disableVideo === false && !newParticipant.isexpert) this.setState({ showHideDivIconAudioOnly: false, hideExpertSSAll: false })
                                    this.setState({disableGridView: (this.state.groupInfo.session_type === "CM" || (this.state.groupInfo.session_type === "RM" && this.checkIfExpertCameraEnabled() === true)) ? (this.state.participants.length >= 1) ? false : true : (this.state.participants.length > 1) ? false : true}); /**TP-5217 & TP-3419 & TP-3119 */
                                    //this.setState({disableGridView: this.state.participants.length > 1 ? false : true});
                                //TP-5879
                                if (track.kind === 'audio')
                                    this.appendTracksForMaxView({participant: newParticipant});
                                const otherParticipants = this.state.participants.filter((p) => p && (p.stream_id !== this.state.maxDivParticipant.stream_id))
                                this.assignSource({otherParticipants})// assign the source to video element of other participant
                            }
                        } else if(!this.state.showMaxDivControls && newParticipant && newParticipant.isexpert && this.state.isScreencasting !== true /**TP-3955*/) {
                            printConsole("Expert user joined the session")
                            this.onClickMultiGridView();
                            let isSame = true;
                            //console.log(this.state.prevMaxDivPartcicipantEmail);
                            if (this.state.prevMaxDivPartcicipantEmail !== '' ) 
                                isSame = false;
                            this.onClickMaximise({participant: maxDivParticipant, isSame})
                        }
                    } 
                }
            }
        }else{
            if (participants.length > 0)
                this.props.setRemoteTrackLoader({flag : false});
            const participant = participants[0]// first tech/expert
            if (participant.isexpert){
                if (this.state.expertShareScreenMaxDiv) {
                    this.onClickMultiGridView();
                } else if (this.state.groupInfo.session_type === "CM" || (this.state.groupInfo.session_type === "RM" && this.checkIfExpertCameraEnabled() === true)) { /**TP-3419 */
                    //TP-3119
                    printConsole ("Draw the other expert in my Max-view");
                    this.onClickMaximise({participant, isSame: false});
                }
                else {
                    printConsole("Here.........");
                    this.onClickMaximizeExpert({participant})//Mb2-573
                }
            }else{
                this.onClickMaximise({participant, isSame: false});
            }
        }
        console.log("track type ====>",track.kind);
        console.log("is Primary expert?? " + this.state.showMaxDivControls+ " is Side bar SS ongoing? " + this.state.isSideBarSS +" newParticipant ------------->", newParticipant);
        if (this.state.showMaxDivControls && newParticipant && !newParticipant.isexpert && this.state.isSideBarSS && track.kind === "audio") {
            //console.log("Participant details ---->", newParticipant);
            console.log(this.waitTimer[tim_index]);
            this.waitTimer[tim_index] = setTimeout(() => { 
                console.log("Hey new user joined in Screencast mode..",newParticipant);
                this.switchOneVideoStream({flag: true, stream: this.state.screenShareStream, partcipant: newParticipant});
                clearTimeout(this.waitTimer[tim_index]);
                tim_index += 1;
            }, 5000);
        } else if (this.state.showMaxDivControls && newParticipant && !this.state.isMaxDivSS && !this.state.isSideBarSS && !newParticipant.isexpert) {
            // Only trigger the file share to the newly joined Technician if Side Bar File share is going on
            this.props.isHeaderFileShare && !this.props.isOneToOneFileShare && this.props.fileData && this.sessionSignal.sendSignalShareArtifacts(this.props.fileData, newParticipant.email) && printConsole(`sending artifacts signal to :: ${newParticipant.email}`);
            // If 3D file is shared then resend the last rotation change
            if (this.props.isHeaderFileShare && !this.props.isOneToOneFileShare && this.props.rotationArray !== null) {
                this.rotationTimer = setTimeout(() => {
                    this.sessionSignal.sendSignal3DRotation(this.props.rotationArray, newParticipant.email) && printConsole(`sending rotation signal to :: ${newParticipant.email}`);
                    clearTimeout(this.rotationTimer);
                }, 3000);
            } else if (this.props.isHeaderFileShare && !this.props.isOneToOneFileShare && this.props.opacityChanges) {
                //this.sessionSignal.sendSignalOpacity(this.props.opacityChanges, newParticipant.email); //TP-841
            }
        }
        if ((this.props.session_type === "CM" || (this.props.session_type === "RM" && this.checkIfExpertCameraEnabled() === true) || this.props.audio_mode === "tech2Tech") && this.state.showMaxDivControls && newParticipant && !newParticipant.isexpert) {
            //TP-3205 & TP-3119 -- Only trigger the Video turn off/on signal payload to the newly joining Technician Participant
            this.triggerVideoTurnOffOnStatusToUser(newParticipant.email);
        }
        //TP-4837 -- Only send this signal if the Expert Video setting (Account level) is not disabled
        /*TP-5310 if (this.props.session_type === "RM" && this.props.enable_user_video !== "my_camera_disabled" && newParticipant && !newParticipant.isexpert && track.kind == "audio") {
            this.sendUserInfoToUser({participant: newParticipant});
        } */
        //TP-3532 -- New Socket signal to let the newly joining user to know this expert user's Video feed is turned on or not
        if (this.props.session_type === "RM" && newParticipant && !newParticipant.isexpert) {
            this.triggerExpertVideoFeedEnabledToUser(newParticipant.email);
        } else if (!this.state.showMaxDivControls && this.props.session_type === "RM" && newParticipant && newParticipant.isexpert) {
            this.triggerExpertVideoFeedEnabledToUser(newParticipant.email); //TP-3532
        }
    };

    // call the user when they join the group call
    callUser = () => {
        const {participants, connections, user} = this.state;
        const peerEmails = connections.map(peer => peer.email);//find the emails for all the participants for whom peerconnection is already established
        printConsole(`the peer connection emails in call user ===============================> ${JSON.stringify(peerEmails)}`);
        participants.length > 0 && participants.forEach((participant) => {
            printConsole("Current Participant list");
            printConsole(participants);
            // check whether peer is already connected. Ideally this condition should always pass
            if(!peerEmails.includes(participant.email)){
                /* 1. If the current user is the group primary expert then he will send any offer to other participant depending on the time stamp
                2. if the current user has joined before the expert participant then he will not send any offer
                3. In multi expert scenario only the expert joining first will send invite to other experts who have joined later */
                printConsole(`checking the condition for sending the offer connection for ${participant.email}, ${this.state.user.joinTimeStamp < participant.joinTimeStamp}`)
                //printConsole(this.state.user.joinTimeStamp);
                //printConsole(participant.joinTimeStamp);
                printConsole(`isOngoingPeer: ${this.state.isOngoingPeer} and for user ${this.state.isOngoingPeerEmail}`);
                if((participant.isexpert && (this.state.user.id === this.state.groupInfo.primary_expert_id || (this.state.currentPrimaryExpert !== null && this.state.user === this.state.currentPrimaryExpert))) 
                    || (participant.isexpert && this.state.user.joinTimeStamp < participant.joinTimeStamp && participant.userId !== this.state.groupInfo.primary_expert_id)
                    || !participant.isexpert) {
                    printConsole(`trying to call user =======================>,${JSON.stringify(participant)}`)
                    this.setState({ isOngoingPeer: true, isOngoingPeerEmail: participant.email }, ()=> {
                        //TP-2618
                        let {localStream} = this.state;
                        //console.log(localStream.getVideoTracks());
                        const connection = this.createPeerConnection({to: participant.email, localStream});
                        connection.peerConnection.offerConnection();
                        this.startPeerConnectionTimer();//TP-1922
                    });
                }
            }
        })
    };

    // the recipient has responded with answer
    callAnswered = (data) => {
        let {connections, socketId, isOngoingPeer, isOngoingPeerEmail} = this.state;
        if(data.recipientSocketId && socketId !== data.recipientSocketId){
            return;
        }
        if (isOngoingPeer === true && isOngoingPeerEmail !== '' && data.from === isOngoingPeerEmail) {
            this.stopPeerConnectionCounter();
            this.setState({ isOngoingPeer: false, isOngoingPeerEmail: '' });
        }
        // update the stream id that has been sent in data
        this.updateParticipantStreamId({data})
        this.updateMediaStreamMetaData({data}); //TP-2539
        // find the exact peerconnection for the participant
        const connection = connections.find((connection) => connection.email === data.from);
        try{
            connection.peerConnection.connectionAnswer(data);
        }catch(e){
            printConsole('answer made error', e)
        }
    }

    //TP-2474 -- Handling of UI on Passive expert side when, Primary expert does Erase canvas feature
    triggerCanvasErase = ({from}) => {
        const {bAnnotate} = this.props;
        if (bAnnotate === true) {
            const expertName = from.firstName + " " + from.lastName;
            this.triggerMessage(expertName,"eraseCanvas");
        }
    }
    
    // TP-2349 -- set the guest user list on the Passive expert's
    setGuestUserList = ({ guestUserList }) => {
        this.setState({ guestUserList });
    }

    // TP-2349 -- Add user into the guest user list for Primary expert
    addGuestUserList = (val) => {
        this.setState({ guestUserList: [...this.state.guestUserList, val]}, () => {
            printConsole(`Add the user ${val} into the guest user list...`);
            const experts = this.state.participants.filter(p => p.isexpert);
            //Send the SYNC_EXPERTS Signal to all Passive experts when guest user list is updated
            experts && experts.forEach(e => {
                this.sessionSignal.sendSignalSyncExperts({
                    guestUserList: this.state.guestUserList,
                    reason: "guest_user_sync"},
                e.email)
            });
        });
    }
  
    // TP-2349 -- Remove user from the guest user list for Primary expert
    removeGuestUserList = (val) => {
        let {guestUserList} = this.state;
        if (guestUserList.indexOf(val) > -1) {
            printConsole(`Remove the email id ${val} from guest user list `);
            this.setState({ guestUser: '' });
            guestUserList = guestUserList.splice(guestUserList.indexOf(val), 1);
            this.setState({ guestUserList }, ()=> {
                const experts = this.state.participants.filter(p => p.isexpert);
                //Send the SYNC_EXPERTS Signal to all Passive experts when guest user list is updated
                experts && experts.forEach(e => {
                    this.sessionSignal.sendSignalSyncExperts({
                        guestUserList: this.state.guestUserList,
                        reason: "guest_user_sync"},
                    e.email);                    
                })
            });
        }
    }

    // change the classes to fit the single video. called from closeVideoCall
    resetLayout = ({participantLeft}) => {
        printConsole(participantLeft);
        const {enable_user_video} = this.props;//TP-3737
        let {participants, maxView, technicianCount, maxDivParticipant, user} = this.state;
        let participant;
        const list = this.state.participants.filter(p => p.disableVideo === false);
        this.setState({ userVideoCount: list.length }); //TP-1546
        // TP-2349 -- Handling the removal of the Guest user when it gets disconnected
        if (this.state.showMaxDivControls && this.state.guestUserList.length > 0 && this.state.guestUserList.indexOf(participantLeft.email) > -1) {
            printConsole("remove guest user from Primary expert's Guest user list");//TP-3075
            this.setState({ guestUser: participantLeft.email });
        }
        //TP-3893
        if (technicianCount === 0) {
            printConsole("Only experts left in current session...");
            //TP-3904 -- Handling the Use cases when Screen cast/File share is ongoing when last technician leaves the session            
            if (this.state.groupInfo.session_type !== "CM" && (this.state.groupInfo.session_type === "RM" && enable_user_video === "my_camera_disabled")){
                /**TP-5501 -- Use case 7 & 8 with TP-4933*/
                if (this.state.showMaxDivControls === true && this.props.bRemoteCapture === true){
                    printConsole("Reset the RC Max-view controls..");
                    this.setState({ triggerCloseRC: true }, () => {
                        this.setState({ triggerCloseRC: false });
                    })
                } else if (this.state.showMaxDivControls === true && this.props.bAnnotate === true) {
                    printConsole("Reset the Annotation Max-view controls..");
                    this.setState({ triggerCloseAnnotate: true }, () => {
                        this.setState({ triggerCloseAnnotate: false });
                    })                    
                }
                /* TP-5763 if (this.state.showMaxDivControls === true && this.props.isHeaderFileShare && this.state.currentPrimaryExpert.id === this.state.user.id) {
                    //If File share is ongoing and there are only experts remaining in the session then trigger closing of shared files
                    this.props.closeFileViewer();
                } TP-5763 else if (this.state.showMaxDivControls === false && this.props.isHeaderFileShare) {
                    //If File share is ongoing and there are only experts remaining in the session then trigger closing of shared files
                    this.props.closeFileViewer();
                } */
                if (this.state.showMaxDivControls === true && this.state.isMaxDivSS && this.state.currentPrimaryExpert.id === this.state.user.id) {
                    //TP-1364 -- If Screen share is ongoing and there are only experts remaining in the session then trigger closing of screen share
                    this.setState({ isMaxDivSS: false }, () => {
                        this.props.setSS({isSideBarSS: this.state.isSideBarSS, isMaxDivSS: this.state.isMaxDivSS});// set ss status in webrtc session
                        //TP-5501 -- Use case 8 (Point # 6)
                        this.state.participants.length > 0 && this.state.participants.forEach(part => {
                            this.sessionSignal.sendSignalStopExpertSS(part.email);
                        })
                    });
                } /* TP-5763 else if (this.state.showMaxDivControls === true && this.state.currentPrimaryExpert.id === this.state.user.id && this.state.isSideBarSS) {
                    //TP-957 -- If (Side bar) Screen share is ongoing and there are only experts remaining in the session then trigger closing of screen share
                    this.initSideBarScreenShare();
                }  */               
            } else if (this.state.showMaxDivControls === true && this.state.groupInfo.session_type === "RM" && this.checkIfExpertCameraEnabled() === false && this.isExpertVideo() === false /* && this.state.maxView === true */) {
                //TP-5501 TP-5489 -- Handled closing of RC/Annotate feature when the last technician leaves leaving only experts
                if (this.state.showMaxDivControls === true && this.props.bRemoteCapture === true){
                    printConsole("Reset the RC Max-view controls..");
                    this.setState({ triggerCloseRC: true }, () => {
                        this.setState({ triggerCloseRC: false });
                    })
                } else if (this.state.showMaxDivControls === true && this.props.bAnnotate === true) {
                    printConsole("Reset the Annotation Max-view controls..");
                    this.setState({ triggerCloseAnnotate: true }, () => {
                        this.setState({ triggerCloseAnnotate: false });
                    })                    
                }
                /* TP-5763 if (this.props.isHeaderFileShare && this.props.isOneToOneFileShare) {
                    //If File share is ongoing and there are only experts remaining in the session then trigger closing of shared files
                    this.props.closeFileViewer();
                } */
                if (this.state.isMaxDivSS) {
                    //If Max-view Screen share is ongoing and there are only experts remaining in the session then trigger closing of screen share
                    this.initMaxScreenShare()
                } else if (this.state.isSideBarSS) {
                    printConsole("If (Side bar) Screen share is ongoing and there are only experts remaining in the session then trigger closing of screen share");
                    //this.initSideBarScreenShare(); TP-5763
                    return;
                }                
            } else if (this.state.groupInfo.session_type === "RM" && enable_user_video !== "my_camera_disabled"/*TP-5501  && this.state.maxView === true  */) {
                //TP-5489 -- Handled closing of RC/Annotate feature when the last technician leaves leaving only experts
                if (this.state.showMaxDivControls === true && this.props.bRemoteCapture === true){
                    printConsole("Reset the RC Max-view controls..");
                    this.setState({ triggerCloseRC: true }, () => {
                        this.setState({ triggerCloseRC: false });
                    })
                } else if (this.state.showMaxDivControls === true && this.props.bAnnotate === true) {
                    printConsole("Reset the Annotation Max-view controls..");
                    this.setState({ triggerCloseAnnotate: true }, () => {
                        this.setState({ triggerCloseAnnotate: false });
                    })                    
                }
                //TP-5502
                if (this.state.showMaxDivControls === false && this.state.expertShareScreenMaxDiv && this.state.ssFrom !== '' && this.state.groupInfo.session_type === "RM" && (this.checkIfExpertCameraEnabled() === true || this.isExpertVideo() === true)) {
                    printConsole("Only experts remaining in current session where screenshare was ongoing")
                    return;
                }
            } else if (this.state.showMaxDivControls === false && this.state.expertShareScreenMaxDiv && this.state.ssFrom !== '' && enable_user_video !== "my_camera_disabled" && this.state.groupInfo.session_type === "RM" && (this.checkIfExpertCameraEnabled() === true || this.isExpertVideo() === true)) {
                printConsole("Only experts remaining in current session where screenshare was ongoing")
                return;
            }
            //TP-4797
            if (this.props.enable_user_video !== "my_camera_disabled") {
                //TP-5459
                printConsole(`Total number of participants in session ${this.state.participants.length}`);
                if (this.state.participants.length === 1) {
                    const expertParticipant = this.state.participants[0]
                    this.onClickMaximise({participant: expertParticipant, isSame: false}) //Only expert remaining in that session
                } else 
                    this.onClickMultiGridView(); //Else draw the Grid View
                /**TZ-989 */
                const flashleft = window.innerWidth >= 1770 ? "4%" : window.innerWidth >= 1200 ? "5.5%" : "6%";
                this.triggerMessage("Waiting for Technician users to join the session", "only-experts-remaining", flashleft, "3%");            
            } else {
                //TP-5763 -- Redraw the Primary expert's view at all times and Passive expert's view only when no Screencast ongoing
                if ((this.state.showMaxDivControls === true && this.state.currentPrimaryExpert.id === this.state.user.id) || (!this.state.expertShareScreenMaxDiv && this.state.ssFrom === '' && this.state.showMaxDivControls === false)) {
                    const [expert] = this.state.participants.filter(p => p.isexpert); //TP-5468 First expert in the expert list
                    //Show the Maximized Silhouette view during "my_camera_disabled" state and only experts remaining in call
                    this.onMaximizeSilhouetteView({participant: expert}); //TP-5468
                }
            }
            //TP-3893
            if (enable_user_video !== "my_camera_disabled")
                this.setState({hideExpertSSAll: false})
            else {
                //TP-3904 -- To handle disabling the ExpertSS & File share buttons on Side bar for Default state
                //Also to hide the Hand Icon during defaualt state for same use case
                this.setState({hideExpertSSAll: false, gridHandIconHide: true}); //TP-4436
            }
        }
        else if (participants.length === 1){
            printConsole("Only one user left in session");
            // only participant left then show max view
            // const participant = participants.find((p) => p.isexpert === false)// first tech
            participant = participants[0]
            //console.log("isMaxView ?? ",maxView);
            if (maxView === false && (this.state.groupInfo.session_type === "CM" || (this.state.groupInfo.session_type === "RM" && enable_user_video !== "my_camera_disabled"))) { /**TP-3419*/
                if (this.checkIfExpertCameraEnabled() === true || participant.isexpert) {
                    //TP-3737 
                    printConsole("not in Max-view hence drawing the Grid....");
                    this.onClickMultiGridView();
                } else {
                    //TP-4022
                    printConsole("Only one technician left in a session with My camera off..");
                    this.onClickMaximise({participant, isSame: false});
                    if (this.state.showHideDivIconAudioOnly === false && participant.disableVideo === true) this.setState({ showHideDivIconAudioOnly: true }) //TP-5483
                }
            }else if (participant.isexpert && this.state.groupInfo.session_type !== "CM" && (this.state.groupInfo.session_type === "RM" && enable_user_video === "my_camera_disabled")){
                //TP-3737 & TP-3119
                printConsole("Showing the new Maxview Expert ----------->", participant);
                this.setState({resetMax: true});
                this.onClickMaximizeExpert({participant});//MB2-573
                this.setState({resetMax: false});
                if (this.state.showMaxDivControls === true && this.props.isHeaderFileShare && this.state.currentPrimaryExpert.id === this.state.user.id) {
                    //If File share is ongoing and there are only experts remaining in the session then trigger closing of shared files
                    this.props.closeFileViewer();
                }
                if (this.state.showMaxDivControls === true && this.state.isMaxDivSS && this.state.currentPrimaryExpert.id === this.state.user.id) {
                    //TP-1364 -- If Screen share is ongoing and there are only experts remaining in the session then trigger closing of screen share
                    this.setState({ isMaxDivSS: false }, () => {
                        this.props.setSS({isSideBarSS: this.state.isSideBarSS, isMaxDivSS: this.state.isMaxDivSS});// set ss status in webrtc session
                    });
                } else if (this.state.showMaxDivControls === true && this.state.isSideBarSS && this.state.currentPrimaryExpert.id === this.state.user.id) {
                    //TP-957 -- If (Side bar) Screen share is ongoing and there are only experts remaining in the session then trigger closing of screen share
                    this.initSideBarScreenShare();
                }
            }else{
                printConsole(participants);
                printConsole(this.props.bAnnotate);
                printConsole(this.props.bRemoteCapture);
                const canvas = document.getElementById('canvas');
                if (this.props.bAnnotate || this.props.bRemoteCapture || canvas) {
                    //TP-4669 -- Not to go through the turning off of the Annotation canvas if Screencast mode is turned on in Passive expert
                    if (this.state.showMaxDivControls === false && this.state.expertShareScreenMaxDiv && this.state.ssFrom === participantLeft.email) 
                        this.setState({isAnnotateWExpertSS: true});
                    else {
                        printConsole("Annotation/RC Mode is ongoing....");
                        if (participantLeft.stream_id === this.maxVideoRef.current.id){
                            printConsole("Participant that left was the Maxview Participant")
                            this.setState({resetMax: true}); //TP-5501
                            printConsole("Showing the new Maxdiv Participant ------------>", participant);
                            if (participant.disableVideo === true) this.setState({ showHideDivIconAudioOnly: true, hideExpertSSAll: false }) //TP-4436
                            this.onClickMaximise({participant, isSame: false});
                            //TP-5501
                            if (!this.state.isMaxDivSS) {
                                //TP-4642 -- Reset the Max-view of primary expert only when Max-view Screencast is not ongoing
                                this.setState({resetMax: false}, () => {
                                    this.setState({resetMax: true}, () => {
                                        this.setState({resetMax: false});
                                    });
                                });
                            }
                        }
                    }
                } else {
                    if (participantLeft.isexpert && participants.length === 1 /**TP-6044&& (this.state.currentPrimaryExpert === null || participantLeft.email === currentPrimaryExpert.email) TP-5980*/ ) {
                        printConsole("Primary expert has left the session");
                        printConsole(`Showing the old Max-view Participant on the Max-view now`);
                        if (participant.disableVideo === true) this.setState({ showHideDivIconAudioOnly: true, hideExpertSSAll: false }) //TP-4436
                        //TP-5980
                        if (this.state.currentPrimaryExpert === undefined || this.state.currentPrimaryExpert === null || participantLeft.email === this.state.currentPrimaryExpert.email)
                            this.onClickMaximise({participant, isSame: true});
                        else
                            this.onClickMaximise({participant, isSame: false});
                    } else {
                        if ((maxDivParticipant === '' || maxDivParticipant === null) && !this.state.maxView) {
                            this.onClickMaximise({participant, isSame: false});
                        } else if (this.state.maxView) {
                            if (participantLeft !== maxDivParticipant && (this.props.isOneToOneFileShare || this.props.isHeaderFileShare)) 
                                printConsole("File share is ongoing..")
                            else if (this.props.isHeaderFileShare && !this.props.isOneToOneFileShare && participantLeft === maxDivParticipant) {
                                printConsole("Side Bar File share ongoing");
                                printConsole(`Showing the new Maxview Technician -----------> ${JSON.stringify(participant)}` );
                                if (participant.disableVideo === true) this.setState({ showHideDivIconAudioOnly: true, hideExpertSSAll: false }) //TP-4436
                                this.onClickMaximise({participant, isSame: false});                                
                            }
                            else if (participantLeft !== maxDivParticipant && this.state.maxDivIconClicked) {
                                printConsole("Non Max-view technician left the session and Max-view feature ongoing");
                            } else {
                                printConsole("Showing the new Maxview Technician ----------->", participant);
                                if (participant.disableVideo === true) this.setState({ showHideDivIconAudioOnly: true, hideExpertSSAll: false }) //TP-4436
                                this.onClickMaximise({participant, isSame: false});
                            }
                        }
                    }
                }
            }
        }else if(participants.length > 1 && maxView === true && participantLeft && this.maxVideoRef.current && participantLeft.stream_id === this.maxVideoRef.current.id){
            //participants are leaving when max view is on then check who left. If the max view participant then replace the max view participant
            printConsole("Participant that left was the Maxview Participant")
            participant = participants.find((p) => p.isexpert === false && p.disableVideo === false)// TP-1596 first tech with Video turned on
            if(this.state.showMaxDivControls === true) {
                //TP-4603 -- For Passive expert if Max-view file share is open but not Side Bar File share
                //and the Max-view user leaves session, then close the file viewer window by sending new SYNC_EXPERT signal
                const experts = this.state.participants.filter(p => p.isexpert);
                experts && experts.forEach(e => {
                    this.sessionSignal.sendSignalSyncExperts({
                        isMaxViewFileClose: this.props.isOneToOneFileShare === true ? true : false, //TP-4550
                        max_view_technician: participantLeft.email,
                        reason: "max_view_fileviewer_sync"}, 
                    e.email)&& printConsole (`Sending the SYNC_EXPERTS signal to all Passive experts with reason = max_view_fileviewer_sync`);
                })
            }
            //printConsole(participants);
            if(participant) {
                printConsole("jkjkjkjkShowing the new Maxdiv Participant ------------>", participant);
                if (participant.disableVideo === true) this.setState({ showHideDivIconAudioOnly: true, hideExpertSSAll: false }) //TP-4436
                // TP-2443
                this.setState({resetMax: true});
                this.onClickMaximise({participant, isSame: false})
                if (!this.state.isMaxDivSS) {
                    //TP-4642 -- Reset the Max-view of primary expert only when Max-view Screencast is not ongoing
                    this.setState({resetMax: false}, () => {
                        this.setState({resetMax: true}, () => {
                            this.setState({resetMax: false});
                        });
                    });
                }
            }else{
                participant = participants.find((p) => p.isexpert === false)// TP-1596 first tech without Video turned on
                if (participant) {
                    printConsole("Showing the new Maxdiv Participant with video turned off------------>", participant);
                    if (participant.disableVideo === true) this.setState({ showHideDivIconAudioOnly: true, hideExpertSSAll: false }) //TP-4436
                    this.onClickMaximise({participant, isSame: false})
                    // TP-1549:Rs Added below condition to align 3D assets in center
                    const canvas3dDiv = document.getElementById('canavas-3d-container');
                    if(canvas3dDiv && canvas3dDiv.style){ 
                        const canvasParanetWidth = canvas3dDiv.parentElement.getBoundingClientRect().width;
                        const canvasWidth = canvas3dDiv.getBoundingClientRect().width;
                        const leftValue  =  Math.floor(((canvasParanetWidth - canvasWidth) / 2))
                        canvas3dDiv.style.left = leftValue+'px';
                    }
                    /*TP-6477 -- Commented out this piece of code, for Side Bar Screencast
                    if (participants.length > 1 && this.state.isSideBarSS && list.length === 0 && this.state.showMaxDivControls === true) {
                        //TP-2447 -- Stop the expertSS when userVideoCount is zero for Primary expert
                        this.initSideBarScreenShare(); //TP-4436
                    } */
                } else {
                    printConsole("is Expert screen share ongoing? ", this.state.expertShareScreenMaxDiv);
                    if (this.state.expertShareScreenMaxDiv) {
                        this.onClickMultiGridView();
                    } else {
                        printConsole("Showing the new Maxview Expert ----------->", participants[0]);
                        this.setState({resetMax: true});
                        this.onClickMaximizeExpert({participant : participants[0]});//multiple expert only left in the call, no technician
                        this.setState({resetMax: false});
                        if (this.state.showMaxDivControls === true && this.props.isHeaderFileShare && this.state.currentPrimaryExpert.id === this.state.user.id) {
                            //If File share is ongoing and there are only experts remaining in the session then trigger closing of shared files
                            this.props.closeFileViewer();
                        }
                        if (this.state.showMaxDivControls === true && this.state.isMaxDivSS && this.state.currentPrimaryExpert.id === this.state.user.id) {
                            //TP-1364 -- If Screen share is ongoing and there are only experts remaining in the session then trigger closing of screen share
                            this.setState({ isMaxDivSS: false }, () => {
                                this.props.setSS({isSideBarSS: this.state.isSideBarSS, isMaxDivSS: this.state.isMaxDivSS});// set ss status in webrtc session
                            });
                        } else if (this.state.showMaxDivControls === true && technicianCount === 0 && this.state.isSideBarSS && this.state.currentPrimaryExpert.id === this.state.user.id) {
                            //TP-957 -- If (Side bar) Screen share is ongoing and there are only experts remaining in the session then trigger closing of screen share
                            this.initSideBarScreenShare();
                        }
                    }
                }
            }
        } else if (participants.length > 1 && enable_user_video !== "my_camera_disabled" && maxView === false) { 
            //TP-3737
            printConsole("not in Max-view hence drawing the Grid....");
            this.onClickMultiGridView();
        } else if (participants.length > 1 && enable_user_video === "my_camera_disabled" && (technicianCount === 1 || list.length === 1) && maxView === false) {
            //TP-3737 -- participants are leaving when grid view is on then last 2nd last technician left.
            printConsole("Only one technician left on the session!!");
            participant = participants.find((p) => p.isexpert === false && p.disableVideo === false)//TP-1596 find all techs with Video turned on
            printConsole(participants);            
            if(participant) {
                printConsole("Showing the new Maxview Technician ----------->", participant);
                if (participant.disableVideo === true) this.setState({ showHideDivIconAudioOnly: true, hideExpertSSAll: false }) //TP-4436
                this.onClickMaximise({participant, isSame: false})
            }else{
                printConsole("is Expert screen share ongoing? ", this.state.expertShareScreenMaxDiv);
                if (this.state.expertShareScreenMaxDiv) {
                    this.onClickMultiGridView();
                } else {
                    printConsole("Showing the new Maxview Expert ----------->", participants[0]);
                    this.setState({resetMax: true});
                    this.onClickMaximizeExpert({participant : participants[0]});//multiple expert only left in the call, no technician
                    this.setState({resetMax: false});
                    if (this.state.showMaxDivControls === true && this.props.isHeaderFileShare && this.state.currentPrimaryExpert.id === this.state.user.id) {
                        //If File share is ongoing and there are only experts remaining in the session then trigger closing of shared files
                        this.props.closeFileViewer();
                    }
                }
            }
        } /* else if (participants.length > 1 && maxView === true && participantLeft.stream_id !== this.maxVideoRef.current.id &&  list.length === 0 && this.state.currentPrimaryExpert !== this.state.user) {
            //TP-2447 -- Hide the max-view controls when userVideoCount is zero for Passive expert
            this.setState({ showHideDivIconAudioOnly: true, hideExpertSSAll: false }) //TP-4436
        } */
        const {currentPrimaryExpert} = this.state;
        printConsole("None of the above is true.......");
        //TP-4522
        if(participantLeft && participantLeft.isexpert === true) this.checkPrimaryExpertPriviledge();
        if (participantLeft && participantLeft.isexpert && participants.length > 0 && this.state.showMaxDivControls && this.state.currentPrimaryExpert === this.state.user && currentPrimaryExpert === null) {
            //The primary expert has left the session making the current expert as Primary Expert
            printConsole("Primary expert has left the session making current expert as new Primary Expert")
            // TP-2349 -- Handling the removal of the Guest user when the user that left is Primary expert who gets disconnected
            if (this.state.guestUserList.length > 0 && this.state.guestUserList.indexOf(participantLeft.email) > -1) {
                this.setState({ guestUser: participantLeft.email });
            }
            //TP-1452 -- Unplanned transfer of Controls to Passive expert
            // While Screen cast is ongoing, change the View Mode to the last saved View Mode of the previous Primary expert
            if (this.state.expertShareScreenMaxDiv && this.state.ssFrom !== '') {
                this.setState({ isScreencasting: false }); //TP-5419
                if (this.state.isSSOngoing === true) this.setState({ isSSOngoing: false }); //TP-5679
                if (this.state.prevMaxDivPartcicipantEmail === '') {
                    /**TP-3953 -- Handling for when only one user left in session and Primary expert leaves in Grid View, while doing Screencast */
                    if (enable_user_video === "my_camera_disabled" || (participants.length > 1 && enable_user_video !== "my_camera_disabled")) {
                        printConsole("Screen share is ongoing so changing to Grid View Mode");
                        this.onClickMultiGridView();
                    } else {
                        printConsole("Only one participant left in session so....");
                        const [participant] = participants.filter((p) => p.isexpert === true)// find the first technician
                        printConsole(`New Max-view Participant ----> ${JSON.stringify(participant)}`)
                        //TP-2662 -- Use case 2
                        this.setState({resetMax: true}, () => {
                            this.setState({resetMax: false});
                            this.onClickMaximise({participant, isSame: false});
                        });
                    }
                } else {
                    printConsole("Screen share is ongoing so changing to Max View Mode");
                    const [participant] = participants.filter((p) => p.email === this.state.prevMaxDivPartcicipantEmail)// find the technician
                    printConsole(`New Max-view Participant ----> ${JSON.stringify(participant)}`)
                    //TP-4669 -- If the isAnnotateWExpertSS flag is turned off only then
                    if (this.state.isAnnotateWExpertSS === false) {
                        //TP-2662 -- Use case 2
                        this.setState({resetMax: true}, () => {
                            this.setState({resetMax: false});
                            //TP-5419
                            if (participant)
                                this.onClickMaximise({participant, isSame: false});
                            else {
                                const lastParticipant = this.state.participants[0] //last user
                                if (lastParticipant) {
                                    printConsole("now Showing Max-view View Mode with only remaining user's Tile");
                                    this.onClickMaximise({participant: lastParticipant, isSame: false});
                                }
                            }
                        });
                    } else {
                        //If the isAnnotateWExpertSS flag is turned on the reset it and maximize the div
                        this.setState({ isAnnotateWExpertSS: false });
                        //TP-5419
                        if (participant) {
                            this.onClickMaximise({participant, isSame: false});
                            if (this.state.showHideDivIconAudioOnly === true && participant.disableVideo === false) this.setState({ showHideDivIconAudioOnly: false/* , hideExpertSSAll: false */ }) //TP-5156
                            if (this.state.expertShareScreenMaxDiv && this.state.ssFrom !== '')
                                this.setState({ expertShareScreenMaxDiv: false, ssFrom: '' })
                        } else {
                            const lastParticipant = this.state.participants[0] //last user
                            if (lastParticipant) {
                                printConsole("Showing Max-view View Mode with only remaining user's Tile");
                                this.onClickMaximise({participant: lastParticipant, isSame: false});
                            }
                        }
                    }
                } 
            }
            else if (maxView === false) { 
                //TP-3832 -- Handling of drawing the new Primary expert's screen, after the Old primary expert left during Grid View Mode               
                if (this.state.groupInfo.session_type === "CM" || (this.state.groupInfo.session_type === "RM" && this.checkIfExpertCameraEnabled() === true)) {
                    printConsole("RM with video enabled state...");                    
                    if (this.state.participants.length >= 1) {
                        printConsole("Showing Grid View Mode");
                        this.onClickMultiGridView();   
                    } 
                    else {                        
                        const [participant] = this.state.participants.filter(p => p.isexpert === false) //first tech
                        if (participant){
                            printConsole("Showing Max-view View Mode with only remaining Technician's Video Tile");
                            this.onClickMaximise({participant, isSame: false});
                        } else {
                            const [expertParticipant] = this.state.participants.filter(p => p.isexpert === true) //first expert
                            if (expertParticipant) {
                                printConsole("Showing Max-view View Mode with only remaining expert's Tile");
                                this.onClickMaximise({participant: expertParticipant, isSame: false});
                            }
                        }
                    }                 
                } else if (enable_user_video !== "my_camera_disabled") {
                    printConsole("RM with preferences video disabled state.."); 
                    if (this.state.participants.length > 1) {
                        printConsole("Showing Grid View Mode");
                        this.onClickMultiGridView();
                    } 
                    else {                                          
                        const [participant] = this.state.participants.filter(p => p.isexpert === false) //first tech
                        if (participant){
                            printConsole("Showing Max-view View Mode with only remaining Technician's Video Tile");
                            this.onClickMaximise({participant, isSame: false});
                        } else {
                            const [expertParticipant] = this.state.participants.filter(p => p.isexpert === true) //first expert
                            if (expertParticipant) {
                                printConsole("Showing Max-view View Mode with only remaining expert's Tile");
                                this.onClickMaximise({participant: expertParticipant, isSame: false});
                            }
                        }
                    }
                } else {
                    printConsole("Default View Showing Grid View Mode");
                    this.onClickMultiGridView();
                }
            } 
            if (maxView === true) {
                printConsole(participants);
                printConsole(this.props.bAnnotate);
                printConsole(this.props.bRemoteCapture);
                const canvas = document.getElementById('canvas');
                if (this.state.maxDivParticipant && (this.props.bAnnotate || this.props.bRemoteCapture || canvas)) {
                    printConsole("Annotation/RC Mode is ongoing....");
                    this.props.stopPointerWhPassiveBecomesPrimary(); //TP-2824
                    //TP-2491
                    if (this.props.bRemoteCapture) {
                        //TP-6163
                        this.setState({ donotSendSyncExpert: true }, () => {
                            this.props.setAnnotateDuringPrimaryExpertLeaving(false);
                            this.annotateTimer = setTimeout(() => {
                                this.props.setAnnotateDuringPrimaryExpertLeaving(true);
                                this.setState({ donotSendSyncExpert: false });//TP-6163
                                clearTimeout(this.annotateTimer);
                            }, 1000);
                        });
                    }
                } else {
                    if(this.state.expertShareScreenMaxDiv) {
                        printConsole("sending the Stop Expert screen share signal");
                        this.sessionSignal.sendSignalStopExpertSS();
                        this.setState({ expertShareScreenMaxDiv: false, ssFrom: '' }, () => {
                            this.props.setMaxDivSS({ expertShareScreenMaxDiv: this.state.expertShareScreenMaxDiv });
                        }, () => {
                            //TP-4019 -- send the Primary expert's Camera view state (if turned off) to all Passive experts right after stopping ExpertSS
                            if (this.state.updatedParticipants && this.state.updatedParticipants.length > 0) {
                                const [me] = this.state.updatedParticipants.filter(up => up.email === this.props.user.email);
                                if (me /*TP-4493 && me.disableVideo === true */) {
                                    const experts = this.state.participants.filter(p => p.isexpert);
                                    this.expertVideoStateTimer = setTimeout(() => {
                                        experts && experts.forEach(e => {                                
                                            this.triggerExpertVideoFeedEnabledToUser(e.email); //TP-3532
                                        })
                                        clearTimeout(this.expertVideoStateTimer)
                                    }, 5000);
                                }
                            }
                            /* const participant = participants.find((p) => p.isexpert === false)// first tech
                            printConsole(participants);
                            if (participant) {
                                this.onClickMaximise({participant, isSame: false});
                            } */
                        });
                        
                    }
                    /* else if (this.state.maxDivParticipant) {
                        printConsole(`Showing the old max div technician--------------> ${this.state.maxDivParticipant}`);
                        this.setState({ hideExpertSSAll: (this.state.showHideDivIconAudioOnly === true) ? true : false})
                        this.onClickMaximise({participant: this.state.maxDivParticipant, isSame: false});
                    } */ else {
                        if(this.state.maxDivParticipant) {
                            printConsole(`Showing the old Max-view Participant on the Max-view now`);
                            const participant = participants.find(p => p.isexpert === false && this.state.maxDivParticipant.userId === p.userId);
                            if (participant) {
                                if (participant.disableVideo === true) this.setState({ showHideDivIconAudioOnly: true, hideExpertSSAll: false })//TP-4436
                                this.onClickMaximise({participant, isSame: true});
                            } else {
                                const participant = participants.find((p) => p.isexpert === false)// first tech
                                if(participant) {
                                    printConsole(`Max-div participant not there so showing the new Maxdiv Participant ------------> ${participant}`);
                                    if (participant.disableVideo === true) this.setState({ showHideDivIconAudioOnly: true, hideExpertSSAll: false }) //TP-4436
                                    this.onClickMaximise({participant, isSame: false})
                                }else{
                                    printConsole("Max-div participant not there so only experts remaining in session");
                                    this.setState({resetMax: true});
                                    /* printConsole("is Expert screen share ongoing? ", this.state.expertShareScreenMaxDiv);
                                    if (this.state.expertShareScreenMaxDiv) {
                                        this.onClickMultiGridView();
                                    } else { */
                                        printConsole(`Showing the new Maxview Expert ----------->`);
                                        printConsole(participants[0]);
                                        this.onClickMaximizeExpert({participant : participants[0]});//multiple expert only left in the call, no technician
                                    //}
                                    this.setState({resetMax: false});
                                }
                            }
                        } else {
                            participant = participants.find((p) => p.isexpert === false)// first tech
                            if(participant) {
                                printConsole(`Showing the new Maxdiv Participant ------------> ${participant}`);
                                if (participant.disableVideo === true) this.setState({ showHideDivIconAudioOnly: true, hideExpertSSAll: false }) //TP-4436
                                this.onClickMaximise({participant, isSame: false})
                            }else{
                                printConsole("Only experts remaining in session");
                                this.setState({resetMax: true});
                                /* printConsole("is Expert screen share ongoing? ", this.state.expertShareScreenMaxDiv);
                                if (this.state.expertShareScreenMaxDiv) {
                                    this.onClickMultiGridView();
                                } else { */
                                    printConsole(`Showing the new Maxview Expert ----------->`);
                                    printConsole(participants[0]);
                                    this.onClickMaximizeExpert({participant : participants[0]});//multiple expert only left in the call, no technician
                                //}
                                this.setState({resetMax: false});
                            }
                        }
                    }
                }                
            }
            //TP-740 -- Implementing the seamless continuation of Recording feature when un-planned Control tranfer happens
            if (isrecording_active === true && isSilentRecording === false && !this.props.isSafari && !this.props.isFirefox && this.state.hideButton !== 'd-none') { //	TP-2262
                this.sendSignalRecordingContinue(parseInt(participantLeft.userId));
                this.onClickRecordScreen("continue");
            } else if (isrecording_active === true && isSilentRecording === true){
                printConsole("Currently Silent recording isn't supported on Webapp Expert side");
                /* const experts = this.state.participants.filter(p => p.isexpert);
                this.sendSignalRecordingStop();
                //Send the SYNC_EXPERTS Signal to all Passive experts when silent recording is stopped
                experts && experts.forEach(e => {
                    this.sessionSignal.sendSignalSyncExperts({
                        isrecording_active: false,
                        is_silent_recording: isSilentRecording,
                        recording_folder_name: this.state.recFileName,
                        frames_per_seconds: screenSettings[2].fps,
                        frame_count: count,
                        reason: "recording_sync"},
                    e.email) && printConsole (`Sending the SYNC_EXPERTS signal to all Passive experts with recording_active = false`);
                })
                this.setState({ recFileName: '' }); */
            } else if (isrecording_active === true && isSilentRecording === false && (this.props.isSafari || this.props.isFirefox || this.state.hideButton === 'd-none')) {
                // TP-2262 -- Handling the stopping of Recording if Expert who takes up control is on Safari/Firefox browsers
                printConsole("Safari/Firefox browser doesn't support recording so stopping the previous recording")
                const experts = this.state.participants.filter(p => p.isexpert);
                this.sendSignalRecordingStop(null, participantLeft.email);
                //Send the SYNC_EXPERTS Signal to all Passive experts when silent recording is stopped
                experts && experts.forEach(e => {
                    this.sessionSignal.sendSignalSyncExperts({
                        isrecording_active: false,
                        is_silent_recording: isSilentRecording,
                        recording_folder_name: this.state.recFileName,
                        frames_per_seconds: screenSettings[2].fps,
                        frame_count: count,
                        reason: "recording_sync"},
                    e.email) && printConsole (`Sending the SYNC_EXPERTS signal to all Passive experts with recording_active = false`);
                })
                this.setState({ recFileName: '' });
            }
        }
    }

    // send the signal for stopping that participants session from Primary Expert
    onClickStopSessionUser = ({participant}) => {
        try {
            printConsole('this is the stop session for user======================>');
            printConsole(participant);
            if (participant && participant.streams && participant.streams.length>0) {
                this.sessionSignal.sendSignalStopSessionUser(participant.email);
            }
        } catch (error) {
            printConsole(error);
        }
    }

    // assign the participant to the max div
    onClickMaximise = ({participant, isSame}) => {
        try{
            const { user } = this.props;
            const { groupInfo, showHideDivIconAudioOnly /**TP-3701*/ } = this.state;
            const otherParticipants = this.state.participants.filter((p) => p && (p.stream_id !== participant.stream_id))
            printConsole('this is on click maximise============================================>');
            printConsole(participant)
            const {maxDivParticipant} = this.state;
            if (participant && participant.streams && participant.streams.length>0) {
                printConsole('this is on click maximise with stream============================================>')
                this.setState({gridHandIconHide: false})//TP-3904
                //TP-3701 -- For non-default states of RM session, show the Max-view controls (both Primary & Passive experts) if any user with Video enabled is put on its Max-view (only Technician users)
                if(this.props.session_type === "RM" && this.props.enable_user_video !== "my_camera_disabled" && participant.disableVideo === false && participant.isexpert === false && showHideDivIconAudioOnly === true) {
                    //TP-3893
                    this.setState({isSilhouetteView: false, /**TP-4797*/ showHideDivIconAudioOnly: false}, () => {
                        this.setState({maxDivParticipant: participant, /*TP-4837*/aspectRatio: maxDivParticipant.aspect_ratio, maxView: true, otherParticipants, hideExpertSSAll: (participant.disableVideo === true) ? false : false, //TP-4436
                            maxDivParticipantEmail: '', /* maxDivSpeakerMuted: participant.disableAudio === true ? true : false */ },() => {
                            participant.streams.forEach((stream) => {
                                //console.log(stream.getVideoTracks());
                                this.maxVideoRef.current.srcObject = stream
                                this.maxVideoRef.current.id = participant.stream_id
                                //TP-4909 -- Show the loader until the video starts playing
                                const vvideo = document.getElementById(this.maxVideoRef.current.id);
                                if (vvideo && vvideo.paused && participant.disableVideo === false /* && this.state.isScreencasting === true */) {
                                    this.setState({ showSSLoader: true });
                                    printConsole("Video is buffering");
                                    vvideo.addEventListener("playing", (event) => {
                                        printConsole("Vidoe is playing")
                                        this.setState({ showSSLoader: false });
                                    });
                                } 
                                if (participant.isThisMe === true) {
                                    //TP-5442
                                    if (stream.getVideoTracks().length > 0) {
                                        stream.getVideoTracks().forEach(track => {
                                            track.addEventListener(
                                                "ended",
                                                (event) => {
                                                  console.log("My video is ended");
                                                  this.triggerDeviceErrorAndEndSession("camera");
                                                },
                                                false,
                                            );
                                        })
                                    }
                                    //TP-5441
                                    if (stream.getAudioTracks().length > 0) {
                                        stream.getAudioTracks().forEach(track => {
                                            track.addEventListener(
                                                "ended",
                                                (event) => {
                                                  console.log("My audio is ended");
                                                  this.triggerDeviceErrorAndEndSession("microphone");
                                                },
                                                false,
                                            );
                                        })
                                    }
                                }
                            })
                            //TP-4837
                            if (participant.aspect_ratio && !this.state.expertShareScreenMaxDiv /**TP-4669*/) {
                                this.calVdoHeightWidth(participant.aspect_ratio);
                            } else {
                                this.calVdoHeightWidth() //TP-4902
                            }
                            if (maxDivParticipant !== participant) {
                                this.props.setMaxDivId({g_maxDivId: participant.stream_id, maxDivParticipant: participant})// set maxdiv id to be used for 3d file rendering
                            }
                            this.assignSource({otherParticipants})// assign the source to video element of other participant
                            if (isSame === false) {
                                this.checkAudioStat({participant})// check the audio stat of the max div participant   
                            }
                            //TP-2967 & TP-2933
                            if (this.state.hideButton === "d-none"){
                                this.setZoomClass();
                            }
                            !this.state.showMaxDivControls && !this.state.expertShareScreenMaxDiv && this.setMaxDivIcons({participant}) // set the current Max-view technician's Icons
                            if (isSame === false) {
                                this.setState({resetMax: true}, ()=>{
                                    this.setState({resetMax: false});
                                }); // check and auto set the Zoom & Torch Icon states for that max-view participant
                            }
                            // send the switch to max div signal to all the other passsive expert if you are primary expert
                            if (this.state.showMaxDivControls && this.state.currentPrimaryExpert && this.state.currentPrimaryExpert.email === this.state.user.email){
                                if (isSame === false) {
                                    this.sendMaxViewSignal({flag: true})//enable local rc button for the technician
                                }
                                /*TP-4837let experts = this.state.participants.filter(p => p.isexpert);
                                !this.state.isSideBarSS && !this.state.isMaxDivSS && experts && experts.forEach((e) => {
                                    printConsole(`onClickMaximise sending sendSignalSyncExperts=====================> ${participant.email} to ${e.email}`)
                                    this.sessionSignal.sendSignalSyncExperts({
                                        show_grid: false,
                                        max_view_technician: participant.email, //TP-3893
                                        ratio: this.state.aspectRatio,
                                        annotation_active: this.props.bAnnotate,
                                        torch_button_active: participant ? participant.torchActive: false,
                                        zoom_button_active: participant && parseInt(participant.zoomLevel) > 1 ? true : false,
                                        zoom_level: participant ? parseInt(participant.zoomLevel) : 1,
                                        hand_icon_enabled: true,
                                        header_fileShare_active: (this.props.isHeaderFileShare && !this.props.isOneToOneFileShare) ? true : false, //TP-2378
                                        reason: "show_action"}, 
                                    e.email);
                                });*/
                                //TP-3114 -- New signal to sync up all the Technician's Views with the Expert's when CM is enabled
                                /*TP-4684 & TP-4683 if (groupInfo.session_type === "CM" || (groupInfo.session_type === "RM" && this.checkIfExpertCameraEnabled() === true) || this.props.audio_mode === "tech2Tech" /**TP-3551 ) {
                                    //TP-3119
                                    let technicians = this.state.participants.filter(p => !p.isexpert);
                                    /*TP-4422 !this.state.isSideBarSS &&  !this.state.isMaxDivSS && technicians && technicians.forEach(t => {
                                        printConsole(`onClickMaximise sending sendSignalCurrentMaxViewUser===========> ${participant.email} to ${t.email}`);
                                        this.sessionSignal.sendSignalCurrentMaxViewUser({
                                            is_grid: false,
                                            max_view_user: participant.email},
                                        t.email)
                                    })
                                } */
                                /* (this.state.isSideBarSS || this.state.isMaxDivSS) && experts && experts.forEach((e) => {
                                    printConsole(`onClickMaximise sending sendSignalSyncExperts=====================> ${this.state.user.email} to ${e.email}`)
                                    this.sessionSignal.sendSignalSyncExperts({
                                        show_grid: false,
                                        max_view_technician: this.state.user.email, //TP-3893
                                        ratio: "16:9", 
                                        hand_icon_enabled: true,
                                        header_fileShare_active: (this.props.isHeaderFileShare && !this.props.isOneToOneFileShare) ? true : false, //TP-2378
                                        reason: "show_action"}, 
                                    e.email);
                                }); */
                                
                                // should not disable grid view when max div actions are going on
                                //!this.state.maxDivIconClicked && this.setState({disableGridView: this.state.participants.length > 1 ? false : true});
                                !this.state.maxDivIconClicked && this.setState({disableGridView: (groupInfo.session_type === "CM" || (groupInfo.session_type === "RM" && this.checkIfExpertCameraEnabled() === true)) ? (this.state.participants.length >= 1) ? false : true : (this.state.participants.length > 1) ? false : true});//MB2-491,739, TP-3119
                                // should disable grid view icon when max div actions are going on
                                //this.state.maxDivIconClicked && this.setState({disableGridView: this.state.participants.length > 1 ? false : true});
                                this.state.maxDivIconClicked && this.setState({disableGridView: (groupInfo.session_type === "CM" || (groupInfo.session_type === "RM" && this.checkIfExpertCameraEnabled() === true)) ? (this.state.participants.length >= 1) ? false : true : (this.state.participants.length > 1) ? false : true});//TP-3119
                            }
                            if (this.decodedUser && this.decodedUser.showuploadspeed){
                                this.props.checkDownloadSpeed();//MB2-588
                                this.props.checkUploadSpeed();//MB2-588
                            }
        
                        })
                    });
                } else if (this.props.session_type === "RM" && this.props.enable_user_video !== "my_camera_disabled" && participant.isexpert === true && this.state.showMaxDivControls === false) {
                    //TP-3893
                    this.setState({isSilhouetteView: false, /**TP-4797*/ showHideDivIconAudioOnly: false}, () => {
                        this.setState({maxDivParticipant: participant, /**TP-4837*/ aspectRatio: maxDivParticipant.aspect_ratio, maxView: true, otherParticipants, hideExpertSSAll: (participant.disableVideo === true) ? false : false, //TP-4436
                            maxDivParticipantEmail: '', /* maxDivSpeakerMuted: participant.disableAudio === true ? true : false */ },() => {
                            participant.streams.forEach((stream) => {
                                //console.log(stream.getVideoTracks());
                                this.maxVideoRef.current.srcObject = stream
                                this.maxVideoRef.current.id = participant.stream_id
                                //TP-4909 -- Show the loader until the video starts playing
                                const vvideo = document.getElementById(this.maxVideoRef.current.id);
                                if (vvideo && vvideo.paused && participant.disableVideo === false /* && this.state.isScreencasting === true */) {
                                    this.setState({ showSSLoader: true });
                                    printConsole("Video is buffering");
                                    vvideo.addEventListener("playing", (event) => {
                                        printConsole("Vidoe is playing")
                                        this.setState({ showSSLoader: false });
                                    });
                                } 
                                if (participant.isThisMe === true) {
                                    //TP-5442
                                    if (stream.getVideoTracks().length > 0) {
                                        stream.getVideoTracks().forEach(track => {
                                            track.addEventListener(
                                                "ended",
                                                (event) => {
                                                  console.log("My video is ended");
                                                  this.triggerDeviceErrorAndEndSession("camera");
                                                },
                                                false,
                                            );
                                        })
                                    }
                                    //TP-5441
                                    if (stream.getAudioTracks().length > 0) {
                                        stream.getAudioTracks().forEach(track => {
                                            track.addEventListener(
                                                "ended",
                                                (event) => {
                                                  console.log("My audio is ended");
                                                  this.triggerDeviceErrorAndEndSession("microphone");
                                                },
                                                false,
                                            );
                                        })
                                    }
                                }
                            })
                            //TP-4837
                            if (participant.aspect_ratio && !this.state.expertShareScreenMaxDiv /**TP-4669*/) {
                                this.calVdoHeightWidth(participant.aspect_ratio);
                            } else {
                                this.calVdoHeightWidth() //TP-4902
                            }
                            if (maxDivParticipant !== participant) {
                                this.props.setMaxDivId({g_maxDivId: participant.stream_id, maxDivParticipant: participant})// set maxdiv id to be used for 3d file rendering
                            }
                            this.assignSource({otherParticipants})// assign the source to video element of other participant
                            if (isSame === false) {
                                this.checkAudioStat({participant})// check the audio stat of the max div participant   
                            }
                            //TP-2967 & TP-2933
                            if (this.state.hideButton === "d-none"){
                                this.setZoomClass();
                            }
                            !this.state.showMaxDivControls && !this.state.expertShareScreenMaxDiv && this.setMaxDivIcons({participant}) // set the current Max-view technician's Icons
                            if (isSame === false) {
                                this.setState({resetMax: true}, ()=>{
                                    this.setState({resetMax: false});
                                }); // check and auto set the Zoom & Torch Icon states for that max-view participant
                            }
                            // send the switch to max div signal to all the other passsive expert if you are primary expert
                            if (this.state.showMaxDivControls && this.state.currentPrimaryExpert && this.state.currentPrimaryExpert.email === this.state.user.email){
                                if (isSame === false) {
                                    this.sendMaxViewSignal({flag: true})//enable local rc button for the technician
                                }
                                /*TP-4837let experts = this.state.participants.filter(p => p.isexpert);
                                !this.state.isSideBarSS && !this.state.isMaxDivSS && experts && experts.forEach((e) => {
                                    printConsole(`onClickMaximise sending sendSignalSyncExperts=====================> ${participant.email} to ${e.email}`)
                                    this.sessionSignal.sendSignalSyncExperts({
                                        show_grid: false,
                                        max_view_technician: participant.email, //TP-3893
                                        ratio: this.state.aspectRatio,
                                        annotation_active: this.props.bAnnotate,
                                        torch_button_active: participant ? participant.torchActive: false,
                                        zoom_button_active: participant && parseInt(participant.zoomLevel) > 1 ? true : false,
                                        zoom_level: participant ? parseInt(participant.zoomLevel) : 1,
                                        hand_icon_enabled: true,
                                        header_fileShare_active: (this.props.isHeaderFileShare && !this.props.isOneToOneFileShare) ? true : false, //TP-2378
                                        reason: "show_action"}, 
                                    e.email);
                                });*/
                                //TP-3114 -- New signal to sync up all the Technician's Views with the Expert's when CM is enabled
                                /*TP-4684 TP-4683 if (groupInfo.session_type === "CM" || (groupInfo.session_type === "RM" && this.checkIfExpertCameraEnabled() === true) || this.props.audio_mode === "tech2Tech" /**TP-3551) {
                                    //TP-3119
                                    let technicians = this.state.participants.filter(p => !p.isexpert);
                                    /* TP-4422 !this.state.isSideBarSS && !this.state.isMaxDivSS && technicians && technicians.forEach(t => {
                                        printConsole(`onClickMaximise sending sendSignalCurrentMaxViewUser===========> ${participant.email} to ${t.email}`);
                                        this.sessionSignal.sendSignalCurrentMaxViewUser({
                                            is_grid: false,
                                            max_view_user: participant.email},
                                        t.email)
                                    })
                                }*/
                                /* (this.state.isSideBarSS || this.state.isMaxDivSS) && experts && experts.forEach((e) => {
                                    printConsole(`onClickMaximise sending sendSignalSyncExperts=====================> ${this.state.user.email} to ${e.email}`)
                                    this.sessionSignal.sendSignalSyncExperts({
                                        show_grid: false,
                                        max_view_technician: this.state.user.email, //TP-3893
                                        ratio: "16:9", 
                                        hand_icon_enabled: true,
                                        header_fileShare_active: (this.props.isHeaderFileShare && !this.props.isOneToOneFileShare) ? true : false, //TP-2378
                                        reason: "show_action"}, 
                                    e.email);
                                }); */
                                
                                // should not disable grid view when max div actions are going on
                                //!this.state.maxDivIconClicked && this.setState({disableGridView: this.state.participants.length > 1 ? false : true});
                                !this.state.maxDivIconClicked && this.setState({disableGridView: (groupInfo.session_type === "CM" || (groupInfo.session_type === "RM" && this.checkIfExpertCameraEnabled() === true)) ? (this.state.participants.length >= 1) ? false : true : (this.state.participants.length > 1) ? false : true});//MB2-491,739, TP-3119
                                // should disable grid view icon when max div actions are going on
                                //this.state.maxDivIconClicked && this.setState({disableGridView: this.state.participants.length > 1 ? false : true});
                                this.state.maxDivIconClicked && this.setState({disableGridView: (groupInfo.session_type === "CM" || (groupInfo.session_type === "RM" && this.checkIfExpertCameraEnabled() === true)) ? (this.state.participants.length >= 1) ? false : true : (this.state.participants.length > 1) ? false : true});//TP-3119
                            }
                            if (this.decodedUser && this.decodedUser.showuploadspeed){
                                this.props.checkDownloadSpeed();//MB2-588
                                this.props.checkUploadSpeed();//MB2-588
                            }
        
                        })
                    }); //TP-3893
                } else {
                    this.setState({isSilhouetteView: false, /**TP-4797*/ maxDivParticipant: participant, /**TP-4837*/ aspectRatio: maxDivParticipant.aspect_ratio, maxView: true, otherParticipants, hideExpertSSAll: (participant.disableVideo === true) ? false : false, //TP-4436
                        maxDivParticipantEmail: '', /* maxDivSpeakerMuted: participant.disableAudio === true ? true : false */ },() => {
                        participant.streams.forEach((stream) => {
                            //console.log(stream.getVideoTracks());
                            this.maxVideoRef.current.srcObject = stream
                            this.maxVideoRef.current.id = participant.stream_id
                            //TP-4909 -- Show the loader until the video starts playing
                            const vvideo = document.getElementById(this.maxVideoRef.current.id);
                            if (vvideo && vvideo.paused && participant.disableVideo === false /**TP-6171*/ /* && this.state.isScreencasting === true */) {
                                this.setState({ showSSLoader: true });
                                printConsole("Video is buffering");
                                vvideo.addEventListener("playing", (event) => {
                                    printConsole("Vidoe is playing")
                                    this.setState({ showSSLoader: false });
                                });
                            } 
                            if (participant.isThisMe === true) {
                                //TP-5442
                                if (stream.getVideoTracks().length > 0) {
                                    stream.getVideoTracks().forEach(track => {
                                        track.addEventListener(
                                            "ended",
                                            (event) => {
                                              console.log("My video is ended");
                                              this.triggerDeviceErrorAndEndSession("camera");
                                            },
                                            false,
                                        );
                                    })
                                }
                                //TP-5441
                                if (stream.getAudioTracks().length > 0) {
                                    stream.getAudioTracks().forEach(track => {
                                        track.addEventListener(
                                            "ended",
                                            (event) => {
                                              console.log("My audio is ended");
                                              this.triggerDeviceErrorAndEndSession("microphone");
                                            },
                                            false,
                                        );
                                    })
                                }
                            }
                        })
                        //TP-4837
                        if (participant.aspect_ratio && !this.state.expertShareScreenMaxDiv /**TP-4669*/) {
                            this.calVdoHeightWidth(participant.aspect_ratio);
                        } else {
                            this.calVdoHeightWidth() //TP-4902
                        }
                        if (maxDivParticipant !== participant) {
                            this.props.setMaxDivId({g_maxDivId: participant.stream_id, maxDivParticipant: participant})// set maxdiv id to be used for 3d file rendering
                        }
                        this.assignSource({otherParticipants})// assign the source to video element of other participant
                        if (isSame === false) {
                            this.checkAudioStat({participant})// check the audio stat of the max div participant   
                        }
                        //TP-2967 & TP-2933
                        if (this.state.hideButton === "d-none"){
                            this.setZoomClass();
                        }
                        if (participant.disableVideo === true && !participant.isexpert && !this.state.showMaxDivControls && showHideDivIconAudioOnly === false) this.setState({ showHideDivIconAudioOnly: true });//TP-5592
                        !this.state.showMaxDivControls && !this.state.expertShareScreenMaxDiv && this.setMaxDivIcons({participant}) // set the current Max-view technician's Icons
                        if (isSame === false) {
                            this.setState({resetMax: true}, ()=>{
                                this.setState({resetMax: false});
                            }); // check and auto set the Zoom & Torch Icon states for that max-view participant
                        }
                        // send the switch to max div signal to all the other passsive expert if you are primary expert
                        if (this.state.showMaxDivControls && this.state.currentPrimaryExpert && this.state.currentPrimaryExpert.email === this.state.user.email){
                            if (isSame === false) {
                                this.sendMaxViewSignal({flag: true})//enable local rc button for the technician
                            }
                            /*TP-4837let experts = this.state.participants.filter(p => p.isexpert);
                             !this.state.isSideBarSS && !this.state.isMaxDivSS && experts && experts.forEach((e) => {
                                printConsole(`onClickMaximise sending sendSignalSyncExperts=====================> ${participant.email} to ${e.email}`)
                                this.sessionSignal.sendSignalSyncExperts({
                                    show_grid: false,
                                    max_view_technician: participant.email, //TP-3893
                                    ratio: this.state.aspectRatio,
                                    annotation_active: this.props.bAnnotate,
                                    torch_button_active: participant ? participant.torchActive: false,
                                    zoom_button_active: participant && parseInt(participant.zoomLevel) > 1 ? true : false,
                                    zoom_level: participant ? parseInt(participant.zoomLevel) : 1,
                                    hand_icon_enabled: true,
                                    header_fileShare_active: (this.props.isHeaderFileShare && !this.props.isOneToOneFileShare) ? true : false, //TP-2378
                                    reason: "show_action"}, 
                                e.email);
                            });*/
                            //TP-3114 -- New signal to sync up all the Technician's Views with the Expert's when CM is enabled
                            /*TP-4684 TP-4683 if (groupInfo.session_type === "CM" || (groupInfo.session_type === "RM" && this.checkIfExpertCameraEnabled() === true) || this.props.audio_mode === "tech2Tech" /**TP-3551) {
                                //TP-3119
                                let technicians = this.state.participants.filter(p => !p.isexpert);
                                /* TP-4422 !this.state.isSideBarSS &&  !this.state.isMaxDivSS && technicians && technicians.forEach(t => {
                                    printConsole(`onClickMaximise sending sendSignalCurrentMaxViewUser===========> ${participant.email} to ${t.email}`);
                                    this.sessionSignal.sendSignalCurrentMaxViewUser({
                                        is_grid: false,
                                        max_view_user: participant.email},
                                    t.email)
                                })
                            }*/
                            /* (this.state.isSideBarSS || this.state.isMaxDivSS) && experts && experts.forEach((e) => {
                                printConsole(`onClickMaximise sending sendSignalSyncExperts=====================> ${this.state.user.email} to ${e.email}`)
                                this.sessionSignal.sendSignalSyncExperts({
                                    show_grid: false,
                                    max_view_technician: this.state.user.email, //TP-3893
                                    ratio: "16:9", 
                                    hand_icon_enabled: true,
                                    header_fileShare_active: (this.props.isHeaderFileShare && !this.props.isOneToOneFileShare) ? true : false, //TP-2378
                                    reason: "show_action"}, 
                                e.email);
                            }); */
                            
                            // should not disable grid view when max div actions are going on
                            //!this.state.maxDivIconClicked && this.setState({disableGridView: this.state.participants.length > 1 ? false : true});
                            !this.state.maxDivIconClicked && this.setState({disableGridView: (groupInfo.session_type === "CM" || (groupInfo.session_type === "RM" && this.checkIfExpertCameraEnabled() === true)) ? (this.state.participants.length >= 1) ? false : true : (this.state.participants.length > 1) ? false : true});//MB2-491,739, TP-3119
                            // should disable grid view icon when max div actions are going on
                            //this.state.maxDivIconClicked && this.setState({disableGridView: this.state.participants.length > 1 ? false : true});
                            this.state.maxDivIconClicked && this.setState({disableGridView: (groupInfo.session_type === "CM" || (groupInfo.session_type === "RM" && this.checkIfExpertCameraEnabled() === true)) ? (this.state.participants.length >= 1) ? false : true : (this.state.participants.length > 1) ? false : true});//TP-3119
                        }
                        if (this.decodedUser && this.decodedUser.showuploadspeed){
                            this.props.checkDownloadSpeed();//MB2-588
                            this.props.checkUploadSpeed();//MB2-588
                        }
    
                    })
                }
            }
        }catch(error){
            printConsole(error)
        }
    }

    onClickMaximizeExpert = ({participant}) => {
        const { user } = this.props;
        const { groupInfo } = this.state;
        const otherParticipants = this.state.participants.filter((p) => p && (p.stream_id && p.stream_id !== participant.stream_id))
        printConsole('this is on click maximise experts============================================>');
        printConsole(participant);
        
        this.setState({isSilhouetteView: false, /**TP-4797*/ maxDivParticipant: participant, maxView: true, otherParticipants,
            maxDivParticipantEmail: '' },() => {   
            this.setState({gridHandIconHide: false})//TP-3904      
            //TP-3701 -- For non-default states of RM session, show the Max-view controls (both Primary & Passive experts) if any user with Video enabled is put on its Max-view (only Technician users)
            if(this.props.session_type === "RM" && this.props.enable_user_video !== "my_camera_disabled" && participant.disableVideo === false) {
                this.setState({hideExpertSSAll: false});
            } else {
                this.setState({hideExpertSSAll: false}); //TP-4436
            }
            //TP-4837
            if (participant.aspect_ratio) {
                this.calVdoHeightWidth(participant.aspect_ratio);
            } else {
                this.calVdoHeightWidth() //TP-4902
            }
            this.props.setMaxDivId({g_maxDivId: participant.stream_id, maxDivParticipant: participant})// set maxdiv id to be used for 3d file rendering
            this.setState({disableGridView: (groupInfo.session_type === "CM" || (groupInfo.session_type === "RM" && this.checkIfExpertCameraEnabled() === true)) ? (this.state.participants.length >= 1) ? false : true : this.state.participants.length > 1 ? false : true});//MB2-491,739
            //TP-3114 & TP-3119 -- New signal to sync up all the Technician's Views with the Expert's when CM is enabled
            /*TP-4684 TP-4683 if (groupInfo.session_type === "CM" || (groupInfo.session_type === "RM" && this.checkIfExpertCameraEnabled() === true) || this.props.audio_mode === "tech2Tech" /**TP-3551) {
                let technicians = this.state.participants.filter(p => !p.isexpert);
                /* !this.state.isSideBarSS && !this.state.isMaxDivSS &&  technicians && technicians.forEach(t => {
                    printConsole(`on click maximise experts sending sendSignalCurrentMaxViewUser===========> ${participant.email} to ${t.email}`);
                    this.sessionSignal.sendSignalCurrentMaxViewUser({
                        is_grid: false,
                        max_view_user: participant.email},
                    t.email)
                })
            }*/
        });
    }

    //TP-4797 & TP-5468
    onMaximizeSilhouetteView = ({participant}) => {
        printConsole('this is on click maximise Silhouette View============================================>');
        printConsole(participant);
        const otherParticipants = this.state.participants.filter((p) => p && (p.stream_id && p.stream_id !== participant.stream_id))
        //TP-4909 -- Show the loader until the video starts playing
        if (this.state.isScreencasting === true && this.state.showMaxDivControls === false) {
            printConsole("Is passive expert and is Expert screen share starting up");
            this.setState({ showSSLoader: true });
        }
        this.setState({isSilhouetteView: true, /**TP-5491*/ maxDivParticipant: '', maxView: true, otherParticipants,
            maxDivParticipantEmail: '' }, () => {
                if (participant.streams) {
                    //TP-5482 TP-5468
                    participant.streams.forEach((stream) => {
                        //console.log(stream.getVideoTracks());
                        this.maxVideoRef.current.srcObject = stream
                        this.maxVideoRef.current.id = participant.stream_id
                    })
                }
                this.props.setMaxDivId({g_maxDivId: participant.stream_id, maxDivParticipant: participant})// set maxdiv id to be used for 3d file rendering
                this.assignSource({otherParticipants})// assign the source to video element of other participant
                /* if (this.maxVideoRef && this.maxVideoRef.current !== null)
                    this.maxVideoRef.current.srcObject = null;
                else {
                    this.maxVideoRef.current = {
                        srcObject: null
                    } 
                } */
            });
    }

    // assign source to other participant 
    assignSource = ({otherParticipants}) => {
        try{
            for(let i= 0; i < otherParticipants.length; i++){
                let vElement = document.getElementById(otherParticipants[i].stream_id)
                //TP-5459
                if (vElement !== null) {
                    otherParticipants[i].streams && otherParticipants[i].streams.forEach((stream) => {
                        vElement.srcObject = stream
                    })
                }
            }
        }catch(error){
            printConsole(error)
        }
    }
    // check the max div mike/speaker status matching with global status
    checkAudioStat = ({participant}) => {
        try{
            printConsole("Check the audio stats for participant");
            printConsole(participant);
            // if(this.state.mutedAll !== this.state.maxDivSpeakerMuted){
                // this.onclickMuteSpeakerMaxDiv({flag: !this.state.mutedAll, participant})// if max div speaker muted, MB2-495
                /* participant && participant.streams && participant.streams.length > 0 && participant.streams.forEach((stream) => {
                    stream.getAudioTracks().forEach(track => {
                        this.setState({maxDivSpeakerMuted: track.enabled});
                    });
                }) */
                this.setState({maxDivSpeakerMuted: participant.disableAudio, maxDivSpeakerDisabled: participant.disableRemoteAudio}); // T32-413
            // }
            //if(this.state.headerMuteMike !== this.state.maxDivMikeMuted){
                //if(this.state.headerMuteMike && !this.state.maxDivMikeMuted){
                    this.onclickMuteMikeMaxDiv({flag: !participant.disableMike, participant})//  Mb2-495 follow max div state in sync with OT
                    //this.setState({openMikeDialog: true})
                /* }else{
                    this.onclickMuteMikeMaxDiv({flag: !participant.disableMike, participant})//  Mb2-495 follow header mute state in sync with OT
                }
            } */
        }catch(error){
            printConsole(error)
        }
    }

    checkAudioStatGrid = ({participant}) => {        
        try{
                
                    //this.setState({maxDivSpeakerMuted: participant.disableAudio});
            this.sendMuteMikeSignal({participant, flag: !participant.disableMike});
            this.muteSpeakerOfOne({participant, flag: !participant.disableAudio});    
            //this.onclickMuteMikeMaxDiv({flag: !participant.disableMike, participant})//  Mb2-495 follow max div state in sync with OT
                        
        }catch(error){
            printConsole(error)
        }
    }

    // TP-377 -- To set the Zoom, Torch, Screenshare, Tech Screen share Icons for Passive expert's screen
    // in Max-view mode
    setMaxDivIcons = ({participant}) => {
        try {
            this.state.userInfoArray.forEach(obj => {
                if (obj.id === participant.userId && participant.isexpert === false) {
                    printConsole("Technician is in session");
                    printConsole(obj.id);
                    participant.zoom_enabled = (obj.data.zoom_enabled && this.state.groupInfo.zoom_enabled) ? obj.data.zoom_enabled : false;
                    participant.torch_enabled = (obj.data.torch_enabled && this.state.groupInfo.torch_enabled) ? obj.data.torch_enabled : false;
                    participant.screenshare_enabled = (obj.data.screenshare_enabled) ? obj.data.screenshare_enabled : true;
                    participant.techSS_enabled = (obj.data.techSS_enabled) ? obj.data.techSS_enabled : false; 
                    participant.aspect_ratio = obj.data.aspectratio ? (obj.data.aspectratio === "1.33" || obj.data.aspectratio === "1.34") ? "4:3" : obj.data.aspectratio === "1.77" ? "16:9" : "16:9" : "16:9"; //TP-4837
                }
            })
        } catch(error) {
            printConsole(error);
        }
    }

    // mute/unmute mike modal
    muteMikeForTechDialog = () => {
        return (
            <div>
                <Modal isOpen={this.state.openMikeDialog} toggle={() => this.setState({ openMikeDialog: false })}>
                    <ModalHeader toggle={() => this.setState({ openMikeDialog: false })} cssModule={{ 'modal-title': 'w-100 text-center' }}><Trans>Audio Alert</Trans></ModalHeader>
                    <ModalBody>
                        <div>
                            {this.state.maxDivParticipant.first_name} {this.state.maxDivMikeMuted ? <Trans>cannot hear you. Do you want to unmute your mike?</Trans> : <Trans>can hear you. Do you want to mute your mike?</Trans>}
                        </div>
                        <div>
                            <Button color="primary" onClick={this.muteUnmuteMaxDiv} className="pull-right">
                                {this.state.maxDivMikeMuted ? <Trans>Unmute Mike</Trans> : <Trans>Mute Mike</Trans>}
                            </Button>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        );
    }

    muteUnmuteMaxDiv = () => {
        this.setState(prevState => ({
            openMikeDialog: false,
            maxDivMikeMuted: !prevState.maxDivMikeMuted
        }), () => {
            this.sendMuteMikeSignal({participant: this.state.maxDivParticipant, flag: !this.state.maxDivMikeMuted});
        });
    }

    // mute Speaker of the max div
    onclickMuteSpeakerMaxDiv = ({flag, participant: maxDivParticipant}) => {
        try {
            printConsole(`Max div Speaker mute: ${flag}`);
            let {participants} = this.state;
            const participant = maxDivParticipant ? maxDivParticipant : this.state.maxDivParticipant;
            let to = {};
            participants && participants.forEach(p => {
                if (p === participant) {
                    p.disableAudio = !flag;
                    to = p;
                }
            })
            if (to !== {}) //set the changed session Joinee in the array
                this.updateEachSessionJoinee({participant: to});
            this.setState({maxDivSpeakerMuted: !flag, participants, isParticipantChanged: true}, () => {
                this.muteSpeakerOfOne({participant, flag})
                this.setState({isParticipantChanged: false})
            })
        }catch(exception) {
            printConsole(exception);
        }
    }

    // mute mike of the max div
    onclickMuteMikeMaxDiv = ({flag, participant: maxDivParticipant}) => {
        try {
            printConsole("Here............");
            let {participants} = this.state;
            //TP-1918 -- To get the previous unMuted Expert Mike count
            let isExpertAudioUnMutedCounterPrev = 0;
            participants.forEach(p => {
                if (!p.disableMike) isExpertAudioUnMutedCounterPrev += 1;
            }) 
            const participant = maxDivParticipant ? maxDivParticipant : this.state.maxDivParticipant
            const index = participants.findIndex(e => e === participant);
            participant.disableMike = !flag;
            participants[index] = participant;
            //TP-1918 -- To get the latest unMuted Expert Mike count
            let isExpertAudioUnMutedCounterLatest = 0;
            participants.forEach(p => {
                if (!p.disableMike) isExpertAudioUnMutedCounterLatest += 1;
            })
            let recordingFlag = false;
            printConsole(`Prev unmuted Mike counter: ${isExpertAudioUnMutedCounterPrev} current unMuted mike counter: ${isExpertAudioUnMutedCounterLatest}`);
            if (this.state.startRecording === true && isExpertAudioUnMutedCounterPrev !== isExpertAudioUnMutedCounterLatest && ((isExpertAudioUnMutedCounterPrev === 0 && isExpertAudioUnMutedCounterLatest > 0) || (isExpertAudioUnMutedCounterPrev > 0 && isExpertAudioUnMutedCounterLatest === 0))) {
                printConsole("The Expert's individual Mikes count changed while recording is ongoing")
                recordingFlag = true;                
            }

            //set the changed session Joinee in the array
            this.updateEachSessionJoinee({participant});
            participant && this.setState({maxDivMikeMuted: !flag, participants, isParticipantChanged: true},() => {
                this.sendMuteMikeSignal({participant, flag});
                this.setState({isParticipantChanged: false});
                // TP-1918
                if (recordingFlag === true) {
                    printConsole("Stop and re-start a new recording due to Mute/un-Mute of Max-div control Mic icon");
                    this.stopAndContinueRecording(); //TP-1881 stop the old recording and start a new recording when Side Bar Mute Mike is clicked
                }
            })
        }catch(exception) {
            printConsole(exception);
        }
    }

    // mute Speaker of one participant (from Grid View)
    onClickMuteSpeakerofOne = ({flag, participant}) => {
        try {
            this.muteSpeakerOfOne({participant, flag});
        } catch (exception) {
            printConsole(exception);
            
        }
    }

    // mute Mike of one participant (from Grid View)
    onClickMuteMikeofOne = ({flag, participant}) => {
        try {
            this.sendMuteMikeSignal({participant, flag});
        } catch (exception) {
            printConsole(exception);
            
        }
    }

    //For TP-1199 -- Setting the current show/hide state of the Primary expert's Request Dialog box
    setRequestMaxViewControl = (value) => {
        this.setState({ showControlRequestDialog: value });
        this.props.setRequestMaxViewControl(value);//TP-1313
    }

    //TP-1313 -- Setting the clickHandSignal from the StreamActions component
    setClickHandSignal = (value) => {
        this.setState({ clickHandSignal: value });
    }

    //TP-2933
    processZoomLevels = (level, i18n) => {
        switch (level) {
            case "1X":
                return i18n._(t`1X`);
            case "2X":
                return i18n._(t`2X`);
            case "3X":
                return i18n._(t`3X`);
            case "4X":
                return i18n._(t`4X`);
            case "5X":
                return i18n._(t`5X`);
            default:
                break;
        }
    }

    //TP-2933
    setZoomClass = () => {
        const {maxDivParticipant} = this.state;
        let zoomClass = "oneX";
        switch(maxDivParticipant.zoomLevel) {
            case 2:
                zoomClass = "twoX";
                break;
            case 3:
                zoomClass = "threeX";
                break;
            case 4:
                zoomClass = "fourX";
                break;
            case 5:
                zoomClass = "fiveX";
                break;
            default:
                zoomClass = "oneX";                
        }
        this.setState({ zoomClass });
    }

    // called when single participant is there/ max view activated
    singleGridView = () => {
        try{
            const {vdoheight, vdowidth, participants, maxDivSpeakerMuted, maxDivSpeakerDisabled, 
                    maxDivMikeMuted, maxDivParticipant, otherParticipants, groupInfo, bMaxChat, 
                    resetMax, showHideDivIconAudioOnly, maxView, changeHandIcon, 
                    expertShareScreenMaxDiv, bAllChat, clickHandSignal /**TP-1313 */, 
                    expertParticipantLeft /**TP-2292*/, resetMaxUserZoom, resetMaxUserTorch, 
                    zoomClass, gridHandIconHide, screenCastStarted /**TP-2971 */} = this.state;
            const { user, enable_user_video } = this.props; //TP-3682
            const newProps = {maxView, participants, maxDivSpeakerMuted, maxDivSpeakerDisabled, maxDivMikeMuted, maxDivParticipant, onclickMuteSpeakerMaxDiv: this.onclickMuteSpeakerMaxDiv,
                onclickMuteMikeMaxDiv: this.onclickMuteMikeMaxDiv, screenCaptureClicked:this.state.screenCaptureClicked,
                takeScreenShot: this.takeScreenShot, screen_recording_enabled: groupInfo.screen_recording_enabled, maxDivActionGoingOn: this.maxDivActionGoingOn,
                groupInfo, onClickMaxChat: this.onClickChat, onClickChatAll: this.onClickChatAll, bMaxChat, bAllChat, closeChat: this.closeChat, initMaxScreenShare: this.initMaxScreenShare,
                isMaxDivSS: this.state.isMaxDivSS, isSideBarSS: this.state.isSideBarSS, showMaxDivControls: this.state.showMaxDivControls, currentPrimaryExpert: this.state.currentPrimaryExpert,
                setPrimaryExpertIDApi: this.setPrimaryExpertIDApi, startMultiExpertWaitTimer: this.startMultiExpertWaitTimer, makeMeExpert: this.makeMeExpert, triggerMessage: this.triggerMessage,/**TP-1677 */
                bHideExpertSSButton: this.state.bHideExpertSSButton, bHideZoomButton: this.state.bHideZoomButton, bHideTorchButton: this.state.bHideTorchButton, startRecording: this.state.startRecording,
                onClickRecordScreen: this.onClickRecordScreen, stopRecordingCallback: this.stopRecordingCallback, resetMax, showHideDivIconAudioOnly, handleScreenShareIcons: this.handleScreenShareIcons,
                aspectRatio: this.state.aspectRatio, initSideBarScreenShare: this.initSideBarScreenShare, changeHandIcon, enable_remote_capture: groupInfo.enable_remote_capture, onClickTorchIcon: this.onClickTorchIcon, 
                onClickZoomIcon: this.onClickZoomIcon, setRequestMaxViewControl: this.setRequestMaxViewControl, setClickHandSignal: this.setClickHandSignal, clickHandSignal, /**TP-1313 */ expertShareScreenMaxDiv, 
                onClickRecordScreen: this.onClickRecordScreen, expertParticipantLeft, setExpertParticipantLeft: this.setExpertParticipantLeft /* TP-2292 */	,resetMaxUserZoom, resetMaxUserTorch, /**TP-2861 */
                rotateUserZoom: this.rotateUserZoom, /**TP-2933 */ isSmallFormFactorDevice: (this.state.hideButton === "d-none" ? true : false), 
                sessionType: groupInfo.session_type === "CM" || (groupInfo.session_type === "RM" && (this.checkIfExpertCameraEnabled() === true || this.isExpertVideo() === true)) ? true : false, /**TP-3119 */
                gridHandIconHide, /**TP-3904*/ screenCastStarted, /**TP-2971*/ hideHandIcon: this.state.hideHandIcon, /**TP-4543*/ triggerCloseRC: this.state.triggerCloseRC, triggerCloseAnnotate: this.state.triggerCloseAnnotate,
                convertMeToPrimary: this.convertMeToPrimary, isGuest: this.props.isGuest, /**TP-5089*/ isSSOngoing: this.state.isSSOngoing, /**TP-5397*/ showIsMutedbutSpeaking: this.state.showIsMutedbutSpeaking
            }
            const logoHeight = Math.floor(vdoheight - ((vdoheight * 60)/100));
            const profileHeight = Math.floor(vdoheight - ((vdoheight * 60)/100)); //TP-3609 & TP-3201
            //console.log(vdoheight, logoHeight, profileHeight);
            //TP-4589 -- position setting for Paused Video on Expert's Max-view
            const pausedTextContainerWidth = vdowidth + "px";
            const pausedTextleft = maxDivParticipant.pausedReason === "FILESHARE_ONGOING" ? Math.floor((vdowidth - 404)/2) + "px" : Math.floor((vdowidth - 202)/2) + "px"; //TP-4762
            const pausedTextTop = vdoheight >= 585 ? Math.floor((vdoheight * 67)/100) + "px" : vdoheight >= 420 ? Math.floor((vdoheight * 65)/100) + "px" : Math.floor((vdoheight * 56)/100) + "px"; /**TP-5673, TP-4958, TP-4900 */
            const noPosterTextLeft = maxDivParticipant.pausedReason === "FILESHARE_ONGOING" ? Math.floor((profileHeight - 404)/2) + "px" : Math.floor((profileHeight - 202)/2) + "px"; //TP-5685
            const noPosterTextTop = /**TP-5685*/ this.state.showMaxDivControls ? (parseInt(pausedTextTop.split(" ")[0]) - 55) + "px" : (profileHeight + 25) + "px"; //TP-5685
            const pausedText = maxDivParticipant.pausedReason === "FILESHARE_ONGOING" ? i18nMark("session.pausedVideo") : i18nMark("session.pausedVideoDefault"); //TP-4822
            const pausedIconWidth= vdowidth  + "px";
            const muteAudioSpeakingLeft = Math.floor((vdowidth - 350)/2) + "px"; //TP-5551
            const muteAudioSpeakingTop = vdoheight >= 585 ? Math.floor((vdoheight * 80)/100) + "px" : vdoheight >= 420 ? Math.floor((vdoheight * 75)/100) + "px" : Math.floor((vdoheight * 68)/100) + "px"; //TP-5673 TP-5673
            const ssLoaderLeft = Math.floor((window.innerWidth - 75)/2) + "px"; //TP-4909
            //printConsole("Single Grid")
            if (this.state.isSilhouetteView === true) {
                //TP-4797
                return (
                    <>
                    { this.singleSilhouetteView() }
                    </>
                )
            } else if (maxDivParticipant.isexpert && !this.state.expertShareScreenMaxDiv) {
                //printConsole("Draw expert single grid");
                if (groupInfo.session_type === "CM" || (groupInfo.session_type === "RM" && (this.checkIfExpertCameraEnabled() === true || (enable_user_video !== "my_camera_disabled" && maxDivParticipant.disableVideo === false) /**TP-3682*/))) {
                    /**TP-3119 */
                    return (
                        <>
                        { this.singleExpertGridCM() }
                        </>
                    )
                } else {
                    return (
                        <>
                        { this.singleExpertGrid() }
                        </>
                    )
                }
            } else {
                //printConsole("Draw technician single grid");
                /* console.log(this.maxVideoRef.current);
                if (this.maxVideoRef && this.maxVideoRef.current && this.maxVideoRef.current !== null && this.maxVideoRef.current.srcObject !== null) {
                    this.maxVideoRef.current.srcObject.getTracks().forEach(track => {
                        console.log(track);
                    })
                } */
                return(
                    <div className="col-sm-12 text-center">
                        {/* the max div video ------------------*/}
                        { /**TP-3201 TP-5284 */ showHideDivIconAudioOnly === true && maxDivParticipant.isPaused === false ?   
                            /*TP-3497*/
                            (maxDivParticipant.myPicture !== undefined && maxDivParticipant.myPicture !== null && maxDivParticipant.myPicture !== "") ?
                            <img className="session-profile-pic" alt="User profile" src={maxDivParticipant.myPicture} style={{height: profileHeight, width: profileHeight}}></img>
                            :
                            <img alt="User profile" src={"/profile.png"} style={{height: logoHeight}}></img>
                            :                       
                            ''                            
                        }
                        { /**TP-4589 & TP-4572 & TP-4483*/ maxDivParticipant.isPaused ?
                            /*TP-4886 TP-5284*/maxDivParticipant.poster && maxDivParticipant.poster !== '' ?
                                <I18n>
                                    {({ i18n }) =>
                                        <>
                                            <span style={{width: pausedTextContainerWidth}}><span className='paused-text' style={{marginLeft: pausedTextleft, marginTop: pausedTextTop}}><Trans id={/*TP-4822 TP-4762*/ pausedText}></Trans></span></span>
                                            {/* TP-4958 <span className={this.checkSmallScreenDevice() === true ? 'paused-icon fa-stack fa-2x' : 'paused-icon fa-stack fa-4x'} style={{width: pausedIconWidth}}>                                    
                                                <i className="fas fa-circle fa-stack-2x"></i>
                                                <i className="fas fa-video-slash fa-stack-1x font-awesome-red"></i>
                                            </span> */}
                                            { /*TP-5681 TP-5551 TP-5673 this.state.showIsMutedbutSpeaking === true ?
                                                <span style={{width: pausedTextContainerWidth}}><span className='muted-text' style={{marginLeft: muteAudioSpeakingLeft, marginTop: muteAudioSpeakingTop}}><Trans>session.mutedbuttalking</Trans></span></span>
                                                :
                                                ''
                                            */}
                                            <img className="video-stream" width={vdowidth} height={vdoheight} src={maxDivParticipant.poster} alt="Max view Stream frame" />
                                            <video id={maxDivParticipant.stream_id} /**TP-4648*/ ref={this.maxVideoRef} autoPlay style={{height: vdoheight, width: vdowidth}} className={"video-stream d-none"} muted={maxDivParticipant.isThisMe} /**TP-6148*/></video>                                    
                                        </>
                                    }
                                </I18n>
                                :
                                <I18n>
                                    {({ i18n }) =>
                                        <>
                                            <span /* style={{width: pausedTextContainerWidth, height: vdoheight+"px" TP-5284 TP-5685}} */><span className='paused-text' style={{marginLeft: noPosterTextLeft, marginTop: noPosterTextTop}}><Trans id={/*TP-4822 TP-4762*/ pausedText}></Trans></span></span>
                                            {/* <span className={this.checkSmallScreenDevice() === true ? 'paused-icon fa-stack fa-2x' : 'paused-icon fa-stack fa-4x'} style={{width: pausedIconWidth}}>                                    
                                                <i className="fas fa-circle fa-stack-2x"></i>
                                                <i className="fas fa-video-slash fa-stack-1x font-awesome-red"></i>
                                            </span> */}
                                            {/**TP-5685*/ (maxDivParticipant.myPicture !== undefined && maxDivParticipant.myPicture !== null && maxDivParticipant.myPicture !== "") ?
                                                <img className="session-profile-pic" alt="User profile" src={maxDivParticipant.myPicture} style={{height: profileHeight, width: profileHeight}}></img>
                                                :                    
                                                <img alt="User profile" src={"/profile.png"} style={{height: logoHeight}}></img>

                                            }
                                            { /**TP-5681 TP-5673 TP-5551 this.state.showIsMutedbutSpeaking === true ?
                                                <span style={{width: pausedTextContainerWidth}}><span className='muted-text' style={{marginLeft: muteAudioSpeakingLeft, marginTop: muteAudioSpeakingTop}}><Trans>session.mutedbuttalking</Trans></span></span>
                                                :
                                                ''
                                            */}
                                            <video id={maxDivParticipant.stream_id} /**TP-4648*/ ref={this.maxVideoRef} autoPlay style={{height: vdoheight, width: vdowidth}} className={"video-stream d-none"} /**TP-5685*/ muted={maxDivParticipant.isThisMe} /**TP-6148*/></video>                                    
                                        </>
                                    }
                                </I18n>
                            :
                            <>
                                { /**TP-5681 TP-5673 TP-5551 showHideDivIconAudioOnly === false && this.state.showIsMutedbutSpeaking === true ?
                                    <span style={{width: pausedTextContainerWidth}}><span className='muted-text' style={{marginLeft: muteAudioSpeakingLeft, marginTop: muteAudioSpeakingTop}}><Trans>session.mutedbuttalking</Trans></span></span>
                                    :
                                    ''
                                */}
                                {/**TP-4909*/ this.state.showSSLoader === true ? 
                                    <span className="SimpleVideo-loader" style={{ marginLeft: ssLoaderLeft }}>Buffering</span>
                                    :
                                    ''
                                }
                                <video ref={this.maxVideoRef} autoPlay style={showHideDivIconAudioOnly === false ? {height: vdoheight, width: vdowidth}: {height: "0px", width: "0px"}} className={showHideDivIconAudioOnly === false ? "video-stream ": "video-stream d-none"} muted={maxDivParticipant.isThisMe} /**TP-6148*/></video>
                            </>
                        }
                        <div id="canvasDiv" ></div>
                        {
                            /* T32-495-- !maxDivParticipant.isexpert ? */
                                <StreamActions 
                                    {...this.props} {...newProps}
                                />
                                /* :
                                '' */
                        }
                        {/**TP-2967 & TP-2933 */ this.state.hideButton === "d-none" && window.innerHeight <= 420 && maxDivParticipant.zoomLevel > 1 /* && this.state.showMaxDivControls === true  */ ?
                                <I18n>
                                    {({ i18n }) =>
                                        <div className={"position-absolute text-center web-grid-view-controls-parent nameDiv "+zoomClass} 
                                            style={{ color: 'white', zIndex:1, right: '10%' }}>
                                            {this.processZoomLevels(maxDivParticipant.zoomLevel+"X", i18n)}
                                        </div>
                                    }
                                </I18n>
                                :
                                ''
                        }
                        {/* max div video end ------------------------*/}
                        {/* other participant audio stream ---------------- */}
                        {
                            <OtherParticipantAudioTrack  otherParticipants={otherParticipants}/>
                        }
                        {/* other participant audio stream end */}
                        {/* the mute mike dialog */}
                        { this.muteMikeForTechDialog() }
                        {/* -------end---------- */}
                    </div>
                )
            }
        }catch(error){
            printConsole(error)
        }
    }

    //TP-5879
    appendTracksForMaxView = ({participant}) => {
        if (participant && participant.stream_id) {
            printConsole("append other participant's tracks in Max-view mode");
            const videoContainer = document.getElementsByClassName("col-sm-12 text-center")[0];
            if (videoContainer && videoContainer !== null){
                const vid = document.createElement("video");
                vid.id = participant.stream_id;
                vid.key = participant.userId;
                vid.setAttribute("autoPlay", true);
                vid.setAttribute("class", "d-none");
                videoContainer.parentElement.appendChild(vid);
            }
        }
    }

    singleExpertGrid = () => {
        //printConsole("Single Expert view");
        const {vdoheight, vdowidth, participants, maxDivSpeakerMuted, maxDivSpeakerDisabled, maxDivMikeMuted, 
                maxDivParticipant, groupInfo, bMaxChat, resetMax, maxView, changeHandIcon, 
                expertShareScreenMaxDiv, bAllChat, /**TP-2292*/expertParticipantLeft, 
                clickHandSignal, /**TP-1313 */ user, gridHandIconHide, screenCastStarted /**TP-2971*/} = this.state;
        const newProps = {maxView, participants, maxDivSpeakerMuted, maxDivSpeakerDisabled, maxDivMikeMuted, maxDivParticipant, onclickMuteSpeakerMaxDiv: this.onclickMuteSpeakerMaxDiv,
                onclickMuteMikeMaxDiv: this.onclickMuteMikeMaxDiv, screenCaptureClicked:this.state.screenCaptureClicked,
                takeScreenShot: this.takeScreenShot, screen_recording_enabled: groupInfo.screen_recording_enabled, maxDivActionGoingOn: this.maxDivActionGoingOn,
                groupInfo, onClickMaxChat: this.onClickChat, onClickChatAll: this.onClickChatAll, bMaxChat, bAllChat, closeChat: this.closeChat, initMaxScreenShare: this.initMaxScreenShare,
                isMaxDivSS: this.state.isMaxDivSS, isSideBarSS: this.state.isSideBarSS, showMaxDivControls: this.state.showMaxDivControls, currentPrimaryExpert: this.state.currentPrimaryExpert,
                setPrimaryExpertIDApi: this.setPrimaryExpertIDApi, startMultiExpertWaitTimer: this.startMultiExpertWaitTimer, makeMeExpert: this.makeMeExpert, triggerMessage: this.triggerMessage, 
                bHideExpertSSButton: this.state.bHideExpertSSButton, bHideZoomButton: this.state.bHideZoomButton, bHideTorchButton: this.state.bHideTorchButton, startRecording: this.state.startRecording,
                onClickRecordScreen: this.onClickRecordScreen, stopRecordingCallback: this.stopRecordingCallback, resetMax, showHideDivIconAudioOnly: true, handleScreenShareIcons: this.handleScreenShareIcons, 
                aspectRatio: this.state.aspectRatio, initSideBarScreenShare: this.initSideBarScreenShare, changeHandIcon, enable_remote_capture: groupInfo.enable_remote_capture, onClickTorchIcon: this.onClickTorchIcon, 
                onClickZoomIcon: this.onClickZoomIcon, setRequestMaxViewControl: this.setRequestMaxViewControl, setClickHandSignal: this.setClickHandSignal, clickHandSignal, /* TP-1313*/ expertShareScreenMaxDiv, 
                onClickRecordScreen: this.onClickRecordScreen, expertParticipantLeft, setExpertParticipantLeft: this.setExpertParticipantLeft, /**TP-2292*/ triggerMessage: this.triggerMessage /**TP-1677 */,
                resetMaxUserZoom: this.state.resetMaxUserZoom, resetMaxUserTorch: this.state.resetMaxUserTorch /**TP-2861 */, rotateUserZoom: this.rotateUserZoom, isSmallFormFactorDevice: (this.state.hideButton === "d-none" ? true : false),
                sessionType: groupInfo.session_type === "CM" || (groupInfo.session_type === "RM" && (this.checkIfExpertCameraEnabled() === true || this.isExpertVideo() === true)) ? true : false, /**TP-3119 */
                gridHandIconHide, /**TP-3904 */ screenCastStarted, /**TP-2971*/ hideHandIcon: this.state.hideHandIcon, /**TP-4543*/ triggerCloseRC: this.state.triggerCloseRC, triggerCloseAnnotate: this.state.triggerCloseAnnotate,
                convertMeToPrimary: this.convertMeToPrimary, isGuest: this.props.isGuest, /**TP-5089*/ isSSOngoing: this.state.isSSOngoing, /**TP-5397*/ showIsMutedbutSpeaking: this.state.showIsMutedbutSpeaking
            }
        const logoHeight = Math.floor(vdoheight - ((vdoheight * 60)/100)); //TP-4939
        const profileHeight = Math.floor(vdoheight - ((vdoheight * 60)/100)); //TP-3609
        const sideBar = document.getElementById('sideBar');
        let sideBarWidth = sideBar ? sideBar.offsetWidth : 0;
        const pausedTextContainerWidth = vdowidth + "px";
        const pausedTextleft = Math.floor((window.innerWidth - sideBarWidth - 20  - 412)/2) + "px";
        const muteAudioSpeakingLeft = Math.floor((vdowidth - 350)/2) + "px"; //TP-5551
        const muteAudioSpeakingTop = vdoheight >= 585 ? Math.floor((vdoheight * 80)/100) + "px" : vdoheight >= 420 ? Math.floor((vdoheight * 75)/100) + "px" : Math.floor((vdoheight * 68)/100) + "px"; //TP-5673
        return (
            <>
                <div className="col-sm-12 text-center">
                    {/*************************** Expert to expert Max-view with Profile Picture/profile silhouette*******************/}
                    { /**TP-3201 & TP-3497*/ (maxDivParticipant.myPicture !== undefined && maxDivParticipant.myPicture !== null && maxDivParticipant.myPicture !== "") ?
                            <img className="session-profile-pic" alt="User profile" src={maxDivParticipant.myPicture} style={{height: profileHeight, width: profileHeight}}></img>
                            :
                            <img alt="User profile" src={"/profile.png"} style={{height: logoHeight}}></img>
                    }
                    { /*TP-5681 TP-5673 TP-5551 this.state.showIsMutedbutSpeaking === true && maxDivParticipant.disableVideo === false ?
                        <span style={{width: pausedTextContainerWidth}}><span className='muted-text' style={{marginLeft: muteAudioSpeakingLeft, marginTop: muteAudioSpeakingTop}}><Trans>session.mutedbuttalking</Trans></span></span>
                        :
                        ''
                    */}
                    <video ref={this.maxVideoRef} autoPlay poster={window._env_.REACT_APP_ASSET_FOLDER+"/session_bg_logo.png"} /**TP-3609*/ className="video-stream d-none" muted={maxDivParticipant.isThisMe} /*TP-6148*//>
                    <StreamActions 
                        {...this.props} {...newProps}
                    />
                </div>
                {/*TP-5681 TP-5551 this.state.showIsMutedbutSpeaking === true && maxDivParticipant.disableVideo === true ?
                    <span style={{width: pausedTextContainerWidth}}><span className='paused-text-other' style={{marginLeft: muteAudioSpeakingLeft}}><Trans>session.mutedbuttalking</Trans></span></span>
                    :
                    ''
                */}            
            </>
        )
    }

    singleExpertGridCM = () => {
        //printConsole("Single Expert view during CM enabled");
        const {vdoheight, vdowidth, participants, maxDivSpeakerMuted, maxDivSpeakerDisabled, maxDivMikeMuted, maxDivParticipant, groupInfo, bMaxChat, resetMax, maxView, otherParticipants, changeHandIcon, expertShareScreenMaxDiv, showHideDivIconAudioOnly, bAllChat, /**TP-2292*/expertParticipantLeft, clickHandSignal, /**TP-1313 */ zoomClass, user, gridHandIconHide, screenCastStarted /**TP-2971*/} = this.state;
        const newProps = {maxView, participants, maxDivSpeakerMuted, maxDivSpeakerDisabled, maxDivMikeMuted, maxDivParticipant, onclickMuteSpeakerMaxDiv: this.onclickMuteSpeakerMaxDiv,
                onclickMuteMikeMaxDiv: this.onclickMuteMikeMaxDiv, screenCaptureClicked:this.state.screenCaptureClicked,
                takeScreenShot: this.takeScreenShot, screen_recording_enabled: groupInfo.screen_recording_enabled, maxDivActionGoingOn: this.maxDivActionGoingOn,
                groupInfo, onClickMaxChat: this.onClickChat, onClickChatAll: this.onClickChatAll, bMaxChat, bAllChat, closeChat: this.closeChat, initMaxScreenShare: this.initMaxScreenShare,
                isMaxDivSS: this.state.isMaxDivSS, isSideBarSS: this.state.isSideBarSS, showMaxDivControls: this.state.showMaxDivControls, currentPrimaryExpert: this.state.currentPrimaryExpert,
                setPrimaryExpertIDApi: this.setPrimaryExpertIDApi, startMultiExpertWaitTimer: this.startMultiExpertWaitTimer, makeMeExpert: this.makeMeExpert, triggerMessage: this.triggerMessage, 
                bHideExpertSSButton: this.state.bHideExpertSSButton, bHideZoomButton: this.state.bHideZoomButton, bHideTorchButton: this.state.bHideTorchButton, startRecording: this.state.startRecording,
                onClickRecordScreen: this.onClickRecordScreen, stopRecordingCallback: this.stopRecordingCallback, resetMax, showHideDivIconAudioOnly: true, handleScreenShareIcons: this.handleScreenShareIcons, 
                aspectRatio: this.state.aspectRatio, initSideBarScreenShare: this.initSideBarScreenShare, changeHandIcon, enable_remote_capture: groupInfo.enable_remote_capture, onClickTorchIcon: this.onClickTorchIcon, 
                onClickZoomIcon: this.onClickZoomIcon, setRequestMaxViewControl: this.setRequestMaxViewControl, setClickHandSignal: this.setClickHandSignal, clickHandSignal, /* TP-1313*/ expertShareScreenMaxDiv, 
                onClickRecordScreen: this.onClickRecordScreen, expertParticipantLeft, setExpertParticipantLeft: this.setExpertParticipantLeft, /**TP-2292*/ triggerMessage: this.triggerMessage /**TP-1677 */,
                resetMaxUserZoom: this.state.resetMaxUserZoom, resetMaxUserTorch: this.state.resetMaxUserTorch /**TP-2861 */, rotateUserZoom: this.rotateUserZoom, isSmallFormFactorDevice: (this.state.hideButton === "d-none" ? true : false),
                sessionType: groupInfo.session_type === "CM" || (groupInfo.session_type === "RM" && (this.checkIfExpertCameraEnabled() === true || this.isExpertVideo() === true)) ? true : false, /**TP-3119*/
                gridHandIconHide, /**TP-3904 */ screenCastStarted, /**TP-2971*/ hideHandIcon: this.state.hideHandIcon, /**TP-4543*/ triggerCloseRC: this.state.triggerCloseRC, triggerCloseAnnotate: this.state.triggerCloseAnnotate,
                convertMeToPrimary: this.convertMeToPrimary, isGuest: this.props.isGuest, /**TP-5089*/ isSSOngoing: this.state.isSSOngoing, /**TP-5397*/ showIsMutedbutSpeaking: this.state.showIsMutedbutSpeaking 
            }
        const logoHeight = Math.floor(vdoheight - ((vdoheight * 60)/100)); //TP-4939
        const profileHeight = Math.floor(vdoheight - ((vdoheight * 60)/100)); //TP-3609
        const sideBar = document.getElementById('sideBar');
        let sideBarWidth = sideBar ? sideBar.offsetWidth : 0;
        const pausedTextContainerWidth = vdowidth + "px";
        const pausedTextleft = Math.floor((window.innerWidth - sideBarWidth - 20  - 412)/2) + "px";
        const muteAudioSpeakingLeft = Math.floor((vdowidth - 350)/2) + "px";
        const muteAudioSpeakingTop = vdoheight >= 585 ? Math.floor((vdoheight * 80)/100) + "px" : vdoheight >= 420 ? Math.floor((vdoheight * 75)/100) + "px" : Math.floor((vdoheight * 68)/100) + "px"; //
        const ssLoaderLeft = Math.floor((window.innerWidth - 75)/2) + "px"; //TP-4909
        return(
            <>
                <div className="col-sm-12 text-center">
                    {/* the max div video ------------------*/}
                    { maxDivParticipant.disableVideo === true ?    
                        /**TP-3201 TP-3497 */ (maxDivParticipant.myPicture !== undefined && maxDivParticipant.myPicture !== null && maxDivParticipant.myPicture !== "") ?
                            <img className="session-profile-pic" alt="User profile" src={maxDivParticipant.myPicture} style={{height: profileHeight, width: profileHeight}}></img>
                            :                    
                            <img alt="User profile" src={"/profile.png"} style={{height: logoHeight}}></img>
                        :                       
                        ''                            
                    }
                    {/*TP-5681 TP-5673 TP-5551 this.state.showIsMutedbutSpeaking === true && maxDivParticipant.disableVideo === false ?
                        <span style={{width: pausedTextContainerWidth}}><span className='muted-text' style={{marginLeft: muteAudioSpeakingLeft, marginTop: muteAudioSpeakingTop}}><Trans>session.mutedbuttalking</Trans></span></span>
                        :
                        ''
                    */}
                    {/**TP-4909*/ this.state.showSSLoader === true ? 
                        <span className="SimpleVideo-loader" style={{ marginLeft: ssLoaderLeft }}>Buffering</span>
                        :
                        ''
                    }
                    <video ref={this.maxVideoRef} autoPlay style={maxDivParticipant.disableVideo === false ? {height: vdoheight, width: vdowidth}: {height: "0px", width: "0px"}} className={maxDivParticipant.disableVideo === false ? "video-stream ": "video-stream d-none"} muted={maxDivParticipant.isThisMe}/**TP-6148*/></video>
                    <div id="canvasDiv" ></div>
                    {
                        /* T32-495-- !maxDivParticipant.isexpert ? */
                            <StreamActions 
                                {...this.props} {...newProps}
                            />
                            /* :
                            '' */
                    }
                    {/**TP-2967 & TP-2933 */ this.state.hideButton === "d-none" && window.innerHeight <= 420 && maxDivParticipant.zoomLevel > 1 /* && this.state.showMaxDivControls === true  */ ?
                            <I18n>
                                {({ i18n }) =>
                                    <div className={"position-absolute text-center web-grid-view-controls-parent nameDiv "+zoomClass} 
                                        style={{ color: 'white', zIndex:1, right: '10%' }}>
                                        {this.processZoomLevels(maxDivParticipant.zoomLevel+"X", i18n)}
                                    </div>
                                }
                            </I18n>
                            :
                            ''
                    }
                    {/* max div video end ------------------------*/}
                    {/* other participant audio stream ---------------- */}
                    {
                        <OtherParticipantAudioTrack  otherParticipants={otherParticipants}/>
                    }
                    {/* other participant audio stream end */}
                    {/* the mute mike dialog */}
                    { this.muteMikeForTechDialog() }
                    {/* -------end---------- */}
                </div>
                { /*TP-5681 TP-5551 this.state.showIsMutedbutSpeaking === true && maxDivParticipant.disableVideo === true ?
                    <span style={{width: pausedTextContainerWidth}}><span className='paused-text-other' style={{marginLeft: muteAudioSpeakingLeft}}><Trans>session.mutedbuttalking</Trans></span></span>
                    :
                    ''
                */}
            </>
        )
    }

    //TP-4797
    singleSilhouetteView = () => {
        //printConsole("Single Silhouette View");
        //TP-5164 Use case 1 -- Added the Stream Actions component to be able to show the Request Maxdiv controls dialog box
        const {participants, maxDivSpeakerMuted, maxDivSpeakerDisabled, maxDivMikeMuted, 
            maxDivParticipant, groupInfo, bMaxChat, resetMax, maxView, changeHandIcon, 
            expertShareScreenMaxDiv, bAllChat, /**TP-2292*/expertParticipantLeft, 
            clickHandSignal, /**TP-1313 */ user, gridHandIconHide, screenCastStarted, /**TP-2971*/otherParticipants} = this.state;
        const newProps = {maxView, participants, maxDivSpeakerMuted, maxDivSpeakerDisabled, maxDivMikeMuted, maxDivParticipant, onclickMuteSpeakerMaxDiv: this.onclickMuteSpeakerMaxDiv,
            onclickMuteMikeMaxDiv: this.onclickMuteMikeMaxDiv, screenCaptureClicked:this.state.screenCaptureClicked,
            takeScreenShot: this.takeScreenShot, screen_recording_enabled: groupInfo.screen_recording_enabled, maxDivActionGoingOn: this.maxDivActionGoingOn,
            groupInfo, onClickMaxChat: this.onClickChat, onClickChatAll: this.onClickChatAll, bMaxChat, bAllChat, closeChat: this.closeChat, initMaxScreenShare: this.initMaxScreenShare,
            isMaxDivSS: this.state.isMaxDivSS, isSideBarSS: this.state.isSideBarSS, showMaxDivControls: this.state.showMaxDivControls, currentPrimaryExpert: this.state.currentPrimaryExpert,
            setPrimaryExpertIDApi: this.setPrimaryExpertIDApi, startMultiExpertWaitTimer: this.startMultiExpertWaitTimer, makeMeExpert: this.makeMeExpert, triggerMessage: this.triggerMessage, 
            bHideExpertSSButton: this.state.bHideExpertSSButton, bHideZoomButton: this.state.bHideZoomButton, bHideTorchButton: this.state.bHideTorchButton, startRecording: this.state.startRecording,
            onClickRecordScreen: this.onClickRecordScreen, stopRecordingCallback: this.stopRecordingCallback, resetMax, showHideDivIconAudioOnly: true, handleScreenShareIcons: this.handleScreenShareIcons, 
            aspectRatio: this.state.aspectRatio, initSideBarScreenShare: this.initSideBarScreenShare, changeHandIcon, enable_remote_capture: groupInfo.enable_remote_capture, onClickTorchIcon: this.onClickTorchIcon, 
            onClickZoomIcon: this.onClickZoomIcon, setRequestMaxViewControl: this.setRequestMaxViewControl, setClickHandSignal: this.setClickHandSignal, clickHandSignal, /* TP-1313*/ expertShareScreenMaxDiv, 
            onClickRecordScreen: this.onClickRecordScreen, expertParticipantLeft, setExpertParticipantLeft: this.setExpertParticipantLeft, /**TP-2292*/ triggerMessage: this.triggerMessage /**TP-1677 */,
            resetMaxUserZoom: this.state.resetMaxUserZoom, resetMaxUserTorch: this.state.resetMaxUserTorch /**TP-2861 */, rotateUserZoom: this.rotateUserZoom, isSmallFormFactorDevice: (this.state.hideButton === "d-none" ? true : false),
            sessionType: groupInfo.session_type === "CM" || (groupInfo.session_type === "RM" && (this.checkIfExpertCameraEnabled() === true || this.isExpertVideo() === true)) ? true : false, /**TP-3119 */
            gridHandIconHide, /**TP-3904 */ screenCastStarted, /**TP-2971*/ hideHandIcon: this.state.hideHandIcon, /**TP-4543*/ triggerCloseRC: this.state.triggerCloseRC, triggerCloseAnnotate: this.state.triggerCloseAnnotate,
            convertMeToPrimary: this.convertMeToPrimary, isGuest: this.props.isGuest, /**TP-5089*/ isSSOngoing: this.state.isSSOngoing, /**TP-5397*/ showIsMutedbutSpeaking: this.state.showIsMutedbutSpeaking 
        }
        let vdowidth, vdoheight, asRatio = "16:9";        
            let winWidth = window.innerWidth;
            let winHeight = window.innerHeight;
            const sideBar = document.getElementById('sideBar');
            let sideBarWidth = sideBar ? sideBar.offsetWidth : 0;
            vdowidth = Math.floor(winWidth - sideBarWidth - 20); // 20 is the margin
            vdoheight = Math.floor((vdowidth * 9)/16);
            //TP-2949 -- Use case 3
            //printConsole(`inner Height: ${winHeight}, inner Width: ${winWidth}, sideBarWidth: ${sideBarWidth}, vdoheight: ${vdoheight}, vdowidth: ${vdowidth}`);
            if (vdoheight > winHeight) {
                const headerBar = document.getElementById('header_bar');
                let headerHeight = headerBar? headerBar.offsetHeight : 0;
                vdoheight = Math.floor(winHeight - headerHeight - 15);// 20 is the margin
                vdowidth =  (asRatio === "4:3") ? Math.ceil((vdoheight * 4)/3) : Math.floor((vdoheight * 16)/9);
                //printConsole(`changed -- inner Height: ${winHeight}, headerHeight: ${headerHeight}, vdoheight: ${vdoheight}, vdowidth: ${vdowidth}`);
            }
        const logoHeight = Math.floor(vdoheight - ((vdoheight * 60)/100)); //TP-4939
        const pausedTextContainerWidth = vdowidth + "px";
        const pausedTextleft = Math.floor((vdowidth - 5 )/2) + "px"; //TZ-1011
        //const pausedTextTop = vdoheight >= 585 ? Math.floor((vdoheight * 80)/100) + "px" : vdoheight >= 420 ? Math.floor((vdoheight * 75)/100) + "px" : Math.floor((vdoheight * 70)/100) + "px";
        const pausedTextTop = Math.floor(winHeight/8) + "px";
        const pausedText = "Waiting for Technician users to join the session";
        const muteAudioSpeakingLeft = Math.floor((vdowidth - 350)/2) + "px";
        const muteAudioSpeakingTop = vdoheight >= 585 ? Math.floor((vdoheight * 80)/100) + "px" : vdoheight >= 420 ? Math.floor((vdoheight * 75)/100) + "px" : Math.floor((vdoheight * 68)/100) + "px"; //TP-5673 
        return (
            <div className="col-sm-12 text-center">
                {/*************************** Expert to expert Max-view with silhouette*******************/}
                {   <>
                        <img alt="User profile" src={"/profile.png"} style={{height: logoHeight}}></img>
                        <span style={{width: pausedTextContainerWidth, display: "block", height: "50px"}}><span className='paused-text' style={{marginLeft: pausedTextleft, marginTop: pausedTextTop, display: "block"}}><Trans id={pausedText} values={{role: this.props.customerRoles.expert}}></Trans></span></span>
                        { /*this.state.showIsMutedbutSpeaking === true ?
                            <span style={{width: pausedTextContainerWidth, display: "block", height: "60px"}}><span className='muted-text' style={{marginLeft: muteAudioSpeakingLeft}}><Trans>session.mutedbuttalking</Trans></span></span>
                            :
                            ''
                        */}
                        <video ref={this.maxVideoRef} autoPlay style={{height: "0px", width: "0px"}} className={"video-stream d-none"} muted={maxDivParticipant.isThisMe} /**TP-6148*/></video>{/**TP-5468 */}
                        {/**TP-5164*/}
                        <StreamActions 
                            {...this.props} {...newProps}
                        />

                        {/*TP-5468 other participant audio stream ---------------- */}
                        {
                            <OtherParticipantAudioTrack  otherParticipants={otherParticipants}/>
                        }
                        {/* other participant audio stream end */}
                        {/* the mute mike dialog */}
                        { this.muteMikeForTechDialog() }
                        {/* -------end---------- */}
                    </>                    
                }
            </div>
        )
    }

    // go back to the grid view from max view
    onClickMultiGridView = (e) => {
        e && e.preventDefault();
        const { groupInfo } = this.state;
        printConsole("on Click Multi user Grid View=========================>")
        //TP-5681
        if (this.state.showFlashMessage === true && this.state.flashMsgText === "session.mutedbuttalking"){
            this.setState({ flashMsgText: "", showFlashMessage: false, flashLeft: "", flashTop: "", flashTimer: true});
        }
        let technicians = this.state.participants.filter(p => !p.isexpert);
        //TP-5399 one tech and multi expert call if tech leaves while max div actions going on we will not stop from going to max view
        if (this.state.showMaxDivControls === false && ((this.state.maxDivIconClicked && !this.props.isHeaderFileShare) || this.state.isMaxDivSS) && technicians.length> 0) return; //except while Artifacts are open on Passive expert switching is disabled
        else if (this.state.showMaxDivControls === true && (this.state.maxDivIconClicked || this.state.isMaxDivSS) && (technicians.length> 0)) return;// while rc/or annotation is going on switching is disabled
        if(this.state.isMaxDivSS) this.initMaxScreenShare(false);//MB2-614
        if (this.state.bMaxChat) this.setState({ bMaxChat: false });
        //const {mutedAll, headerMuteMike, isSideBarSS} = this.state
        if(this.maxVideoRef.current){
            const participant = this.state.participants.find((p) => p.email === this.state.maxDivParticipant.email) //TP-6148

            // this.state.showMaxDivControls && participant && this.muteSpeakerOfOne({participant, flag: !mutedAll})// mute/unmute the speaker for the outgoing participant// only by primary expert, Mb2-495
            //this.state.showMaxDivControls && participant && this.sendMuteMikeSignal({participant, flag: !headerMuteMike})// mute/unmute the mike for the outgoing participant// only by primary expert
            this.state.showMaxDivControls && participant && this.checkAudioStatGrid({participant});
            this.maxVideoRef.current.srcObject = null;
            this.state.showMaxDivControls && this.sendMaxViewSignal({flag: false});// disable max view for outgoing 
        }
        printConsole("Set maxDivParticipant as empty");
        this.props.setMaxDivId({g_maxDivId: 'allVideos', maxDivParticipant: ''})// set maxdiv id to allVideos
        this.setState({maxView: false, maxDivParticipant: '', maxDivParticipantEmail: '', /**TP-4955*/ hideExpertSSAll: false, disableGridView: true})
        // if this expert is primary expert then sent it to all other passive expert
        if(this.state.showMaxDivControls){
            const { user } = this.props;
            /* if (this.props.isHeaderFileShare && this.props.isOneToOneFileShare === false)
                this.props.closeFileViewer(); */
            this.setState({ disableGridView: true });
            let experts = this.state.participants.filter(p => p.isexpert)
            /* !isSideBarSS && */ experts && experts.length> 0 && experts.forEach((participant) => {
                printConsole(`onClickMultiGridView sendSignalSyncExperts=================================>, ${JSON.stringify(participant)}`)
                this.sessionSignal.sendSignalSyncExperts({
                    show_grid: true,
                    hand_icon_enabled: (this.state.showControlRequestDialog === true) ? false : true, //TP-1313
                    reason: "show_action",
                    header_fileShare_active: (this.props.isHeaderFileShare && !this.props.isOneToOneFileShare) ? true : false, //TP-2378
                },  participant.email);
            })

            //TP-3114 -- New signal to sync up all the Technician's Views with the Expert's when CM is enabled
            /*TP-4684 TP-4683 if (groupInfo.session_type === "CM" || (groupInfo.session_type === "RM" && this.checkIfExpertCameraEnabled() === true) || this.props.audio_mode === "tech2Tech" /**TP-3551) {
                //TP-3119
                let technicians = this.state.participants.filter(p => !p.isexpert);
                /*TP-4422 !this.state.isSideBarSS && technicians && technicians.forEach(t => {
                    printConsole(`onClickMultiGridView sending sendSignalCurrentMaxViewUser===========> to ${t.email}`);
                    this.sessionSignal.sendSignalCurrentMaxViewUser({
                        is_grid: true},
                    t.email)
                })
            }*/
        }
    }

    // check if the current expert will be the primary expert
    checkPrimaryExpert = () => {
        let experts = this.state.participants.filter(p => p.isexpert)
        printConsole(`checkPrimaryExpert===========================================> ${this.state.groupInfo.primary_expert_id}, ${this.state.user.id}, ${this.state.currentPrimaryExpert}`)
        if (this.state.currentPrimaryExpert !== null && this.state.user.id !== this.state.currentPrimaryExpert.id) {
            printConsole("current primary expert is set and it isn't me");
            return;
        }
        else if (this.state.user.id === this.state.groupInfo.primary_expert_id && this.state.currentPrimaryExpert !== null) {
            printConsole("make me expert...");
            this.makeMeExpert({experts});
        // TP-105 code moved to updateUserlist            
        // } else if(experts && experts.length> 0 ){//&& this.state.maxView && !this.state.expertShareScreenMaxDiv            
            // printConsole("send signal to primary expert");
            // experts.forEach((participant) => {
            //     //this.sessionSignal.sendSignalQueryExpertMaxdivSwitch(participant.email); // send to each expert
            //     this.sessionSignal.sendSignalSyncExperts({reason: "request_expert_sync"}, participant.email) // send to each expert
            // })
            // blocking here since we are calling getGroupDetails()
            // this.setState({ connections: [] }, () => {
            //     // sometimes expert will recieve sessiondataH364 API response even later than its peerconnections starts
            //     // to handle those cases, the expert needs to start the peer-connection after receiving the response
            //     if(this.state.participants.length > 0){
            //         this.callUser();
            //     }                

            // })
        } else if (this.state.user.id === this.state.groupInfo.primary_expert_id && this.state.currentPrimaryExpert === null) {
            // TP-2166 -- To handle the use case when and expert joins the session with primary_expert_id = user.id
            // but the currentPrimaryExpert isn't set
            printConsole("set my id as Primary expert") 
            this.setMeAsPrimary({experts});            
        }
    }

    // process the SYNC_EXPERTS with reason="show_action" signal
    triggerExpertViewControlsSync = ({from, data}) => {
        //console.log("Sync the passive xpert view...");
        this.triggerExpertSyncView({data, from});
        const {zoom_button_active, zoom_level, torch_button_active, max_view_technician} = data;
        let {maxDivParticipant} = this.state;
        if (zoom_button_active === undefined && zoom_level === undefined && torch_button_active === undefined) return printConsole("Undefined data");
        else {
            try {
                let participants = [...this.state.participants];
                if (max_view_technician !== '') {
                    const [to] = this.state.participants.filter(e => e.email === max_view_technician);
                    const index = this.state.participants.findIndex(e => e.email === max_view_technician);
                    //TP-3893
                    if (to) {
                        to.torchActive = torch_button_active;
                        to.zoomLevel = zoom_level;
                        // Checking for the Max-view participant's Zoom & Torch values
                        if(maxDivParticipant.email === max_view_technician) {
                            maxDivParticipant.torchActive = torch_button_active;
                            maxDivParticipant.zoomLevel = zoom_level;
                        }

                        //TP-4332 -- Handled the local torch & zoom signal for camera enabled expert session
                        let updatedParticipants = [...this.state.updatedParticipants];
                        if (updatedParticipants && updatedParticipants.length > 0) {
                            const [up] = updatedParticipants.filter(u => u.email === max_view_technician);
                            const upindex = updatedParticipants.findIndex(u => u.email === max_view_technician);
                            up.torchActive = torch_button_active;
                            up.zoomLevel = zoom_level;
                            updatedParticipants[upindex] = up;
                            this.setState({updatedParticipants});
                        }
        
                        participants[index] = to;
                        this.setState({participants, maxDivParticipant}, () => {
                            if (this.state.maxView) {
                                this.setState({resetMax: true}, ()=>{
                                    this.setState({resetMax: false});
                                }); // check and auto set the Zoom & Torch Icon states for that max-view participant
                                this.setZoomClass(); //TP-2933
                            }
                        });
                    }
    
                }
                
            } catch (error) {
                printConsole ("Exception occured in the triggerExpertViewControlsSync method");
                printConsole(error);
            }
        }
    }

    // process the SYNC_EXPERTS with reason="show_action" signal
    triggerExpertSyncView = ({data, from}) => {
        const {expertShareScreenMaxDiv, ssFrom, participants, userVideoCount, groupInfo} = this.state;
        const {bRemoteCapture, enable_user_video} = this.props;
        //console.log(expertShareScreenMaxDiv, ssFrom, this.state.isScreencasting, bRemoteCapture);
        if (expertShareScreenMaxDiv && ssFrom !== '') {
            // TP-1452
            if (data.show_grid === true && (typeof(data.max_view_technician) === "undefined" || data.max_view_technician === ''))
                this.setState({ prevMaxDivPartcicipantEmail: ''}); //set Grid view
            else
                this.setState({ prevMaxDivPartcicipantEmail: data.max_view_technician}) //set Max View
            return;
        }
        if (this.state.isScreencasting === true) return; //TP-3955
        if (bRemoteCapture) return;
        //if (isOneToOneFileShare) return;
        printConsole('triggerExpertSyncView=================================>')
        printConsole(data);
        const technicians = participants.filter(p => !p.isexpert);
        printConsole(`count of Technicians in the session ${technicians.length}`);
        const {maxDivParticipant, aspectRatio} =  this.state;
        const {show_grid, max_view_technician, ratio} = data;
        //TP-3661
        if (show_grid === true && this.state.maxView === true) {  
            if (enable_user_video === "my_camera_disabled" && technicians.length > 0 ) {
                this.setState({maxDivParticipantEmail: ""})//MB2-608
                this.onClickMultiGridView();
                this.triggerMessage("Switch to Grid View", "switch-to-grid");
            } else if (enable_user_video === "my_camera_disabled" && technicians.length === 0) {
                const [expert] = this.state.participants.filter(p => p.isexpert); //TP-5482 TP-5468 First expert in the expert list 
                //TP-5202 - Show the Maximized Silhouette view during "my_camera_disabled" state and only experts remaining in call
                this.onMaximizeSilhouetteView({participant: expert});
            } else if (groupInfo.session_type === "CM" || (groupInfo.session_type === "RM" && (this.checkIfExpertCameraEnabled() === true || this.isExpertVideo() === true))) {
                this.setState({maxDivParticipantEmail: ""})//MB2-608
                this.onClickMultiGridView();
                this.triggerMessage("Switch to Grid View", "switch-to-grid");
            } else if (participants.length > 1) {
                //TP-3681
                this.setState({maxDivParticipantEmail: ""})//MB2-608
                this.onClickMultiGridView();
                this.triggerMessage("Switch to Grid View", "switch-to-grid");
            } else {
                printConsole("Grid view cannot be drawn with only experts (without Video being turned on)")
            }         
            
        } else if (show_grid === false && max_view_technician !== undefined) {
            /**TP-3734*/
            //TP-4797
            if (this.props.enable_user_video === "my_camera_disabled" && technicians.length === 0) {
                //TP-5202 Show the Maximized Silhouette view during "my_camera_disabled" state and only experts remaining in call
                const [expert] = this.state.participants.filter(p => p.isexpert); //TP-5482 TP-5468 First expert in the expert list 
                this.onMaximizeSilhouetteView({participant: expert});
            } else {
                //TP-3893
                if (max_view_technician === '' && technicians.length === 0) {
                    if (this.props.enable_user_video === "my_camera_disabled") {
                        //Show the Maximized Silhouette view during "my_camera_disabled" state and only experts remaining in call
                        const [expert] = this.state.participants.filter(p => p.isexpert); //TP-5482 TP-5468 First expert in the expert list 
                        this.onMaximizeSilhouetteView({participant: expert});
                    } else {
                        //TP-5459
                        if (technicians.length === 0 && this.state.participants.length === 1) {
                            const expertParticipant = this.state.participants[0];
                            this.onClickMaximise({participant: expertParticipant, isSame: false});
                            this.triggerMessage(expertParticipant.first_name+" "+expertParticipant.last_name, "switch-to-max");
                        }
                        this.setState({maxDivParticipantEmail: ""})//MB2-608
                        this.onClickMultiGridView();
                        this.triggerMessage("Switch to Grid View", "switch-to-grid");
                    }
                } else if (max_view_technician === '') {
                    
                        const [participant] = this.state.participants.filter(e => e.email === from.email/*  && e.disableVideo === false */);
                        printConsole(`The Max-view expert is: ${JSON.stringify(participant)}`);
                        if (participant) {
                            this.onClickMaximise({participant, isSame: false});
                            this.triggerMessage(participant.first_name+" "+participant.last_name, "switch-to-max");
                        }
                    
                    /* else    
                        this.onClickMaximizeExpert({participant}) */
                } else {
                    //TP-3893
                    let participant;
                    if (this.checkIfExpertCameraEnabled() === true) {
                        [participant] = this.state.updatedParticipants.filter(p => p.email === max_view_technician)
                    } else {
                        [participant] = this.state.participants.filter(p => p.email === max_view_technician)                    
                    }
                    this.setState({maxDivParticipantEmail: max_view_technician})//MB2-608
                    if ((participant && maxDivParticipant && participant.stream_id !== maxDivParticipant.stream_id) || (maxDivParticipant === "" && participant)) {
                        if (participant.disableVideo === false && this.state.showHideDivIconAudioOnly === true) this.setState({ showHideDivIconAudioOnly: false })
                        this.onClickMaximise({participant, isSame: false});
                        //TP-4772
                        if (this.state.aspectRatio !== ratio && (this.props.bAnnotate === true || this.props.bRemoteCapture === true))
                            this.props.triggerRerenderPassiveCanvas();
                        this.calVdoHeightWidth(ratio);
                        if (/*TP-3893*/enable_user_video === "my_camera_disabled" && userVideoCount === 0) this.setState({ showHideDivIconAudioOnly: true });
                        participant && this.triggerMessage(participant.first_name+" "+participant.last_name, "switch-to-max");
                    } else if(aspectRatio !== ratio){ 
                        this.calVdoHeightWidth(ratio);
                        //TP-4772
                        if (this.props.bAnnotate === true || this.props.bRemoteCapture === true)
                            this.props.triggerRerenderPassiveCanvas();
                    }
                }
            }
        } else {
            return;
        }
    }

    // process the SYNC_EXPERTS with reason="update_action" signal
    triggerExpertGridViewSync = ({from, data}) => {
        try {
            const {email, zoom_level, torch_button_active} = data;
            let participants = [...this.state.participants];
            let {maxDivParticipant} = this.state;
            ///Here
            if (zoom_level === undefined && torch_button_active === undefined) return printConsole("zoom & torch data undefined");
            else {
                const [to] = this.state.participants.filter(e => e.email === email);
                const index = this.state.participants.findIndex(e => e.email === email);
    
                to.torchActive = torch_button_active;
                to.zoomLevel = zoom_level;
                // Checking for the Max-view participant's Zoom & Torch values
                if(maxDivParticipant.email === email) {
                    maxDivParticipant.torchActive = torch_button_active;
                    maxDivParticipant.zoomLevel = zoom_level;
                }

                //TP-4332 -- Handled the local torch & zoom signal for camera enabled expert session
                let updatedParticipants = [...this.state.updatedParticipants];
                if (updatedParticipants && updatedParticipants.length > 0) {
                    const [up] = updatedParticipants.filter(u => u.email === email);
                    const upindex = updatedParticipants.findIndex(u => u.email === email);
                    up.torchActive = torch_button_active;
                    up.zoomLevel = zoom_level;
                    updatedParticipants[upindex] = up;
                    this.setState({updatedParticipants});
                }

                participants[index] = to;
                this.setState({participants, maxDivParticipant}, () => {
                    if (this.state.maxView) {
                        this.setZoomClass(); //TP-2933
                        this.setState({resetMax: true}, ()=>{
                            this.setState({resetMax: false});
                        }); // check and auto set the Zoom & Torch Icon states for that max-view participant
                    }
                });
            }            
        } catch (error) {
            printConsole ("Exception occured in the triggerExpertGridViewSync method");
            printConsole(error);
        }

    }

    // TP-1906 -- Sending the current status of the recording button to newly joining Passive expert
    triggerExpertQueryMaxDiv = ({from}) => {
        printConsole(`started recording: ${this.state.startRecording} isSilent: ${isSilentRecording} isSafari Browser: ${this.props.isSafari} isFirefox Browser: ${this.props.isFirefox}`);
        //check if currently screen recording is active or not
        if (this.state.startRecording && isSilentRecording === false && !this.props.isSafari && !this.props.isFirefox && this.state.hideButton !== 'd-none') {//TP-2262
            const experts = this.state.participants.filter(p => p.isexpert);
            experts && experts.forEach(e => {
                this.sessionSignal.sendSignalSyncExperts({
                    isrecording_active: true,
                    is_silent_recording: isSilentRecording,
                    recording_folder_name: this.state.recFileName,
                    frames_per_seconds: screenSettings[2].fps,
                    frame_count: count,
                    reason: "recording_sync"},
                e.email) && printConsole (`Sending the SYNC_EXPERTS signal to all Passive experts with recording_active = true`);
            })
        }
    }

    //TP-552 -- set the Registered Expert flag for an Expert user in Guest expert user flow
    setRegisteredExpert = ({ from, data }) =>{
        printConsole(`the isRegistered flag is ${data.isRegistered} from ${from.email}`);
        let participants = [...this.state.participants];
        let [participant] = this.state.participants.filter((p) => p.email === from.email);
        const index = participants.findIndex((e) => e.email === from.email);
        if (participant) {
            printConsole(`found expert ----> ${participant.email}`);
            printConsole(data.isRegistered);
            if (participant.isRegistered !== data.isRegistered) {
                participant.isRegistered = data.isRegistered;
                participants[index] = participant;
                this.setState({ participants });
            } else 
                printConsole("the isRegistered flag is same as currently set for this Expert");
        }
    }

    //TP-740 -- setting the recording feature button and related parameters on Passive expert 
    //when Primary expert has recording ongoing
    setRecordingStatus = ({from, data}) => {
        const {recording_folder_name, is_silent_recording, frame_count} = data;
        if (data.isrecording_active === true && !this.props.isSafari && !this.props.isFirefox && this.state.hideButton !== 'd-none') {
            //let countString = frame_count.replace(recording_folder_name, ''); //frame_count.substr(0)
            //console.log(frame_count, countString);
            count = parseInt(frame_count);
            isrecording_active = data.isrecording_active;
            isSilentRecording = is_silent_recording;
            this.setState({ recFileName: recording_folder_name });
        } else {
            count = 0;
            isrecording_active = data.isrecording_active;
            // TP-2262 -- Handling the stopping of Recording if Expert who takes up control is on Safari/Firefox browsers
            isSilentRecording = is_silent_recording;
            if (data.isrecording_active === true && (this.props.isSafari || this.props.isFirefox || this.state.hideButton === 'd-none')) {
                this.setState({ recFileName: recording_folder_name })
            } else 
                this.setState({ recFileName: ''});
        }
    }

    // Post the 10 seconds timeout check if this user is the to be Primary expert
    checkPrimaryExpertTimeOut = () => {
        if (this.state.showWebrtcErrorMessage === true) {
            printConsole(" Post 10 seconds into the session if Expert camera/microphone device error is detected then stop the Primary expert timer");
            this.stopprimaryExpertCheck(); //TP-5098
        } else {
            printConsole(" Post 10 seconds into the session check if other Exprts in session or not")
            const allParticipants = [...this.state.participants];
            let allExperts = allParticipants.filter(p => p.isexpert);
            printConsole("Currently joined experts");
            printConsole(allExperts);
            if(allExperts.length > 0){
                printConsole('checkPrimaryExpertTimeOut all experts==========================>', this.state.currentPrimaryExpert, allExperts);
                if(this.state.currentPrimaryExpert === null){
                    allExperts.sort((e1, e2) => e1.joinTimeStamp - e2.joinTimeStamp)
                    printConsole(`sorted experts==========================> ${this.state.user.joinTimeStamp}`)
                    printConsole(`other expert's =====================> ${allExperts[0].joinTimeStamp}`);
                    if(allExperts[0].joinTimeStamp > this.state.user.joinTimeStamp){
                        this.makeMeExpert({experts: allExperts});
                        if (this.props.isRespondToCall === false /**TP-2462*/ && this.props.isUserCallDone === false /**TP-5036*/)
                            this.startMissedCallNotifyTimer(); // TP-1129
                        this.setState({ changeHandIcon: true}, () => {
                            this.setState({ changeHandIcon: false })
                        })
                    }
                }
            }else {
                printConsole(" No other Expert users in session ");
                /**TP-2462*/
                if (this.state.currentPrimaryExpert === null && this.state.user.id !== this.state.groupInfo.primary_expert_id) {
                    this.makeMeExpert({experts: allExperts});
                    if (this.props.isRespondToCall === false /**TP-2462*/ && this.props.isUserCallDone === false /**TP-5036*/)
                        this.startMissedCallNotifyTimer(); // TP-1129
                    this.setState({ changeHandIcon: true}, () => {
                        this.setState({ changeHandIcon: false })
                    })
                }
            }
            this.stopprimaryExpertCheck();
        }
    }

    // check the whether this user is suppose to be the primary expert
    checkPrimaryExpertPriviledge = () => {
        const {isGuest} = this.props;
        printConsole(`is expert a Guest user ? ${isGuest}`);
        const allParticipants = [...this.state.participants];
        let allExperts = allParticipants.filter(p => p.isexpert);
        printConsole("Currently joined experts");
        printConsole(allExperts);
        let registeredExperts = allParticipants.filter(p => p.isexpert && p.isRegistered);
        printConsole("Current joined Registered Experts ------>")
        printConsole(registeredExperts);
        if (isGuest && registeredExperts && registeredExperts.length === 0) {
            this.props.onSessionCmdChange('session_stopped');
        }
        else if(!isGuest && registeredExperts.length > 0){
            printConsole('checkPrimaryExpertPriviledge all experts==========================>', this.state.currentPrimaryExpert, allExperts);
            if(this.state.currentPrimaryExpert === null){
                registeredExperts.sort((e1, e2) => e1.joinTimeStamp - e2.joinTimeStamp)
                printConsole(`sorted experts==========================> ${this.state.user.joinTimeStamp}`)
                printConsole(registeredExperts);
                if(registeredExperts[0].joinTimeStamp > this.state.user.joinTimeStamp){
                    this.makeMeExpert({experts: allExperts});
                    this.setState({ changeHandIcon: true}, () => {
                        this.setState({ changeHandIcon: false })
                    })
                }
            }
        }else if (!isGuest){
            //TP-5458
            if(this.state.currentPrimaryExpert === null || this.state.currentPrimaryExpert.id !== this.state.user.id) {
                this.makeMeExpert({experts: allExperts});
                const {user} = this.props;
                const {groupInfo} = this.state;
    
                this.convertMeToPrimary(); //TP-5089
                this.setState({ changeHandIcon: true}, () => {
                    this.setState({ changeHandIcon: false })
                })
            }
        }
        this.stopprimaryExpertCheck();
    }

    //TP-5089 -- Handling of automatic turning on the Camera when me becomes Primary expert
    convertMeToPrimary = () => {             
        const {groupInfo, updatedParticipants} = this.state; 
        const allParticipants = [...this.state.participants];
        // TP-247 --  Handling of automatic turning on the Remote user cameras
            // If the current Primary expert doesn't have the Video turn on/off button enabled
            // and if all remote user's Videos are turned off
            if (this.state.userVideoCount === 0 && groupInfo.can_turn_off_user_video === false) {
                let changedParticipant = null;//TP-3677
                let userVideoCount = 0;
                let payload = [];
                allParticipants.forEach( p => {
                    p.disableVideo = false;  
                    p.streams && p.streams.forEach( stream => {
                        if (stream.getVideoTracks()[0])
                            stream.getVideoTracks()[0].enabled = true;
                    })  
                    printConsole(`Turn on/off video signal being sent to user ----> ${p.email}`);
                    this.sessionSignal.sendSignalTurnOnUserVideo(true, p.email);
                    //set the changed session Joinee in the array
                    this.updateEachSessionJoinee({participant: p});  
                    if (p!== undefined) {
                        let obj = {}
                        obj = {
                            flag: p.disableVideo, 
                            userId: p.userId,
                            email: p.email
                        }                        
                        payload.push(obj);
                    }              
                })   

                // Send the signal to other experts to keep the disableVideo flag of the 
                // participants in sync with the Primary expert
                allParticipants.forEach( p => {
                    this.sessionSignal.sendSignalTurnOnMaxViewUserVideo(payload, p.email);
                });
                
                if (this.props.session_type === "RM" && this.props.enable_user_video !== "my_camera_disabled") {
                    updatedParticipants && updatedParticipants.forEach((p,i) => {
                        /**TP-3788*/
                        updatedParticipants[i].disableVideo = false;     
                        updatedParticipants[i].streams && updatedParticipants[i].streams.forEach( stream => {
                            if (stream.getVideoTracks()[0])
                                stream.getVideoTracks()[0].enabled = true;
                        })          
                        changedParticipant = updatedParticipants[i];
                        this.setState({ changedParticipant }, () => {
                            this.setState({changedParticipant: null});
                        })
                    });

                    allParticipants.forEach( p => {
                        //TP-3532 -- New Socket signal to let the other Remote users to know this expert user's Video feed is turned on or not
                        this.sessionSignal.sendSignalExpertVideoFeedEnabled(true, this.checkIfExpertCameraEnabled(), p.email);//TP-3683
                    })
                }
                printConsole("changed subscriber list: ");
                printConsole(allParticipants);
                const list = allParticipants.filter(p => p.disableVideo === false && p.isexpert === false);
                printConsole("list ---------------------------");
                printConsole(list);
                if (list !== undefined) {
                    printConsole(`Length of Users with Video on -- ${list.length}`)
                    userVideoCount = list.length;
                }
                //this.updateJoinees({other_participants: participants})
                this.setState({participants: allParticipants, updatedParticipants}, () => {
                    //TP-3677
                    this.setState({userVideoCount}, () => {                        
                        /**	TP-3679 TP-3419 */
                        /* if (this.props.session_type === "RM" && this.checkIfExpertCameraEnabled() === true)
                            this.getUpdatedParticipantswithPreview();
                        else { */
                            if (this.state.maxView === false && ((this.checkIfExpertCameraEnabled() === false && userVideoCount > 1) || this.checkIfExpertCameraEnabled() === true))
                                this.onClickMultiGridView();
                            else {
                                //TP-4019
                                this.setState({ disableGridView: (groupInfo.session_type === "CM" || (groupInfo.session_type === "RM" && this.checkIfExpertCameraEnabled() === true)) ? (this.state.participants.length >= 1) ? false : true : this.state.participants.length > 1 ? false : true });
                                if (this.state.showHideDivIconAudioOnly === true) this.setState({ showHideDivIconAudioOnly: false, hideExpertSSAll: false })
                                
                            }
                        //}
                    });
                })
            }
    }

    // sent the bakend request to set the primary expert in group table
    setPrimaryExpertIDApi = (userId) => {
        let fetchString = 'setPrimaryExpertID';
        printConsole(`setPrimaryExpert ID is called with payload { groupID: ${this.props.selectedGroupId} primary_expert_id: ${this.state.user.id}}`);
        if (this.state.user.log_level >= 3) {
            diagnostics(this.authService,{
                action: `setPrimaryExpertID called`,
                sequence: 3,
                next_step: '',
                data: `{ groupID: ${this.props.selectedGroupId}, primary_expert_id: ${this.state.user.id}}`,
                error: 'none'
            })
        }
        this.authService.fetch(fetchString, {
            method: 'put',
            body: JSON.stringify({
                groupID: this.props.selectedGroupId,
                primary_expert_id: userId || this.state.user.id
            })
        })
        .then(response => {
            if (response.status >= 200 && response.status < 300) {
                //return response.json();
            } else {
                throw response;
            }
        })
        .catch(err => {
            throw err;
        })
    }

    // all steps to make me the primary expert
    //MB2-739 disable Grid View when max div actions going on and this method is called
    makeMeExpert = ({experts}) =>{
        printConsole("Make me the Primary Expert");
        const { user, groupInfo } = this.state;
        if (this.state.currentPrimaryExpert === this.state.user && this.state.showMaxDivControls === true) {
            printConsole("This expert is already the Primary expert. Hence returning...");
            let allExperts = experts && experts.length> 0 ? experts : this.state.participants.filter(p => p.isexpert)
            let allTechnicians = this.state.participants.filter(p => !p.isexpert)
            //console.log("Technician list ---->", allTechnicians);
            // TP-359 -- Sending this signal to announce the Current Primary expert's identity to all the joined technicians
            printConsole(" sending the Current Primary expert's identity to all joined technician users ")
            allTechnicians && allTechnicians.length>0 && allTechnicians.forEach(p => {
                printConsole(`technician email is ${p.email}`);
                this.sessionSignal.sendSignalCurrentPrimaryExpert(0, p.email);
            })
            allExperts && allExperts.length> 0 && allExperts.forEach((participant) => {
                this.sessionSignal.sendSignalSyncExperts({
                    isPrimary: true,
                    expertId: this.state.user.id,
                    email: this.state.user.email,
                    firstname: this.state.user.firstname,
                    lastname: this.state.user.lastname,
                    joinTimeStamp: this.state.user.joinTimeStamp,
                    reason: "primary_sync"
                    },
                participant.email);  
                // Send this signal to all the Passive experts so they can enable their Hand Icons (if disabled)
                this.sessionSignal.sendSignalSyncExperts({
                    show_grid: this.state.maxDivParticipant && this.state.maxDivParticipant !== '' ? false : true,
                    max_view_technician: this.state.maxDivParticipant && this.state.maxDivParticipant !== '' ? this.state.maxDivParticipant.email : '', //TP-3893
                    annotation_active: this.props.bAnnotate,
                    ratio: this.state.aspectRatio,
                    torch_button_active: this.state.maxDivParticipant && this.state.maxDivParticipant !== '' ? this.state.maxDivParticipant.torchActive: false,
                    zoom_button_active: this.state.maxDivParticipant && this.state.maxDivParticipant !== '' && parseInt(this.state.maxDivParticipant.zoomLevel) > 1 ? true : false,
                    zoom_level: this.state.maxDivParticipant && this.state.maxDivParticipant !== '' ? parseInt(this.state.maxDivParticipant.zoomLevel) : 1,
                    hand_icon_enabled: true,
                    header_fileShare_active: (this.props.isHeaderFileShare && !this.props.isOneToOneFileShare) ? true : false, //TP-2378
                    reason: "show_action"}, 
                participant.email);
            })
            return;
        }
        this.setState({ /**TP-5595*/ showMaxDivControls: true, currentPrimaryExpert: this.state.user, disableGridView: (groupInfo.session_type === "CM" || (groupInfo.session_type === "RM" && this.checkIfExpertCameraEnabled() === true)) ? (this.state.participants.length >= 1 && this.state.maxView === true) ? false : true : this.state.participants.length > 1 && this.state.maxView === true ? false : true, /**TP-3854 & TP-3629*/
            showHeaderActionButtons: true
        },() => {
            printConsole("Set my user id as Primary Expert id");
            this.setPrimaryExpertIDApi();
            this.props.setHasMaxDivControls({flag: true});
            let allExperts = experts && experts.length> 0 ? experts : this.state.participants.filter(p => p.isexpert)
            let allTechnicians = this.state.participants.filter(p => !p.isexpert)
            //console.log("Technician list ---->", allTechnicians);
            // TP-359 -- Sending this signal to announce the Current Primary expert's identity to all the joined technicians
            printConsole(" sending the Current Primary expert's identity to all joined technician users ")
            allTechnicians && allTechnicians.length>0 && allTechnicians.forEach(p => {
                printConsole(`technician email is ${p.email}`);
                this.sessionSignal.sendSignalCurrentPrimaryExpert(0, p.email);
            })
            printConsole(`All experts in the current session are ${JSON.stringify(allExperts)}`);
            allExperts && allExperts.length> 0 && allExperts.forEach((participant) => {
                this.sessionSignal.sendSignalSyncExperts({
                    isPrimary: true,
                    expertId: this.state.user.id,
                    email: this.state.user.email,
                    firstname: this.state.user.firstname,
                    lastname: this.state.user.lastname,
                    joinTimeStamp: this.state.user.joinTimeStamp,
                    reason: "primary_sync"
                    },
                participant.email);  
                // Send this signal to all the Passive experts so they can enable their Hand Icons (if disabled)
                this.sessionSignal.sendSignalSyncExperts({
                    show_grid: this.state.maxDivParticipant && this.state.maxDivParticipant !== '' ? false : true,
                    max_view_technician: this.state.maxDivParticipant && this.state.maxDivParticipant !== '' ? this.state.maxDivParticipant.email : '', //TP-3893
                    ratio: this.state.aspectRatio,
                    annotation_active: this.props.bAnnotate,
                    torch_button_active: this.state.maxDivParticipant && this.state.maxDivParticipant !== '' ? this.state.maxDivParticipant.torchActive: false,
                    zoom_button_active: this.state.maxDivParticipant && this.state.maxDivParticipant !== '' && parseInt(this.state.maxDivParticipant.zoomLevel) > 1 ? true : false,
                    zoom_level: this.state.maxDivParticipant && this.state.maxDivParticipant !== '' ? parseInt(this.state.maxDivParticipant.zoomLevel) : 1,
                    hand_icon_enabled: true,
                    header_fileShare_active: (this.props.isHeaderFileShare && !this.props.isOneToOneFileShare) ? true : false, //TP-2378
                    reason: "show_action"}, 
                participant.email);
            })
            if(this.state.maxDivParticipant && this.state.maxDivParticipant && this.state.maxDivParticipant !== '' /* && !this.state.maxDivIconClicked */){
                this.sendMaxViewSignal({flag: true})// send the signal to reset the primary expert on techcnian side and also to check the ss button state
                //TP-3893
                if (this.state.maxDivParticipant.isexpert === true && this.state.showHideDivIconAudioOnly === false) {
                    this.setState({showHideDivIconAudioOnly: true});
                }
            }
        });
    }

    setMeAsPrimary = ({experts}) => {
        printConsole("Set my id as the Primary Expert id");
        this.setState({ showMaxDivControls: true, currentPrimaryExpert: this.state.user, disableGridView: this.state.participants.length > 1 /*TP-3854 && this.state.maxView === false TP-3629*/? false : true, /*(this.state.groupInfo.session_type === "CM" || (this.state.groupInfo.session_type === "RM" && this.checkIfExpertCameraEnabled() === true)) ? (this.state.userVideoCount > 1 && this.state.maxView) ? false : true : (this.state.technicianCount > 1 && this.state.userVideoCount > 1 && this.state.maxView) ? false : true, /**TP-3119*/
            showHeaderActionButtons: true
        },() => {
            printConsole("Setting my UI as Primary Expert's View");
            //this.setPrimaryExpertIDApi();
            this.props.setHasMaxDivControls({flag: true});
            let allExperts = experts && experts.length> 0 ? experts : this.state.participants.filter(p => p.isexpert)
            let allTechnicians = this.state.participants.filter(p => !p.isexpert)
            //console.log("Technician list ---->", allTechnicians);
            // TP-359 -- Sending this signal to announce the Current Primary expert's identity to all the joined technicians
            printConsole(" sending the Current Primary expert's identity to all joined technician users ")
            allTechnicians && allTechnicians.length>0 && allTechnicians.forEach(p => {
                printConsole(`technician email is ${p.email}`);
                this.sessionSignal.sendSignalCurrentPrimaryExpert(0, p.email);
            })
            printConsole(`All experts in the current session are ${JSON.stringify(allExperts)}`);
            allExperts && allExperts.length> 0 && allExperts.forEach((participant) => {
                this.sessionSignal.sendSignalSyncExperts({
                    isPrimary: true,
                    expertId: this.state.user.id,
                    email: this.state.user.email,
                    firstname: this.state.user.firstname,
                    lastname: this.state.user.lastname,
                    joinTimeStamp: this.state.user.joinTimeStamp,
                    reason: "primary_sync"
                    },
                participant.email);  
                // Send this signal to all the Passive experts so they can enable their Hand Icons (if disabled)
                this.sessionSignal.sendSignalSyncExperts({
                    show_grid: this.state.maxDivParticipant && this.state.maxDivParticipant !== '' ? false : true,
                    max_view_technician: this.state.maxDivParticipant && this.state.maxDivParticipant !== '' ? this.state.maxDivParticipant.email : '', //TP-3893
                    ratio: this.state.aspectRatio,
                    annotation_active: this.props.bAnnotate,
                    torch_button_active: this.state.maxDivParticipant && this.state.maxDivParticipant !== '' ? this.state.maxDivParticipant.torchActive: false,
                    zoom_button_active: this.state.maxDivParticipant && this.state.maxDivParticipant !== '' && parseInt(this.state.maxDivParticipant.zoomLevel) > 1 ? true : false,
                    zoom_level: this.state.maxDivParticipant && this.state.maxDivParticipant !== '' ? parseInt(this.state.maxDivParticipant.zoomLevel) : 1,
                    hand_icon_enabled: true,
                    header_fileShare_active: (this.props.isHeaderFileShare && !this.props.isOneToOneFileShare) ? true : false, //TP-2378
                    reason: "show_action"}, 
                participant.email);
            })
            if(this.state.maxDivParticipant && this.state.maxDivParticipant && this.state.maxDivParticipant !== '' /* && !this.state.maxDivIconClicked */){
                this.sendMaxViewSignal({flag: true})// send the signal to reset the primary expert on techcnian side and also to check the ss button state
                //TP-3893
                if (this.state.maxDivParticipant.isexpert === true && this.state.showHideDivIconAudioOnly === false) {
                    this.setState({showHideDivIconAudioOnly: true});
                }
            }
        });
    }

    // MB2-498
    initprimaryExpertCheck = () => {
        // 10 seconds timer to start when the Expert joins the session
        // to check if there are any other experts in a session or not
        //this.primaryExpertCheckTimer = setInterval(this.checkPrimaryExpertPriviledge.bind(this), 10000);
        this.setState({hideHandIcon: true});//TP-4543
        this.primaryExpertCheckTimer = setInterval(this.checkPrimaryExpertTimeOut.bind(this), 10000);
    }

    // MB2-498
    stopprimaryExpertCheck = () => {
        printConsole("close the primary expert timer...");
        this.setState({hideHandIcon: false}); //TP-4543
        if (this.primaryExpertCheckTimer) clearInterval(this.primaryExpertCheckTimer);
    }

    //T32-495 Handling of Screen share Icons, when it is forcibly stopped
    handleScreenShareIcons = (flag) => {
        this.setState({isMaxDivSS: flag, screenShareStream: null},() => {
            this.props.setSS({isSideBarSS: flag, isMaxDivSS: this.state.isMaxDivSS});// set ss status in webrtc session
        })
    }
    
    // max div screen share MB2-550
    initMaxScreenShare = async (dontSendArtifacts=false) => {
        printConsole("initMaxScreenShare");
        const { participants, maxDivParticipant, user, groupInfo } = this.state;
        printConsole(dontSendArtifacts);
        if(this.state.isSideBarSS) return;// dont trigger max div ss when side bar ss going on
        if(!this.state.isMaxDivSS){ 
            //TP-5982
            this.sessionSignal.sendSignalPrepareExpertSS(true, maxDivParticipant.email);//TP-5852    
            const experts = participants.filter(p => p.isexpert);  
            experts && experts.forEach(expert => {
                this.sessionSignal.sendSignalPrepareExpertSS(true, expert.email);
            })    
            //For Max view Technician Screen cast only remove the duplicate assets for Max-view technician
            if (this.props.isHeaderFileShare /* && this.props.isOneToOneFileShare */)
                this.removeArtifactsMaxView();
            else if (this.props.isOneToOneFileShare) 
                this.removeArtifactsMaxView();
            /* } else if (this.props.isHeaderFileShare && !this.props.isOneToOneFileShare) {
                this.removeArtifactsForAll();
            } */
            //this.props.setAnnotationDrawings(true);
            navigator.mediaDevices.getDisplayMedia({
                video: true
            })
            .then((stream) => {
                this.addScreenShareStopListener(stream, this.initMaxScreenShare);//TP-5397 add the listner
                //TP-2971
                this.setState({screenCastStarted: true}, () => {
                    this.setState({screenCastStarted: false})
                })
                //TP-5397 -- set the isSSOngoing flag as true to make the SS buttons not clickable
                this.setState({ isSSOngoing: true }, () => {
                    this.setState({screenShareStream: stream, isMaxDivSS: true}, () => {                        
                        this.props.setSS({isSideBarSS: this.state.isSideBarSS, isMaxDivSS: this.state.isMaxDivSS});// set ss status in webrtc session
                        this.props.setDisplayMedia({stream})
                    });
                    this.switchMaxVideoStream({flag: true, stream})
                })
            })
            .catch(err => {
                printConsole(`screen share error ${err}`)
                //TP-5982
                this.sessionSignal.sendSignalPrepareExpertSSCancel(maxDivParticipant.email);//TP-5852
                experts && experts.forEach(expert => {
                    this.sessionSignal.sendSignalPrepareExpertSSCancel(expert.email);
                })
                //Screen share was cancelled
                //this.props.setAnnotationDrawings(true);
                if (this.props.isHeaderFileShare)
                    this.props.sendSharedArtifactsMaxDiv();
                else if (this.props.isOneToOneFileShare)
                    this.props.sendSharedArtifactsMaxDiv();
            });
        }else{            
            if (this.state.bAnnotate === true) {
                this.onClickAnnotate();
            }
            if (this.state.bZoomClick === true)
                document.getElementById('zoomlevelselector').style.display = 'block';
            
            this.removeScreenShareStopListener(this.state.screenShareStream, this.initMaxScreenShare);// first remove the listner
            this.state.screenShareStream && this.state.screenShareStream.getTracks().forEach((track) => {
                track.stop();
            });
            let videoConfig = false;
            //TP-2578
            if (groupInfo.session_type === "CM" || (groupInfo.session_type === "RM" && this.checkIfExpertCameraEnabled() === true)) { //TP-3119 & TP-2618
                videoConfig = { 
                    width: this.state.selectedWidth, 
                    height: this.state.selectedHeight 
                }; 
            }
            //TP-4258
            if (this.state.bMaxChat) {
                this.setState({bMaxChat: false}, () => {
                    this.setState({bMaxChat: true});
                })
            }
            //TP-4258
            if (this.state.bAllChat) {
                this.setState({ bAllChat: false}, () => {
                    this.setState({ bAllChat: true });
                })
            }
                const audioConfig = {
                    echoCancellation: true, 
                    noiseSuppression: true,
                    googNoiseSupression: true
                }
                /*TP-5320 navigator.mediaDevices.getUserMedia({
                    audio: audioConfig,
                    video: videoConfig
                })
                .then((stream) => { */
                    //TP-2618
                    this.switchMaxVideoStream({flag: false, stream: this.state.localStream})
                    //TP-5320this.setState({ localStream: stream,
                        this.setState({isMaxDivSS: false, screenShareStream: null},() => {                    
                        this.props.setSS({isSideBarSS: this.state.isSideBarSS, isMaxDivSS: this.state.isMaxDivSS});// set ss status in webrtc session
                        this.props.setDisplayMedia({stream: null})
                        if (this.props.isHeaderFileShare  && dontSendArtifacts === false /* && this.props.isOneToOneFileShare */)
                            this.props.sendSharedArtifactsMaxDiv();
                        else if (this.props.isOneToOneFileShare && dontSendArtifacts === false) 
                            this.props.sendSharedArtifactsMaxDiv();
                        else if (dontSendArtifacts === true && (this.props.isHeaderFileShare || this.props.isOneToOneFileShare) && this.state.showMaxDivControls) {
                            this.props.closeFileViewer();
                        }
                        /* } else if (this.props.isHeaderFileShare && !this.props.isOneToOneFileShare) {
                            this.props.sendArtifactsToAll();
                        } */
                        if (this.state.startRecording === true && dontSendArtifacts === false /**TP-6573*/) {
                            printConsole("stop the old recording and start a new recording when screen share stops");
                            this.stopAndContinueRecording(); //TP-1881 stop the old recording and start a new recording when screen share stops
                        }
                    })
                //})
        }
    };

    // side bar screen share MB2-550
    initSideBarScreenShare = async (buttonState, dontSendArtifacts=false) => {
        printConsole("initSideBarScreenShare");
        const { user, participants, groupInfo } = this.state;
        printConsole(dontSendArtifacts);
        if(!this.state.isSideBarSS){            
            this.sessionSignal.sendSignalPrepareExpertSS(true);//TP-5852
            if (this.props.isHeaderFileShare && this.props.isOneToOneFileShare) {
                this.removeArtifactsMaxView();
            } else if (this.props.isHeaderFileShare && !this.props.isOneToOneFileShare) {
                this.removeArtifactsForAll();
            } else if (this.props.isOneToOneFileShare) {
                this.removeArtifactsForAll();
            }
            
            //this.setState({isSideBarSS: true});
            //this.props.setAnnotationDrawings(true);
            navigator.mediaDevices.getDisplayMedia({
                video: true
            })
            .then((stream) => {
                this.addScreenShareStopListener(stream, this.initSideBarScreenShare);//TP-5397 add the listner
                //TP-2971 && TP-2861
                this.state.participants.forEach(p => {
                    if (p.zoomLevel > 1) 
                        this.resetUserZoom(p.userId, '1');
                    if (p.torchActive === true)
                        this.toggleUserTorch(p.userId);
                });
                //TP-5397 -- Set the isSSOngoig state as true to make the SS buttons not clickable
                this.setState({ isSSOngoing: true }, () => {
                    this.setState({screenShareStream: stream, isSideBarSS: true}, () => {                        
                        this.props.setSS({isSideBarSS: this.state.isSideBarSS, isMaxDivSS: this.state.isMaxDivSS});// set ss status in webrtc session
                        this.props.setDisplayMedia({stream});
                    });
                    this.switchAllVideoStream({flag: true, stream});
                })
            
            })
            .catch(err => {
                printConsole(`screen share error ${err}`)
                this.sessionSignal.sendSignalPrepareExpertSSCancel();//TP-5852
                //Screen share was cancelled
                //this.props.setAnnotationDrawings(true);
                if (this.props.isHeaderFileShare && this.props.isOneToOneFileShare) {
                    this.props.sendSharedArtifactsMaxDiv();
                } else if (this.props.isHeaderFileShare && !this.props.isOneToOneFileShare) {
                    this.props.sendArtifactsToAll();
                } else if (this.props.isOneToOneFileShare) {
                    this.props.sendSharedArtifactsMaxDiv();
                }
                //this.setState({isSideBarSS: false});
                this.setState({ changeScreenShareIcon: true }, () => {
                    this.setState({ changeScreenShareIcon: false }); //TP-1042
                })
            });
        }else{
            this.removeScreenShareStopListener(this.state.screenShareStream, this.initSideBarScreenShare);// first remove the listner
            this.state.screenShareStream && this.state.screenShareStream.getTracks().forEach((track) => {
                track.stop();
            });
            let videoConfig = false;
            //TP-2578
            if (groupInfo.session_type === "CM" || (groupInfo.session_type === "RM" && this.checkIfExpertCameraEnabled() === true)) { //TP-3119 & TP-2618
                videoConfig = { 
                    width: this.state.selectedWidth, 
                    height: this.state.selectedHeight 
                }; 
            }
            //TP-4258
            if (this.state.bMaxChat) {
                this.setState({bMaxChat: false}, () => {
                    this.setState({bMaxChat: true});
                })
            } //TP-4258
            if (this.state.bAllChat) {
                this.setState({ bAllChat: false}, () => {
                    this.setState({ bAllChat: true });
                })
            }
            const audioConfig = {
                echoCancellation: true, 
                noiseSuppression: true,
                googNoiseSupression: true
            }
            /*TP-5320 navigator.mediaDevices.getUserMedia({
                audio: audioConfig,
                video: videoConfig
            })
            .then((stream) => { */
                //TP-2618
                this.switchAllVideoStream({flag: false, stream: this.state.localStream});
                //this.setState({ localStream: stream,
                    this.setState({isSideBarSS: false, screenShareStream: null},() => {
                    this.props.setSS({isSideBarSS: this.state.isSideBarSS, isMaxDivSS: this.state.isMaxDivSS});// set ss status in webrtc session
                    this.props.setDisplayMedia({stream: null})
                    if (this.props.isHeaderFileShare && this.props.isOneToOneFileShare && dontSendArtifacts === false) {
                        this.props.sendSharedArtifactsMaxDiv();
                    } else if (this.props.isHeaderFileShare && !this.props.isOneToOneFileShare && dontSendArtifacts === false) {
                        this.props.sendArtifactsToAll();
                    } else if (this.props.isOneToOneFileShare && dontSendArtifacts === false) {
                        this.props.sendSharedArtifactsMaxDiv();
                    } else if (dontSendArtifacts === true && (this.props.isHeaderFileShare || this.props.isOneToOneFileShare) && this.state.showMaxDivControls) {
                        this.props.closeFileViewer();
                    }
                    if (this.state.startRecording === true && dontSendArtifacts === false /**TP-6573*/) {
                        printConsole("stop the recording and start a new recording when screen share starts")
                        this.stopAndContinueRecording(); //TP-1881 stop the recording and start a new recording when screen share starts
                    }
                });
            //});
        }
    };

    // add listner for the ss stream
    addScreenShareStopListener = (stream, callback) => {
        stream.addEventListener('ended',callback, false);
        stream.addEventListener('inactive',callback, false);
        stream.getTracks().forEach((track) => { 
            track.addEventListener('ended',callback, false);
            track.addEventListener('inactive',callback, false);
        });
    }

    // remove listener for the ss stream
    removeScreenShareStopListener = (stream, callback) => {
        stream.removeEventListener('ended',callback, false);
        stream.removeEventListener('inactive',callback, false);
        stream.getTracks().forEach((track) => { 
            track.removeEventListener('ended',callback, false);
            track.removeEventListener('inactive',callback, false);
        });
    }

    //to send the REMOVE_ARTIFACTS signal to Max-div participant and all the Passive experts
    removeArtifactsMaxView = () => {
        const { participants, maxDivParticipant } = this.state;
        this.sessionSignal.sendSignalRemoveArtifacts(true, maxDivParticipant.email);
        const experts = participants.filter(p => p.isexpert);
        experts && experts.forEach(e => {
            this.sessionSignal.sendSignalRemoveArtifacts(true, e.email);
        });

        if(this.state.maxView && this.state.maxDivParticipant && this.state.maxDivParticipant !== ''){
            experts && experts.forEach((e) => {
                this.sessionSignal.sendSignalSyncExperts({
                    show_grid: false,
                    max_view_technician: maxDivParticipant.email, //TP-3893
                    annotation_active: false,
                    zoom_button_active:  (maxDivParticipant.zoomLevel > 1) ? true : false,
                    zoom_level: maxDivParticipant.zoomLevel,
                    torch_button_active: maxDivParticipant.torchActive,
                    ratio: this.state.aspectRatio,
                    hand_icon_enabled: (this.state.showControlRequestDialog === true) ? false : true, //TP-1313
                    header_fileShare_active: false, //TP-2378
                    reason: "show_action"},  
                e.email);                        
            });
        }
    }

    //to send the REMOVE_ARTIFACTS signal to all users in that session
    removeArtifactsForAll = () => {
        const { participants, maxDivParticipant } = this.state;
        participants && participants.forEach(p => {
            this.sessionSignal.sendSignalRemoveArtifacts(true, p.email);
        });
        const experts = participants.filter(p => p.isexpert);
        if(this.state.maxView && this.state.maxDivParticipant && this.state.maxDivParticipant !== ''){
            experts && experts.forEach((e) => {
                this.sessionSignal.sendSignalSyncExperts({
                    show_grid: false,
                    max_view_technician: maxDivParticipant.email, //TP-3893
                    annotation_active: false,
                    zoom_button_active:  (maxDivParticipant.zoomLevel > 1) ? true : false,
                    zoom_level: maxDivParticipant.zoomLevel,
                    torch_button_active: maxDivParticipant.torchActive,
                    ratio: this.state.aspectRatio,
                    hand_icon_enabled: (this.state.showControlRequestDialog === true) ? false : true, //TP-1313
                    header_fileShare_active: false, //TP-2378
                    reason: "show_action"},  
                e.email);                        
            });
        }
    }

    onClickTorchIcon = ({flag}) => {
        let participants = [...this.state.participants];
        let {maxDivParticipant} = this.state;
        const index = this.state.participants.findIndex(e => e.userId === maxDivParticipant.userId);
        printConsole(maxDivParticipant);
        // Making the torch flag for that participant enabled/disabled
        maxDivParticipant.torchActive = flag;
        participants[index] = maxDivParticipant;
        this.setState({participants, maxDivParticipant}, () => {
            this.props.setMaxDivId({g_maxDivId: maxDivParticipant.stream_id, maxDivParticipant})// set maxdiv id to be used for 3d file rendering
            this.props.setParticipant({participantList: participants});// to set the changes in the participant list
        });
    }

    onClickZoomIcon = ({zoom_level}) => {
        let participants = [...this.state.participants];
        let {maxDivParticipant} = this.state;
        const index = this.state.participants.findIndex(e => e.userId === maxDivParticipant.userId);
        printConsole(maxDivParticipant);
        // Making the torch flag for that participant enabled/disabled
        maxDivParticipant.zoomLevel = parseInt(zoom_level);
        participants[index] = maxDivParticipant;
        this.setState({participants, maxDivParticipant}, () => {
            this.setZoomClass(); //TP-2933
            this.props.setMaxDivId({g_maxDivId: maxDivParticipant.stream_id, maxDivParticipant})// set maxdiv id to be used for 3d file rendering
            this.props.setParticipant({participantList: participants});// to set the changes in the participant list
        });
    }

    /**Swap Camera Signals processing methods */
    processSignalSwapCamera = ({data, eventType, from}) => {
        //const {data, eventType} = json;
        /* if (data === 0 && this.state.swapTechCamera === false) {
            printConsole("Automatic swap the Technician camera started!!");
            this.setState({ swapTechCamera: true });
            this.props.updateRCStatus(true);
        }
        else */ 
        //printConsole(this.state.swapTechCamera);
        //printConsole(eventType);
        if (data > 0 ) { //&& this.state.swapTechCamera === false
            printConsole("Swap the Technician camera!!");
            this.setState({ swapTechCamera: true, maxDivCameraCount: data, swapTechDetails: from.email }, () => {
                if (this.state.showMaxDivControls && this.state.currentPrimaryExpert && this.state.currentPrimaryExpert.email === this.state.user.email)
                this.sessionSignal.sendSignalSwapCameraReceived (data, eventType, from.email
                    //type: "SWAP_CAMERA_RECEIVED"
                )
                printConsole("Swap camera signal command sent... to: ",from.email); 
            });
            //this.props.updateRCStatus(true);
            //this.props.updateCameraSwapStatus(true); 
        } /* else if (data > 0 && this.state.swapTechCamera === true) {
            printConsole(" Swap camera received signal to be sent to technician ");
            if (this.state.showMaxDivControls && this.state.currentPrimaryExpert && this.state.currentPrimaryExpert.email === this.state.user.email)
                this.sessionSignal.sendSignalSwapCameraReceived (data, eventType, from.email
                    //type: "SWAP_CAMERA_RECEIVED"
                )
        } */ 
        else if (data === 0 ) {
            this.setState({ swapTechCamera: false, confirmSwapCamera: false, maxDivCameraCount: data, swapTechDetails: '', swapCamTechData: '' });
            //this.props.updateRCStatus(false);
            //this.props.updateCameraSwapStatus(false);
        }
    }

    processSignalConfirmSwapCamera = ({data, eventType, from}) => {
        printConsole("swapTechCamera: ", this.state.swapTechCamera);
        if (this.state.swapTechCamera === true) {
            //const {data, eventType} = json;
            printConsole("confirmSwapCamera: ", this.state.confirmSwapCamera);
            if (data === true && this.state.showMaxDivControls && this.state.confirmSwapCamera === false){
                this.setState({ confirmSwapCamera: true });
                if (eventType === "auto") {
                    printConsole(eventType);
                    this.waitTimer = setTimeout(() => { 
                        this.handleFeaturesForTech(from); 
                        clearTimeout(this.waitTimer);
                    }, 10000);
                    
                } else if (eventType === "click") {
                    printConsole(eventType);
                    //this.handleFeaturesForTech(from); 
                    //this.props.updateRCStatus(true);
                    //this.props.updateCameraSwapStatus(true); 
                    this.sessionSignal.sendSignalSwapCameraReceived (data, eventType, from.email
                        //type: "SWAP_CAMERA_RECEIVED"
                    )
                }
                this.setState({ swapTechCamera: false, confirmSwapCamera: false });
            } else if (data === false) {
                this.setState({ swapTechCamera: data, confirmSwapCamera: false, swapTechDetails: '', swapCamTechData: '' });
                //this.props.updateRCStatus(data);
                //printConsole(this.state.swapTechCamera); 
            }
        }
    }
    
    processSignalMaxDivShowButtons = ({data, from}) => {
        //"tech_ss:::expert_ss:::zoom:::torch:::1280px720px";
        //NS2-119
        printConsole(data);
        const { screen_share_mode, torch_enabled, zoom_enabled } = this.state.groupInfo;
        const { showMaxDivControls, maxDivParticipant } = this.state;
        // When the maxdiv controls div is hidden then just ignore this signal.
        if (!showMaxDivControls) return;
        let dataArr = data.split(":::");
        // This if condition is a combination of NS2-99 & NS2-77 
        if (screen_share_mode !== 'screen_share_disabled' && dataArr.length > 0 && !(this.props.isiOS && this.props.isSafari)) {
            if (dataArr.includes("tech_ss") && (screen_share_mode === 'screen_share_enabled' || screen_share_mode === 'tech_screen_share_enabled')) {
                this.setState({ bHideTechnicianSSButton: false });
            }else{
                this.setState({ bHideTechnicianSSButton: true });
            }
            if (dataArr.includes("expert_ss") && (screen_share_mode === 'screen_share_enabled' || screen_share_mode === 'expert_screen_share_enabled')) {
                this.setState({ bHideExpertSSButton: false, hideExpertSSAll: (maxDivParticipant.disableVideo === true) ? false : false }); //TP-4436
            } else{
                this.setState({ bHideExpertSSButton: true });
            } 
        } else {
            //To enable the Side Bar File Share Icon for Prepaid accounts/Safari Browsers
            this.setState({ hideExpertSSAll: (maxDivParticipant.disableVideo === true) ? false : false }) //TP-4436
        }
        // If statement for only the zoom and torch buttons
        if (dataArr.length > 0) {
            if (dataArr.includes("zoom") && zoom_enabled === true){
                this.setState({ bHideZoomButton: false });
            }else{
                this.setState({ bHideZoomButton: true });
            }
            if (dataArr.includes("torch") && torch_enabled === true){
                this.setState({ bHideTorchButton: false });
            }else{
                this.setState({ bHideTorchButton: true });
            }
            /* const i = dataArr.findIndex((dt) => {
                return dt.includes("type-");
            });
            let maxDivTechType = "";
            if (i > -1) {
                maxDivTechType = dataArr[i].split("-")[1];
            } */
            // FQ3-334
            /*if (maxDivTechType === "IPK") {
                this.calVdoHeightWidth("4:3");
            }  else if (maxDivTechType === "APK") {
                this.calVdoHeightWidth("4:3");
            } else { */
                /* let dta = null;
                dta = dataArr.filter((str) => str.indexOf("px") > -1);
                printConsole(`dta=========> ${dta}`);
                if (dta !== null) {
                    let camArr = dta[0].split("px");
                    this.setState({ techCameraRes: camArr[1] }, ()=> {
                        if (parseInt(camArr[1].trim("px")) < 720) {
                            this.calVdoHeightWidth("4:3");
                        } else {
                            this.calVdoHeightWidth("16:9");
                        }
                    });
                } */
            //}
            //TP-4772
            const [participant] = this.state.participants.filter(p => p.email === from.email)
            if (dataArr.includes("1.33") || dataArr.includes("1.34")) {
                if (this.state.aspectRatio === "16:9")
                    this.setState({aspectRatio: "4:3"}, () => {
                        this.onChangeAspectRatio(participant);
                    })
                this.calVdoHeightWidth("4:3");
            }
            else if (dataArr.includes("1.77")) {
                if (this.state.aspectRatio === "4:3")
                    this.setState({aspectRatio: "16:9"}, () => {
                        this.onChangeAspectRatio(participant);
                    })
                this.calVdoHeightWidth("16:9");
            }
            else {
                if (this.state.aspectRatio === "4:3")
                    this.setState({aspectRatio: "16:9"}, () => {
                        this.onChangeAspectRatio(participant);
                    })
                this.calVdoHeightWidth("16:9");
            }
        }else{
            this.setState({
                bHideExpertSSButton: true,
                bHideTechnicianSSButton: true,
                bHideZoomButton: true,
                bHideTorchButton: true
            })
        }
    }

    processSignalUserInfo = ({data, from}) => {
        printConsole("IM signal received with JSON data");
        let participants = [...this.state.participants];
        let updatedParticipants = [...this.state.updatedParticipants]; //TP-3611
        const { torch_enabled, zoom_enabled } = this.state.groupInfo;
        let {maxDivParticipant, showMaxDivControls} =  this.state;
        //TP-263
        const userObj = {
            data,
            id: from.userId
        }
        let userInfoArray = [...this.state.userInfoArray];
        userInfoArray.push(userObj);
        this.setState({ userInfoArray });

        let flag = false;

        if (participants === undefined || participants === null || participants.length === 0) return;
        
        if (participants !== undefined && participants !== null && participants.length >0) {
            let [user] = participants.filter(p => from.userId === p.userId);
            const index = this.state.participants.findIndex(e => e.userId === from.userId);
            //TP-3611 & TP-3573
            if (this.props.session_type === "RM" && this.checkIfExpertCameraEnabled() === true) {
                const ii = this.state.participants.findIndex(e => e.email === from.email);
                // TP-3850 -- Commented out for resolving the issue of turned off Video getting turned on by mistake
                //updatedParticipants[ii].disableVideo = !data; //TP-3551
                //if (data === false && this.state.showMaxDivControls === true) updatedParticipants[ii].grayedVideo = true;
                /* updatedParticipants[ii].streams && updatedParticipants[ii].streams.forEach( stream => {
                    stream.getVideoTracks()[0].enabled = data;
                }) */
                //TP-3788 -- Zoom, Torch buttons in Grid View controls for my camera enabled state of RM sessions
                let [upuser] = updatedParticipants.filter(p => from.userId === p.userId);
                const upindex = this.state.updatedParticipants.findIndex(up => up.userId === from.userId);
                if (upuser !== null && upuser !== undefined) {
                    upuser.zoom_enabled = (data.zoom_enabled && zoom_enabled) ? data.zoom_enabled : false;
                    upuser.torch_enabled = (data.torch_enabled && torch_enabled) ? data.torch_enabled : false;
                    upuser.screenshare_enabled = (data.screenshare_enabled) ? data.screenshare_enabled : true;
                    upuser.techSS_enabled = (data.techSS_enabled) ? data.techSS_enabled : false; 
                    upuser.aspect_ratio = data.aspectratio ? (data.aspectratio === "1.33" || data.aspectratio === "1.34") ? "4:3" : data.aspectratio === "1.77" ? "16:9" : "16:9" : "16:9"; //TP-4837
                    updatedParticipants[upindex] = upuser;
                }
            }
            if (maxDivParticipant.userId === from.userId) {
                printConsole("Max-view participant's Control Icons changed");
                maxDivParticipant.zoom_enabled = (data.zoom_enabled && zoom_enabled) ? data.zoom_enabled : false;
                maxDivParticipant.torch_enabled = (data.torch_enabled && torch_enabled) ? data.torch_enabled : false;
                maxDivParticipant.screenshare_enabled = (data.screenshare_enabled) ? data.screenshare_enabled : true;
                maxDivParticipant.techSS_enabled = (data.techSS_enabled) ? data.techSS_enabled : false; 
                maxDivParticipant.aspect_ratio = data.aspectratio ? (data.aspectratio === "1.33" || data.aspectratio === "1.34") ? "4:3" : data.aspectratio === "1.77" ? "16:9" : "16:9" : "16:9"; //TP-4837
                flag = true;
            }
            //printConsole("Inside the participants list");
            //printConsole(user);
            if (user === null || user === undefined) return;
            user.zoom_enabled = (data.zoom_enabled && zoom_enabled) ? data.zoom_enabled : false;
            user.torch_enabled = (data.torch_enabled && torch_enabled) ? data.torch_enabled : false;
            user.screenshare_enabled = (data.screenshare_enabled) ? data.screenshare_enabled : true;
            user.techSS_enabled = (data.techSS_enabled) ? data.techSS_enabled : false; 
            user.aspect_ratio = data.aspectratio ? (data.aspectratio === "1.33" || data.aspectratio === "1.34") ? "4:3" : data.aspectratio === "1.77" ? "16:9" : "16:9" : "16:9"; //TP-4837
    
            participants[index] = user;
            this.setState({participants, updatedParticipants /**TP-3788*/}, () => {
                if (this.state.maxView === true && flag === true && showMaxDivControls === false) {
                    printConsole("Re-draw the Passive expert's Max-view UI");
                    if (maxDivParticipant.disableVideo === false) this.setState({ showHideDivIconAudioOnly: false, hideExpertSSAll: false })
                    this.onClickMaximise({participant: maxDivParticipant, isSame: false});
                }
            })
        } 
    }

    /* T32-413 -- Disabled Speaker of Remote user implementation */
    processSignalMuteRemoteUserAudio = ({data, from}) => {
        try {
            let participants = [...this.state.participants];
            let updatedParticipants = [...this.state.updatedParticipants]; //TP-4253
            const [to] = this.state.participants.filter(e => e.userId === from.userId);
            const index = this.state.participants.findIndex(e => e.userId === from.userId);
            printConsole(to);
            let isParticipantChanged = true;
            
            // Making the Speaker button for that participant not-clickable/clickable again
            //to.disableAudio = data;
            to.disableRemoteAudio = data;
            //set the changed session Joinee in the array
            this.updateEachSessionJoinee({participant: to});
            participants[index] = to;
            //TP-4253 -- Handling of Speaker button state for this remote user when Expert's Video is enabled/enabled with preference
            if (this.props.session_type === "RM" && this.props.enable_user_video !== "my_camera_disabled") {
                let [upuser] = updatedParticipants.filter(p => from.userId === p.userId);
                const upindex = this.state.updatedParticipants.findIndex(up => up.userId === from.userId);
                if (upuser !== null && upuser !== undefined) {
                    upuser.disableRemoteAudio = data;
                    updatedParticipants[upindex] = upuser;
                }
            }
            
            this.setState({participants}, () => {
                this.props.setParticipant({participantList: participants});
                const list = this.state.participants.filter(p => p.disableRemoteAudio === false);
                let length = 0;
                //console.log("list ---------------------------", list)
                    if (list !== undefined) {
                        printConsole(`Length of Users with Speaker button enabled & clickable -- ${list.length}`)
                        length = list.length;
                    }
                    //TP-4253 -- Handling of Expert's Preview tile Speaker button state when Expert's Video is enabled/enabled with preference
                    if (this.props.session_type === "RM" && this.props.enable_user_video !== "my_camera_disabled") {
                        let [meParticipant] = updatedParticipants.filter(p => p.email === this.props.user.email);
                        const iii = this.state.updatedParticipants.findIndex(up => up.email === this.props.user.email);
                        if (meParticipant) {
                            meParticipant.disableRemoteAudio = length === 0 ? true : false
                            updatedParticipants[iii] = meParticipant;
                            this.setState({updatedParticipants})
                        }
                    }
                    this.setState({ userAudioEnabledCount: length }, () => {
                        if (this.state.maxView && to.stream_id === this.state.maxDivParticipant.stream_id) {
                            printConsole(`Maxview user's Speaker was turned disabled: ${data}`);
                            this.setState({ /* maxDivSpeakerMuted: data, */ maxDivSpeakerDisabled: data }, () => {
                                //this.muteSpeakerOfOne({participant: to, flag})
                            });                            
                        } else {
                            printConsole(`User's Speaker was turned disabled: ${data}`);
                            /* this.muteSpeakerOfOne({participant: to, flag}); */
                        }
                        if (this.state.userAudioEnabledCount === 0) {
                            this.setState({/* mutedAll: data, */ disabledMutedAll: data, isParticipantChanged}, () => {
                                this.setState({isParticipantChanged: false});                                
                            })
                        } else if (this.state.userAudioEnabledCount > 0) {
                            this.setState({/* mutedAll: false, */ disabledMutedAll: false, isParticipantChanged}, () => {
                                this.setState({isParticipantChanged: false});
                            })
                        }
                    })

            });  
        } catch (error) {
            printConsole(error);
        }
    }

    processSignalLocalTorch = ({from, data}) => {
        try {
            const {maxDivParticipant, maxView} = this.state;
            const {email} = from;
            let participants = [...this.state.participants];
            const experts = this.state.participants.filter(p => p.isexpert)                
            const [to] = this.state.participants.filter(e => e.email === email);
            const index = this.state.participants.findIndex(e => e.email === email);

            to.torchActive = data;
            //to.zoomLevel = zoom_level;

            //TP-4332 -- Handled the local torch signal for camera enabled expert session
            let updatedParticipants = [...this.state.updatedParticipants];
            if (updatedParticipants && updatedParticipants.length > 0) {
                const [up] = updatedParticipants.filter(u => u.email === email);
                const upindex = updatedParticipants.findIndex(u => u.email === email);
                up.torchActive = data;
                updatedParticipants[upindex] = up;
                this.setState({updatedParticipants});
            }

            participants[index] = to;
            if ((maxDivParticipant && maxDivParticipant.email !== email) || maxView === false) {
                // Send the signal to other experts to keep the torchActive flag of the 
                // other experts in sync with the Primary expert
                if (experts.length > 0) {
                    experts.forEach((e) => {
                        this.sessionSignal.sendSignalSyncExperts({
                            show_grid: true,
                            email: to.email,                                    
                            torch_button_active: data,
                            zoom_level: to.zoomLevel,
                            hand_icon_enabled: (this.state.showControlRequestDialog === true) ? false : true, //TP-1313
                            reason: "update_action"
                        }, e.email);
                    });
                }
            }
            this.setState({participants}, () => {
                this.props.setParticipant({participantList: participants});// to set the changes in the participant list
            });
            
        } catch (error) {
            printConsole("Exception occured in the processLocalTorch inside Expert component");
            printConsole(error);
        }
    }

    processSignalLocalZoomLevel = ({from, data}) => {
        try {
            const {maxDivParticipant, maxView} = this.state;
            const {email} = from;
            let participants = [...this.state.participants];
            const experts = this.state.participants.filter(p => p.isexpert)                
            const [to] = this.state.participants.filter(e => e.email === email);
            const index = this.state.participants.findIndex(e => e.email === email);
    
            //to.torchActive = data;
            to.zoomLevel = data;
            //TP-4332 -- Handled the local torch signal for camera enabled expert session
            let updatedParticipants = [...this.state.updatedParticipants];
            if (updatedParticipants && updatedParticipants.length > 0) {
                const [up] = updatedParticipants.filter(u => u.email === email);
                const upindex = updatedParticipants.findIndex(u => u.email === email);
                up.zoomLevel = data;
                updatedParticipants[upindex] = up;
                this.setState({updatedParticipants});
            }
    
            participants[index] = to;
            if ((maxDivParticipant && maxDivParticipant.email !== email) || maxView === false) {
                printConsole("Sending SYNC_EXPERTS from Expert component");
                // Send the signal to other experts to keep the zoomLevel value of the 
                // other experts in sync with the Primary expert
                if (experts.length > 0) {
                    experts.forEach((e) => {
                        this.sessionSignal.sendSignalSyncExperts({
                            show_grid: true,
                            email: to.email,                                    
                            torch_button_active: to.torchActive,
                            zoom_level: data,
                            hand_icon_enabled: (this.state.showControlRequestDialog === true) ? false : true, //TP-1313
                            reason: "update_action"
                        }, e.email);
                    });
                }
            }
            this.setState({participants}, () => {
                this.props.setParticipant({participantList: participants});// to set the changes in the participant list
            });
            
        } catch (error) {
            printConsole("Exception occured in the processSignalLocalZoomLevel inside Expert component");
            printConsole(error);
        }
    }

    //TP-3067 -- handler for STOP_SESSION_USER - FQ3-30
    processSignalStopSessionUser = ({to}) => {
        const {user} = this.props;
        if (user.email === to) {
            const message = "You have been disconnected from the session remotely."
            this.triggerMessage(message, "stopsession", '', '');
            /* this.setState({ 
                flashMsgText: "You have been disconnected from the session remotely.", 
                showFlashMessage: true, flashLeft:"42%", flashTop:"50%" 
            }); */
            this.timeOut = setTimeout(() => {
                this.props.onSessionCmdChange('session_stopped');
                clearTimeout(this.timeOut);
            }, 2000);            
        }
    }

    // Handling of Technician functions when camera is swapped
    handleFeaturesForTech = (techDetailsObj) => {
        const {maxDivParticipant} = this.state;
        printConsole(techDetailsObj);
        if (this.state.showMaxDivControls === false) return;
        // If the technician that swapped its camera is the Max-view technician?
        if (techDetailsObj.email === maxDivParticipant.email) {
            this.maxDivActionGoingOn({flag: false});
            this.setState({resetMax: true});
            if (this.state.startRecording && isSilentRecording === false)
                this.stopRecordingCallback(); // stop the recording               
            //this.stopScreenshotRecordingCb(); 
            this.setState({resetMax: false});
        }
    }

    // switch the stream for max div participant MB2-550
    switchMaxVideoStream = ({flag, stream}) => {
        const {connections, participants, maxDivParticipant, user, groupInfo} = this.state;
        const experts = participants.filter(p => p.isexpert);
        let expertsConnections = [];
        experts && experts.forEach((e) => {
            expertsConnections.push(connections.filter(c => c.email === e.email));
        })
        printConsole('sending ss signal to ', maxDivParticipant.email)
        const [maxDivConnection] = connections.filter(c => c.email === maxDivParticipant.email);
        if (flag){
            //const to = maxDivConnection.email;
            //TP-4077 & TP-3119 & TP-2578
            if (groupInfo.session_type === "CM" || (groupInfo.session_type === "RM" && this.props.enable_user_video !== "my_camera_disabled") || this.props.audio_mode === "tech2Tech")
                maxDivConnection && maxDivConnection.peerConnection.addScreenShareTrackPilot({displayMediaStream: stream});
                //maxDivConnection && maxDivConnection.peerConnection.replaceVideoTrackWithScreenShare({displayMediaStream: stream});
            else 
                maxDivConnection && maxDivConnection.peerConnection.addScreenShareTrack({displayMediaStream: stream});
                //maxDivConnection && maxDivConnection.peerConnection.offerConnectionWhileScreenShare({displayMediaStream: stream, to});
            expertsConnections && expertsConnections.forEach((connection) => {
                //const to = connection && connection[0].email;
                //TP-4077 & TP-3119 & TP-2578
                if (groupInfo.session_type === "CM" || (groupInfo.session_type === "RM" && this.props.enable_user_video !== "my_camera_disabled") || this.props.audio_mode === "tech2Tech")
                    connection && connection[0].peerConnection.addScreenShareTrackPilot({displayMediaStream: stream});
                else
                    connection && connection[0].peerConnection.addScreenShareTrack({displayMediaStream: stream})
                //connection && connection[0].peerConnection.offerConnectionWhileScreenShare({displayMediaStream: stream, to});
            });
            this.ssTimer = setTimeout(() => { 
                //TP-5397 -- do not send these socket signals if the button has been turned off for Screencast
                if (this.state.isMaxDivSS) {
                    //TP-5982
                    //this.sessionSignal.sendSignalPrepareExpertSS(flag, maxDivParticipant.email);    
                    this.sessionSignal.sendSignalStartExpertSS(maxDivParticipant.email); 
                    experts && experts.forEach((e) => {
                        //TP-5982
                        //this.sessionSignal.sendSignalPrepareExpertSS(flag, e.email);
                        this.sessionSignal.sendSignalStartExpertSS(e.email);
                        // T32-463
                        /* this.sessionSignal.sendSignalSyncExperts({
                            show_grid: false,
                            max_view_technician: this.state.user.email, //TP-3893
                            ratio: "16:9",
                            header_fileShare_active: (this.props.isHeaderFileShare && !this.props.isOneToOneFileShare) ? true : false, //TP-2378
                            reason: "show_action"},  
                        e.email); */                    
                        printConsole('sending ss signal to ', e.email);
                    });
                }
                this.setState({ isSSOngoing: false }); //TP-5397
                clearTimeout(this.ssTimer);
            }, 5000);
               
        }
        if (!flag){
            this.sessionSignal.sendSignalStopExpertSS(maxDivParticipant.email); 
            experts && experts.forEach((e) => {
                // T32-463 Expert SS for the new Multi-expert implementation
                printConsole('sending stop ss signal to ', e.email);
                this.sessionSignal.sendSignalStopExpertSS(e.email);
                this.sessionSignal.sendSignalSyncExperts({
                    show_grid: false,
                    max_view_technician: maxDivParticipant.email, //TP-3893
                    annotation_active: false,
                    zoom_button_active:  (maxDivParticipant.zoomLevel > 1) ? true : false,
                    zoom_level: maxDivParticipant.zoomLevel,
                    torch_button_active: maxDivParticipant.torchActive,
                    ratio: this.state.aspectRatio,
                    hand_icon_enabled: true,
                    header_fileShare_active: (this.props.isHeaderFileShare && !this.props.isOneToOneFileShare) ? true : false, //TP-2378
                    reason: "show_action"},  
                e.email);                
            });   
            //this.sendMaxViewSignal({flag: true}); 
            //const to = maxDivConnection && maxDivConnection.email; 
            //TP-4077 & TP-3119 & TP-2578
            if (groupInfo.session_type === "CM" || (groupInfo.session_type === "RM" && this.props.enable_user_video !== "my_camera_disabled") || this.props.audio_mode === "tech2Tech" /**TP-5882*/)
                maxDivConnection && maxDivConnection.peerConnection.removeScreenShareTrackPilot({userMediaStream: stream});
                //maxDivConnection && maxDivConnection.peerConnection.replaceScreenTrackWithVideo();
            else   
                maxDivConnection && maxDivConnection.peerConnection.removeScreenShareTrack({audioMediaStream: stream});
                //maxDivConnection && maxDivConnection.peerConnection.offerConnectionWhileStopScreenShare({displayMediaStream: stream, to})
            expertsConnections && expertsConnections.forEach((connection) => {
                //const to = connection && connection[0].email;
                //TP-4077 & TP-3119 & TP-2578
                if (groupInfo.session_type === "CM" || (groupInfo.session_type === "RM" && this.props.enable_user_video !== "my_camera_disabled") || this.props.audio_mode === "tech2Tech" /**TP-5882*/)
                    connection && connection[0].peerConnection.removeScreenShareTrackPilot({userMediaStream: stream});
                    //connection && connection[0].peerConnection.replaceScreenTrackWithVideo();
                else
                    connection && connection[0].peerConnection.removeScreenShareTrack({audioMediaStream: stream});
                    //connection && connection[0].peerConnection.offerConnectionWhileStopScreenShare({displayMediaStream: stream, to});
            });
            //TP-4019 -- send the Primary expert's Camera view state (if turned off) to all Passive experts right after stopping ExpertSS
            if (this.state.updatedParticipants && this.state.updatedParticipants.length > 0) {
                const [me] = this.state.updatedParticipants.filter(up => up.email === this.props.user.email);
                if (me /*TP-4493 && me.disableVideo === true */) {
                    const experts = this.state.participants.filter(p => p.isexpert);
                    this.expertVideoStateTimer = setTimeout(() => {
                        experts && experts.forEach(e => {                                
                            this.triggerExpertVideoFeedEnabledToUser(e.email); //TP-3532
                        })
                        clearTimeout(this.expertVideoStateTimer)
                    }, 5000);
                }
            }
        }
    }

    // change the stream for all participant MB2-550
    switchAllVideoStream = ({flag, stream}) => {
        const {connections, participants, maxDivParticipant, maxView, user, groupInfo} = this.state;
        const experts = participants.filter(p => p.isexpert);
        /* let disabledEmails = participants.map(p => {
            if (p.disableVideo === false && p.isexpert === false)
                return p.email;
            else if (p.isexpert === true)
                return p.email;
            else
                return;
        });
        const filteredEmails = disabledEmails.filter(function (el) {
            return el !== undefined;
        }); */          
        //printConsole(filteredEmails);
        connections && connections.forEach((connection) => {
            //TP-4077 & TP-3119 & TP-2578
            if (groupInfo.session_type === "CM" || (groupInfo.session_type === "RM" && this.props.enable_user_video !== "my_camera_disabled") || this.props.audio_mode === "tech2Tech")
                flag && connection && /* filteredEmails.includes(connection.email) && */ connection.peerConnection.addScreenShareTrackPilot({displayMediaStream: stream})
            else 
                flag && connection && /* filteredEmails.includes(connection.email) && */ connection.peerConnection.addScreenShareTrack({displayMediaStream: stream})
            //flag && connection && connection.peerConnection.offerConnectionWhileScreenShare()
            
            //TP-4077 & TP-3119
            if (groupInfo.session_type === "CM" || (groupInfo.session_type === "RM" && this.props.enable_user_video !== "my_camera_disabled") || this.props.audio_mode === "tech2Tech" /**TP-5881*/)
                !flag && connection && /* filteredEmails.includes(connection.email) && */ connection.peerConnection.removeScreenShareTrackPilot({userMediaStream: stream})
            else
                !flag && connection && /* filteredEmails.includes(connection.email) && */ connection.peerConnection.removeScreenShareTrack({audioMediaStream: stream})
        });
        //if (filteredEmails.length === connections.length) {
            if (flag) {
                this.ssTimer = setTimeout(() => { 
                    //TP-5397 -- do not send these socket signals if the button has been turned off for Screencast
                    if (this.state.isSideBarSS) {                        
                        this.sessionSignal.sendSignalStartExpertSS();
                    }
                    // T32-463
                    /* experts && experts.forEach((e) => {
                        this.sessionSignal.sendSignalSyncExperts({
                            show_grid: false,
                            max_view_technician: this.state.user.email, //TP-3893
                            ratio: "16:9",
                            header_fileShare_active: (this.props.isHeaderFileShare && !this.props.isOneToOneFileShare) ? true : false, //TP-2378
                            reason: "show_action"},  
                        e.email);                         
                    }); */
                    if (this.state.maxView === false && this.state.showMaxDivControls === true) {
                        this.onClickMultiGridView();
                    }
                    this.setState({ isSSOngoing: false }); /**TP-5397*/
                    clearTimeout(this.ssTimer);
                }, 5000);
            }
            if (!flag){
                // Expert SS for the new Multi-expert implementation
                this.sessionSignal.sendSignalStopExpertSS();    
                if(this.state.maxView && this.state.maxDivParticipant && this.state.maxDivParticipant !== ''){
                    experts && experts.forEach((e) => {
                        this.sessionSignal.sendSignalSyncExperts({
                            show_grid: false,
                            max_view_technician: maxDivParticipant.email, //TP-3893
                            annotation_active: false,
                            zoom_button_active:  (maxDivParticipant.zoomLevel > 1) ? true : false,
                            zoom_level: maxDivParticipant.zoomLevel,
                            torch_button_active: maxDivParticipant.torchActive,
                            ratio: this.state.aspectRatio,
                            hand_icon_enabled: (this.state.showControlRequestDialog === true) ? false : true, //TP-1313
                            header_fileShare_active: (this.props.isHeaderFileShare && !this.props.isOneToOneFileShare) ? true : false, //TP-2378
                            reason: "show_action"},  
                        e.email);                        
                    });
                }else{
                    experts && experts.forEach((e) => {
                        this.sessionSignal.sendSignalSyncExperts({
                            show_grid: (this.state.showControlRequestDialog === true) ? false : true, //TP-1313
                            hand_icon_enabled: true,
                            header_fileShare_active: (this.props.isHeaderFileShare && !this.props.isOneToOneFileShare) ? true : false, //TP-2378
                            reason: "show_action"}, 
                        e.email);                        
                    });
                    if (this.state.showMaxDivControls === true) {
                        const technicians = this.state.participants.filter(p => !p.isexpert);
                        //TP-5164 -- Use case 2
                        if (this.props.enable_user_video !== "my_camera_disabled") {
                            this.ssTimer = setTimeout(() => { 
                                this.onClickMultiGridView();
                                clearTimeout(this.ssTimer);
                            }, 300);
                        } else if (this.props.enable_user_video === "my_camera_disabled" && (technicians === undefined || technicians.length === 0)) {
                            const [expert] = this.state.participants.filter(p => p.isexpert); //TP-5468 First expert in the expert list
                            //TP-5723 - Show the Maximized Silhouette view during "my_camera_disabled" state and only experts remaining in call
                            if (expert)
                                this.onMaximizeSilhouetteView({participant: expert}); //TP-5468
                        } else {
                            this.ssTimer = setTimeout(() => { 
                                this.onClickMultiGridView();
                                clearTimeout(this.ssTimer);
                            }, 300);
                        }
                    }
                }
                if(maxView && maxDivParticipant && maxDivParticipant !== ''){
                    //this.sendMaxViewSignal({flag: true});// enable the local rc for max div technician
                }
                //TP-4019 -- send the Primary expert's Camera view state (if turned off) to all Passive experts right after stopping ExpertSS
                if (this.state.updatedParticipants && this.state.updatedParticipants.length > 0) {
                    const [me] = this.state.updatedParticipants.filter(up => up.email === this.props.user.email);
                    if (me /*TP-4493 && me.disableVideo === true */) {
                        const experts = this.state.participants.filter(p => p.isexpert);
                        this.expertVideoStateTimer = setTimeout(() => {
                            experts && experts.forEach(e => {                                
                                this.triggerExpertVideoFeedEnabledToUser(e.email); //TP-3532
                            })
                            clearTimeout(this.expertVideoStateTimer)
                        }, 5000);
                    }
                }
            }
        /*}  else {
            if (flag) {                
                this.ssTimer = setTimeout(() => { 
                    filteredEmails && filteredEmails.forEach((f) => {
                        this.sessionSignal.sendSignalPrepareExpertSS(flag, f);
                        this.sessionSignal.sendSignalStartExpertSS(f);
                    })
                    experts && experts.forEach((e) => {
                        this.sessionSignal.sendSignalSyncExperts({
                            show_grid: false,
                            max_view_technician: this.state.user.email, //TP-3893
                            ratio: "16:9",
                            header_fileShare_active: (this.props.isHeaderFileShare && !this.props.isOneToOneFileShare) ? true : false, //TP-2378
                            reason: "show_action"},  
                        e.email);
                        //this.sessionSignal.sendSignalExpertSwitchMaxdiv({email: this.state.user.email, ratio: "16:9"}, e.email);
                    });
                    clearTimeout(this.ssTimer);
                }, 5000);                
            }
            if (!flag){
                if(maxView && maxDivParticipant && maxDivParticipant !== ''){
                    experts && experts.forEach((e) => {
                        this.sessionSignal.sendSignalSyncExperts({
                            show_grid: false,
                            max_view_technician: maxDivParticipant.email, //TP-3893 
                            ratio: this.state.aspectRatio,
                            header_fileShare_active: (this.props.isHeaderFileShare && !this.props.isOneToOneFileShare) ? true : false, //TP-2378
                            reason: "show_action"}, 
                        e.email);
                        //this.sessionSignal.sendSignalExpertSwitchMaxdiv({email: maxDivParticipant.email, ratio: this.state.aspectRatio}, e.email);
                    });
                }else{
                    experts && experts.forEach((e) => {
                        this.sessionSignal.sendSignalSyncExperts({
                            show_grid: true
                            header_fileShare_active: (this.props.isHeaderFileShare && !this.props.isOneToOneFileShare) ? true : false, //TP-2378
                            reason: "show_action"}, 
                        e.email);
                        //this.sessionSignal.sendSignalExpertSwitchMaxdiv('', e.email);
                    });
                }
                filteredEmails && filteredEmails.forEach((f) => {
                    this.sessionSignal.sendSignalStopExpertSS(f); 
                });   
                if(maxView && maxDivParticipant && maxDivParticipant !== ''){
                    this.sendMaxViewSignal({flag: true});// enable the local rc for max div technician
                }
            }
        } */
    }

    // change the stream for a particular participant
    switchOneVideoStream = ({flag, stream, partcipant}) => {
        printConsole("Switching the remote track for new user to screen share!!");
        const {connections, user, groupInfo} = this.state;
        //printConsole(flag);
        //printConsole(stream);
        if (partcipant === null) return;
        printConsole(`sending ss signal to ${partcipant.email}`);
        const [partcipantConnection] = connections.filter(c => c.email === partcipant.email);
        printConsole(partcipantConnection);
        if (flag){
            //TP-4077 & TP-3119 & TP-2578
            if (groupInfo.session_type === "CM" || (groupInfo.session_type === "RM" && this.props.enable_user_video !== "my_camera_disabled") || this.props.audio_mode === "tech2Tech")
                partcipantConnection && partcipantConnection.peerConnection.addScreenShareTrackPilot({displayMediaStream: stream});
            else
                partcipantConnection && partcipantConnection.peerConnection.addScreenShareTrack({displayMediaStream: stream});
            //partcipantConnection && partcipantConnection.peerConnection.offerConnectionWhileScreenShare();
            this.ssTimer = setTimeout(() => { 
                this.sessionSignal.sendSignalPrepareExpertSS(flag, partcipant.email);
                this.sessionSignal.sendSignalStartExpertSS(partcipant.email);
                if (partcipant.isexpert)
                // T32-463
                    /* this.sessionSignal.sendSignalSyncExperts({
                        show_grid: false,
                        max_view_technician: this.state.user.email, //TP-3893
                        ratio: "16:9", 
                        header_fileShare_active: (this.props.isHeaderFileShare && !this.props.isOneToOneFileShare) ? true : false, //TP-2378
                        reason: "show_action"}, 
                    partcipant.email); */                    
                clearTimeout(this.ssTimer);
            }, 5000);
               
        }
        if (!flag){
            this.sessionSignal.sendSignalStopExpertSS(partcipant.email);                
            //this.sendMaxViewSignal({flag: true});   
            //TP-4077 & TP-3119 & TP-2578
            if (groupInfo.session_type === "CM" || (groupInfo.session_type === "RM" && this.props.enable_user_video !== "my_camera_disabled") || this.props.audio_mode === "tech2Tech" /**TP-5882*/) 
                partcipantConnection && partcipantConnection.peerConnection.removeScreenShareTrackPilot({userMediaStream: stream});            
            else
                partcipantConnection && partcipantConnection.peerConnection.removeScreenShareTrack({audioMediaStream: stream}); 

            //TP-4019 -- send the Primary expert's Camera view state (if turned off) to all Passive experts right after stopping ExpertSS            
            if (this.state.updatedParticipants && this.state.updatedParticipants.length > 0) {
                const [me] = this.state.updatedParticipants.filter(up => up.email === this.props.user.email);
                if (me /*TP-4493 && me.disableVideo === true */) {
                    const experts = this.state.participants.filter(p => p.isexpert);
                    this.expertVideoStateTimer = setTimeout(() => {
                        experts && experts.forEach(e => {                                
                            this.triggerExpertVideoFeedEnabledToUser(e.email); //TP-3532
                        })
                        clearTimeout(this.expertVideoStateTimer)
                    }, 5000);
                }
            }
        }
        //TP-5499 -- Reset the sSParticipant only when the switch one video with Screencast track happens for that participant only
        if (this.props.sSParticipant === partcipant)
            this.props.setSSParams({ sendExpertSS: false, sSParticipant: null })
    }

    triggerVideoTurnOffOnStatusToUser = (participantEmail) => {
        const {participants}  = this.state;
        //printConsole(`participant list ---> ${JSON.stringify(participants)}`);
        // Video turn off/on Signal to be sent to the newly joining technician 
        let payload = [];
        participants && participants.forEach(p => {
            if (p!== undefined) {
                let obj = {}
                if (p.isexpert === false) {
                    obj = {
                        flag: p.disableVideo, 
                        userId: p.userId,
                        email: p.email
                    }
                } else {
                    obj = {
                        flag: (this.props.session_type === "CM" || (this.props.session_type === "RM" && this.checkIfExpertCameraEnabled() === true)) ? p.disableVideo : true, /**TP-3119*/
                        userId: p.userId,
                        email: p.email
                    }
                }
                payload.push(obj);
            }
        })
        printConsole(`payload for Video turn off signal --> ${JSON.stringify(payload)}`);
        if (payload && payload.length > 0)
            this.sessionSignal.sendSignalTurnOnMaxViewUserVideo(payload, participantEmail);
    }

    triggerExpertVideoFeedEnabledToUser = (participantEmail) => {
        //TP-3532 -- New Socket signal to let the newly joining Expert to know this expert user's Video feed is turned on or not
        //	TP-3737	-- to send the current expert's Video state instead of only the Preference
        if (this.checkIfExpertCameraEnabled() === true) {
            const {updatedParticipants} = this.state;
            if (updatedParticipants && updatedParticipants.length > 0) {
                const [me] = updatedParticipants.filter(up => up.email === this.props.user.email);
                if (me)
                    this.sessionSignal.sendSignalExpertVideoFeedEnabled(!me.disableVideo, this.checkIfExpertCameraEnabled(), participantEmail); //TP-3683 & TP-3679                    
                else
                    this.sessionSignal.sendSignalExpertVideoFeedEnabled(true, this.checkIfExpertCameraEnabled(), participantEmail); //TP-3683 & TP-3679                    
            } else 
                this.sessionSignal.sendSignalExpertVideoFeedEnabled(true, this.checkIfExpertCameraEnabled(), participantEmail); //TP-3683 & TP-3679                    
        } else {
            this.sessionSignal.sendSignalExpertVideoFeedEnabled(this.checkIfExpertCameraEnabled(), this.checkIfExpertCameraEnabled(), participantEmail); //TP-3683
        }
    }

    //TP-3205 -- When the turn on/off Expert Video signal is received
    processSignalTurnOnUserVideo = ({data, to, from}) => {
        //TP-3119
        if (this.state.groupInfo.session_type === "CM" || (this.state.groupInfo.session_type === "RM" && this.props.enable_user_video !== "my_camera_disabled")) {
            printConsole(data);
            //TP-3679
            let {participants, updatedParticipants, expertShareScreenMaxDiv, ssFrom /**TP-4019*/} = this.state;
            const {user} = this.props;
            let userVideoCount = 0;    
            let changedParticipant = null;//TP-3677  
            if (updatedParticipants && updatedParticipants.length > 0) {
                updatedParticipants.forEach((p,i) => {
                    if (p.userId === user.id) {
                        const beforeVideoState = updatedParticipants[i].disableVideo; //TP-3677                                  
                        updatedParticipants[i].disableVideo = !data;
                        //TP-3736
                        updatedParticipants[i].streams && updatedParticipants[i].streams.forEach(stream => {
                            if (stream.getVideoTracks().length > 0)
                                stream.getVideoTracks()[0].enabled = data;
                        })
                        //TP-3677
                        if (beforeVideoState !== updatedParticipants[i].disableVideo){
                            changedParticipant = updatedParticipants[i];
                        }
                        this.props.setMyVideoState(data); //TP-5393
                    }
                });
            } else {
                participants && participants.forEach((p,i) => {
                    if (p.userId === user.id) {
                        const beforeVideoState = participants[i].disableVideo; //TP-3677                                  
                        participants[i].disableVideo = !data;
                        //TP-3736
                        participants[i].streams && participants[i].streams.forEach(stream => {
                            if (stream.getVideoTracks().length > 0)
                                stream.getVideoTracks()[0].enabled = data;
                        })
                        //TP-3677
                        if (beforeVideoState !== participants[i].disableVideo){
                            changedParticipant = participants[i];
                        }
                    }
                })
            }
            printConsole("changed participant list: ");
            printConsole(changedParticipant);
            //TP-5420
            const list = participants.filter(p => p.disableVideo === false && p.isexpert === false);
            printConsole("list ---------------------------");
            printConsole(list);
            if (list !== undefined) {
                printConsole(`Length of Users with Video on -- ${list.length}`)
                userVideoCount = list.length;
            }
            //this.updateJoinees({other_participants: participants})
            
            this.setState({updatedParticipants, participants, changedParticipant}, () => {
                /**TP-3679 & TP-3611 */
                this.setState({ triggerGridView: true }, () => {
                    this.setState({ triggerGridView: false }, () => {
                        this.setState({ changedParticipant: null })
                    });//TP-3677
                })
                if (this.state.maxView === false &&( (this.checkIfExpertCameraEnabled() === false && userVideoCount > 1) || this.checkIfExpertCameraEnabled() === true))
                    this.onClickMultiGridView();
                else {
                    //TP-4019
                    if (expertShareScreenMaxDiv && from.email === ssFrom) {
                        printConsole("Don't Draw screen of the Expert when screensharing");
                    } else {
                        if (userVideoCount === 0) this.setState({ showHideDivIconAudioOnly: true });
                        else this.setState({ showHideDivIconAudioOnly: false });
                    }
                }
                
            }) 
        }
    }

    processSignalTurnOnMaxViewUserVideo = ({data, from}) => {
        let {participants, updatedParticipants, expertShareScreenMaxDiv, ssFrom /**TP-4019*/} = this.state; //TP-3679
        let changedParticipant = null;//TP-3677
        let userVideoCount = 0;
        participants && participants.forEach((p,i) => {
            const [datObj] = data.filter(d => d.userId === p.userId);
            const beforePartVideoState = participants[i].disableVideo; //TP-3677
            printConsole(datObj);
            if (datObj !== undefined && datObj !== null) {                
                /**TP-3788*/
                participants[i].disableVideo = datObj.flag;   
                participants[i].streams && participants[i].streams.forEach( stream => {
                    if (stream.getVideoTracks()[0])
                        stream.getVideoTracks()[0].enabled = !datObj.flag;
                }) 
                //TP-5508
                if (participants[i].isexpert === false && participants[i].grayedVideo === true && datObj.flag === true)            
                    participants[i].grayedVideo = !datObj.flag;
                //TP-6491
                if (this.state.maxView === true && participants[i].email === this.state.maxDivParticipantEmail && participants[i].disableVideo === true && this.state.showSSLoader === true) {
                    printConsole("Vidoe is turned off so buffering killed..")
                    this.setState({ showSSLoader: false });
                }
            }    
            //TP-3677       
            if (beforePartVideoState !== participants[i].disableVideo){
                changedParticipant = participants[i];
            }        
        });
        //TP-3679
        if (this.props.session_type === "RM" && this.props.enable_user_video !== "my_camera_disabled") {
            updatedParticipants && updatedParticipants.forEach((p,i) => {
                const [datObj] = data.filter(d => d.userId === p.userId);
                printConsole(datObj);
                const beforeVideoState = updatedParticipants[i].disableVideo; //TP-3677
                if (datObj !== undefined && datObj !== null) {                
                    /**TP-3788*/
                    updatedParticipants[i].disableVideo = datObj.flag;     
                    updatedParticipants[i].streams && updatedParticipants[i].streams.forEach( stream => {
                        if (stream.getVideoTracks()[0])
                            stream.getVideoTracks()[0].enabled = !datObj.flag;
                    })  
                    //TP-5508  
                    if (updatedParticipants[i].isexpert === false && updatedParticipants[i].grayedVideo === true && datObj.flag === true)            
                        updatedParticipants[i].grayedVideo = !datObj.flag;      
                }     
                //TP-3677       
                if (beforeVideoState !== updatedParticipants[i].disableVideo){
                    changedParticipant = updatedParticipants[i];
                }
            });
        }
        printConsole("changed subscriber list: ");
        printConsole(participants);
        const list = participants.filter(p => p.disableVideo === false && p.isexpert === false);
        printConsole("list ---------------------------");
        printConsole(list);
        if (list !== undefined) {
            printConsole(`Length of Users with Video on -- ${list.length}`)
            userVideoCount = list.length;
        }
        this.updateJoinees({other_participants: participants})
        this.setState({participants, updatedParticipants, changedParticipant}, () => {
            //TP-3677
            this.setState({userVideoCount}, () => {
                this.setState({changedParticipant: null});
                /**	TP-3679 TP-3419 */
                /* if (this.props.session_type === "RM" && this.checkIfExpertCameraEnabled() === true)
                    this.getUpdatedParticipantswithPreview();
                else { */
                    if (this.state.maxView === false && ((this.checkIfExpertCameraEnabled() === false && userVideoCount > 1) || this.checkIfExpertCameraEnabled() === true))
                        this.onClickMultiGridView();
                    else {
                        //TP-4019
                        if (expertShareScreenMaxDiv && from.email === ssFrom) {
                            printConsole("Don't Draw screen of the Expert when screensharing");
                        } else {
                            if (userVideoCount === 0) this.setState({ showHideDivIconAudioOnly: true });
                            else this.setState({ showHideDivIconAudioOnly: false });
                        }
                    }
                //}
            });
        })             
    }

    //TP-4483 -- Process the "VIDEO_PAUSED" socket signal received by Expert
    processSignalLiveVideoPaused = ({data, from}) => {
        let {maxView, maxDivParticipant} = this.state; //TP-3679
        let participants = [...this.state.participants];
        let updatedParticipants = [...this.state.updatedParticipants];
        if (/* (this.props.bRemoteCapture || this.props.rcStart) && */ data.isPaused === true && data.reason === "RC_ONGOING" /**TP-5492*/) return;
        if ((this.props.bRemoteCapture || this.props.rcStart) && data.isPaused === true) return; //	TP-4540
        //TP-4633 & TP-4556 -- Ignore this signal if expert in Screencast mode and re-negotiation happens
        //if (this.state.isMaxDivSS === true || this.state.isSideBarSS === true) return;
        let changedParticipant = null;//TP-3677
        //TP-4538
        participants && participants.forEach((p,i) => {
            const beforePartVideoState = participants[i].isPaused; //TP-3677
            if (from.email === p.email) {                
                const contentType = 'image/'+data.format;
                //TP-4537
                if (data.isPaused === true) {
                    p.isPaused = data.isPaused;
                    if (maxView && p.email === this.state.maxDivParticipant.email) {
                        maxDivParticipant.isPaused = data.isPaused;
                        maxDivParticipant.poster = (data.imageframe === "") ? "" : 'data:'+contentType+';base64,'+data.imageframe; 
                        maxDivParticipant.pausedReason = data.reason; //TP-4822 TP-4762
                    }
                    p.poster = (data.imageframe === "") ? "" : 'data:'+contentType+';base64,'+data.imageframe;                             
                    p.pausedReason = data.reason; //TP-4762
                } else if (data.isPaused === false) {
                    p.isPaused = data.isPaused;
                    if (maxView && p.email === this.state.maxDivParticipant.email) {
                        maxDivParticipant.isPaused = data.isPaused;
                        maxDivParticipant.poster = "";
                        maxDivParticipant.pausedReason = data.reason; //TP-4822 TP-4762
                    }
                    p.poster = "";
                    p.pausedReason = data.reason; //TP-4762
                }           
            }    
            if (beforePartVideoState !== p.isPaused){
                changedParticipant = p;
            } 
        });
        this.updateJoinees({other_participants: participants}); //TP-4538
        if (this.props.session_type === "RM" && this.props.enable_user_video !== "my_camera_disabled") {
            updatedParticipants && updatedParticipants.forEach((p,i) => {
                const beforeVideoState = updatedParticipants[i].isPaused; //TP-3677
                if (from.email === p.email) {                
                    const contentType = 'image/'+data.format;
                    //TP-4537
                    if (data.isPaused === true) {              
                        p.isPaused = data.isPaused;
                        p.poster = (data.imageframe === "") ? "" : 'data:'+contentType+';base64,'+data.imageframe;                             
                        p.pausedReason = data.reason; //TP-4762
                    } else if (data.isPaused === false) {
                        p.isPaused = data.isPaused;
                        p.poster = "";
                        p.pausedReason = data.reason; //TP-4762
                    } 
                } 
                if (beforeVideoState !== p.isPaused){
                    changedParticipant = p;
                }
            });
        }

        this.setState({participants, maxDivParticipant, updatedParticipants, changedParticipant}, () => {            
            this.setState({ triggerGridView: true }, () => {
                this.setState({ triggerGridView: false }, () => {
                    this.setState({ changedParticipant: null })
                });//TP-3677
            })
            if (maxView && from.email === this.state.maxDivParticipant.email) {
                //TP-4599
                this.props.setMaxDivId({g_maxDivId: this.state.maxDivParticipant.stream_id, maxDivParticipant: this.state.maxDivParticipant})
                //TP-5472
                maxDivParticipant.streams.forEach((stream) => {
                    //console.log(stream.getVideoTracks());
                    this.maxVideoRef.current.srcObject = stream
                    this.maxVideoRef.current.id = maxDivParticipant.stream_id
                })
                /* this.maxVideoRef.current && this.maxVideoRef.current.srcObject && this.maxVideoRef.current.srcObject.getAudioTracks().forEach(track => {
                    console.log("Audio tracks on Max-view");
                    console.log(track);
                }) */
                //TP-5074
                if (data.isPaused === true && data.reason === "DEFAULT") {
                    this.setState({resetMax: true}, ()=>{
                        this.setState({resetMax: false});
                    });
                }
            }
            //TP-4538
            if (maxView && from.email === this.state.maxDivParticipant.email && data.isPaused === false){
                this.setState({changedParticipant: null})
                this.onClickMaximise({participant: maxDivParticipant, isSame: true}); //TP-4546
                this.maxTimer = setTimeout(() => {
                    //TP-4585
                    if (this.props.bAnnotate && !this.props.bRemoteCapture) {
                        this.props.reRenderAnnotateCanvas();
                    }
                    clearTimeout(this.maxTimer);
                }, 2000);
            }
            if (data.isPaused === true && this.state.showMaxDivControls === true && this.props.isHeaderFileShare === true) {
                //TP-4637 -- set the opacity to low when live video is paused by APK technician
                var rangeSlider = document.getElementById('slider-range');
                //TP-4641
                if (rangeSlider && rangeSlider !== null) {
                    this.props.setAssetOpacity(20);
                    rangeSlider.noUiSlider.set(20);
                    const fileElem = document.getElementById('file-viewer');
                    if (fileElem && fileElem !==null ){
                        fileElem.addEventListener('mousemove', this.maximizeOpacityPrimary);
                    }
                }
            } 
        })  

    }

    //TP-4772
    onChangeAspectRatio = (participant) => {
        if (participant === undefined) return; //TP-5164
        let experts = this.state.participants.filter(p => p.isexpert);
        /* !this.state.isSideBarSS && !this.state.isMaxDivSS && */ experts && experts.forEach((e) => {
            printConsole(`onChangeAspectRatio sending sendSignalSyncExperts=====================> ${participant.email} to ${e.email}`)
            this.sessionSignal.sendSignalSyncExperts({
                show_grid: false,
                max_view_technician: participant.email, //TP-3893
                ratio: this.state.aspectRatio,
                annotation_active: this.props.bAnnotate,
                torch_button_active: participant ? participant.torchActive: false,
                zoom_button_active: participant && parseInt(participant.zoomLevel) > 1 ? true : false,
                zoom_level: participant ? parseInt(participant.zoomLevel) : 1,
                hand_icon_enabled: true,
                header_fileShare_active: (this.props.isHeaderFileShare && !this.props.isOneToOneFileShare) ? true : false, //TP-2378
                reason: "show_action"}, 
            e.email);
        });
    }

    //TP-4637 -- Then maximize the opacity to 80% right after the mouse movement is detected over the file viewer
    maximizeOpacityPrimary = () => {
        var rangeSlider = document.getElementById('slider-range');
        //TP-4641
        if (rangeSlider && rangeSlider !== null) {
            this.props.setAssetOpacity(80);
            rangeSlider.noUiSlider.set(80);
            const fileElem = document.getElementById('file-viewer');
            if (fileElem && fileElem !==null ){
                fileElem.removeEventListener('mousemove', this.maximizeOpacityPrimary);
            }
        }
    }

    //TP-740 -- Setting the details of the (Passive) Expert who asked for 
    // transfer of Max-view controls
    processSignalRequestMaxDivControl = ({data, from}) => {
        if (this.state.currentPrimaryExpert && this.state.currentPrimaryExpert.email === this.state.user.email) {
            this.setState({ expertFirstName: data.firstName,
                expertLastName: data.lastName, expertUserId: from.userId
            });
        }
    }

    processSignalYieldMaxDivControl = ({data, from}) => {
        if (data) {
            this.setOnlineUsers(); //TP-3468
            printConsole(`started recording: ${this.state.startRecording} isSilent: ${isSilentRecording} isSafari Browser: ${this.props.isSafari} isFirefox Browser: ${this.props.isFirefox}`);
            //TP-740 -- Implementing the seamless continuation of Recording feature when Control tranfer happens
            if (isrecording_active === true && isSilentRecording === false && !this.props.isSafari && !this.props.isFirefox && this.state.hideButton !== 'd-none') { //TP-2262
                this.onClickRecordScreen("continue");
            } else if (isrecording_active === true && isSilentRecording === true){
                printConsole("Currently Silent recording isn't supported on Webapp Expert side");
                /* const experts = this.state.participants.filter(p => p.isexpert);
                this.sendSignalRecordingStop();
                //Send the SYNC_EXPERTS Signal to all Passive experts when silent recording is stopped
                experts && experts.forEach(e => {
                    this.sessionSignal.sendSignalSyncExperts({
                        isrecording_active: false,
                        is_silent_recording: isSilentRecording,
                        recording_folder_name: this.state.recFileName,
                        frames_per_seconds: screenSettings[2].fps,
                        frame_count: count,
                        reason: "recording_sync"},
                    e.email) && printConsole (`Sending the SYNC_EXPERTS signal to all Passive experts with recording_active = false`);
                })
                this.setState({ recFileName: '' }); */
            } else if (isrecording_active === true && isSilentRecording === false && (this.props.isSafari || this.props.isFirefox || this.state.hideButton === 'd-none')) {
                // TP-2262 -- Handling the stopping of Recording if Expert who takes up control is on Safari/Firefox browsers
                printConsole("Safari/Firefox browser doesn't support recording so stopping the previous recording");
                const experts = this.state.participants.filter(p => p.isexpert);
                this.sendSignalRecordingStop(null, from.email);
                //Send the SYNC_EXPERTS Signal to all Passive experts when silent recording is stopped
                experts && experts.forEach(e => {
                    this.sessionSignal.sendSignalSyncExperts({
                        isrecording_active: false,
                        is_silent_recording: isSilentRecording,
                        recording_folder_name: this.state.recFileName,
                        frames_per_seconds: screenSettings[2].fps,
                        frame_count: count,
                        reason: "recording_sync"},
                    e.email) && printConsole (`Sending the SYNC_EXPERTS signal to all Passive experts with recording_active = false`);
                })
                this.setState({ recFileName: '' });
            }
        }
    }

    // start recording NS2-274, MB2-717
    onClickRecordScreen = (state="start") => {
        try{
            this.setState((prevState) => ({
                startRecording: !prevState.startRecording
            }),async () =>{
                if(this.state.startRecording){
                    printConsole(`Start recording clicked: ${performance.now()}`);
                    await this.startRecordingNew(state); //TP-740
                    //this.startScreenshotRecording();
                }else{
                    printConsole(`Stop recording clicked: ${performance.now()}`); //TP-740
                    this.stopRecordingCallback();
                    //this.stopScreenshotRecordingCb();
                }
            })
        }catch(error){
            printConsole(error)
        }
    }

    //Screen recording methods
    startRecording = async () => {
        try {
            // calling the screen capture
            this.captureScreen((screen) => {
                this.screen = screen
                // calling the camera feed containing only the audio
                let audioStream = this.captureCamera();
                this.recorder = RecordRTC([screen, audioStream], {
                    type: 'video',
                    mimeType: 'video/webm',
                    video: { width: 1280, height: 720 }
                });

                this.recorder.startRecording();
                this.setState({ showRecordingBeacon: true }) // show the beacon
            });
        }catch(error){
            console.error(error);
            this.setState((prevState) => ({
                startRecording: !prevState.startRecording
            }))
        }
    }

    startScreenshotRecording = async () => {
        try {            
            const outputStream = this.captureAudioStreams();
            printConsole(outputStream);
            //this.openUploadFrames();
            printConsole(`mimeType audio webm supported? ${MediaRecorder.isTypeSupported('audio/webm')}`);
            this.recorder = new MediaRecorder(outputStream, { mimeType: "audio/webm" })
            this.chunks = []
    
            this.recorder.ondataavailable = event => {
                if (event.data.size > 0) {
                        this.chunks.push(event.data)
                }
            }
            this.recorder.start();
            this.triggerCaptureScreenShotEachScond();
                         
            this.setState({ showRecordingBeacon: true }) // show the beacon 
        } catch (error) {
            printConsole(error);
        }
    }

    /* openUploadFrames = () => {
        fileDialog({ multiple: true, accept: '.jpg, .png' })
        .then( async (files) => {
            printConsole(`start uploading frames================> time -- ${Date.now() - this.state.recFileName}`);
            printConsole(files);
            const frameArray = Object.values(files);
            if (frameArray.length > 0) {
                let i = 0;
                for (const frame of frameArray) {
                    let reader = new FileReader();
                    reader.onloadend = function() {
                        console.log("Frame no# ", i+1);
                        i++;
                        console.log("Converting single frame to base 64 format: ",Date.now() - webRtcExpObj.state.recFileName)
                        var srcData = arrayBufferToBase64(reader.result);
                        console.log("Finished converting frame to base 64 format: ",Date.now() - webRtcExpObj.state.recFileName);
                       
                        const { recFileName } = webRtcExpObj.state;
                        const { user } = webRtcExpObj.props;
                        let selectedGroupId = webRtcExpObj.props;
                        if (typeof selectedGroupId === "string") selectedGroupId = parseInt(selectedGroupId);
                        const screensPayload = {
                            groupId: selectedGroupId,
                            userId: user.id,
                            folderName: selectedGroupId+"_"+user.id+"_"+recFileName,
                            sequenceId: Date.now(),                                    
                            from: user.email,                                   
                            imgData: srcData
                        }
                        console.log("sending each frame data as socket signal payload: ",Date.now() - webRtcExpObj.state.recFileName);
                        webRtcExpObj.sessionSignal.sendSignalScreenShotImage(screensPayload);
                    }
                    reader.readAsArrayBuffer(frame);
                }
            }
        });
    } */

    triggerCaptureScreenShotEachScond = () => {
        //this.perSecondTimer = setInterval(() => {            
            this.captureScreenShots();            
            this.screenshotsTimer1 = setInterval(() => {                
                //clearTimeout(this.screenshotsTimer1);
                this.captureScreenShots();
            }, 1000); // duration of each screenshot set as 250 milli secs
            /* this.screenshotsTimer2 = setTimeout(() => {
                frameNo = 3;
                clearTimeout(this.screenshotsTimer2);
                this.captureScreenShots();
            }, 400); // duration of each screenshot set as 400 milli secs
            this.screenshotsTimer3 = setTimeout(() => {
                frameNo = 4;
                clearTimeout(this.screenshotsTimer3);
                this.captureScreenShots();
            }, 600); // duration of each screenshot set as 600 milli secs
            this.screenshotsTimer4 = setTimeout(() => {
                frameNo = 5;
                clearTimeout(this.screenshotsTimer4);
                this.captureScreenShots();
            }, 800); // duration of each screenshot set as 800 milli secs */
        //}, 1000); // duration of each thread set as 1 sec   
    }

    captureScreenShotsNew = () => {
        frameNo += 1;
        const {maxDivParticipant} = this.state;
        const screenshotTarget = document.getElementById(maxDivParticipant.stream_id); 
        const rect = screenshotTarget.getBoundingClientRect();

        

        Promise.all([
            // Cut out two sprites from the sprite sheet
            createImageBitmap(screenshotTarget, rect.x, rect.y, rect.width, rect.height),
          ]).then(function(sprites) {
            console.log("Frame no# ", frameNo);
            // Draw each sprite onto the canvas
            var canvas = document.createElement('canvas');
            let ctx = canvas.getContext('2d');
            ctx.drawImage(sprites[0], rect.x, rect.y);
            console.log("New screenshot is created: ",Date.now() - webRtcExpObj.state.recFileName);
            const { selectedGroupName, user } = webRtcExpObj.props;
            // create the watermark on the image frames
            const text = "Recording Details: "+selectedGroupName+" - "+user.firstname+" "+user.lastname+" - "+webRtcExpObj.getDateStringForWatermark();
            var tempCtx=ctx;
            tempCtx.font="15px Titillium Web";
            var textWidth=tempCtx.measureText(text).width;
            tempCtx.globalAlpha=.75;
            tempCtx.fillStyle='white'
            tempCtx.fillText(text,rect.width-textWidth-10,rect.height-20);
            tempCtx.fillStyle='black'
            tempCtx.fillText(text,rect.width-textWidth-10+2,rect.height-20+2);
            console.log("Converting canvas image to base 64 format: ",Date.now() - webRtcExpObj.state.recFileName)
            base64image = canvas.toDataURL("image/jpeg").replace('data:image/jpeg;base64,', '');
            console.log("Finished converting image to base 64 format: ",Date.now() - webRtcExpObj.state.recFileName);
            console.log(base64image);   
            console.log("Downloading the image jpg file: ",Date.now() - webRtcExpObj.state.recFileName);
                    var link = document.createElement('a');
                    link.download = `Telepresenz_${webRtcExpObj.getDateString()}.jpeg`;
                    link.href = base64image;
                    link.click();
                    console.log("Downloading image complete: ",Date.now() - webRtcExpObj.state.recFileName)
                    
                    if (frameNo >= webRtcExpObj.state.totalFrames) {
                        clearInterval(webRtcExpObj.screenshotsTimer1);
                    }
            
          });


                







        /*htmlToImage.toJpeg(document.getElementById(maxDivParticipant.stream_id), { quality: 0.95 })
            .then(function (dataUrl) {
                console.log("Frame no# ", frameNo);
                console.log("New screenshot is created: ",Date.now() - webRtcExpObj.state.recFileName);
                /* const base64image = dataUrl.replace('data:image/jpeg;base64,', '')
                //console.log(base64image);
                const { recFileName } = webRtcExpObj.state;
                const { selectedGroupId, selectedGroupName, user } = this.props;
                if (typeof selectedGroupId === "string") selectedGroupId = parseInt(selectedGroupId);
                const screensPayload = {
                    groupId: selectedGroupId,
                    userId: user.id,
                    folderName: selectedGroupId+"_"+user.id+"_"+recFileName,
                    sequenceId: Date.now(),                                    
                    from: user.email,                                   
                    imgData: base64image
                }
                console.log("sending the screen shots data as socket signal payload: ",Date.now() - webRtcExpObj.state.recFileName);
                webRtcExpObj.sessionSignal.sendSignalScreenShotImage(screensPayload);
                console.log("total number of frames --->", webRtcExpObj.state.totalFrames)
                if (frameNo >= webRtcExpObj.state.totalFrames) {
                    console.log("sending the audio as socket signal payload when recording is stopped: ",Date.now() - webRtcExpObj.state.recFileName);
                    webRtcExpObj.sessionSignal.sendSignalAudioData(webRtcExpObj.state.audioDataPayload);
                    clearInterval(webRtcExpObj.screenshotsTimer1);
                } */

                /* console.log("Downloading the image jpg file: ",Date.now() - webRtcExpObj.state.recFileName);
                var link = document.createElement('a');
                link.download = `Telepresenz_${webRtcExpObj.getDateString()}.jpeg`;
                link.href = dataUrl;
                link.click();
                console.log("Downloading image complete: ",Date.now() - webRtcExpObj.state.recFileName)
                if (frameNo >= webRtcExpObj.state.totalFrames) {
                    clearInterval(webRtcExpObj.screenshotsTimer1);
                }
            });*/
    }

    captureScreenShots = () => {
        frameNo += 1;
        let screenshotTargetParent;
        if (this.state.maxView === true) {
            const {maxDivParticipant} = this.state;
            screenshotTargetParent = document.getElementById(maxDivParticipant.stream_id);
            if (screenshotTargetParent === null) return;
            let screenshotTarget = screenshotTargetParent;
            if (document.getElementById('recording_frame_'+maxDivParticipant.stream_id) !== undefined && document.getElementById('recording_frame_'+maxDivParticipant.stream_id) !== null) {
                //screenshotTarget[0].parentNode.removeChild(document.getElementsByTagName('canvas')[0]);
                const old_canvas = document.getElementById('recording_frame_'+maxDivParticipant.stream_id);
                old_canvas.remove();                
            }

            const rect = screenshotTarget.getBoundingClientRect();
            const sideBar = document.getElementById('sideBar');
            const videoContainer = document.getElementById('allVideos');
            const videoContainerPadding = window.getComputedStyle(videoContainer).getPropertyValue('padding-left')

            //console.log(rect);
            var canvas = document.createElement('canvas');
            canvas.id = 'recording_frame_'+maxDivParticipant.stream_id;
            canvas.width = rect.width;
            canvas.height = rect.height;
            canvas.style.position = 'absolute';
            canvas.style.left = (rect.x - sideBar.offsetWidth - parseInt(videoContainerPadding) ) + 'px'; // document.getElementById('meet').className === "col-sm-12 text-center" ? : '0px'
            canvas.style.top =  '0px'; //rect.y + 'px'
            var ctx = canvas.getContext('2d');
            //draw image to canvas. scale to target dimensions
            ctx.drawImage(screenshotTarget, 0, 0, canvas.width, canvas.height);
            // insert canvas after video element
            if (screenshotTarget.length < 1)
                screenshotTargetParent.appendChild(canvas);
            else 
                screenshotTarget.parentNode.insertBefore(canvas, screenshotTarget.nextSibling);
        }
        else {
            const {participants} = this.state;
            participants && participants.forEach( p => {
                screenshotTargetParent = document.getElementById(p.stream_id);
                if (screenshotTargetParent === null) return;
                let screenshotTarget = screenshotTargetParent
                if (document.getElementById('recording_frame_'+p.stream_id) !== undefined && document.getElementById('recording_frame_'+p.stream_id) !== null) {
                    //screenshotTarget[0].parentNode.removeChild(document.getElementsByTagName('canvas')[0]);
                    const old_canvas = document.getElementById('recording_frame_'+p.stream_id);
                    old_canvas.remove();                
                }
    
        
                const rect = screenshotTarget.getBoundingClientRect();
                const sideBar = document.getElementById('sideBar');
                const videoContainer = document.getElementById('allVideos');
                const videoContainerPadding = window.getComputedStyle(videoContainer).getPropertyValue('padding-left')
        
                console.log(rect);
                var canvas = document.createElement('canvas');
                canvas.id = 'recording_frame_'+p.stream_id;
                canvas.width = rect.width;
                canvas.height = rect.height;
                canvas.style.position = 'absolute';
                canvas.style.left = (rect.x - sideBar.offsetWidth - parseInt(videoContainerPadding) ) + 'px'; // document.getElementById('meet').className === "col-sm-12 text-center" ? : '0px'
                canvas.style.top =  rect.y+ 'px';
                var ctx = canvas.getContext('2d');
                //draw image to canvas. scale to target dimensions
                ctx.drawImage(screenshotTarget, 0, 0, canvas.width, canvas.height);
                // insert canvas after video element
                if (screenshotTarget.length < 1)
                    screenshotTargetParent.appendChild(canvas);
                else 
                    screenshotTarget.parentNode.insertBefore(canvas, screenshotTarget.nextSibling);
            })
        }
        

        html2canvas(document.body).then((canvas) => {
            console.log("New screenshot is created: ",Date.now() - this.state.recFileName);
            const { selectedGroupName, user } = this.props;
            let {selectedGroupId} = this.props;
            //console.log(user);

            // create the watermark on the image frames
            const text = "Recording Details: "+selectedGroupName+" - "+user.firstname+" "+user.lastname+" - "+this.getDateStringForWatermark();
            var tempCtx=canvas.getContext('2d');
            tempCtx.font="15px Titillium Web";
            var textWidth=tempCtx.measureText(text).width;
            tempCtx.globalAlpha=.75;
            tempCtx.fillStyle='white'
            tempCtx.fillText(text,canvas.width-textWidth-10,canvas.height-50);
            tempCtx.fillStyle='black'
            tempCtx.fillText(text,canvas.width-textWidth-10+2,canvas.height-50+2);
            
            //convert to desired file format
            /* var dataURI = canvas.toDataURL('image/jpeg'); 
            console.log(dataURI)
            console.log("Downloading the image jpg file: ",Date.now() - webRtcExpObj.state.recFileName);
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            a.href = dataURI;
            a.download = `Telepresenz_${this.getDateString()}.jpeg`;
            a.click();
            window.URL.revokeObjectURL(dataURI); */
            
            console.log("Converting canvas image to base 64 format: ",Date.now() - this.state.recFileName)
            base64image = canvas.toDataURL("image/jpeg").replace('data:image/jpeg;base64,', '');
            console.log("Finished converting image to base 64 format: ",Date.now() - this.state.recFileName);
            //console.log(base64image);   
            //window.location.href = base64image; return;            
            const { recFileName } = this.state;
            if (typeof selectedGroupId === "string") selectedGroupId = parseInt(selectedGroupId);
            const screensPayload = {
                groupId: selectedGroupId,
                userId: user.id,
                folderName: selectedGroupId+"_"+user.id+"_"+recFileName,
                sequenceId: Date.now(),                                    
                from: user.email,                                   
                imgData: base64image
            }
            console.log("sending the screen shots data as socket signal payload: ",Date.now() - this.state.recFileName);
            this.sessionSignal.sendSignalScreenShotImage(screensPayload);
            console.log("current frameNo:  ", frameNo);
            console.log("total number of frames --->", webRtcExpObj.state.totalFrames)
                if (frameNo >= this.state.totalFrames) {
                    console.log("sending the audio as socket signal payload when recording is stopped: ",Date.now() - webRtcExpObj.state.recFileName);
                    this.sessionSignal.sendSignalAudioData(this.state.audioDataPayload);
                    clearInterval(this.screenshotsTimer1);
                    this.setState({ audioDataPayload: null })
                }
            //if () {
                //if (frameNo%2 === 0) frameNo = 0;
                /* console.log(this.state.startRecording);
                console.log(this.state.audioDataPayload);
                console.log(this.state.totalFrames);
                if (frameNo > 0 && frameNo === this.state.totalFrames && this.state.startRecording === false && this.state.audioDataPayload !== null) {
                    console.log("sending the audio as socket signal payload when all frames are sent to server: ",Date.now() - webRtcExpObj.state.recFileName);
                    this.sessionSignal.sendSignalAudioData(this.state.audioDataPayload);
                    frameNo = 0;
                    this.setState({ audioDataPayload: null })
                } */
            //}
            // removing all the individual canvas created for getting the recording image frames 
            const {participants} = this.state;
            participants && participants.forEach( p => {
                if (document.getElementById('recording_frame_'+p.stream_id) !== undefined && document.getElementById('recording_frame_'+p.stream_id) !== null) {
                    //screenshotTarget[0].parentNode.removeChild(document.getElementsByTagName('canvas')[0]);
                    const old_canvas = document.getElementById('recording_frame_'+p.stream_id);
                    old_canvas.remove();                
                }
            })
        });    
    }

    addTracksWhenScreenshareStops = () => {
        try {

            let { outputStream } = this.state;
            const audioContext = new AudioContext();                
            let source3 = audioContext.createMediaStreamSource(this.state.localStream);
            //connect sources to destination 
            const destination = audioContext.createMediaStreamDestination();
            source3 && source3.connect(destination);
            outputStream.addTrack(destination.stream.getAudioTracks()[0]);
            //outputStream.addTrack(this.state.localStream.getAudioTracks()[0]);
            console.log(outputStream.getTracks());
            this.setState({ outputStream }, () => {
                //this.recorder(this.state.outputStream);
            });
            
        } catch (error) {
            printConsole(error);
        }
    }

    swapStreamsWhileChangeParticipant = () => {
        try {
            if (this.recorder) {
                // getting the new array of audio/video feeds
                const outputStream = this.captureStreams();
                this.recorder.onstop = event => {
                    this.newChunkTimer = setTimeout(() => {
                        printConsole("stopping Video recording if participants change: ",Date.now() - this.state.recFileName);
                        console.log(this.chunks);
                        console.log("Converting webm to mp4 file: ",Date.now() - this.state.recFileName)
                        const blob = new Blob(this.chunks, {
                            type: 'video/mp4;codecs=vp9'
                        })
                        console.log("Finished converting webm to mp4 file: ",Date.now() - this.state.recFileName);
                        this.chunks = []
        
                        let reader = new FileReader();
                        console.log("reading the mp4 file as arrayBuffer: ",Date.now() - this.state.recFileName)
                        reader.readAsArrayBuffer(blob); // converts the blob to base64 and calls onload
        
                        //console.log(this.props.user);
                        reader.onload = function() {
                            console.log("Starting base64 encryption of mp4 file: ",Date.now() - webRtcExpObj.state.recFileName)
                            var srcData = arrayBufferToBase64(reader.result);
                            console.log("ending base64 encryption of mp4 file: ",Date.now() - webRtcExpObj.state.recFileName)
                            //console.log(srcData);
                            const { selectedGroupName, user } = webRtcExpObj.props;
                            let { selectedGroupId } = webRtcExpObj.props;
                            const { recFileName } = webRtcExpObj.state;
                            if (typeof selectedGroupId === "string") selectedGroupId = parseInt(selectedGroupId);
                            const recordingPayload = {
                                group_id: selectedGroupId,
                                user_id: user.id,
                                folder_name: recFileName,
                                sequence_id: Date.now(),
                                stop_recording: false,
                                from: user.email,
                                group_name: selectedGroupName.replace(/ /g,"_"),
                                video_data: srcData,
                                user_name: user.firstname.replace(/ /g,"_")+"_"+user.lastname.replace(/ /g,"_")
                            }
                            console.log("sending the data as socket signal payload: ",Date.now() - webRtcExpObj.state.recFileName);
                            webRtcExpObj.sessionSignal.sendSignalRecordingData(recordingPayload); 
                        };                        
                    }, 1000);
                }
                this.recorder.stop();                    
                this.recorder = new MediaRecorder(outputStream)
                this.recorder.onstart = event => {
                    console.log("Started a new recording after screen share stopped");
                }
                this.recorder.start();
            }
            
        } catch (error) {
            printConsole (error);
        }
    }

    onRecorderStopEachClip = (stopClipNo) => {
        printConsole(`New chunk of audio recording created: ${performance.now()}`);
        //console.log(this.chunks);
        printConsole(`Converting audio webm to m4a file: ${performance.now()}`);
        const blob = new Blob(this.chunks, {
            type: 'audio/m4a'
        })
        printConsole(`Finished converting audio webm to m4a file: ${performance.now()}`);
        this.chunks = [];
    
        printConsole(`Stopping Audio Clip # ${stopClipNo}`);
        let reader = new FileReader();
        printConsole(`reading the mp4 file as arrayBuffer: ${performance.now()}`)
        reader.readAsArrayBuffer(blob); // converts the blob to base64 and calls onload
    
        reader.onload = function() {            
            var srcData = reader.result;
            // use byteLength to check the size
            const bytes = srcData.byteLength;
            printConsole(`byte size of Audio file: ${bytes}`);

            printConsole(`sending the audio clip data No: ${audioNo} as socket signal payload: ${performance.now()}`);
            // Sending the audio clips as Socket Signal payload to the Server-side
            webRtcExpObj.sendSignalRecordingUploadAudio(srcData, webRtcExpObj.callBackForAudioRecording); 
        };
    }

    onRecorderStopInterval = (stopClipNo) => {
        //clipNo += 1
        printConsole(`New chunk of audio recording created: ${performance.now()}`);
        //console.log(this.chunks);
        printConsole(`Converting audio webm to m4a file: ${performance.now()}`);
        const blob = new Blob(this.chunks, {
            type: 'audio/m4a'
        })
        printConsole(`Finished converting audio webm to m4a file: ${performance.now()}`);
        this.chunks = [];
        this.recorder[stopClipNo] = null;
    
        printConsole(`Stopping Audio Clip # ${stopClipNo}`);
        let reader = new FileReader();
        printConsole(`reading the mp4 file as arrayBuffer: ${performance.now()}`)
        reader.readAsArrayBuffer(blob); // converts the blob to base64 and calls onload
    
        reader.onload = function() {
            var srcData = reader.result;
            // use byteLength to check the size
            const bytes = srcData.byteLength;
            printConsole(`byte size of Audio file: ${bytes}`);

            printConsole(`sending the audio clip data No. ${audioNo} as socket signal payload: ${performance.now()}`);
            // Sending the audio clips as Socket Signal payload to the Server-side
            webRtcExpObj.sendSignalRecordingUploadAudio(srcData, webRtcExpObj.callBackForAudioRecording);  
        };


        /* printConsole(`Creating the object URL: ${performance.now()`);
        const blobUrl = URL.createObjectURL(blob);
                            
        printConsole(blobUrl)
        printConsole(`Downloading the m4a clip: ${performance.now()`);
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = blobUrl;
        a.download = `Telepresenz_Audio_${this.getDateString()}.m4a`;
        a.click();
        window.URL.revokeObjectURL(blobUrl); */
        
    }

    onRecorderDataAvailable = (event) => {
        printConsole(`data available for Audio Clip # ${clipNo}`);
        if (event.data.size > 0) {
            this.chunks.push(event.data)
        }
    }

    addTelepresenzWatermark = (canvas) => {
        const { selectedGroupName, user } = this.props;
        // create the watermark on the image frames
        const text = "Recording Details: "+selectedGroupName+" - "+user.firstname+" "+user.lastname+" - "+this.getDateStringForWatermark();
        var tempCtx=canvas.getContext('2d');
        tempCtx.font=screenSettings[2].waterMarkFont+" Titillium Web";
        var textWidth=tempCtx.measureText(text).width;
        tempCtx.globalAlpha=.75;
        tempCtx.fillStyle='white'
        tempCtx.fillText(text,canvas.width-textWidth-60,canvas.height-60);
        tempCtx.fillStyle='black'
        tempCtx.fillText(text,canvas.width-textWidth-60+2,canvas.height-60+2);
    }

    // TP-740 -- new start recording
    startRecordingNew = async (state) => {
        try {
            // calling the screen capture
            printConsole(`Start screen capture ${performance.now()}`)
            this.captureScreen((screen) => {
                const {participants} = this.state;
                const experts = participants.filter(p => p.isexpert);
                ackFrameNo = 0;
                ackClipNo = 0;
                errAckFrameNo = 0; //TP-1966
                errAudioAckNo = 0;
                framesPreviousState = 'ok'; //TP-2215
                audioPreviousState = 'ok'; //TP-2215
                this.screen = screen;
                printConsole(`User permission for screen capture obtained ${performance.now()}`);
                this.setState({recFileName : (isrecording_active && state === "continue" && this.state.recFileName !== '' ) ? this.state.recFileName : Date.now()}, () => {
                    clipNo = 0;
                    audioNo = 0;
                    // TP-1974
                    experts && experts.forEach(e => {
                        this.sessionSignal.sendSignalSyncExperts({
                            isrecording_active: true,
                            is_silent_recording: false,
                            recording_folder_name: this.state.recFileName,
                            frames_per_seconds: screenSettings[2].fps,
                            frame_count: count,
                            reason: "recording_sync"},
                        e.email) && printConsole (`Sending the SYNC_EXPERTS signal to all Passive experts with recording_active = true`);
                    })
                    // TP-1877
                    if (state === "start") {
                        this.sendSignalRecordingStart(this.callBackForStartRecording); // Start the recording at this point
                    }
                    try{
                        let imageCapture = [];
                        //video.srcObject = screen;
                        this.frameThread = setInterval(() => {
                            this.frameTimer = setTimeout(() => {                                
                                frameNo += 1;
                                printConsole(`trigger new frame thread ${performance.now()}`);
                                const canvas = document.createElement("canvas");
                                canvas.id = "fake";
                                canvas.style.display = "none";
                                document.body.appendChild(canvas);
                                // get correct video track
                                const track = this.screen.getVideoTracks()[0]
                                printConsole(`frame grab of screen track started: ${performance.now()}`);
                                    // init Image Capture and not Video stream
                                    imageCapture[frameNo] = new ImageCapture(track);
                                    // take a single frame only
                                    imageCapture[frameNo].grabFrame()
                                        .then(function(imageBitmap){
                                            printConsole(`current frame ----> ${frameNo}`);
                                            printConsole(`frame grab of screen track done with new Bitmap generated ${performance.now()}`);
                                            //console.log(imageBitmap);
                                            
                                            const canvas = document.getElementById('fake') 
                                            if (canvas !== null) {
                                                // this could be a document.createElement('canvas') if you want
                                                // draw weird image type to canvas so we can get a useful image
                                                printConsole(`Draw canvas with the Screen grab ${performance.now()}`)
                                                canvas.width = imageBitmap.width
                                                canvas.height = imageBitmap.height
                                                const context = canvas.getContext('2d')
                                                context.drawImage(imageBitmap, 0, 0, imageBitmap.width, imageBitmap.height)
                                                
                                                webRtcExpObj.addTelepresenzWatermark(canvas); // Add the watermark for Telepresenz 
                                                
                                                printConsole(`Convert canvas to base64 image format ${performance.now()}`)
                                                const frameRaw = canvas.toDataURL('image/jpeg', 0.5);
                                                const frame = frameRaw.replace('data:image/jpeg;base64,', '');
                                                canvas.parentNode.removeChild(canvas);
                                                printConsole(`Canvas changed to base64 image format done: ${performance.now()}`)
                                                if (turn_off_frames_signal === false) {
                                                    printConsole(`sending the image frame as socket signal payload: ${performance.now()}`);
                                                    // Sending the frame as Socket Signal payload to the Server-side
                                                    webRtcExpObj.sendSignalRecordingUploadImages(frame, webRtcExpObj.callBackForFramesSent);
                                                } else {
                                                    printConsole("Pausing the frame creation and sending")
                                                }
        
                                                /* if (frameNo >= 30) {
                                                    printConsole(`Downloading the image jpg file: ${performance.now()}`);
                                                    var a = document.createElement("a");
                                                    document.body.appendChild(a);
                                                    a.style = "display: none";
                                                    a.href = frameRaw;
                                                    a.download = `Telepresenz_${webRtcExpObj.getDateString()}.jpeg`;
                                                    a.click();
                                                    window.URL.revokeObjectURL(frame);
                                                } */
                                            } else {
                                                frameNo -= 1;
                                            }
            
                                        })
                                        .catch(error => {
                                            console.log("the frame grab promise returned an error ",error);
                                        })
                                
                                clearTimeout(this.frameTimer);
                            }, 1)
                            //clearInterval(webRtcExpObj.frameThread);
                        }, screenSettings[2].timer);
                    } catch(err) {
                        console.log("Error: " + err);
                    }

                    // Setting the looped Audio Recording feature for intervals of 15 secs each
                    this.recorder = [];
                    this.chunks = [];
                    this.triggerRecordingInterval();
                    this.setState({ showRecordingBeacon: true }) // show the beacon

                    /* this.recStartInterval = setInterval(() => {
                        clipNo += 1;
                        this.triggerRecordingInterval();                            
                    }, 14970); */ // start a new recorder for Audio at the 14th sec mark       
                    
                    this.recStopInterval = setInterval(() => {
                        this.recStopTimer = setTimeout(() => {
                            printConsole(`Trigger Stop Recorder for current recording ${performance.now()}`);                        
                            printConsole(`now the ClipNo: ${clipNo}`);
                            this.recorder[clipNo].onstart = event => {
                                audioNo += 1;
                                printConsole(`Current audio file no: ${audioNo}`);
                            }
                            this.recorder[clipNo].ondataavailable = event => {
                                this.onRecorderDataAvailable(event);
                            }
                            this.recorder[clipNo].onstop = event => {
                                this.recorder[clipNo].start();
                                this.chunkTimer = setTimeout(() => {
                                    this.onRecorderStopEachClip(clipNo);
                                    clearTimeout(this.chunkTimer);                           
                                }, 1);                            
                            }
                            this.recorder[clipNo] && this.recorder[clipNo].state !== "inactive" && this.recorder[clipNo].stop();
                            clearTimeout(this.recStopTimer);
                        }, 1);
                    }, 15000) //stop the audio then start it again at the 15th sec mark         
                                        
                });
            });
        }catch(error){
            console.error(error);
            this.setState((prevState) => ({
                startRecording: !prevState.startRecording
            }))
        }
    }

    //TP-1881
    stopAndContinueRecording = () => {
        printConsole(`Start a new audio recording and stop the old recording ${performance.now()}`);
        clipNo += 1;
        this.triggerRecordingInterval();
                        
        printConsole(`###Trigger Stop Recorder for previous recording ${performance.now()}`);                        
        printConsole(`now the ClipNo: ${clipNo}`);
        this.recorder[clipNo-1].ondataavailable = event => {
            this.onRecorderDataAvailable(event);
        }
        this.recorder[clipNo-1].onstop = event => {
            //this.recorder.start();
            this.chunkTimer = setTimeout(() => {
                this.onRecorderStopInterval(clipNo-1);
                clearTimeout(this.chunkTimer);                           
            }, 1);                            
        }
        this.recorder[clipNo-1] && this.recorder[clipNo-1].state !== "inactive" && this.recorder[clipNo-1].stop();
    }

    triggerRecordingInterval = () => {
        printConsole(`Trigger new Audio recorder for 15 seconds ${performance.now()}`);
        printConsole(`current clipNo: ${clipNo}`);
        const outputStream = this.captureStreams();
        printConsole(outputStream);
        printConsole(`mimeType audio webm supported? ${MediaRecorder.isTypeSupported('audio/webm')}`);
        this.recorder[clipNo] = new MediaRecorder(outputStream)
        //this.chunks = [];
        this.recorder[clipNo].onstart = event => {
            audioNo += 1;
            printConsole(`Current audio file no: ${audioNo}`);
        }
        this.recorder[clipNo].start();
    }

    captureAudioStreams = () => {
        try {
            const {participants} = this.state;
            let isExpertAudioMuted = false;
            participants.forEach(p => {
                if (p.disableMike) isExpertAudioMuted = true;
            })

            const audioContext = new AudioContext();
            const destination = audioContext.createMediaStreamDestination();
            printConsole(isExpertAudioMuted);
            if (isExpertAudioMuted === false) {
                let source1;                
                source1 = audioContext.createMediaStreamSource(this.state.localStream);
                //connect sources to destination 
                source1 && source1.connect(destination);

            }
                //cameraStream.getAudioTracks().forEach(track => track.enabled = true);
            const outputStream= new MediaStream();
                
            participants.forEach(p => {
                console.log(p.streams);
                let source2;
                if(p.streams && p.streams.length > 0){
                    source2 = audioContext.createMediaStreamSource(p.streams[0]);
                }   
                source2 && source2.connect(destination);
            });
            console.log(destination.stream.getAudioTracks());
            outputStream.addTrack(destination.stream.getAudioTracks()[0]);
            return outputStream;
        } catch (error) {
            printConsole(error);
        }
    }

    captureStreams = () => {
        try {    
            
                const {participants} = this.state;
                let isExpertAudioMutedCounter = 0;
                participants.forEach(p => {
                    if (p.disableMike) isExpertAudioMutedCounter += 1;
                })
                const audioContext = new AudioContext();                
                const destination = audioContext.createMediaStreamDestination();
                printConsole(`Current Expert's Muted Mic count: ${isExpertAudioMutedCounter}`);
                printConsole(`total no of participants in session: ${participants.length}`);
                if (isExpertAudioMutedCounter < participants.length) { //TP-1918
                    let source1;
                    source1 = audioContext.createMediaStreamSource(this.state.localStream);
                    //connect sources to destination 
                    source1 && source1.connect(destination);
                }
                //cameraStream.getAudioTracks().forEach(track => track.enabled = true);
                const outputStream= new MediaStream();
                
                participants && participants.forEach( p => {
                    printConsole(p.streams);
                    let source2;
                    if(p.streams && p.streams.length > 0){    
                        //TP-4129 -- Reverting this below piece of code
                        /* for (let i=0; i<=p.streams; i++ ) {
                            console.log("stream --->", p.streams[i]," audio tracks ....", p.streams[i].getAudioTracks());
                            if(p.streams[i].getAudioTracks().length > 0) {
                                source2 = audioContext.createMediaStreamSource(p.streams[i]);
                                break;
                            }
                        }  */  
                        //TP-4161 -- reverted to the old code for generating Audio streams
                        //TP-4129
                        try {
                            source2 = audioContext.createMediaStreamSource(p.streams[0]); 
                        } catch (error) {
                            printConsole(error);
                        }
                    }   
                    source2 && source2.connect(destination);
                });
                //mediaTrackArr.push(cameraStream.getAudioTracks()[0]);
                //mediaTrackArr.push(this.screen.getTracks()[0]);
                //console.log(mediaTrackArr);
                //outputStream = new MediaStream(mediaTrackArr);
                printConsole(destination.stream.getAudioTracks());
                outputStream.addTrack(destination.stream.getAudioTracks()[0]);
                //outputStream.addTrack(this.screen.getTracks()[0]);
                return outputStream;
        } catch(error) {
            printConsole(error);
        }
    }
    
    invokeGetDisplayMedia(success, error) {
        try{
            let displaymediastreamconstraints = {
                //TP-740 -- set the frame rate as 5 per second
                video: screenSettings[2].constraints,
                preferCurrentTab: true
            };
            if (navigator.mediaDevices.getDisplayMedia) {
                navigator.mediaDevices.getDisplayMedia(displaymediastreamconstraints).then(success)
                .catch(error => {
                    // TP-1974
                    printConsole(`the recording permission has been denied ${error}`);
                    // change the recording button icon
                    this.setState((prevState) => ({
                        startRecording: !prevState.startRecording
                    }))
                    const experts = this.state.participants.filter(p => p.isexpert);
                    //Send the SYNC_EXPERTS Signal to all Passive experts when recording is stopped
                    experts && experts.forEach(e => {
                        this.sessionSignal.sendSignalSyncExperts({
                            isrecording_active: false,
                            is_silent_recording: false,
                            recording_folder_name: this.state.recFileName,
                            frames_per_seconds: screenSettings[2].fps,
                            frame_count: (count) ? count : 0,
                            reason: "recording_sync"},
                        e.email) && printConsole (`Sending the SYNC_EXPERTS signal to all Passive experts with recording_active = false`);
                    })
                    if (isrecording_active === true) {
                        this.sendSignalRecordingStop();
                        isrecording_active = false;
                        this.setState({ recFileName: ''});
                    }
                });
            } else {
                navigator.getDisplayMedia(displaymediastreamconstraints).then(success)
                .catch(error => {
                    // TP-1974
                    printConsole(`the recording permission has been denied ${error}`);
                    this.setState((prevState) => ({
                        startRecording: !prevState.startRecording
                    }))
                    const experts = this.state.participants.filter(p => p.isexpert);
                    //Send the SYNC_EXPERTS Signal to all Passive experts when recording is stopped
                    experts && experts.forEach(e => {
                        this.sessionSignal.sendSignalSyncExperts({
                            isrecording_active: false,
                            is_silent_recording: false,
                            recording_folder_name: this.state.recFileName,
                            frames_per_seconds: screenSettings[2].fps,
                            frame_count: (count) ? count : 0,
                            reason: "recording_sync"},
                        e.email) && printConsole (`Sending the SYNC_EXPERTS signal to all Passive experts with recording_active = false`);
                    })
                    if (isrecording_active === true) {
                        this.sendSignalRecordingStop();
                        isrecording_active = false;
                        this.setState({ recFileName: '' });
                    }
                });
            }
        }catch(error){
            printConsole(error)
        }
    }

    captureCamera = () => {
        try {
            let source1, source2;
            // merge audio from remote stream and micStream
            const audioContext = new AudioContext();
            if(this.state.maxDivParticipant.streams && this.state.maxDivParticipant.streams.length > 0){
                source1 = audioContext.createMediaStreamSource(this.state.maxDivParticipant.streams[0]);
            }
            source2 = audioContext.createMediaStreamSource(this.state.localStream);
            //connect sources to destination 
            const destination = audioContext.createMediaStreamDestination();
            source1.connect(destination);
            source2.connect(destination);

            const outputStream= new MediaStream();
            outputStream.addTrack(destination.stream.getAudioTracks()[0]);
            return outputStream;
        }catch(error){
            printConsole(error)
        }
    }

    captureScreen = (callback) => {
        try {
            this.invokeGetDisplayMedia((screen) => {
                // when screen capture is directly stopped by clicking on the popup then this execute  
                if (isSilentRecording === false){
                    this.addScreenShareStopListener(screen, this.stopRecordingCallback);
                    callback(screen);
                }
            }, (error) => {
                console.error(error);
                this.setState((prevState) => ({
                    startRecording: !prevState.startRecording
                }))
            });
        }catch(error){
            printConsole(error)
        }
    }

    callBackForAudioRecording = (response) => {
        printConsole("************Received the acknowledgement for each audio clip socket signal************")
        printConsole(response); // ok
        if (response) {
            ackClipNo += 1;
            audioPreviousState = response.status;
            printConsole(`is recording ongoing: ${this.state.startRecording}`)
            if (response.status === "error" && isSilentRecording === false && !this.props.isSafari && !this.props.isFirefox && this.state.hideButton !== 'd-none') {//TP-2262
                printConsole(" Audio Clip sent has thrown error ");                

                errAudioAckNo += 1;
                // TP-2215 -- Log Diagnostics for error acknowledgement during upload each frame for recording
                if (errAudioAckNo === 1 && this.state.user.log_level >= 3) {
                    diagnostics(this.authService,{
                        action: `recordingError`,
                        sequence: 3,
                        next_step: '',
                        data: `{ folder_name: ${response.folder_name}, group_id: ${response.group_id}, message: ${response.message}, sequence_id: ${response.sequence_id}, status: ${response.status}, type_of_audio: ${response.type_of_audio}, user_id: ${response.user_id} }`,
                        error: 'none'
                    })
                }

                this.stopRecordingCallback();
                this.triggerMessage("","errorRecording");
                //TP-4013
                if (restartRecordNo <= 2) {
                    restartRecordNo += 1;
                    this.onClickRecordScreen() // Start the recording again
                }
                else {
                    restartRecordNo = 0;
                    this.triggerMessage("","failedRecording"); //Show the message and don't restart again
                }
            } else if (isSilentRecording === false){
                printConsole(` Audio clip sent successfully to server side for clip NO: ${ackClipNo}`);
            }
        }
    }

    callBackForFinalAudioRecording = (response) => {
        printConsole("************Received the acknowledgement for final audio clip socket signal************")
        printConsole(response); // ok
        if (response) {
            ackClipNo += 1;
            audioPreviousState = response.status;
            printConsole(`is startRecording: ${this.state.startRecording}`)
            if (response.status === "error" && isSilentRecording === false && !this.props.isSafari && !this.props.isFirefox && this.state.hideButton !== 'd-none') { //TP-2262
                
                errAudioAckNo += 1;
                // TP-2215 -- Log Diagnostics for error acknowledgement during upload each frame for recording
                if (errAudioAckNo === 1 && this.state.user.log_level >= 3) {
                    diagnostics(this.authService,{
                        action: `recordingError`,
                        sequence: 3,
                        next_step: '',
                        data: `{ folder_name: ${response.folder_name}, group_id: ${response.group_id}, message: ${response.message}, sequence_id: ${response.sequence_id}, status: ${response.status}, type_of_audio: ${response.type_of_audio}, user_id: ${response.user_id} }`,
                        error: 'none'
                    })
                }

                this.stopRecordingCallback();
                this.triggerMessage("","errorRecording");
                //TP-4013
                if (restartRecordNo <= 2) {
                    restartRecordNo += 1;
                    this.onClickRecordScreen() // Start the recording again
                }
                else {
                    restartRecordNo = 0;
                    this.triggerMessage("","failedRecording"); //Show the message and don't restart again
                }
            } else if (response.status === "ok" && isSilentRecording === false) {
                // Send the stop Recording signal only after the final Audio signal is successfully uploaded
                if (this.state.startRecording === false /* && ackFrameNo === frameNo */) {
                    frameNo = 0;
                    printConsole(this.state.stopState);
                    if (this.state.stopState === "stop"){
                        this.sendSignalRecordingStop();
                        this.setState({ recFileName: '', stopState: '' });
                    }
                    /* else if (this.state.stopState === "pause") {
                        const passive_user_id = parseInt(this.state.expertUserId);
                        const passive_user_name = this.state.expertFirstName.replace(/ /g,"_")+"_"+this.state.expertLastName.replace(/ /g,"_");
                        this.sendSignalRecordingPause(passive_user_id, passive_user_name, this.callBackForPauseRecording);
                        this.setState({ stopState: '' });
                    } */
                }
            }
        }
    }

    callBackForFramesSent = (response) => {
        printConsole("************Received the acknowledgement for each frame's socket signal************")
        printConsole(response); // ok
        if (response) {
            ackFrameNo += 1;
            framesPreviousState = response.status;
            printConsole(`acknowledgement received for frame # ${ackFrameNo}`);
            if (response.status === "error" && isSilentRecording === false && !this.props.isSafari && !this.props.isFirefox && this.state.hideButton !== 'd-none') {//TP-2262
                // TP-1966 -- Implementation of triggering stop recording if at least one error acknowledgement
                // is received from the server-side 
                errAckFrameNo += 1;

                // TP-2215 -- Log Diagnostics for error acknowledgement during upload each frame for recording
                if (errAckFrameNo === 1 && this.state.user.log_level >= 3) {
                    diagnostics(this.authService,{
                        action: `recordingError`,
                        sequence: 3,
                        next_step: '',
                        data: `{ folder_name: ${response.folder_name}, group_id: ${response.group_id}, message: ${response.message}, sequence_id: ${response.sequence_id}, status: ${response.status}, type_of_image: ${response.type_of_image}, user_id: ${response.user_id} }`,
                        error: 'none'
                    })
                }
                //console.log(errAckFrameNo);
                if (errAckFrameNo === 1) {
                    //console.log("stop the recording callback");
                    this.stopRecordingCallback();
                    // Start the error acknowledgement timer to check if multiple error acknowledgements are received
                    // from server-side or only "one"
                    printConsole(" trigger the error acknowledgement counter ");
                    this.setState({
                        framesErrorTimeout : 2
                    },
                    () => {
                        this.ackTimer = setInterval(this.decrementAckCounter.bind(this), 1000)
                    });
                } else if (errAckFrameNo > 1) {
                    printConsole("close the wait timer for multiple acknowledgements");
                    this.closeAckCounter();
                }
            }
            /* printConsole("current frame no ",frameNo);
            printConsole("is startRecording: ",this.state.startRecording)
            if (this.state.startRecording === false && ackFrameNo === frameNo) {
                frameNo = 0;
                printConsole(this.state.stopState);
                if (this.state.stopState === "stop"){
                    this.sendSignalRecordingStop();
                    this.setState({ recFileName: '', stopState: '' });
                }
                else if (this.state.stopState === "pause") {
                    const passive_user_id = parseInt(this.state.expertUserId);
                    const passive_user_name = this.state.expertFirstName.replace(/ /g,"_")+"_"+this.state.expertLastName.replace(/ /g,"_");
                    this.sendSignalRecordingPause(passive_user_id, passive_user_name, this.callBackForPauseRecording);
                    this.setState({ stopState: '' });
                }
            } */            
        }
    }

    callBackForStartRecording = (response) => {
        printConsole("************Received the acknowledgement for Start Recording socket signal************")
        printConsole(response);
    }

    callBackForPauseRecording = (response) => {
        printConsole("************Received the acknowledgement for Pause Recording socket signal************")
        printConsole(response);
    }

    //TP-1966 -- Acknowledgement error handling timer
    decrementAckCounter = () => {
        this.setState((prevState) =>({
            framesErrorTimeout: prevState.framesErrorTimeout - 1
        }),() => {
            if(this.state.framesErrorTimeout === 0 && errAckFrameNo === 1){
                printConsole(" timer ended and error acknowledgement count is still 1 ");
                this.triggerNewRecording();
            }
        });
    }

    closeAckCounter = () => {
        printConsole("Close the rcording signal acknowledgement timer")
        this.setState({
            framesErrorTimeout : 0
        },() => {
            clearInterval(this.ackTimer);
        })
    }

    triggerNewRecording = () => {
        clearInterval(this.ackTimer);
        this.triggerMessage("","errorRecording");
        this.newRecordingInterval = setInterval(() => {
            //TP-4013
            if (restartRecordNo <= 2) {
                restartRecordNo += 1;
                this.onClickRecordScreen() // Start the recording again
            }
            else {
                restartRecordNo = 0;
                this.triggerMessage("","failedRecording"); //Show the message and don't restart again
            }
            //this.onClickRecordScreen() // Start the recording again
            clearInterval(this.newRecordingInterval);
        }, 1000);
    }

    //TP-2041-- Pause handler for features that are causing audio-video sync issues on recording
    handlePauseRecordingDuringAnyFeature = () => {
        if (count !== 0){
            const experts = this.state.participants.filter(p => p.isexpert);
            //Send the SYNC_EXPERTS Signal to all Passive experts when recording is stopped
            experts && experts.forEach(e => {
                this.sessionSignal.sendSignalSyncExperts({
                    isrecording_active: true,
                    is_silent_recording: false,
                    recording_folder_name: this.state.recFileName,
                    frames_per_seconds: screenSettings[2].fps,
                    frame_count: count,
                    reason: "recording_sync"},
                e.email) && printConsole (`Sending the SYNC_EXPERTS signal to all Passive experts with recording_active = false`);
            })
        }
        printConsole(`Pause the ongoing recording audio ${performance.now()}`);
        if(this.recorder[clipNo] && this.recorder[clipNo].state === "recording") {
            this.recorder[clipNo].pause(); //recording paused
        }
        //this.onRecorderStopEachClip(clipNo);
        printConsole(`Stop the frames and audio recorder timers ${performance.now()}`);
        clearInterval(this.recStopInterval);
        // Turn off the frames sent to server
        turn_off_frames_signal = true;
        //frameNo = 0;
        //clearInterval(this.frameThread);
        const canvasArr = document.querySelectorAll('[id=fake]');
        if (canvasArr !== undefined && canvasArr.length > 0) {
            canvasArr.forEach(canvas => {
                canvas.parentNode.removeChild(canvas);
            })
        }
        printConsole(`Paused Audio Clip #  ${clipNo}`);  
    }

    //TP-2041-- Resume handler for features that are causing audio-video sync issues on recording
    handleResumeRecordingAfterPause = () => {
        printConsole(`Resume the frame capture after the Pause ${performance.now()}`);
        // Turn on the frames sent to server
        turn_off_frames_signal = false;
        printConsole(`Current Audio Recorder count #${clipNo}`)
        printConsole(`Resume the audio recorder after the Pause ${performance.now()}`);
        if(this.recorder[clipNo] && this.recorder[clipNo].state === "paused") {
            this.recorder[clipNo].resume();
        }
        //this.recorder[clipNo].start();
        this.recStopInterval = setInterval(() => {
            this.recStopTimer = setTimeout(() => {
                printConsole(`Trigger Stop Recorder for current recording ${performance.now()}`);                        
                printConsole(`now the ClipNo: ${clipNo}`);
                this.recorder[clipNo].onstart = event => {
                    audioNo += 1;
                    printConsole(`Current audio file no: ${audioNo}`);
                }
                this.recorder[clipNo].ondataavailable = event => {
                    this.onRecorderDataAvailable(event);
                }
                this.recorder[clipNo].onstop = event => {
                    this.recorder[clipNo].start();
                    this.chunkTimer = setTimeout(() => {
                        this.onRecorderStopEachClip(clipNo);
                        clearTimeout(this.chunkTimer);                           
                    }, 1);                            
                }
                this.recorder[clipNo] && this.recorder[clipNo].state !== "inactive" && this.recorder[clipNo].stop();
                clearTimeout(this.recStopTimer);
            }, 1);
        }, 15000) //stop the audio then start it again at the 15th sec mark 
    }

    // recorder is stopped
    stopRecordingCallback = async (status="stop") => {
        try {
            printConsole("the recorder object ?");
            printConsole(this.recorder[clipNo]);
            printConsole(`current recorder status >>>>>>> `);
            printConsole(status);
            if (typeof status === "object") 
                status = "stop";
            if (this.recorder[clipNo] && this.recorder[clipNo] !== null) {
                // TP-740
                this.recorder[clipNo].ondataavailable = event => {
                    this.onRecorderDataAvailable(event);
                }
                this.recorder[clipNo].onstop = () => {
                    //clipNo += 1;
                    printConsole(`close the recording file. Last chunk of audio file creating: ${Math.ceil((Date.now() - this.state.recFileName) / 1000)} seconds`);
                    //clearInterval(this.recStartInterval);
                    clearInterval(this.recStopInterval);
                    //clearInterval(this.recTimeInterval);
                    clearInterval(this.frameThread);
                    this.recorder[clipNo] = null;
    
                    [this.screen].forEach(function (stream) {
                        stream.getTracks().forEach(function (track) {
                            track.stop();
                        });
                    });
                    const canvasArr = document.querySelectorAll('[id=fake]');
                    if (canvasArr !== undefined && canvasArr.length > 0) {
                        canvasArr.forEach(canvas => {
                            canvas.parentNode.removeChild(canvas);
                        })
                    }
                    if (status !== "end") {
                        this.setState({ stopState: status }, () => {
                            //frameNo = 0;
                            printConsole(`Total number of frames = ${Math.ceil((Date.now() - this.state.recFileName) / screenSettings[2].timer)}`);
                            printConsole(`Frames sent to the server side ${count}`);
                            
                            // if instead of clicking on the icon user clicks the popup the state will
                            // change from here
                            if (this.state.startRecording) {
                                isrecording_active = false; 
                                isSilentRecording = false;
                                // Turn on the frames sent to server
                                turn_off_frames_signal = false;
                                this.setState({ startRecording: false })
                            } else {
                                isrecording_active = false;
                                isSilentRecording = false;
                                // Turn on the frames sent to server
                                turn_off_frames_signal = false;
                            }
                            this.setState({ showRecordingBeacon: false })// hide the beacon

                            const experts = this.state.participants.filter(p => p.isexpert);
                            if (this.state.stopState === "stop") {
                                //Send the SYNC_EXPERTS Signal to all Passive experts when recording is stopped
                                experts && experts.forEach(e => {
                                    this.sessionSignal.sendSignalSyncExperts({
                                        isrecording_active: false,
                                        is_silent_recording: false,
                                        recording_folder_name: this.state.recFileName,
                                        frames_per_seconds: screenSettings[2].fps,
                                        frame_count: count,
                                        reason: "recording_sync"},
                                    e.email) && printConsole (`Sending the SYNC_EXPERTS signal to all Passive experts with recording_active = false`);
                                })
                            } else if (this.state.stopState === "pause") {
                                if (count !== 0){
                                    //Send the SYNC_EXPERTS Signal to all Passive experts when recording is stopped
                                    experts && experts.forEach(e => {
                                        this.sessionSignal.sendSignalSyncExperts({
                                            isrecording_active: true,
                                            is_silent_recording: false,
                                            recording_folder_name: this.state.recFileName,
                                            frames_per_seconds: screenSettings[2].fps,
                                            frame_count: count,
                                            reason: "recording_sync"},
                                        e.email) && printConsole (`Sending the SYNC_EXPERTS signal to all Passive experts with recording_active = false`);
                                    })
                                }

                                const passive_user_id = parseInt(this.state.expertUserId);
                                const passive_user_name = this.state.expertFirstName.replace(/ /g,"_")+"_"+this.state.expertLastName.replace(/ /g,"_");
                                this.sendSignalRecordingPause(passive_user_id, passive_user_name, this.callBackForPauseRecording);
                                this.setState({ stopState: '' });
                            }
                            count = 0;
                            printConsole(`Final Audio Clip # ${clipNo}`);
                            //console.log(this.chunks);
                            printConsole(`Converting audio blob to m4a file: ${performance.now()}`)
                            const blob = new Blob(this.chunks, {
                                    type: 'audio/m4a'
                            })
                            printConsole(`Finished converting blob to m4a file: ${performance.now()}`);
        
                            let reader = new FileReader();
                            printConsole(`reading the mp4 file as arrayBuffer: ${performance.now()}`)
                            reader.readAsArrayBuffer(blob); // converts the blob to base64 and calls onload
        
                            reader.onload = function() {
                                /* console.log("Starting base64 encryption of mp4 file: ",Date.now() - otExpObj.state.recFileName)
                                var srcData = arrayBufferToBase64(reader.result);
                                console.log("ending base64 encryption of mp4 file: ",Date.now() - otExpObj.state.recFileName) */
                                var srcData = reader.result;
                                //const {participants} = webRtcExpObj.state;
                                //const experts = participants.filter(p => p.isexpert);
                                // use byteLength to check the size
                                const bytes = srcData.byteLength;
                                printConsole(`byte size of Audio file: ${bytes}`);
                                
                                printConsole(`sending the audio clip data No. ${audioNo} as socket signal payload: ${performance.now()}`);
                                // Sending the audio clips as Socket Signal payload to the Server-side
                                webRtcExpObj.sendSignalRecordingUploadAudio(srcData, webRtcExpObj.callBackForFinalAudioRecording);
                                if (status === "stop"){
                                    //webRtcExpObj.sendSignalRecordingStop();                                    
                                    //webRtcExpObj.setState({ recFileName: '' });
                                }
                                else if (status === "pause") {
                                    /* const passive_user_id = parseInt(webRtcExpObj.state.expertUserId);
                                    const passive_user_name = webRtcExpObj.state.expertFirstName.replace(/ /g,"_")+"_"+otExpObj.state.expertLastName.replace(/ /g,"_");
                                    webRtcExpObj.sendSignalRecordingPause(passive_user_id, passive_user_name, otExpObj.callBackForPauseRecording); */
                                }
                                clipNo = 0;
                                audioNo = 0;
                            };
        
                            /* console.log("Creating the object URL: ",performance.now());
                            const blobUrl = URL.createObjectURL(blob)
                        
                            console.log(blobUrl)
                            console.log("Downloading the final m4a audio clip: ",performance.now());
                            var a = document.createElement("a");
                            document.body.appendChild(a);
                            a.style = "display: none";
                            a.href = blobUrl;
                            a.download = `Telepresenz_audio_${this.getDateString()}.m4a`;
                            a.click();
                            window.URL.revokeObjectURL(blobUrl); */                        
                        });
                    } else {
                        printConsole(`Total number of frames = ${Math.ceil((Date.now() - this.state.recFileName) / screenSettings[2].timer)}`);
                        printConsole(`Frames sent to the server side ${count}`);

                        // if instead of clicking on the icon user clicks the popup the state will
                        // change from here
                        if (this.state.startRecording) {
                                isrecording_active = false; 
                                isSilentRecording = false;
                                // Turn on the frames sent to server
                                turn_off_frames_signal = false;
                                clearInterval(this.recStopInterval);
                                clearInterval(this.frameThread);
                                this.recorder[clipNo] = null;
                        } else {
                                isrecording_active = false;
                                isSilentRecording = false;
                                // Turn on the frames sent to server
                                turn_off_frames_signal = false;
                        }
                        this.setState({ showRecordingBeacon: false })// hide the beacon
                        count = 0;
                        printConsole(`Final Audio Clip # ${clipNo}`);                        
                        printConsole(`Converting audio blob to m4a file: ${performance.now()}`)
                        const blob = new Blob(this.chunks, {
                            type: 'audio/m4a'
                        })
                        printConsole(`Finished converting blob to m4a file: ${performance.now()}`);
        
                        let reader = new FileReader();
                        printConsole(`reading the mp4 file as arrayBuffer: ${performance.now()}`)
                        reader.readAsArrayBuffer(blob); // converts the blob to base64 and calls onload
        
                        reader.onload = function() {
                            var srcData = reader.result;
                            // use byteLength to check the size
                            const bytes = srcData.byteLength;
                            printConsole(`byte size of Audio file: ${bytes}`);
                                
                            printConsole(`sending the audio clip data No. ${audioNo} as socket signal payload: ${performance.now()}`);
                            // Sending the audio clips as Socket Signal payload to the Server-side
                            webRtcExpObj.sendSignalRecordingUploadAudio(srcData, webRtcExpObj.callBackForFinalAudioRecording);
                            /* if(typeof(Storage) !== "undefined"){
                                printConsole("Clearing the session storage");
                                ['chunk_data', 'folder_name', 'user_id', 'group_id', 'sequence_id'].forEach(key =>  sessionStorage.removeItem(key));
                            }else{
                                printConsole(`Sorry, your browser does not support Web Storage...`)
                            } */
                                
                            clipNo = 0;
                            audioNo = 0;

                            webRtcExpObj.notifyEndCallGroup(); //TP-2209
                        };
                    }
                }
                if (this.recorder[clipNo] && this.recorder[clipNo].state !== "inactive" )
                    this.recorder[clipNo].stop();
                else if (this.recorder[clipNo] && this.recorder[clipNo].state === "inactive") {
                    printConsole(`Stopping the image frames being sent if the audio recording is already stopped ${Math.ceil((Date.now() - this.state.recFileName) / 1000)} seconds`)
                    //printConsole(`close the recording file. Last chunk of audio file creating: ${Math.ceil((Date.now() - this.state.recFileName) / 1000)} seconds`);
                    //clearInterval(this.recStartInterval);
                    clearInterval(this.recStopInterval);
                    //clearInterval(this.recTimeInterval);
                    clearInterval(this.frameThread);
                    this.recorder[clipNo] = null;
    
                    [this.screen].forEach(function (stream) {
                        stream.getTracks().forEach(function (track) {
                            track.stop();
                        });
                    });
                    const canvasArr = document.querySelectorAll('[id=fake]');
                    if (canvasArr !== undefined && canvasArr.length > 0) {
                        canvasArr.forEach(canvas => {
                            canvas.parentNode.removeChild(canvas);
                        })
                    }

                    printConsole(`Total number of frames = ${Math.ceil((Date.now() - this.state.recFileName) / screenSettings[2].timer)}`);
                    printConsole(`Frames sent to the server side ${count}`);
        
                    // if instead of clicking on the icon user clicks the popup the state will
                    // change from here
                    if (this.state.startRecording) {
                        isrecording_active = false; 
                        isSilentRecording = false;
                        // Turn on the frames sent to server
                        turn_off_frames_signal = false;
                        this.setState({ startRecording: false })
                    } else {
                        isrecording_active = false;
                        isSilentRecording = false;
                        // Turn on the frames sent to server
                        turn_off_frames_signal = false;
                    }
                    this.setState({ showRecordingBeacon: false })// hide the beacon

                    const experts = this.state.participants.filter(p => p.isexpert);
                    /* if (this.state.stopState === "stop") {
                        //Send the SYNC_EXPERTS Signal to all Passive experts when recording is stopped
                        experts && experts.forEach(e => {
                            this.sessionSignal.sendSignalSyncExperts({
                                isrecording_active: false,
                                is_silent_recording: false,
                                recording_folder_name: this.state.recFileName,
                                frames_per_seconds: screenSettings[2].fps,
                                frame_count: count,
                                reason: "recording_sync"},
                            e.email) && printConsole (`Sending the SYNC_EXPERTS signal to all Passive experts with recording_active = false`);
                        })
                    } else */ if (status === "pause") {
                        if (count !== 0){
                            //Send the SYNC_EXPERTS Signal to all Passive experts when recording is stopped
                            experts && experts.forEach(e => {
                                this.sessionSignal.sendSignalSyncExperts({
                                    isrecording_active: true,
                                    is_silent_recording: false,
                                    recording_folder_name: this.state.recFileName,
                                    frames_per_seconds: screenSettings[2].fps,
                                    frame_count: count,
                                    reason: "recording_sync"},
                                e.email) && printConsole (`Sending the SYNC_EXPERTS signal to all Passive experts with recording_active = false`);
                            })
                        }                        
                        const passive_user_id = parseInt(this.state.expertUserId);
                        const passive_user_name = this.state.expertFirstName.replace(/ /g,"_")+"_"+this.state.expertLastName.replace(/ /g,"_");
                        this.sendSignalRecordingPause(passive_user_id, passive_user_name, this.callBackForPauseRecording);
                        this.setState({ stopState: '' });
                    }
                    
                    count = 0;
                    if (this.chunks) {
                        printConsole(`Final Audio Clip # ${clipNo}`);
                        //console.log(this.chunks);
                        printConsole(`Converting audio blob to m4a file: ${performance.now()}`)
                        const blob = new Blob(this.chunks, {
                                        type: 'audio/m4a'
                        })
                        printConsole(`Finished converting blob to m4a file: ${performance.now()}`);
        
                        let reader = new FileReader();
                        printConsole(`reading the mp4 file as arrayBuffer: ${performance.now()}`)
                        reader.readAsArrayBuffer(blob); // converts the blob to base64 and calls onload
        
                        reader.onload = function() {
                                /* console.log("Starting base64 encryption of mp4 file: ",Date.now() - otExpObj.state.recFileName)
                                var srcData = arrayBufferToBase64(reader.result);
                                console.log("ending base64 encryption of mp4 file: ",Date.now() - otExpObj.state.recFileName) */
                                var srcData = reader.result;
                                //const {participants} = webRtcExpObj.state;
                                //const experts = participants.filter(p => p.isexpert);
                                // use byteLength to check the size
                                const bytes = srcData.byteLength;
                                printConsole(`byte size of Audio file: ${bytes}`);
                                
                                printConsole(`sending the audio clip data No. ${audioNo} as socket signal payload: ${performance.now()}`);
                                // Sending the audio clips as Socket Signal payload to the Server-side
                                webRtcExpObj.sendSignalRecordingUploadAudio(srcData, webRtcExpObj.callBackForFinalAudioRecording);
                                
                        }
                        
                    }
                }
                
            } else if ((this.recorder[clipNo] === null || this.recorder[clipNo] === '' ) && status === "pause") {
                //printConsole(`Stopping the image frames being sent if the audio recording is already stopped ${Math.ceil((Date.now() - this.state.recFileName) / 1000)} seconds`)
                printConsole(`Pausing the recording due to control transfer and audio is not there: ${Math.ceil((Date.now() - this.state.recFileName) / 1000)} seconds`);
                //clearInterval(this.recStartInterval);
                clearInterval(this.recStopInterval);
                //clearInterval(this.recTimeInterval);
                clearInterval(this.frameThread);
                this.recorder[clipNo] = null;
    
                [this.screen].forEach(function (stream) {
                    stream.getTracks().forEach(function (track) {
                            track.stop();
                    });
                });
                const canvasArr = document.querySelectorAll('[id=fake]');
                if (canvasArr !== undefined && canvasArr.length > 0) {
                    canvasArr.forEach(canvas => {
                            canvas.parentNode.removeChild(canvas);
                    })
                }

                printConsole(`Total number of frames = ${Math.ceil((Date.now() - this.state.recFileName) / screenSettings[2].timer)}`);
                printConsole(`Frames sent to the server side ${count}`);

                // if instead of clicking on the icon user clicks the popup the state will
                // change from here
                if (this.state.startRecording) {
                    isrecording_active = false; 
                    isSilentRecording = false;
                    // Turn on the frames sent to server
                    turn_off_frames_signal = false;
                    this.setState({ startRecording: false })
                } else {
                    isrecording_active = false;
                    isSilentRecording = false;
                    // Turn on the frames sent to server
                    turn_off_frames_signal = false;
                }
                this.setState({ showRecordingBeacon: false })// hide the beacon
                if (count !== 0) {
                    const experts = this.state.participants.filter(p => p.isexpert);
                    //Send the SYNC_EXPERTS Signal to all Passive experts when recording is stopped
                    experts && experts.forEach(e => {
                        this.sessionSignal.sendSignalSyncExperts({
                            isrecording_active: true,
                            is_silent_recording: false,
                            recording_folder_name: this.state.recFileName,
                            frames_per_seconds: screenSettings[2].fps,
                            frame_count: count,
                            reason: "recording_sync"},
                        e.email) && printConsole (`Sending the SYNC_EXPERTS signal to all Passive experts with recording_active = false`);
                    })
                }
                count = 0;
                const passive_user_id = parseInt(this.state.expertUserId);
                const passive_user_name = this.state.expertFirstName.replace(/ /g,"_")+"_"+this.state.expertLastName.replace(/ /g,"_");
                this.sendSignalRecordingPause(passive_user_id, passive_user_name, this.callBackForPauseRecording);
                this.setState({ stopState: '' });
            }
        }catch(error){
            this.setState((prevState) => ({
                startRecording: !prevState.startRecording
            }))
        }
    }

    stopScreenshotRecordingCb = () => {
        try {
            if (this.recorder) {
                this.recorder.onstop = () => {
                    printConsole(`close the recording file. Last chunk of Video creating: ${Math.ceil((Date.now() - this.state.recFileName) / 1000)} seconds`);
                    this.setState({ totalFrames: Math.ceil((Date.now() - this.state.recFileName) / 1000)}, () => {
                        console.log("Total number of frames = ", this.state.totalFrames);                        
                        // if instead of clicking on the icon user clicks the popup the state will change from here
                        if (this.state.startRecording) {
                            this.setState({ startRecording: false })
                        } else {
                            isrecording_active = false;
                            isSilentRecording = false;
                            // Turn on the frames sent to server
                            turn_off_frames_signal = false;
                        }
                        this.setState({ showRecordingBeacon: false })// hide the beacon
                        console.log(this.chunks);
                        console.log("Converting audio blob to m4a file: ",Date.now() - this.state.recFileName)
                        const blob = new Blob(this.chunks, {
                            type: 'audio/m4a'
                        })
                        console.log("Finished converting blob to m4a file: ",Date.now() - this.state.recFileName);
                        this.chunks = []
    
                        let reader = new FileReader();
                        console.log("reading the m4a file as arrayBuffer: ",Date.now() - this.state.recFileName)
                        reader.readAsArrayBuffer(blob); // converts the blob to base64 and calls onload
    
                        reader.onload = function() {
                            //console.log("Starting base64 encryption of mp3 audio file: ",Date.now() - webRtcExpObj.state.recFileName)
                            //var srcData = arrayBufferToBase64(reader.result);
                            var srcData = reader.result;
                            //console.log("ending base64 encryption of mp3 audio file: ",Date.now() - webRtcExpObj.state.recFileName)
                            //console.log(srcData);
                            const { selectedGroupName, user } = webRtcExpObj.props;
                            let { selectedGroupId } = webRtcExpObj.props;
                            const { recFileName } = webRtcExpObj.state;
                            if (typeof selectedGroupId === "string") selectedGroupId = parseInt(selectedGroupId);
                            const audioDataPayload = {
                                groupId: selectedGroupId,
                                userId: user.id,
                                folderName: selectedGroupId+"_"+user.id+"_"+recFileName,
                                fps: 1,
                                from: user.email,
                                groupName: selectedGroupName.replace(/ /g,"_"),
                                audioData: srcData,
                                audioType: "m4a",
                                userName: user.firstname.replace(/ /g,"_")+"_"+user.lastname.replace(/ /g,"_")
                            }
                            console.log("frameNo --->", frameNo);
                            console.log("set the audio data payload in state");
                            webRtcExpObj.setState({ audioDataPayload });
                            //if (frameNo === webRtcExpObj.state.totalFrames/* frameNo%2 === 0 */) {
                                //console.log("sending the audio as socket signal payload when recording is stopped: ",Date.now() - webRtcExpObj.state.recFileName);
                                //webRtcExpObj.sessionSignal.sendSignalAudioData(audioDataPayload);
                                //clearInterval(this.perSecondTimer);
                                if (frameNo >= webRtcExpObj.state.totalFrames)
                                    clearInterval(webRtcExpObj.screenshotsTimer1);
                            /* } else {
                                
                            } */
                        };
    
                        /* console.log("Creating the object URL: ",Date.now() - webRtcExpObj.state.recFileName);
                        const blobUrl = URL.createObjectURL(blob)
                    
                        console.log(blobUrl)
                        console.log("Downloading the mp4 clip: ",Date.now() - webRtcExpObj.state.recFileName);
                        var a = document.createElement("a");
                        document.body.appendChild(a);
                        a.style = "display: none";
                        a.href = blobUrl;
                        a.download = `Telepresenz_${this.getDateString()}.m4a`;
                        a.click();
                        window.URL.revokeObjectURL(blobUrl); */
                        this.recorder = null;                    
                    })
                    
                }
                this.recorder.stop();
            }
            
        } catch (error) {
            this.setState((prevState) => ({
                startRecording: !prevState.startRecording
            }))
        }
    }    

    /** Methods to handle emit Socket signals for recording feature*/
    sendSignalRecordingStart = (callback=null) => {
        try {
            const { user, sessionInfo } = this.props;
            let {selectedGroupId, selectedGroupName} = this.props;
            const { recFileName } = this.state;
            if (typeof selectedGroupId === "string") selectedGroupId = parseInt(selectedGroupId);
            const json = {
                session_id: (sessionInfo !== null) ? sessionInfo.sessionID : this.state.groupInfo.sessionID,
                group_id: selectedGroupId,
                user_id: user.id,
                fps: screenSettings[2].fps,
                images_per_second: screenSettings[2].fps,
                type_of_image: "jpeg",
                type_of_audio: "m4a",
                folder_name: recFileName,
                group_name: selectedGroupName.replace(/ /g,"_"),
                user_name: user.firstname.replace(/ /g,"_")+"_"+user.lastname.replace(/ /g,"_"),
                is_silent: false
            }
            this.sessionSignal.sendSignalRecordingStart(json, callback);
        } catch (error) {
            console.log("error in sendSignalRecordingStart method ", error)
        }
    }

    sendSignalRecordingUploadImages = (image_data, callback=null) => {
        try {
            let { selectedGroupId, user } = this.props;
            const { recFileName } = this.state;
            if (typeof selectedGroupId === "string") selectedGroupId = parseInt(selectedGroupId);
            count += 1;
            //if (count >= 50) selectedGroupId = 0; user.id = 190;
            printConsole(`Frame count for sequence_id: ${count}`);
            const json = {
                folder_name: recFileName,
                sequence_id: recFileName.toString()+count.toString(), // "folder_name+count" // change this later to timestamp changed to secs
                group_id: selectedGroupId,
                user_id: user.id,
                type_of_image: "jpeg",
                image_data,
                previous_status: framesPreviousState                
            }
            this.sessionSignal.sendSignalRecordingUploadImages(json, callback);
        } catch (error) {
            console.log("error in sendSignalRecordingUploadImages method ", error)
        }
    }

    sendSignalRecordingUploadAudio = (audio_data, callback=null) => {
        try {
            let { selectedGroupId, user } = this.props;
            const { recFileName } = this.state;
            if (typeof selectedGroupId === "string") selectedGroupId = parseInt(selectedGroupId);
            //if (audioNo >= 2) selectedGroupId = 0; user.id = 190;
            const json = {
                folder_name: recFileName,
                sequence_id: Date.now(), // change this later to timestamp changed to secs
                group_id: selectedGroupId,
                user_id: user.id,
                type_of_audio: "m4a",
                audio_data,
                previous_status: audioPreviousState
            }
            this.sessionSignal.sendSignalRecordingUploadAudio(json, callback);
        } catch (error) {
            console.log("error in sendSignalRecordingUploadAudio method ", error)
        }
    }

    sendSignalRecordingStop = (callback=null, user_email='') => {
        try {
            const { user, sessionInfo } = this.props;
            let {selectedGroupId, selectedGroupName} = this.props;
            const { recFileName } = this.state;
            if (typeof selectedGroupId === "string") selectedGroupId = parseInt(selectedGroupId);
            let json;
            if (user_email !== '') {
                json = {
                    session_id: (sessionInfo !== null) ? sessionInfo.sessionID : this.state.groupInfo.sessionID,
                    folder_name: recFileName,
                    group_name: selectedGroupName.replace(/ /g,"_"),
                    group_id: selectedGroupId,
                    user_id: user.id,
                    type_of_image: "jpeg",
                    type_of_audio: "m4a",
                    fps: screenSettings[2].fps,
                    user_email,
                    user_name: user.firstname.replace(/ /g,"_")+"_"+user.lastname.replace(/ /g,"_")
                }
            } else {
                json = {
                    session_id: (sessionInfo !== null) ? sessionInfo.sessionID : this.state.groupInfo.sessionID,
                    folder_name: recFileName,
                    group_name: selectedGroupName.replace(/ /g,"_"),
                    group_id: selectedGroupId,
                    user_id: user.id,
                    type_of_image: "jpeg",
                    type_of_audio: "m4a",
                    fps: screenSettings[2].fps,
                    user_name: user.firstname.replace(/ /g,"_")+"_"+user.lastname.replace(/ /g,"_")
                }
            }
            this.sessionSignal.sendSignalRecordingStop(json, callback);
        } catch (error) {
            console.log("error in sendSignalRecordingStop method ", error)
        }
    }

    sendSignalRecordingPause = (passive_user_id, passive_user_name, callback=null) => {
        try {
            const { user, sessionInfo } = this.props;
            let {selectedGroupId} = this.props;
            const { recFileName } = this.state;
            if (typeof selectedGroupId === "string") selectedGroupId = parseInt(selectedGroupId);
            const json = {
                session_id: (sessionInfo !== null) ? sessionInfo.sessionID : this.state.groupInfo.sessionID,
                group_id: selectedGroupId,
                user_id: user.id,
                folder_name: recFileName,
                passive_user_id,
                passive_user_name                 
            }
            this.sessionSignal.sendSignalRecordingPause(json, callback);
        } catch (error) {
            console.log("error in sendSignalRecordingPause method ", error)
        }
    }

    sendSignalRecordingContinue = (previous_user_id, callback=null) => {
        try {
            const { user, sessionInfo } = this.props;
            let { selectedGroupId } = this.props;
            const { recFileName } = this.state;
            if (typeof selectedGroupId === "string") selectedGroupId = parseInt(selectedGroupId);
            const json = {
                session_id: (sessionInfo !== null) ? sessionInfo.sessionID : this.state.groupInfo.sessionID,
                group_id: selectedGroupId,
                user_id: user.id,
                folder_name: recFileName,
                previous_user_id,
                user_name: user.firstname.replace(/ /g,"_")+"_"+user.lastname.replace(/ /g,"_")                
            }
            this.sessionSignal.sendSignalRecordingContinue(json, callback);
        } catch (error) {
            console.log("error in sendSignalRecordingContinue method ", error)
        }
    }

    getDeviceClassname = () => {
        var ua = navigator.userAgent.toLowerCase();
        var isiPad = /ipad/i.test(ua) || /iphone/i.test(ua) || /iphone /i.test(ua);
        var isAndroid = /android/.test(ua);
        //printConsole(isiPad);
        let hideButton = "d-blk";
        if (isiPad || isAndroid) {
            hideButton = "d-none";
        } 
        return hideButton;
    }

    renderSwitch(mode, selectedUser) {
        switch(mode) {
            case 'callUser':
                return <Modal size="sm" isOpen={true} className="call-notification-toast">
                    <ModalHeader className="text-center">
                        { this.state.userCallResponse ? <Trans>Call failed</Trans> : <Trans id="Calling {name}" values={{'name': `${selectedUser.first_name} ${selectedUser.last_name}`}}></Trans> }
                    </ModalHeader>
                    <ModalBody className="d-flex justify-content-start align-items-center">
                    {this.state.userCallResponse ? '' : <Spinner className="align-self-center mr-2" type="grow" size="sm" />}
                    {
                        this.state.userCallResponse ?
                        <Trans id={this.state.userCallResponse} values={{recipient: `${selectedUser.first_name} ${selectedUser.last_name}`}}></Trans>:
                        <>
                            <Trans id="Calling {name}" values={{'name': `${selectedUser.first_name} ${selectedUser.last_name}`}}></Trans>
                            {
                                /* !this.state.isIOS ? */
                                    <audio autoPlay="true" loop="true" src="/incoming_call.mp3"/>
                                /* : '' */
                            }
                        </>
                    }
                    </ModalBody>
                    <ModalFooter>
                        <div className="d-flex justify-content-end">
                            <Button size="sm" onClick={this.cancelCall}><Trans>Cancel</Trans></Button>
                        </div>
                    </ModalFooter>
                </Modal>
            default:
                return '';
        }
    }
    //TP-5551
    processSignalUserSpeakerOn = ({data, from}) => {
        const [participant] = this.state.participants.filter(p => p.email === from.email /* && p.type === "screen" */);
        const index = this.state.participants.findIndex(p => p.email === from.email);
        //Handled the user is speaking or not signal for camera not enabled expert session
        if (participant) {
            let participants = [...this.state.participants];
            participant.isSpeaking = data;
            participants[index] = participant;
            this.setState({participants}, () =>{
                this.props.setParticipant({participantList: participants});// to set the changes in the participant list
            });
            let {maxDivParticipant} = this.state;
            if (maxDivParticipant && maxDivParticipant.email === from.email) {
                maxDivParticipant.isSpeaking = data;
                this.setState({ maxDivParticipant });
            }
        }
        //Handled the user is speaking or not signal for camera enabled expert session
        let updatedParticipants = [...this.state.updatedParticipants];
        if (updatedParticipants && updatedParticipants.length > 0) {
            const [up] = updatedParticipants.filter(u => u.email === from.email);
            if (up) {
                const upindex = updatedParticipants.findIndex(u => u.email === from.email);
                up.isSpeaking = data;
                updatedParticipants[upindex] = up;
                this.setState({updatedParticipants});
            }
        }
    }

    //TP-3797 TP-3790 TP-2578 process screen share signal 
    processSignalSSPilot = ({from, type}) => {
        printConsole("Start/Stop Expert SS Pilot")
        const {expertShareScreenMaxDiv, user, localStream} = this.state
        if(!expertShareScreenMaxDiv && type === 'START_EXPERT_SS'){
            const [participant] = this.state.expertVideoArr.filter(p => p.email === from.email && p.type === "screen");
            printConsole(participant);
            if (participant){
                this.setState({ expertShareScreenMaxDiv: true /*ssFrom: from.email, hideLocalVdoClass: 'd-none', */}, () => {
                            this.props.setMaxDivSS({ expertShareScreenMaxDiv: this.state.expertShareScreenMaxDiv });
                            if (this.state.showHideDivIconAudioOnly === true) this.setState({ showHideDivIconAudioOnly: false, hideExpertSSAll: false })
                            // T32-463 -- The Passive expert side handling of the UI switch
                            // for START_EXPERT_SS Signal
                            if (this.state.maxView)
                                this.setState({ prevMaxDivPartcicipantEmail: this.state.maxDivParticipant.email});
                            else
                                this.setState({ prevMaxDivPartcicipantEmail: ''})
                            this.setState({maxDivParticipantEmail: participant.email})//MB2-608
                            this.localVideoRef.current.srcObject = participant.stream;
                            this.localVideoRef.current.id = participant.stream.id;
                            //this.setState({ setTechMaxStreamid: participant.stream.id });
                            //this.onClickMaximise({participant, isSame: false});
                            this.calVdoHeightWidth("16:9");
                });
                this.props.checkAnnotation();//check annotation on/off in webrtc session
                if (participant.streams) {
                    participant.streams && participant.streams.length > 0 && participant.streams.forEach((stream) => {
                        stream.getVideoTracks().forEach(track => {
                            if(!expertShareScreenMaxDiv && type === 'START_EXPERT_SS'){
                                track.enabled = true;
                            }/* else{
                                track.enabled = false;
                            } */
                        });
                    })
                }
            }
        }else{
                    /* this.setState({ expertShareScreenMaxDiv: false, maxUserName: user.firstname + " " + user.lastname, /* ssFrom: '', hideLocalVdoClass: '', showRc: (this.state.maxView === true) ? true : false}, ()=> {
                        this.props.setMaxDivSS({ expertShareScreenMaxDiv: this.state.expertShareScreenMaxDiv });
                    });
                    this.localVideoRef.current.srcObject = localStream;
                    this.localVideoRef.current.id = localStream.id;
                    this.setState({ setTechMaxStreamid: localStream.id }) */
                    this.setState({ expertShareScreenMaxDiv: false, ssFrom: '' }, () => {
                        this.props.setMaxDivSS({ expertShareScreenMaxDiv: this.state.expertShareScreenMaxDiv });
                    });
                    if (this.state.showHideDivIconAudioOnly === true) this.setState({ showHideDivIconAudioOnly: false, hideExpertSSAll: false })
                    if (this.props.bAnnotate === true) {
                        this.props.setMaxAnnotate(false);
                    }
                    const technicians = this.state.participants.filter(p => !p.isexpert);
                    printConsole(`previous Max-view participant ${this.state.prevMaxDivPartcicipantEmail}`);
                    // TP-1605 -- use case #4 -- 
                    if (this.state.prevMaxDivPartcicipantEmail !== '') {
                        const [maxDivParticipant] = this.state.expertVideoArr.filter(p => p.email === this.state.prevMaxDivPartcicipantEmail && p.type !== "screen")
                        if (maxDivParticipant) {
                            this.setState({maxDivParticipantEmail: this.state.prevMaxDivPartcicipantEmail})//MB2-608
                            if (maxDivParticipant.isexpert === true) {
                                this.pilotSSTimer = setTimeout(() => {
                                    this.onClickMaximise({participant: maxDivParticipant, isSame: false});
                                    this.calVdoHeightWidth("16:9");
                                    clearTimeout(this.pilotSSTimer);
                                }, 2000);
                            } else {
                                this.onClickMaximise({participant: maxDivParticipant, isSame: false});
                                this.calVdoHeightWidth("16:9");
                            }
                        } else if(technicians.length > 1) {
                            this.onClickMultiGridView();
                        } else if(technicians.length === 1) {
                            const maxDivParticipant = this.state.participants.filter(p => p.isexpert === false) // Only technician in that session
                            //this.setState({maxDivParticipantEmail: this.state.prevMaxDivPartcicipantEmail})//MB2-608
                            this.onClickMaximise({participant: maxDivParticipant, isSame: false});
                            this.calVdoHeightWidth("16:9");
                        } else {
                            const [maxDivParticipant] = this.state.participants.filter(p => p.isexpert === true) // Only experts in that session
                            this.onClickMaximizeExpert({participant: maxDivParticipant});
                        }
                    } else if (this.state.prevMaxDivPartcicipantEmail === '' && technicians.length > 1) {
                        this.onClickMultiGridView();
                    }
        }
    }
    /**TP-3573 */
    getUpdatedParticipantswithPreview = () => {
        let {localStream} = this.state;
        let {user} = this.state;
        if (this.state.participants.length > 0) {
            let [me] = this.state.participants.filter(p => p.email === user.email);
            if (me) return;
            //else if (this.checkIfExpertCameraEnabled() === false)
            else {
                console.log("Update participant list by adding my preview stream");
                let updatedParticipants = this.state.participants;
                let [myParticipant] = this.state.updatedParticipants.filter(p => p.email === user.email);
                let myVideoState = false;
                if (myParticipant) {
                    myVideoState = myParticipant.disableVideo;
                }
                this.setState({updatedParticipants}, () => {
                    //console.log("user >>>", user.email, "   ", user.myPicture);
                    printConsole(`preview streamid ${localStream.id}`);
                    //TP-3506 -- handle picture = "" for delete picture flow
                    if (user.myPicture && user.myPicture !== null && user.myPicture !== "" && user.myPicture.includes("base64") === false && user.myPicture.includes("profile.png") === false)
                        user.myPicture = 'data:image/jpg;base64,'+ user.myPicture;
                    else if (user.myPicture && user.myPicture !== null && user.myPicture !== "" && user.myPicture.includes("base64") === true)
                        user.myPicture = user.myPicture;
                    else 
                        user.myPicture = "/profile.png";
                    //if (this.state.headerMuteMike === true) {
                        localStream.getAudioTracks().forEach(track => track.enabled = !this.state.headerMuteMike);
                        //TP-5442
                        if (localStream.getVideoTracks().length > 0) {
                            localStream.getVideoTracks().forEach(track => {
                                track.addEventListener(
                                    "ended",
                                    (event) => {
                                      console.log("My video is ended");
                                      this.triggerDeviceErrorAndEndSession("camera");
                                    },
                                    false,
                                );
                            })
                        }
                        //TP-5441
                        if (localStream.getAudioTracks().length > 0) {
                            localStream.getAudioTracks().forEach(track => {
                                track.addEventListener(
                                    "ended",
                                    (event) => {
                                      console.log("My audio is ended");
                                      this.triggerDeviceErrorAndEndSession("microphone");
                                    },
                                    false,
                                );
                            })
                        }
                        //TP-3679
                        if (myVideoState === true) localStream.getVideoTracks().forEach(track => track.enabled = !myVideoState);
                    //}
                    const myParticipant = {
                        //first_name: user.first_name,
                        //last_name: user.last_name,
                        first_name: "You",
                        last_name: "",
                        email: user.email,
                        isexpert: true,
                        disableAudio: (this.state.mutedAll) ? true : false,
                        disableMike: (this.state.headerMuteMike) ? true : false,
                        disableVideo: (this.checkIfExpertCameraEnabled() === true) ? (myVideoState) ? myVideoState : !this.checkIfExpertCameraEnabled() : true, //TP-3679
                        grayedVideo: (this.checkIfExpertCameraEnabled()) ? false : true,
                        isRegistered: !this.props.isGuest,
                        metaData: "", 
                        disableRemoteAudio: false,
                        joinTimeStamp: user.joinTimeStamp,
                        /* disableVideo: !this.checkIfExpertCameraEnabled(), */
                        isadmin: user.isadmin,
                        myPicture: user.myPicture,
                        screenshare_enabled: false,
                        stream_id: localStream.id,
                        streams: [localStream],
                        techSS_enabled: false,
                        torchActive: false,
                        torch_enabled: false,
                        userId: user.id,
                        zoomLevel: 1,
                        zoom_enabled: false,
                        isThisMe: true
                    }
                    //console.log(myParticipant);
                    this.setState((prevState) => ({
                        updatedParticipants: [...prevState.updatedParticipants, myParticipant]
                    }), () => {
                        //TP-5475
                        if (localStream !== '') {
                            localStream = this.enableAudioTrack(localStream); //TP-5475
                            this.setState({ localStream });
                        }
                    });
                })
            }
        }
    }

    render(){
        const {user, participants, connections, isParticipantChanged,/**TP-3573 */updatedParticipants, vdoheight, vdowidth, participantNames, bMaxChat, maxView, bAllChat, chatMsgInfoArr, maxDivParticipant, ssFrom, mode, selectedUser, groupInfo, changeHandIcon, userVideoCount, hideButton, expertShareScreenMaxDiv, /**TP-2292*/expertParticipantLeft, clickHandSignal, /**TP-1313 */ changedParticipant, /**TP-3677*/gridHandIconHide, screenCastStarted /**TP-2971*/} = this.state;
        const parentClass = maxView ? "no-gutters" : "";
        const maxDivRecordScreenColor = this.state.startRecording ? 'red' : 'black';
        const maxDivRecordScreenIcon = this.state.startRecording ? 'fa-stop' : 'fa-record-vinyl';
        const sideBar = document.getElementById('sideBar');
        let sideBarWidth = sideBar ? sideBar.offsetWidth : 0;
        const pausedTextContainerWidth = vdowidth + "px";
        const pausedTextleft = Math.floor((window.innerWidth - sideBarWidth - 20  - 412)/2) + "px";
        const buttonSize = (window.innerWidth > 1299) ? "fa-lg" : "fa-sm";
        const newProps = { maxView: this.state.maxView, participants, showMaxDivControls: this.state.showMaxDivControls,
            currentPrimaryExpert: this.state.currentPrimaryExpert, showHideDivIconAudioOnly: false,
            setPrimaryExpertIDApi: this.setPrimaryExpertIDApi, screen_recording_enabled: groupInfo.screen_recording_enabled, isMaxDivSS: this.state.isMaxDivSS, 
            isSideBarSS: this.state.isSideBarSS, startMultiExpertWaitTimer: this.startMultiExpertWaitTimer, 
            makeMeExpert: this.makeMeExpert, handleScreenShareIcons: this.handleScreenShareIcons, maxDivActionGoingOn: this.maxDivActionGoingOn, 
            aspectRatio: this.state.aspectRatio, initSideBarScreenShare: this.initSideBarScreenShare, enable_remote_capture: groupInfo.enable_remote_capture, 
            onClickTorchIcon: this.onClickTorchIcon, startRecording: this.state.startRecording, onClickRecordScreen: this.onClickRecordScreen, 
            stopRecordingCallback: this.stopRecordingCallback, onClickZoomIcon: this.onClickZoomIcon, onClickChatAll: this.onClickChatAll, 
            setRequestMaxViewControl: this.setRequestMaxViewControl, setClickHandSignal: this.setClickHandSignal, clickHandSignal, /*TP-1313*/ 
            changeHandIcon, groupInfo, expertShareScreenMaxDiv, bAllChat, /**TP-2292*/expertParticipantLeft, 
            setExpertParticipantLeft: this.setExpertParticipantLeft, /**T32-495*/ triggerMessage: this.triggerMessage /**TP-1677 */, 
            resetMaxUserZoom: this.state.resetMaxUserZoom, resetMaxUserTorch: this.state.resetMaxUserTorch, /**TP-2861 */ 
            rotateUserZoom: this.rotateUserZoom, /**TP-2933*/ isSmallFormFactorDevice: (this.state.hideButton === "d-none" ? true : false), 
            sessionType: groupInfo.session_type === "CM" || (groupInfo.session_type === "RM" && (this.checkIfExpertCameraEnabled() === true || this.isExpertVideo() === true)) ? true : false, /**TP-3419 & TP-3119*/
            gridHandIconHide, /**TP-3904*/ screenCastStarted, /**TP-2971*/ hideHandIcon: this.state.hideHandIcon, /**TP-4543*/ triggerCloseRC: this.state.triggerCloseRC, triggerCloseAnnotate: this.state.triggerCloseAnnotate,
            convertMeToPrimary: this.convertMeToPrimary, isGuest: this.props.isGuest, /**TP-5089*/ isSSOngoing: this.state.isSSOngoing, /**TP-5397*/ showIsMutedbutSpeaking: this.state.showIsMutedbutSpeaking
        }; 
        //const [closeIcon, canvas] = document.getElementsByClassName('asset-3d'); // TP-841
        let sliderRight, sliderHeight;
        if (maxView) {
            const paramElem = document.getElementById(maxDivParticipant.stream_id);
            if (paramElem && (paramElem.offsetHeight !== 0 || paramElem.style.height !== null)) { //TP-2446
                //TP-4648
                if (paramElem.offsetHeight !== 0) {
                    sliderRight = Math.floor((window.innerWidth - sideBar.offsetWidth - paramElem.offsetWidth + 40)/2 + 30 /**For the scrollbar */) + 'px';
                    sliderHeight = (window.innerWidth > 1299) ? Math.floor((paramElem.offsetHeight)* 3/5) : Math.floor((paramElem.offsetHeight)* 4/7);
                    //console.log("offsetheight sliderright --- ", sliderRight," sliderheight --", sliderHeight);
                } else if (paramElem.style.height !== "0px") {
                    //TP-4785
                    const tmpWidth = paramElem.style.width.replace('px',''); //TP-4687
                    const tmpHeight = paramElem.style.height.replace('px',''); //TP-4687
                    sliderRight = Math.floor((window.innerWidth - sideBar.offsetWidth - tmpWidth + 40)/2 + 30 /**For the scrollbar */) + 'px';
                    sliderHeight = (window.innerWidth > 1299) ? Math.floor((tmpHeight)* 3/5) : Math.floor((tmpHeight)* 4/7);
                    //console.log("style Height sliderright --- ", sliderRight," sliderheight --", sliderHeight);
                } else {
                    //TP-4785
                    const parentNode = document.getElementById("allVideos");
                    sliderRight = Math.floor((window.innerWidth - sideBar.offsetWidth - parentNode.offsetWidth + 40)/2 + 30 /**For the scrollbar */) + 'px';
                    sliderHeight = (window.innerWidth > 1299) ? Math.floor((parentNode.offsetHeight)* 3/5) : Math.floor((parentNode.offsetHeight)* 4/7);
                    //console.log("allVideos Height sliderright --- ", sliderRight," sliderheight --", sliderHeight); //TP-4687
                }
            } else {
                //TP-2446
                const parentNode = document.getElementById("allVideos");
                sliderRight = Math.floor((window.innerWidth - sideBar.offsetWidth - parentNode.offsetWidth + 40)/2 + 30 /**For the scrollbar */) + 'px';
                sliderHeight = (window.innerWidth > 1299) ? Math.floor((parentNode.offsetHeight)* 3/5) : Math.floor((parentNode.offsetHeight)* 4/7);
                //console.log("allVideos Height sliderright --- ", sliderRight," sliderheight --", sliderHeight); //TP-4687
            }
        } else {
            const paramElem = document.getElementById("allVideos");
            if (paramElem) {
                sliderHeight = (window.innerWidth > 1299) ? Math.floor((paramElem.offsetHeight)* 3/5) : Math.floor((paramElem.offsetHeight)* 4/7);
                sliderRight = "";
                //console.log("grid view allVideos Height sliderright --- ", sliderRight," sliderheight --", sliderHeight); //TP-4687
            }
        }
        let fileNameTopMargin = (window.innerWidth > 1299 && window.innerWidth <= 1559) ? "13%" : (window.innerWidth > 1599) ? "10%" : "15%";
        let uploadSpeedTopMargin = (window.innerWidth > 1559) ? (maxView) ? "7.5%" : "2%" : (maxView) ? "7.5%" : "2%"; //TP-3201
        if (this.props.isPDFFile === true) {
            fileNameTopMargin = (window.innerWidth > 1559) ? "12%" : "15%";
        } 

        const resizeMsgLeft = Math.ceil((window.innerWidth - 391)/2); /**TP-4956*/
        const myPicture = (user.myPicture && user.myPicture !== null && user.myPicture.includes("base64") === false && user.myPicture.includes("profile.png") === false /**TP-3795*/) ? 'data:image/jpg;base64,'+ user.myPicture: user.myPicture;
        //TP-3423 -- Bubble element info object
        let userdetails = null;
        if(this.state.maxDivParticipant ) {
            userdetails = {
                id: this.state.maxDivParticipant.userId,
                myPicture: this.state.maxDivParticipant.myPicture,
                first_name: this.state.maxDivParticipant.first_name,
                last_name: this.state.maxDivParticipant.last_name,
                email: this.state.maxDivParticipant.email,
                showOnlineIndicator: true,
                isexpert: this.state.maxDivParticipant.isexpert, /**TP-3657 */
                isadmin: this.state.maxDivParticipant.isadmin /**TP-3657 */
            }
        }
        const muteAudioSpeakingLeft = Math.floor((vdowidth - 350)/2) + "px";
        const muteAudioSpeakingTop = vdoheight >= 585 ? Math.floor((vdoheight * 80)/100) + "px" : vdoheight >= 420 ? Math.floor((vdoheight * 75)/100) + "px" : Math.floor((vdoheight * 68)/100) + "px"; //TP-5673
        //const chatHeight = Math.floor((window.innerHeight - (98+42+2)) * 86/100);
        const chatHeight = Math.floor(window.innerHeight - 2);
        //TP-3877 -- removing the pointer cursor for the Profile pictures
        return (
            <div className="d-flex">
                {/* MB2-431 */}
                <WebrtcErrorMessage showWebrtcErrorMessage={this.state.showWebrtcErrorMessage} text={this.state.webrtcErrorType} onClickClose={this.onCloseWebrtcMessage}/>
                <div className='flex-shrink-1' id="sideBar">
                    <WebrtcSideBar
                        {...this.props}
                        participants={this.state.participants}
                        fileShareMaxDiv={this.props.isOneToOneFileShare}
                        participantNames={participantNames}
                        isChanged={isParticipantChanged}
                        onlineUsers={this.state.onlineList}
                        technicianCount={this.state.technicianCount}
                        userVideoCount={this.state.userVideoCount}
                        toggleUserVideo={this.toggleUserVideo}
                        toggleUserSpeaker={this.toggleUserSpeaker}
                        toggleUserMike={this.toggleUserMike}
                        callAndInviteUser={this.callAndInviteUser}
                        muteHeaderMikeForAll={this.muteHeaderMikeForAll}
                        muteHeaderSpeakerOfAll={this.muteHeaderSpeakerOfAll}
                        disabledMutedAll={this.state.disabledMutedAll} // T32-413 - To disable the Side Bar Speaker Mute Icon
                        disableGridView={this.state.disableGridView}
                        showMaxDivControls={this.state.showMaxDivControls}
                        onClickMultiGridView={this.onClickMultiGridView}
                        isOngoingMaxDivFeatures={this.state.maxDivIconClicked}
                        openExpertChatWindow={this.onClickChatAll}
                        chatAll={bAllChat}
                        chatNameArr={this.state.chatUserNames}
                        chatUserCount={this.state.chatUserNames.length}
                        showHeaderActionButtons={this.state.showHeaderActionButtons}
                        openExpertScreenShare={this.initSideBarScreenShare}
                        screenShareMaxDiv={this.state.isMaxDivSS}
                        screenShareAll={this.state.isSideBarSS}
                        hideExpertSSAll={this.state.hideExpertSSAll}
                        canTurnOffUserVideo={this.state.groupInfo.can_turn_off_user_video} //TP-2343
                        inviteOnlineUser={groupInfo.invite_online_user}
                        isOnprem={true}
                        changeScreenShareIcon={this.state.changeScreenShareIcon} //TP-1042
                        addGuestUserList={this.addGuestUserList}
                        removeGuestUserList={this.removeGuestUserList}
                        guestUser={this.state.guestUser}
                        isSmallFormFactor={this.checkSmallScreenDevice()}
                        sessionType={groupInfo.session_type === "CM" || (groupInfo.session_type === "RM" && (this.checkIfExpertCameraEnabled() === true || this.isExpertVideo() === true))} /**	TP-3419 & TP-3119*/
                        isAccountLevelExpertVideoDisabled={this.props.enable_user_video === "my_camera_disabled" ? true : false} //TP-3690 & TP-3637
                        myPicture={myPicture}
                        triggerMyMike={this.state.triggerMyMike} /**TP-3573 */
                        triggerMySpeaker={this.state.triggerMySpeaker} /**TP-3573 */
                        maxView={this.state.maxView} //TP-3629
                        isSSOngoing={this.state.isSSOngoing} //TP-5397
                    />
                </div>
                <div className={"w-100 p-2 row min-vh-100 "+parentClass+" justify-content-center align-items-center"} id="allVideos">
                    { (!(/* this.props.isiOS && */ this.props.isSafari) && !this.props.isFirefox && groupInfo.screen_recording_enabled) ?
                        <>
                            <span style={/**TP-1870**/(window.innerWidth > 1299) ? {bottom: "2%", right: "0.5%"} : {bottom: "2%", right: "0.5%"}} className={!(this.props.isiOS && this.props.isSafari)? "fa-stack position-absolute rec-button "+buttonSize+" "+hideButton : "fa-stack position-absolute rec-button "+buttonSize}>                                        
                                <SessionIcon id="recordScreen" circleClass={(this.state.showMaxDivControls) ? this.state.startRecording ? "fas fa-circle fa-stack-2x Blink": "fas fa-circle fa-stack-2x" : "fas fa-circle font-awesome-grey fa-stack-2x"}
                                    iconClass={'fas ' + maxDivRecordScreenIcon + ' fa-stack-1x'} iconStyle={{ color: maxDivRecordScreenColor }} tooltipPlament="left"
                                    innerClass="tip-max no-pointer-events" tooltip={this.state.startRecording ? "session.stopRecordScreen" : "session.recordScreen"} tooltipStyle="Trans"
                                    onClickMethod = {(this.state.showMaxDivControls) ? () => this.onClickRecordScreen() : () => {return null}} isSmallFormFactorDevice={hideButton === "d-none" ? true : false} />
                            </span>
                            <span style={/**TP-1870**/(window.innerWidth > 1559) ? {bottom: "8%", right: "1.25%", fontSize: '1.5em'} : {bottom: "9%", right: "1.25%", fontSize: '1em'}} className={`position-absolute nameDiv rec Blink ${this.state.showRecordingBeacon ? '' : 'd-none'}`}>
                                    REC{/* &nbsp;&nbsp;<span style={{ fontSize: `1em`, color: `red` }}><i className="fas fa-circle Blink"></i></span> */}
                            </span> 
                        </>
                        :
                        ''
                    }
                    {/* <div className="position-absolute " style={{ fontSize: '20px', color: 'red', right: '5%', zIndex:1, top: '2%'}}>Calculated resolution of Video element: {vdowidth}px {vdoheight}px</div> */}  
                    <div className="position-absolute w-100 text-center nameDiv" style={{ color: 'white', zIndex:1, top: '10px'}}>
                        {/**TP-3201 */ this.state.maxDivParticipant && (!this.state.maxDivParticipant.disableVideo || (this.state.maxDivParticipant.isexpert && this.state.expertShareScreenMaxDiv ))?
                            /*TP-3497*/
                            (this.state.maxDivParticipant.myPicture !== undefined && this.state.maxDivParticipant.myPicture !== null && this.state.maxDivParticipant.myPicture !== "") ?
                                <span className='position-relative'>
                                    <img id={"logo-pic-"+userdetails.id} /* onClick={() => this.openBubble(userdetails)} */ className="session-profile-pic logo-profile-pic  " src={this.state.maxDivParticipant.myPicture} alt='profile_logo' />
                                    {/* <BubbleElement index={userdetails.id} row={userdetails} className={"high-zIndex d-none"} /**TP-3480 ></BubbleElement> */}
                                </span>
                                : 
                                <span className='position-relative'>
                                    <img id={"logo-pic-"+userdetails.id} /* onClick={() => this.openBubble(userdetails)}  */className="session-profile-pic logo-profile-pic  " src={"/profile.png"} alt='profile_logo' />
                                    {/* <BubbleElement index={userdetails.id} row={userdetails} className={"high-zIndex d-none"} /**TP-3480 ></BubbleElement> */}
                                </span>
                            :
                            /*TP-3497*/
                            this.state.maxDivParticipant && this.state.maxDivParticipant.isexpert && (this.state.maxDivParticipant.myPicture === undefined || this.state.maxDivParticipant.myPicture === null || this.state.maxDivParticipant.myPicture === "") ?
                                <span className='position-relative'>
                                    <img id={"logo-pic-"+userdetails.id} /* onClick={() => this.openBubble(userdetails)}  */className="session-profile-pic logo-profile-pic  " src={"/profile.png"} alt='profile_logo' />
                                    {/* <BubbleElement index={userdetails.id} row={userdetails} className={"high-zIndex d-none"} /**TP-3480 ></BubbleElement>*/}
                                </span>
                                :
                                ''
                        }
                        {this.state.maxDivParticipant /**TP-3657 */ ? 
                            this.state.maxDivParticipant.isexpert ?
                                <><i className="fas fa-asterisk fa-xs"></i>&nbsp;&nbsp;
                                    {this.state.maxDivParticipant.first_name + ' ' + this.state.maxDivParticipant.last_name}
                                </>
                            :
                            <>{this.state.maxDivParticipant.first_name + ' ' + this.state.maxDivParticipant.last_name}</>
                        : '' 
                        }
                    </div>
                    <div className="position-absolute w-100 text-center nameDiv" style={{ color: 'white', zIndex:1, top: uploadSpeedTopMargin}}>
                        {this.props.downLoadSpeed ? <>{this.props.downLoadSpeed+' MBPS'}&darr;:</> : ''}
                        {this.props.uploadSpeed ? <>{this.props.uploadSpeed+' MBPS'}&uarr;</> : ''}
                    </div>
                    { (this.props.isHeaderFileShare || this.props.isOneToOneFileShare || this.state.redrawFileShare) && !this.props.is3DFile && this.props.shareFileName ?
                        <div className="position-absolute w-100 file-name" style={{ color: 'white', zIndex: '1060', background: '#D3D3D300', top: fileNameTopMargin}}>
                            {this.props.shareFileName}
                        </div>
                        :
                        ''
                    }
                    { (this.props.isHeaderFileShare || this.props.isOneToOneFileShare || this.state.redrawFileShare) && !this.props.is3DFile ?
                        <div className="position-fixed" style={{ zIndex: '1060' }}>
                            <span className={(window.innerWidth > 1299) ? "fa-stack fa-lg close-modal ": "fa-stack fa-sm close-modal "} 
                                style={(maxView)? { right: sliderRight, left: "", top: fileNameTopMargin} : {right: sliderRight, left: "94%", top: fileNameTopMargin}}>
                                <SessionIcon id="closeAsset" circleClass="fas fa-circle fa-stack-2x"
                                    iconClass="fas fa-times fa-stack-1x close-file-icon" tooltipPlament="top"
                                    innerClass="tip-max no-pointer-events" tooltip="Close Asset" tooltipStyle="Trans"
                                    onClickMethod = {() => this.props.closeFileViewer(false)} />
                                {/* <a href="#!" id="closeAsset" onClick={() => this.props.closeFileViewer(false)}>
                                    <i className="fas fa-circle fa-stack-2x"></i>
                                    <i className="fas fa-times fa-stack-1x close-file-icon" ></i>                    
                                    <UncontrolledTooltip innerClassName="tip-max" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="closeAsset">                                            
                                        <Trans id="Close Asset" ></Trans>                                            
                                    </UncontrolledTooltip>
                                </a> */}
                            </span>
                        </div>
                        :
                        ''
                    }
                    { this.state.redrawFileShare && !this.props.isAudioFile ?
                        <div id="slider-parent">                                    
                            <RangeSlider startPosition={(this.props.opacityChanges !== "0.60" && this.props.opacityChanges !== '') ? (parseFloat(this.props.opacityChanges) * 100) : 60} 
                                minimum={0} maximum={100} setCurrentValue={this.props.setAssetOpacity} 
                                sliderTop= {(!maxView) ? "25%" : "20%"}
                                sliderLeft={(!maxView)? "94%": ""} sliderRight={sliderRight} 
                                sliderHeight={sliderHeight}
                            />
                        </div>
                        :
                            (this.state.showMaxDivControls === true && (this.props.isHeaderFileShare || this.props.isOneToOneFileShare) && !this.props.is3DFile && !this.props.isAudioFile) ?
                            <div id="slider-parent">                                    
                                <RangeSlider startPosition={(this.props.opacityChanges !== "0.60" && this.props.opacityChanges !== '') ? (parseFloat(this.props.opacityChanges) * 100) : 60} 
                                    minimum={0} maximum={100} setCurrentValue={this.props.setAssetOpacity} 
                                    sliderTop= {(!maxView) ? "25%" : "20%"}
                                    sliderLeft={(!maxView)? "94%": ""} sliderRight={sliderRight} 
                                    sliderHeight={sliderHeight}
                                />
                            </div>
                            :
                            ''
                    }

                    { !maxView ?
                        <div className="col-sm-12 text-center" style={{position: 'absolute', top: '100%'}}>
                            <StreamActions 
                                {...this.props} {...newProps}
                            />
                        </div>
                        :
                        ''
                    }
                    {/**TP-4956*/ maxView && this.state.isAtMaxWidth === false ?
                        /* UI for showing message "Please maximise your Browser window" 
                        if the user has resized his window in Max-view */
                        <div className='position-absolute nameDiv' style={{color: 'red', left: resizeMsgLeft+"px", bottom: "4.5%"}}>
                            <Trans id="Screen not Maximised"></Trans>
                        </div>
                        :
                        ''
                    }                        
                    {/* UI of message "Please maximise your Browser window" ends */} 
                    <div className="position-absolute nameDiv" style={maxView ? { left: '50%', zIndex:1, bottom: '2%'} : (window.innerHeight <= 420) ? {left: '51.5%', zIndex: 1, top: '2%' } : { left: '51.5%', zIndex: 1, top: '5%'}}>
                        <SessionTimer
                            credit_limits={this.state.groupInfo.credit_limits}
                            startTimer={this.state.startTimer}
                        />
                    </div>

                    {/*TP-3572*/maxView ? this.singleGridView() : 
                        (this.props.session_type === "RM" && this.checkIfExpertCameraEnabled() === true) ?
                        ( <>                            
                            <I18n>
                                {({i18n}) =>
                                    updatedParticipants && updatedParticipants.map((participant, index, updatedParticipants) => {
                                        const [videoConnection] = connections.filter(conn => conn.email === participant.email && participant.email !== user.email);
                                        return (
                                            <WebrtcVideoTile
                                                key={index}
                                                participants= {updatedParticipants}
                                                videoConnection={videoConnection}
                                                participant={participant}
                                                userVideoCount={userVideoCount}
                                                user = {user}
                                                index= {index}
                                                vdoheight= {vdoheight}
                                                vdowidth={vdowidth}
                                                onClickMaximise={this.onClickMaximise}
                                                onClickStopSessionUser={this.onClickStopSessionUser}
                                                onClickMuteSpeakerofOne={this.onClickMuteSpeakerofOne}
                                                onClickMuteMikeofOne={this.onClickMuteMikeofOne}
                                                ssFrom={ssFrom}
                                                currentPrimaryExpert={this.state.currentPrimaryExpert}
                                                expertCanDisconnectUser={this.state.groupInfo.can_disconnect_user} //TP-2343
                                                canTurnOffUserVideo={this.state.groupInfo.can_turn_off_user_video} //TP-2343
                                                toggleUserVideo={this.toggleUserVideo}
                                                toggleUserSpeaker={this.toggleUserSpeaker}
                                                toggleUserMike={this.toggleUserMike}
                                                toggleUserTorch={this.toggleUserTorch}
                                                rotateUserZoom={this.rotateUserZoom}
                                                redrawGrid={this.state.redrawGrid} //TP-1730
                                                isSmallFormFactor={this.checkSmallScreenDevice()}
                                                sessionType={groupInfo.session_type === "CM" || (groupInfo.session_type === "RM" && this.checkIfExpertCameraEnabled() === true)} /**TP-3119*/
                                                isAccountLevelExpertVideoDisabled={this.props.enable_user_video === "my_camera_disabled" ? true : false} //TP-3637
                                                openBubble={this.openBubble} //TP-3423
                                                triggerGridView={this.state.triggerGridView} /**TP-3611*/
                                                checkFlag={true} //	TP-3645
                                                changedParticipant={changedParticipant}//TP-3677
                                                showIsMutedbutSpeaking={this.state.showIsMutedbutSpeaking}
                                                triggerDeviceErrorAndEndSession={this.triggerDeviceErrorAndEndSession} //TP-5442
                                            />
                                        )
                                    })
                                }
                            </I18n>
                        </>
                        )  :
                        ( <>                            
                            <I18n>
                                {({i18n}) =>
                                    participants && participants.map((participant, index, participants) => {
                                        const [videoConnection] = connections.filter(conn => conn.email === participant.email);
                                        /**	TP-3645 */
                                        let checkFlag = false;
                                        if (participants.length === 2 && participants[0].isThisMe && participants[0].isThisMe === true) {
                                            checkFlag = true;
                                        }  
                                        return (
                                            <WebrtcVideoTile
                                                key={index}
                                                participants= {participants}
                                                videoConnection={videoConnection}
                                                participant={participant}
                                                userVideoCount={userVideoCount}
                                                user = {user}
                                                index= {index}
                                                vdoheight= {vdoheight}
                                                vdowidth={vdowidth}
                                                onClickMaximise={this.onClickMaximise}
                                                onClickStopSessionUser={this.onClickStopSessionUser}
                                                onClickMuteSpeakerofOne={this.onClickMuteSpeakerofOne}
                                                onClickMuteMikeofOne={this.onClickMuteMikeofOne}
                                                ssFrom={ssFrom}
                                                currentPrimaryExpert={this.state.currentPrimaryExpert}
                                                expertCanDisconnectUser={this.state.groupInfo.can_disconnect_user} //TP-2343
                                                canTurnOffUserVideo={this.state.groupInfo.can_turn_off_user_video} //TP-2343
                                                toggleUserVideo={this.toggleUserVideo}
                                                toggleUserSpeaker={this.toggleUserSpeaker}
                                                toggleUserMike={this.toggleUserMike}
                                                toggleUserTorch={this.toggleUserTorch}
                                                rotateUserZoom={this.rotateUserZoom}
                                                redrawGrid={this.state.redrawGrid} //TP-1730
                                                isSmallFormFactor={this.checkSmallScreenDevice()}
                                                sessionType={groupInfo.session_type === "CM" || (groupInfo.session_type === "RM" && this.checkIfExpertCameraEnabled() === true)} /**TP-3119*/
                                                isAccountLevelExpertVideoDisabled={this.props.enable_user_video === "my_camera_disabled" ? true : false} //TP-3637
                                                openBubble={this.openBubble} //TP-3423
                                                checkFlag={checkFlag}
                                                changedParticipant={changedParticipant}//TP-3677
                                                showIsMutedbutSpeaking={this.state.showIsMutedbutSpeaking}
                                                triggerDeviceErrorAndEndSession={this.triggerDeviceErrorAndEndSession} //TP-5442
                                            />
                                        )
                                    })
                                }
                            </I18n>
                        </>
                        )                                                                    
                    }
                    { /**TP-5681 TP-5551maxView && (this.state.showHideDivIconAudioOnly === true || this.state.isSilhouetteView === true) && !(maxDivParticipant.isexpert && !this.state.expertShareScreenMaxDiv) ? 
                        this.state.showIsMutedbutSpeaking === true ?
                            <span style={{width: pausedTextContainerWidth}}><span className={'paused-text-other' /* : 'muted-text' } style={{marginLeft: muteAudioSpeakingLeft}}><Trans>session.mutedbuttalking</Trans></span></span>
                            :
                            ''
                        :
                        ''
                    */}
                    {/**TP-3572 */ !(this.props.session_type === "RM" && this.checkIfExpertCameraEnabled() === true) ?
                        <>
                            <video ref={this.localVideoRef} autoPlay muted className="d-none"></video>
                        </>
                        :
                        ''
                    }
                </div>
                {/*TZ-56 <NewChat
                    recipientUser={maxDivParticipant}
                    chatMsgInfoArr={this.state.chatMsgInfoArr}
                    onSendChatMsg={this.sendChatMsg}
                    bPrivate={bMaxChat}
                    bShowChat={bMaxChat || bAllChat}
                    elemId={maxDivParticipant === '' ? maxDivParticipant : maxDivParticipant.email}
                    recipientname={maxDivParticipant === '' ? null : maxDivParticipant.firstName+" "+maxDivParticipant.lastName}
                    recipientImage={maxDivParticipant === '' ? null : maxDivParticipant.myPicture}
                    startTimer={false}
                    updateChatFlags={this.updateChatFlags}
                    chatHeight={chatHeight}
                    isGroupChat={bMaxChat}
                    editChatMsg={this.editChatMsg}
                    deleteChatMsg={this.deleteChatMsg}
                    replyChatMsg={this.replyChatMsg}
                    isSessionChat={true}
                ></NewChat> */}
                <SessionChat 
                    onSendChatMsg={this.sendChatMsg}
                    bPrivate={bMaxChat}
                    bShowChat={bMaxChat || bAllChat}
                    chatMsgInfoArr={chatMsgInfoArr}
                    elemId={maxDivParticipant.email}
                    startTimer={this.state.startChatTimer}
                    updateChatFlags={this.updateChatFlags}
                />
                {this.renderSwitch(mode, selectedUser)}
                <FlashMessage flashMsgText={this.state.flashMsgText} flashValues={this.state.flashValues} showMessage={this.state.showFlashMessage} updateShowMessage={this.updateShowMessage}
                    flashLeft={this.state.flashLeft} flashTop={this.state.flashTop} isTimerEnabled={this.state.flashTimer} /**TP-5681*/
                />
            </div>
        )
    }
}

export default WebrtcExpert;

const OtherParticipantAudioTrack = ({otherParticipants}) => {
    otherParticipants.length>0 && otherParticipants.forEach(participant => {
        if (participant.isThisMe === true) {
            participant.streams && participant.streams.forEach(stream => {
                //TP-5442
                if (stream.getVideoTracks().length > 0) {
                    stream.getVideoTracks().forEach(track => {
                        track.addEventListener(
                            "ended",
                            (event) => {
                              console.log("My video is ended");
                              this.props.triggerDeviceErrorAndEndSession("camera");
                            },
                            false,
                        );
                    })
                }
                //TP-5441
                if (stream.getAudioTracks().length > 0) {
                    stream.getAudioTracks().forEach(track => {
                        track.addEventListener(
                            "ended",
                            (event) => {
                              console.log("My audio is ended");
                              this.props.triggerDeviceErrorAndEndSession("microphone");
                            },
                            false,
                        );
                    })
                }
            })
        }
    })
    let videoElements = otherParticipants.map((participant) =>
                            <video id={participant && participant.stream_id} key={participant.userId} autoPlay className="d-none" />
                        );

    return videoElements;
}